import styled from 'styled-components';
import {
	BUTTON_PRIMARY_GREEN,
	BUTTON_SECONDARY,
	FILTER_MAIN,
	HIGHLIGHT_COLOR,
	PRIMARY_DARK_GRAY,
	SECONDARY_MAIN,
	TERTIARY_MAIN,
	TEXT_DARK,
	TEXT_GRAYED_OUT,
	TEXT_GREEN,
	TEXT_SECONDARY,
	TEXT_WHITE,
	SWITCH_BUTTON_OFF,
	PRIMARY_MAIN,
} from '../constants/colors';
import ScrollbarArrowUp from '../images/scrollbar/scrollbar-arrow-up.png';
import ScrollbarArrowDown from '../images/scrollbar/scrollbar-arrow-down.png';

interface PropsInterface {
	fontSize?: string;
	color?: string;
	marginTop?: string;
	marginBottom?: string;
	marginLeft?: string;
	gap?: string;
	isResponsive?: boolean;
	display?: string;
	justify?: string;
	hasCardTag?: boolean | undefined;
}

export const ContentHeader = styled.div`
	display: flex;
	gap: 30px;

	@media screen and (max-width: 992px) {
		display: block;
	}

	.close-icon{
		padding-top: 5px;
		padding-bottom: 10px;
		margin-left: 90%;
		cursor: pointer;
	}

	.close-icon-container{
		width: 100%;
		height: 30px;
		margin-bottom: 10px;
		background-color: ${PRIMARY_MAIN};
		cursor: pointer;
`;

export const MainModalView = styled.div`
	

	

	.close-icon{
		padding-top: 5px;
		padding-bottom: 10px;
		margin-left: 90%;
		cursor: pointer;

		@media screen and (min-width: 992px) {
			display: none;
		}
	}

	.close-icon-container{
		width: 100%;
		height: 30px;
		margin-bottom: 10px;
		background-color: ${PRIMARY_MAIN};
		cursor: pointer;

		@media screen and (min-width: 992px) {
			display: none;
		}
`;

export const ImageContainer = styled.div<PropsInterface>`
	margin: auto;
	border-radius: 10px;

	#card-tag {
		cursor: pointer;
		display: ${(props) => (props.hasCardTag ? 'flex' : 'none')};
		position: absolute;
		align-items: center;
		justify-content: center;
		width: 52px;
		height: 28px;
		background-color: white;
		border-radius: 5px;
		margin-top: 10px;
		margin-left: 10px;
		color: ${TEXT_DARK};
		font-size: 12px;
		font-weight: 600;
	}

	img {
		height: 100%;
		width: 100%;
		border-radius: 10px;
	}
	video {
		height: 100%;
		width: 100%;
		border-radius: 10px;
	}
`;

export const TransactionTypeActivityDiv = styled.div`
	display: grid;
	grid-template-columns: 28px 50%;
	text-align: left;

	img {
		margin-top: 2.5px;
	}
`;

export const DetailGridDiv = styled.div<PropsInterface>`
	display: grid;
	grid-template-columns: 30% 40% 20%;
	text-items: left;
	gap: ${(props) => props.gap};
	font-size: ${(props) => props.fontSize};
	color: ${(props) => props.color};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};

	.green {
		color: ${TEXT_GREEN};
	}

	#badges-div {
		margin-left: 8%;
	}
	.rank{
		margin-left: 18%;
	}

	a:link {
		text-decoration: none;
	}

	@media screen and (max-width: 992px) {
		display: ${(props) => (props.isResponsive ? 'block' : 'flex')};

		#buy {
			margin-top: 20px;
			width: 100%;
			height: 40px;
			font-size: 13px;
		}

		#offer {
			margin-top: 10px;
			margin-bottom: 30px;
			width: 100%;
			height: 40px;
			font-size: 13px;
		}
	}
`;

export const DetailFlexDiv = styled.div<PropsInterface>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justify};
	gap: ${(props) => props.gap};
	font-size: ${(props) => props.fontSize};
	color: ${(props) => props.color};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
	.green {
		color: ${TEXT_GREEN};
		cursor: pointer;
	}

	.long {
		min-width: 250px;
	}

	a:link {
		text-decoration: none;
	}

	#last-sale-usd {
		font-size: 12px;
		text-align: left;
		padding-left: 3px;
		color: ${TEXT_GRAYED_OUT};
	}

	@media screen and (max-width: 992px) {
		display: ${(props) => (props.isResponsive ? 'block' : 'flex')};

		#buy {
			margin-top: 20px;
			width: 100%;
			height: 40px;
			font-size: 13px;
		}

		#offer {
			margin-top: 10px;
			margin-bottom: 30px;
			width: 100%;
			height: 40px;
			font-size: 13px;
		}
	}
`;

DetailFlexDiv.defaultProps = {
	fontSize: '13px',
	color: TEXT_WHITE,
	marginTop: '0px',
	marginBottom: '0px',
	marginLeft: '0px',
	gap: '0px',
	isResponsive: false,
};

export const PropertyButtonsContainer = styled.div<PropsInterface>`
	cursor: pointer;

	p {
		margin-bottom: 9px;
		color: ${(props) =>
			props.display === 'block' ? TEXT_WHITE : TEXT_GRAYED_OUT};
	}

	div {
		display: ${(props) => props.display};
		background-color: ${BUTTON_PRIMARY_GREEN};
		height: 3px;

		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
`;

PropertyButtonsContainer.defaultProps = {
	display: 'none',
};

export const TraitsContainer = styled.div`
	display: grid;
	gap: 10px;
	margin-top: 1px;
	margin-bottom: 10px;

	grid-template-columns: repeat(4, 1fr);

	@media screen and (max-width: 992px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (max-width: 440px) {
		grid-template-columns: 1fr;
	}
`;

export const TraitDiv = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${SECONDARY_MAIN};
	width: 180px;
	height: 100px;
	border-radius: 8px;
	justify-self: center;
	text-align: center;

	:hover {
		border: solid 0.5px ${TEXT_SECONDARY};
	}

	p {
		margin: 0;
		font-size: 13px;
	}

	.type {
		color: ${TEXT_SECONDARY};
		font-size: 9px;
		text-transform: uppercase;
	}

	.value {
		text-transform: capitalize;
	}

	@media screen and (max-width: 440px) {
		width: 90%;
	}

	.points {
		font-size: 9px;
		color: ${BUTTON_PRIMARY_GREEN};
	}
`;

export const Ammolite = styled.img<PropsInterface>`
	margin-top: 0px;
	padding-right: 5px;
`;

export const BadgeContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	row-gap: 5px;
`;

export const BadgeIcon = styled.img<PropsInterface>`
	margin-left: 7px;
	margin-top: -5px;
`;

export const Badge = styled.div`
	max-width: 28px;
	min-width: 30px;
	height: 28px;
	background: #131b21;
	border-radius: 6px;
	margin-right: 6px;
	:hover {
		background-color: ${PRIMARY_DARK_GRAY};
	}
`;

export const ActivityDiv = styled.div`
	margin-top: 15px;
	font-size: 12px;
	height: 45%;
	overflow: auto;
	
	.event{
		text-align: left;
		padding-top: 20px;
		width: 100px;
		height: 60px;
		background-color: ${HIGHLIGHT_COLOR};
	}
	.event b {
		padding-left: 10px;
	}

	table {
		width: 100%;
	}

	td {
		padding: 15px;

		img {
			margin-right: 5px;
		}
	}

	thead {
		margin-top: -5px;
	}

	@media screen and (min-width: 310px) {
		thead,
		tbody tr {
			display: block;
		}
		thead td {
			display: none;
			margin-top: -5px;
		}
		.activity-table-mobile {
			display: initial;
		}
		.activity-table-desktop {
			display: none;
		}
	}

	@media screen and (min-width: 990px) {
		thead,
		tbody tr {
			display: table;
		}
		thead td {
			display: initial;
			margin-left: 2%;
			margin-right: 5%;
		}
		.activity-table-mobile {
			display: none;
		}
		.activity-table-desktop {
			display: initial;
		}
	}

	thead,
	tbody tr {	
		width: 100%;
		table-layout: fixed;
	}

	tr {
		background-color: ${SWITCH_BUTTON_OFF};
	}

	tr:nth-child(even) {
		background-color: ${SWITCH_BUTTON_OFF};
	}

	tbody {
		::-webkit-scrollbar {
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-track {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-corner {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-thumb {
			background-color: ${BUTTON_SECONDARY};
			border-radius: 2px;
		}

		::-webkit-scrollbar-button:single-button {
			background-color: ${TERTIARY_MAIN};
			display: block;
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-button:vertical:increment {
			background-image: url(${ScrollbarArrowDown});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}

		::-webkit-scrollbar-button:vertical:decrement {
			background-image: url(${ScrollbarArrowUp});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	::-webkit-scrollbar {
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background-color: ${FILTER_MAIN};
	}

	::-webkit-scrollbar-corner {
		background-color: ${FILTER_MAIN};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${BUTTON_SECONDARY};
		border-radius: 2px;
	}

	::-webkit-scrollbar-button:single-button {
		background-color: ${TERTIARY_MAIN};
		display: block;
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-button:vertical:increment {
		background-image: url(${ScrollbarArrowDown});
		background-size: 5px 5px;
		background-repeat: no-repeat;
		background-position: center;
	}

	::-webkit-scrollbar-button:vertical:decrement {
		background-image: url(${ScrollbarArrowUp});
		background-size: 5px 5px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.green {
		color: ${TEXT_GREEN};
	}
`;


export const PriceInput = styled.div`
	width: 134px;
	height: 34px;
	background-color: ${SECONDARY_MAIN};
	border-radius: 8px;

	input {
		width: 300px;
		height: 100%;
		background-color: ${SECONDARY_MAIN};
		border: 1px;
		border-radius: 8px;
		color: ${TEXT_WHITE};
		padding-top: 7px;
		padding-bottom: 7px;
		padding-left: 10px;

		::-webkit-outer-spin-button,
		::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		-moz-appearance: textfield;

		::placeholder {
			color: ${TEXT_GRAYED_OUT};
			font-style: italic;
		}
	}
`;
