import React from 'react';
import { useSelector } from 'react-redux';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { GenericError } from '../../../../resources/enums/GenericError';
import { tokenParams } from '../../../../resources/constants/token';
import { SiteRoutes } from '../../../../resources/enums/SiteRoutes';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { CustomNavLink } from '../../../../resources/styles/Router.styles';
import Ammolite from '../../../../services/Ammolite';
import Wallet from '../../../../services/Wallet';
import SweetAlert from '../../../SweetAlert';
import config from '../../../../config';

interface PropsInterface {
	wallet: Wallet | null;
	ammolite: Ammolite | undefined;
	handleSidebar: () => void;
}

const SidebarUserDropdown = (props: PropsInterface): JSX.Element => {
	const { wallet, ammolite, handleSidebar } = props;
	const { discord } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);

	const handleAddTokenToMetaMask = () => {
		wallet?.library.provider.request({
			method: 'wallet_watchAsset',
			params: tokenParams,
		});
	};

	const handleApproveSpend = async (spenderAddress: string) => {
		SweetAlert({
			showConfirmButton: false,
			title: 'Approving Store',
			text: 'Confirm the MetaMask transaction and wait. The transaction will run in the background and confirmation will be shown.',
		});
		const result = await ammolite?.approveSpender(spenderAddress);
		SweetAlert({
			title: `Approval ${result ? 'Successful' : 'Failed'}`,
			text: result
				? 'You have now approve spending for the Hideout Store'
				: GenericError.ETHERSCAN_TRANSACTION_FAILED,
		});
	};

	return (
		<DropdownMenu>
			<DropdownItem>
				<p className="sidebar-dropdown" onClick={handleAddTokenToMetaMask}>
					Import Token
				</p>
			</DropdownItem>
			<hr></hr>
			<DropdownItem>
				<p
					className="sidebar-dropdown"
					onClick={() => handleApproveSpend(config.STORE_CONTRACT_ADDRESS)}
				>
					Set Store Limit
				</p>
			</DropdownItem>
			<hr></hr>
			<DropdownItem>
				<p
					className="sidebar-dropdown"
					onClick={() => handleApproveSpend(config.LOTTERY_CONTRACT_ADDRESS)}
				>
					Set Animus Limit
				</p>
			</DropdownItem>
			<hr></hr>
			<DropdownItem id="connect-discord">
				<p
					className="sidebar-dropdown"
					onClick={() => discord?.connectDiscord()}
				>
					Connect Discord
				</p>
			</DropdownItem>
			<hr></hr>
			<DropdownItem>
				<CustomNavLink to={SiteRoutes.changeWallet} onClick={handleSidebar}>
					Change wallet
				</CustomNavLink>
			</DropdownItem>
		</DropdownMenu>
	);
};

export default SidebarUserDropdown;
