import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { processSelectedTraits } from '../../../../helpers/gallery';
import { setSelectedTraits } from '../../../../redux/actions/filter';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import TraitObjectInterface from '../../../../resources/interfaces/TraitObjectInterface';
import TraitValueInterface from '../../../../resources/interfaces/TraitValueInterface';
import { FilterCheckboxWrapper } from '../../../../resources/styles/FilterPanel.styles';

interface Props {
	traitValues: TraitValueInterface[];
	traitType: string;
	inputSearch: string;
}

const TraitFilterContent = (props: Props): JSX.Element => {
	const { traitValues, traitType, inputSearch } = props;

	const { selectedTraits } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);
	const dispatch = useDispatch();

	const handleSetFilter = (trait_type: string, trait_name: string) => {
		let newSelectedTraits: TraitObjectInterface[] = selectedTraits;
		newSelectedTraits = processSelectedTraits(newSelectedTraits, {
			traitType: trait_type,
			value: trait_name,
		});
		dispatch(setSelectedTraits(newSelectedTraits));
	};

	const replaceAll = (text: string, find: string, replace: string) =>
		text.replace(new RegExp(find, 'g'), replace);

	return (
		<>
			{traitValues
				.filter((traitValue) => {
					const { name, trait_name } = traitValue;
					const traitName = name ? name : trait_name;
					const traitNameFormatted = replaceAll(
						traitName,
						'_',
						' '
					).toLowerCase();
					return (
						traitNameFormatted.search(inputSearch.toLowerCase().trim()) !== -1
					);
				})
				.map((traitValue, idx) => {
					const { trait_name, name, display_name, count } = traitValue;
					const traitName = name ? name : trait_name;
					return (
						<div
							key={idx}
							onClick={() => handleSetFilter(traitType, traitName)}
						>
							<FilterCheckboxWrapper>
								<input
									type="checkbox"
									id="check"
									value={traitName}
									readOnly
									checked={selectedTraits.some((traitCategory) =>
										traitCategory.values.some((value) => value === traitName)
									)}
								/>
								<label id="label">{display_name}</label>
								<label id="trait">{count}</label>
							</FilterCheckboxWrapper>
							<br />
						</div>
					);
				})}
		</>
	);
};

export default TraitFilterContent;
