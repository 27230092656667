import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContainer from './resources/styles/App.style';
import Navigationbar from './components/Navigationbar/Navigationbar';
import { useWeb3React } from '@web3-react/core';
import ConnectWalletPage from './pages/ConnectWalletPage/ConnectWalletPage';
import { authenticateWallet, resetReduxState } from './redux/actions/wallet';
import { fetchSelectedWalletOption } from './helpers/session';
import DiscoverPage from './pages/DiscoverPage/DiscoverPage';
import { ConnectorNames } from './resources/enums/ConnectorNames';
import DaycarePage from './pages/DaycarePage/DaycarePage';
import HideoutPage from './pages/HideoutPage/HideoutPage';
import StorePage from './pages/StorePage/StorePage';
import LandingPage from './pages/LandingPage';
import { SidebarContainer } from './resources/styles/Sidebar.style';
import Sidebar from './components/Navigationbar/Sidebar/Sidebar';
import ChangeWallet from './pages/ConnectWalletPage/ChangeWallet';
import ReleaseNotesPage from './pages/ReleaseNotesPage/ReleaseNotesPage';
import DiscordPage from './pages/DiscordPage/DiscordPage';
import { connectorsByName } from './config/connectors';
import { setAuthState } from './redux/actions/auth';
import RootReducerStateInterface from './resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import FirebaseHandler from './FirebaseHandler';
import RafflePage from './pages/RafflePage/RafflePage';
import BreedingPage from './pages/BreedingPage/BreedingPage';
import ProfilePage from './pages/Profile/ProfilePage';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SiteRoutes } from './resources/enums/SiteRoutes';
import { getUserStats } from './firebase/functions/user';
import { setStatsState } from './redux/actions/stats';
import { Collections } from './resources/enums/Collections';
import { ButtonStatus } from './resources/enums/ProfileButton';

declare global {
	interface Window {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ethereum: any;
	}
}

const App = (): JSX.Element => {
	const dispatch = useDispatch();
	const web3Context = useWeb3React();

	const { active, account, activate, connector, library, chainId } =
		web3Context;
	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);
	const isFetched = useSelector(
		(state: RootReducerStateInterface) => state.stats.isFetched
	);
	const isConnected = useSelector(
		(state: RootReducerStateInterface) => state.stats
	);
	const { id } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);

	const handleFetchUserStats = async () => {
		try {
			if (!isFetched) {
				const userStats = await getUserStats();
				dispatch(setStatsState({ ...userStats }));
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const activateAuthorizedWallet = async () => {
			const selectedWalletOption =
				fetchSelectedWalletOption() as ConnectorNames;
			const connector = connectorsByName[selectedWalletOption];
			await activate(connector);
		};

		if (account && user?.uid === account) {
			dispatch(setAuthState(user, active, account));
		} else {
			dispatch(setAuthState(user, false, ''));
			dispatch(resetReduxState());
		}

		if (user?.uid === account && connector && account && library) {
			dispatch(authenticateWallet(connector, account, library));
		} else if (user) {
			activateAuthorizedWallet();
		}
	}, [account, activate, connector, library, user]);

	useEffect(() => {
		handleFetchUserStats();
	}, [isConnected]);

	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

	const sidebarRef = useRef() as React.RefObject<HTMLDivElement>;
	const handleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};
	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		if (!sidebarRef?.current?.contains(e.target as Node)) {
			if (isSidebarOpen) setIsSidebarOpen(false);
		}
	};

	return (
		<FirebaseHandler>
			<AppContainer onClick={(e) => handleClick(e)}>
				<Navigationbar handleSidebar={handleSidebar} />
				<SidebarContainer
					isSidebarOpen={isSidebarOpen}
					ref={sidebarRef}
					chainId={chainId}
					isDiscordConnected={!!id}
				>
					<Sidebar handleSidebar={handleSidebar} />
				</SidebarContainer>
				<Routes>
					<Route
						element={<ConnectWalletPage />}
						path={SiteRoutes.connectWallet}
					/>
					<Route
						element={<ReleaseNotesPage />}
						path={SiteRoutes.releaseNotes}
					/>
					<Route element={<BreedingPage />} path={SiteRoutes.breeding} />
					<Route element={<HideoutPage />} path={SiteRoutes.hideout} />
					<Route element={<DaycarePage />} path={SiteRoutes.daycare} />
					<Route element={<StorePage />} path={SiteRoutes.store} />
					<Route
						element={<ConnectWalletPage />}
						path={SiteRoutes.connectWallet}
					/>
					<Route element={<ChangeWallet />} path={SiteRoutes.changeWallet} />
					<Route
						element={
							<Navigate
								to={`${SiteRoutes.discover
									}/${Collections.skvllpvnkz_hideout.replace('_', '-')}`}
								replace
							/>
						}
						path={SiteRoutes.discover}
					/>
					<Route
						element={
							<Navigate
								to={`${SiteRoutes.discover
									}/${Collections.skvllpvnkz_hideout.replace('_', '-')}`}
								replace
							/>
						}
						path={SiteRoutes.discover}
					/>
					<Route
						element={<DiscoverPage />}
						path={`${SiteRoutes.discover}/:collectionName`}
					/>

					<Route
						element={<DiscoverPage />}
						path={`${SiteRoutes.discover}/:collectionName/:tokenId`}
					/>

					{/* <Route
						element={<ProfilePage />}
						path={`${SiteRoutes.profile}/:address`}
					/>
					<Route
						element={<ProfilePage />}
						path={`${SiteRoutes.profile}/:address/:collectionName`}
					/> */}

					<Route
						element={
							<Navigate to={`${SiteRoutes.profile}/collections/`} replace />
						}
						path={SiteRoutes.profile}
					></Route>
					<Route
						element={<ProfilePage activeTab={ButtonStatus.FIRST_BUTTON} />}
						path={`${SiteRoutes.profile}/collections`}
					/>
					<Route
						element={<ProfilePage activeTab={ButtonStatus.FIRST_BUTTON} />}
						path={`${SiteRoutes.profile}/collections/:collectionName`}
					/>
					<Route
						element={<ProfilePage activeTab={ButtonStatus.FIRST_BUTTON} />}
						path={`${SiteRoutes.profile}/collections/:collectionName/:tokenId`}
					/>

					<Route
						element={<ProfilePage activeTab={ButtonStatus.SECOND_BUTTON} />}
						path={`${SiteRoutes.profile}/animus`}
					/>
					<Route
						element={<ProfilePage activeTab={ButtonStatus.THIRD_BUTTON} />}
						path={`${SiteRoutes.profile}/purchases`}
					/>

					<Route element={<DiscordPage />} path={SiteRoutes.discord} />
					<Route element={<RafflePage />} path={SiteRoutes.raffle} />
					<Route element={<LandingPage />} path="*" />
				</Routes>
			</AppContainer>
		</FirebaseHandler>
	);
};

export default App;
