import React, { useState, useEffect, useCallback } from 'react';
import { ProfileSection } from '../../resources/styles/Profile.styles';
import CollectionSection from './components/CollectionSection';
import Animus from './components/Animus';
import Purchases from './components/Purchases';
import { ButtonStatus } from '../../resources/enums/ProfileButton';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { handleSearchByOwner } from '../../helpers/gallery';
import { useDispatch, useSelector } from 'react-redux';
import { SiteRoutes } from '../../resources/enums/SiteRoutes';
import { useNavigate } from 'react-router-dom';
import { ProfileTabs } from '../../resources/enums/ProfileTabs';
import PageHeaderContent from '../../components/PageHeaderContent';
import { PageContainer } from '../../resources/styles/Page.styles';
import { formatDate } from '../../helpers/date';

interface Props {
	activeTab: ButtonStatus;
}
const ProfilePage = (props: Props): JSX.Element => {
	const { activeTab } = props;
	const [activeButton, setActiveButton] = useState(activeTab);
	const [areStatsLoading, setAreStatsLoading] = useState(true);

	const { stats, isFetched } = useSelector(
		(state: RootReducerStateInterface) => state.stats
	);

	const { username } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);

	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);

	const { selectedCollection } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);

	const { address } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { transactionCount, totalGas, totalVolume, averageValue } = stats;

	const gweiToEth = useCallback(
		(value: number | undefined) => (value ? value / 100000000 : 0),
		[]
	);

	const memberSince = () =>
		formatDate(new Date(String(user?.metadata.creationTime)));

	const tabIndex = Object.values(ProfileTabs);

	const handleClick = (active: number) => {
		setActiveButton(active);
		navigate(`${SiteRoutes.profile}/${tabIndex[active]}`);
		active === 0 &&
			handleSearchByOwner(dispatch, selectedCollection, address, navigate);
	};

	//add ?.toFixed(3) to volume

	const statsData = {
		transactionCount: {
			title: 'Trade Count',
			value: transactionCount,
			ethIcon: false,
			toolTip: 'The total amount of transaction',
		},
		totalGas: {
			title: 'Total Gas',
			value: gweiToEth(totalGas).toFixed(4),
			ethIcon: true,
			toolTip: 'Total Gas value',
		},
		totalVolume: {
			title: 'Total Volume',
			value: totalVolume?.toFixed(4),
			ethIcon: true,
			toolTip: 'Total Volume used',
		},
		averageValue: {
			title: 'Collection Value',
			value: averageValue?.toFixed(3),
			ethIcon: true,
			toolTip: 'Collection value',
		},
	};

	useEffect(() => {
		isFetched && setAreStatsLoading(false);
	}, [isFetched]);

	const pageHeader = {
		img: 'imageurl',
		user: user,
		title: username,
		subtitle: address,
		textAlign: 'left',
		secondarySubtitle: memberSince(),
	};

	const Stats = {
		areStatsLoading,
		statsData,
	};

	const tabs = {
		activeTab: ButtonStatus.FIRST_BUTTON,
		buttonParameters: ProfileTabs,
		handleContentChange: handleClick,
	};

	return (
		<PageContainer noBackgroundImage>
			<PageHeaderContent pageHeader={pageHeader} stats={Stats} tabs={tabs} />
			<ProfileSection>
				{activeButton === ButtonStatus.FIRST_BUTTON && <CollectionSection />}
				{activeButton === ButtonStatus.SECOND_BUTTON && <Animus />}
				{activeButton === ButtonStatus.THIRD_BUTTON && <Purchases />}
			</ProfileSection>
		</PageContainer>
	);
};

export default ProfilePage;
