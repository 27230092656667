import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setCheckInState,
	setNotStakedDaycareRewardState,
} from '../../../../redux/actions/rewardsAndStaking';
import {
	BUTTON_PRIMARY_PURPLE,
	BUTTON_PRIMARY_PURPLE_DARK,
	BUTTON_PRIMARY_PURPLE_LIGHT,
	BUTTON_PRIMARY_MAIN,
	HIGHLIGHT_COLOR,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
} from '../../../../resources/constants/colors';
import { TransactionState } from '../../../../resources/enums/states';
import NFTAssetInterface from '../../../../resources/interfaces/NFTAssetInterface';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import {
	CheckInAndOutDiv,
	NFTVerticalScrollDiv,
	StakeContainer,
} from '../../../../resources/styles/StakeNFT.styles';
import Skvllbabiez from '../../../../services/Skvllbabiez';
import ChooesBabiezContent from './ChooesBabiezContent';

interface PropsInterface {
	skvllbabiezList: NFTAssetInterface[] | undefined;
	skvllbabiezContract: Skvllbabiez | undefined;
}

const ChooseBabiezToStake = (props: PropsInterface): JSX.Element => {
	const dispatch = useDispatch();

	const { skvllbabiezList, skvllbabiezContract } = props;
	const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(true);
	const [hasNoBabiez, setHasNoBabiez] = useState(false);
	const [selectedBabyIds, setSelectedBabyIds] = useState<number[]>([]);

	const { skvllbabiez } = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez
	);
	const { daycare, checkInState, daycareRewardState, isDaycareOpen } =
		useSelector(
			(state: RootReducerStateInterface) => state.rewards_and_staking
		);
	const { address } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);
	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite
	);
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);

	useEffect(() => {
		if (skvllbabiezList && skvllbabiezContract) {
			if (skvllbabiezList.length < 1) {
				setHasNoBabiez(true);
				setIsAllImagesLoaded(true);
			}
		}
	}, [skvllbabiezContract, skvllbabiezList]);

	const handleCheckIn = async () => {
		const idList = selectedBabyIds;
		dispatch(setCheckInState(TransactionState.IN_PROGRESS));
		setSelectedBabyIds([]);
		const isApproved = await skvllbabiez?.isApprovedForAll(address);
		if (!isApproved && idList.length === 1) {
			skvllbabiez?.stakeOneBaby(address, idList[0]);
			return;
		}
		if (!isApproved) {
			dispatch(setCheckInState(TransactionState.APPROVE_OPERATOR_IN_PROGRESS));
			skvllbabiez?.setApprovalForAll(true, handleCheckIn);
		}
		if (daycare && isApproved) {
			daycare.checkInSkvllbabiez(idList);
		}
	};

	const handleCollectReward = () => {
		dispatch(setNotStakedDaycareRewardState(TransactionState.IN_PROGRESS));
		const idList = selectedBabyIds;
		setSelectedBabyIds([]);
		if (ammolite) {
			daycare?.collectBatchBabyReward(idList, false);
		}
	};

	const handleSelectAll = () => {
		if (skvllbabiezList) {
			if (
				selectedBabyIds.length === skvllbabiezList.length ||
				selectedBabyIds.length >= 1
			) {
				setSelectedBabyIds([]);
			} else {
				const allIds = skvllbabiezList.map((baby) => baby.id);
				setSelectedBabyIds(allIds);
			}
		}
	};

	const alignment =
		!isAllImagesLoaded || hasNoBabiez || !isAuthenticated
			? 'center'
			: 'flex-start';

	const isButtonDisabled =
		checkInState !== TransactionState.DEFAULT ||
		daycareRewardState.notStakedDaycare !== TransactionState.DEFAULT ||
		!isDaycareOpen;

	const justifyAlignment =
		isAllImagesLoaded && !hasNoBabiez && isAuthenticated
			? 'flex-start'
			: 'center';

	return (
		<StakeContainer>
			<div className="mobile-select-all">
				<p>
					{selectedBabyIds.length
						? `${selectedBabyIds.length} baby selected`
						: 'Tap a baby to check-in'}
				</p>
				<CustomButton
					width="116px"
					height="34px"
					fontSize="16px"
					borderRadius="0"
					borderWidth="0px"
					backgroundColor={
						selectedBabyIds.length ? 'transparent' : 'transparent'
					}
					color={selectedBabyIds.length ? TEXT_WHITE : BUTTON_PRIMARY_MAIN}
					backgroundColorHover="transparent"
					backgroundColorActive="transparent"
					fontColorActive={TEXT_WHITE}
					fontColorHover={TEXT_WHITE}
					disabled={
						isButtonDisabled ||
						(typeof skvllbabiezList !== 'undefined' &&
							skvllbabiezList.length < 1)
					}
					onClick={handleSelectAll}
				>
					<b className="select-and-unselect">
						{selectedBabyIds.length ? `Unselect all` : 'Select all'}
					</b>
				</CustomButton>
			</div>
			<NFTVerticalScrollDiv justify={justifyAlignment} align={alignment}>
				<ChooesBabiezContent
					checkInState={checkInState}
					daycareRewardState={daycareRewardState}
					isAllImagesLoaded={isAllImagesLoaded}
					hasNoBabiez={hasNoBabiez}
					skvllbabiezList={skvllbabiezList}
					setIsAllImagesLoaded={setIsAllImagesLoaded}
					selectedBabyIds={selectedBabyIds}
					setSelectedBabyIds={setSelectedBabyIds}
					isAuthenticated={isAuthenticated}
				/>
			</NFTVerticalScrollDiv>
			<CheckInAndOutDiv dayCareCheck={+selectedBabyIds}>
				<CustomButton
					width="116px"
					height="34px"
					fontSize="12px"
					borderRadius="5px"
					borderWidth="0px"
					backgroundColor={
						selectedBabyIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
					}
					color={selectedBabyIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
					backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
					backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
					fontColorActive={TEXT_WHITE}
					disabled={selectedBabyIds.length < 1 || isButtonDisabled}
					onClick={handleCheckIn}
				>
					<b>Check in</b>
				</CustomButton>
				<CustomButton
					width="116px"
					height="34px"
					fontSize="12px"
					borderRadius="5px"
					borderWidth="0px"
					backgroundColor={
						selectedBabyIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
					}
					color={selectedBabyIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
					backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
					backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
					fontColorActive={TEXT_WHITE}
					disabled={selectedBabyIds.length < 1 || isButtonDisabled}
					onClick={handleCollectReward}
				>
					<b>Collect Rewards</b>
				</CustomButton>
				<div className="desktop-select-all">
					<CustomButton
						width="116px"
						height="34px"
						fontSize="12px"
						borderRadius="5px"
						borderWidth="0px"
						backgroundColor={
							selectedBabyIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
						}
						color={selectedBabyIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
						backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
						backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
						fontColorActive={TEXT_WHITE}
						fontColorHover={TEXT_WHITE}
						disabled={
							isButtonDisabled ||
							(typeof skvllbabiezList !== 'undefined' &&
								skvllbabiezList.length < 1)
						}
						onClick={handleSelectAll}
					>
						<b>{selectedBabyIds.length ? `Unselect All` : 'Select All'}</b>
					</CustomButton>
				</div>
			</CheckInAndOutDiv>
		</StakeContainer>
	);
};

export default ChooseBabiezToStake;
