import React from 'react';
import { StakeNFTTableContainer } from '../../../resources/styles/StakeNFT.styles';
import { CollectionTitles } from '../../../resources/enums/CollectionTitles';
import NoNFTFound from '../../NoNFTFound';
import { SiteRoutes } from '../../../resources/enums/SiteRoutes';
import NFTAssetInterface from '../../../resources/interfaces/NFTAssetInterface';
import TableContent from './TableContent';
import {
	BABIEZ_AMMOLITE_INCREMENT_RATE,
	PVNKZ_AMMOLITE_INCREMENT_RATE,
} from '../../../resources/constants/rewards';

interface PropInterface {
	handleClick: (id: number) => void;
	handleIncludeCheck: (id: number) => boolean;
	collection: CollectionTitles;
	nftWithRewards: NFTAssetInterface[];
}

const StakeNFTTable = (props: PropInterface): JSX.Element => {
	const { handleClick, handleIncludeCheck, collection, nftWithRewards } = props;
	const incrementRate =
		collection === CollectionTitles.Skvllpvnkz
			? PVNKZ_AMMOLITE_INCREMENT_RATE
			: BABIEZ_AMMOLITE_INCREMENT_RATE;

	return nftWithRewards.length > 0 ? (
		<StakeNFTTableContainer>
			<table>
				<thead className="stake-nft-table-header">
					<tr>
						<td id="header" className="item">
							<b>Item</b>
						</td>
						<td id="headerStats">
							<td id="header">
								<b>Duration</b>
							</td>
							<td id="header">
								<b>Ammolite</b>
							</td>
							<td id="header" className="action"></td>
						</td>
					</tr>
				</thead>
				<tbody>
					{nftWithRewards.map((nft) => {
						const { id, name, thumb, staked_on, held_for, rewards } = nft;
						return (
							<tr key={id} onClick={() => handleClick(id)}>
								<TableContent
									name={name}
									thumb={thumb}
									staked_on={staked_on}
									held_for={held_for}
									rewards={rewards}
									clicked={handleIncludeCheck(id)}
									incrementRate={incrementRate}
									collection={collection}
								/>
							</tr>
						);
					})}
				</tbody>
			</table>
		</StakeNFTTableContainer>
	) : (
		<NoNFTFound
			title={`No ${collection} staked 😔`}
			route={SiteRoutes.discover}
		/>
	);
};

export default React.memo(StakeNFTTable);
