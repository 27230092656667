import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import RewardCollection from '../../resources/enums/RewardCollection';
import AssetRewardsInterface from '../../resources/interfaces/AssetRewardsInterface';
import NFTAssetInterface from '../../resources/interfaces/NFTAssetInterface';

export const fetchAndUpdateTokenRewards = async (
	stakingContractName: RewardCollection,
	tokenIds: string[]
): Promise<AssetRewardsInterface[]> => {
	const fn = httpsCallable<
		{ stakingContractName: RewardCollection; tokenIds: string[] },
		AssetRewardsInterface[]
	>(functions, 'getAndUpdateStakingRewards');
	return (await fn({ stakingContractName, tokenIds })).data;
};

export const getWalletRewardsReport = async (
	stakingContractName: RewardCollection
): Promise<NFTAssetInterface[]> => {
	const fn = httpsCallable<
		{ stakingContractName: RewardCollection },
		NFTAssetInterface[]
	>(functions, 'getWalletRewardsReport');
	return (await fn({ stakingContractName })).data;
};

export const updateStakedState = async (
	tokenIds: number[],
	stakingContractName: RewardCollection
): Promise<void> => {
	const fn = httpsCallable<
		{ stakingContractName: RewardCollection; tokenIds: number[] },
		void
	>(functions, 'updateStakedState');
	await fn({ stakingContractName, tokenIds });
};
