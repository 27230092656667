import { AbstractConnector } from '@web3-react/abstract-connector';
import { ethers } from 'ethers';

export default class Wallet {
	public connector;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public library: any;
	public address = '';

	constructor(
		connector: AbstractConnector,
		account: string,
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		library: any // eslint-disable-line @typescript-eslint/no-explicit-any
	) {
		this.connector = connector;
		this.address = account;
		this.library = library;
	}

	public static initializeListeners(): void {
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', () => window.location.reload());
			window.ethereum.on('chainChanged', () => window.location.reload());
		}
	}

	public static async initialize(): Promise<void> {
		this.initializeListeners();
	}

	public async getBalance(): Promise<string> {
		const balance = await this.library.getBalance(this.address);
		return ethers.utils.formatEther(balance);
	}
}
