import {
	GetLotteryEntriesFilter,
	GetWinningEntriesFilter,
	SignLotteryEntryResponseInterface,
} from './../../resources/interfaces/LotteryInterface';
import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import {
	castToLotteryEntryInterface,
	castToLotteryInterface,
} from '../../helpers/lottery';
import {
	LotteryEntryInterface,
	LotteryInterface,
} from '../../resources/interfaces/LotteryInterface';

export const signLotteryEntry = async (
	lotteryId: number,
	numberOfEntries: number
): Promise<SignLotteryEntryResponseInterface> => {
	const fn = httpsCallable<
		{ lotteryId: number; numberOfEntries: number },
		SignLotteryEntryResponseInterface
	>(functions, 'signLotteryEntry');

	return (
		await fn({
			lotteryId,
			numberOfEntries,
		})
	).data;
};

export const registerLotteryEntry = async (
	lotteryId: number,
	transactionHash: string
): Promise<void> => {
	const fn = httpsCallable<
		{ lotteryId: number; transactionHash: string },
		void
	>(functions, 'registerLotteryEntry');

	return (
		await fn({
			lotteryId,
			transactionHash,
		})
	).data;
};

export const getLottery = async (
	lotteryId: number
): Promise<LotteryInterface> => {
	const fn = httpsCallable<{ lotteryId: number }, unknown>(
		functions,
		'getLottery'
	);

	return castToLotteryInterface(
		(
			await fn({
				lotteryId,
			})
		).data
	);
};

export const getCurrentLottery = async (): Promise<LotteryInterface> => {
	const fn = httpsCallable<unknown, unknown>(functions, 'getCurrentLottery');
	return castToLotteryInterface((await fn()).data);
};

export const getUserLotteryEntries = async (
	filter?: GetLotteryEntriesFilter
): Promise<LotteryEntryInterface[]> => {
	const fn = httpsCallable<GetLotteryEntriesFilter, unknown[]>(
		functions,
		'getUserLotteryEntries'
	);

	return (
		await fn({
			...filter,
		})
	).data.map((entry) => castToLotteryEntryInterface(entry));
};

export const getWinningEntries = async (
	filter?: GetWinningEntriesFilter
): Promise<LotteryEntryInterface[]> => {
	const fn = httpsCallable<GetWinningEntriesFilter, any[]>(
		functions,
		'getWinningEntries'
	);

	return (
		await fn({
			...filter,
		})
	).data.map((entry) => castToLotteryEntryInterface(entry));
};

export const formatPortalKeyCode = (
	text: string
): { part1: string; part2: string } => {
	const halfTextLength = Math.floor(text.length / 2);
	const fullTextLength = text.length;
	const part1 = text.slice(0, halfTextLength);
	const part2 = text.slice(fullTextLength - halfTextLength, fullTextLength);
	return { part1, part2 };
};
