import CollectionStatsInterface from '../interfaces/CollectionStatsInterface';

const placeholderCollectionStats: CollectionStatsInterface = {
    "name": "Skvllpvnkz Hideout",
    "subtitle": "An interactive collection of 10,000 machine generated avatar NFTs",
    "description": "With the transformative power of blockchain and a shared vision for a decentralised future, a dedicated team of artists, coders and misfits initiated the Skvllpvnkz. Sophisticated computer algorithms stored on the Ethereum blockchain were employed to create 10,000 unique characters, all with distinctive traits. More than 350 delicately compiled, hand-drawn layers were stitched together and fed through the machine. Boundaries between art and code were questioned, bent, then broken.",
    "totalVolume": 1210.3514477117692,
    "totalGas": 3891970365,
    "totalOwners": 3000,
    "averagePrice": 0.08951641503674057,
    "transactionCount": 13521,
    "numberOfSales": 12090,
    "topTrade": 11,
    "currentMarketCap": 895.1641503674057,
    "valueIncrease": 400.1641503674057,
    "valueIncreasePerc": 80.84124249846579,
	"totalSupply": 10000
}

export default placeholderCollectionStats;