import Skvllpvnkz from '../../services/Skvllpvnkz';
import {
	CONNECT_SKVLLPVNKZ,
	DISPOSE_USED_SKVLLPVNKZ,
	RESET_SKVLLPVNKZ_STATE,
	UPDATE_SKVLLPVNKZ_LIST,
} from './types/skvllpvnkz';
import { Dispatch } from 'react';
import { fetchSkvllpvnkzDetails } from '../../helpers/skvllpvnk';
import { batch } from 'react-redux';
import { connectSkvllpvnkzAmmoliteUpdater } from './pendingRewards';
import { updateVirginSkvllpvnkz } from '../../firebase/functions/breeding';

export const connectSkvllpvnkz =
	(skvllpvnkz: Skvllpvnkz) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		const ownedSkvllpvnkzList = await skvllpvnkz.getSkvllpvnkz();
		batch(() => {
			dispatch({
				type: CONNECT_SKVLLPVNKZ,
				payload: {
					skvllpvnkz,
					ownedSkvllpvnkzList,
				},
			});
			dispatch(connectSkvllpvnkzAmmoliteUpdater(ownedSkvllpvnkzList));
		});
	};

export const disposeUsedSkvllpvnkz =
	(skvllpvnkzIds: number[]) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		await updateVirginSkvllpvnkz(skvllpvnkzIds);
		dispatch({
			type: DISPOSE_USED_SKVLLPVNKZ,
			payload: {
				usedSkvllpvnkIds: skvllpvnkzIds,
			},
		});
	};

export const updateSkvllpvnkzList =
	() =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		const ownedSkvllpvnkzList = await fetchSkvllpvnkzDetails();
		batch(() => {
			dispatch({
				type: UPDATE_SKVLLPVNKZ_LIST,
				payload: {
					ownedSkvllpvnkzList,
				},
			});
			dispatch(connectSkvllpvnkzAmmoliteUpdater(ownedSkvllpvnkzList));
		});
	};

export const resetSkvllpvnkzState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_SKVLLPVNKZ_STATE });
	};
