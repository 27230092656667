import React from 'react';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';

interface PropsInterface {
	isHideoutRewards?: boolean;
	decimalPrecision: number;
	hasText?: boolean;
}

const PendingRewards = (props: PropsInterface) => {
	const { isHideoutRewards, decimalPrecision, hasText } = props;
	const { totalHideoutRewards, totalDaycareRewards } = useSelector(
		(state: RootReducerStateInterface) => state.pendingRewards
	);
	return isHideoutRewards ? (
		<p>
			{totalHideoutRewards.toFixed(decimalPrecision)} {hasText && 'AMMO'}
		</p>
	) : (
		<p>
			{totalDaycareRewards.toFixed(decimalPrecision)} {hasText && 'AMMO'}
		</p>
	);
};

export default React.memo(PendingRewards);
