import { BigNumber, ethers } from 'ethers';
import { Dispatch } from 'react';
import config from '../config';
import storeABI from '../contracts/storeABI.json';
import { signStorePurchase } from '../firebase/functions';
import {
	getStoreItems,
	getStoreItem,
	reserveItemForPurchase,
	getUserReservations,
} from './../firebase/functions/store';
import {
	addNewStoreItem,
	setPurchasedItems,
	setReservedItems,
	setStoreData,
	updateStoreItem,
} from '../redux/actions/store';
import { StoreError } from '../resources/enums/StoreError';
import { TransactionState } from '../resources/enums/states';
import StoreItemInterface from '../resources/interfaces/StoreItemInterface';
import Ammolite from './Ammolite';
import Skvllpvnkz from './Skvllpvnkz';
import Wallet from './Wallet';
import { isAllowedToPurchase } from '../helpers/store';
import StoreItemBalance from '../resources/interfaces/StoreItemBalance';
import { collection, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { db } from '../firebase';
import {
	getPendingTransaction,
	setPendingTransaction,
} from '../helpers/session';
import ReservedItemInterface from '../resources/interfaces/ReservedItemsInterface';
import { GenericError } from '../resources/enums/GenericError';
import SessionKeys from '../resources/enums/SessionKeys';

export default class Store {
	private contract;
	private provider;
	private skvllpvnkz;
	private ammolite;
	private wallet;
	private dispatch;
	private dbListenerUnsubscribers: Unsubscribe[] = [];

	constructor(
		dispatch: Dispatch<unknown>,
		ammolite: Ammolite,
		skvllpvnkz: Skvllpvnkz,
		wallet: Wallet
	) {
		this.provider = wallet.library;
		this.skvllpvnkz = skvllpvnkz;
		this.ammolite = ammolite;
		this.wallet = wallet;
		this.contract = new ethers.Contract(
			config.STORE_CONTRACT_ADDRESS,
			storeABI,
			this.provider.getSigner()
		);
		this.dispatch = dispatch;
		this.contract.removeAllListeners();
		this.fetchPurchasedItems();
		this.fetchReservedItems();
		this.fetchStoreData();
		this.initializeContractListeners();
	}

	public initializeDBListeners(): void {
		const storeItemsUnsubscriber = onSnapshot(
			collection(db, 'store', 'inventory', 'digital_items'),
			(querySnapshot) => {
				querySnapshot.docChanges().forEach((change) => {
					if (change.type === 'modified') {
						this.updateStoreItem(parseInt(change.doc.id));
					}
				});
			}
		);
		this.dbListenerUnsubscribers.push(storeItemsUnsubscriber);
	}

	public destroyDBListeners(): void {
		this.dbListenerUnsubscribers.forEach((unsubscribe) => unsubscribe());
	}

	public initializeContractListeners(): void {
		this.contract.on('ItemCreated', (item) => this.addNewStoreItem(item.id));
		this.contract.on('TransferSingle', (operator, from, to, id) => {
			this.updateStoreItem(id.toNumber());
		});
	}

	public async fetchPurchasedItems(): Promise<void> {
		const balanceOfItems = await this.balanceOfItems();
		const userPurchases: StoreItemBalance[] = [];
		Object.entries(balanceOfItems).forEach(([key, value]) => {
			if (value > 0) {
				userPurchases.push({
					id: parseInt(key),
					balance: value,
				});
			}
		});
		this.dispatch(setPurchasedItems(userPurchases));
	}

	public async fetchReservedItems(): Promise<void> {
		const reservedItems: ReservedItemInterface[] = await getUserReservations();
		const itemsInPurchaseList = reservedItems.filter(
			(item) => item.isItemReserved
		);
		this.dispatch(setReservedItems(itemsInPurchaseList));
	}

	public async fetchStoreData(): Promise<void> {
		const storeData = await getStoreItems();
		this.dispatch(setStoreData(storeData));
	}

	public async addNewStoreItem(item: BigNumber): Promise<void> {
		const storeItem = await getStoreItem(item.toNumber());
		this.dispatch(addNewStoreItem(storeItem));
	}

	public async updateStoreItem(itemId: number): Promise<void> {
		const storeItem = await getStoreItem(itemId);
		this.dispatch(updateStoreItem(storeItem));
	}

	public async doesHavePendingItemPurchase(itemId: number): Promise<boolean> {
		const transactionHash = getPendingTransaction(
			itemId,
			SessionKeys.PENDING_ITEM_PURCHASE_TRANSACTION
		);

		if (!transactionHash) {
			return false;
		}

		const transactionReceipt = await this.provider.getTransactionReceipt(
			transactionHash
		);

		const isPending = !transactionReceipt;
		if (!isPending) {
			setPendingTransaction(
				itemId,
				null,
				SessionKeys.PENDING_ITEM_PURCHASE_TRANSACTION
			);
		} else {
			setPendingTransaction(
				itemId,
				transactionHash,
				SessionKeys.PENDING_ITEM_PURCHASE_TRANSACTION
			);
		}

		return isPending;
	}

	public async buyItem(selectedItem: StoreItemInterface): Promise<boolean> {
		try {
			const { id, price } = selectedItem;

			await isAllowedToPurchase(
				this.contract,
				this.wallet.address,
				id,
				this.skvllpvnkz,
				this.ammolite,
				price,
				true
			);

			if (await this.doesHavePendingItemPurchase(id)) {
				throw new Error(StoreError.PENDING_PURCHASE_EXISTS);
			}

			const purchaseTransactionResult = await this.executePurchase(id);

			if (!purchaseTransactionResult) {
				throw new Error(GenericError.ETHERSCAN_TRANSACTION_FAILED);
			}

			return true;
			// eslint-disable-next-line
		} catch (error: any) {
			throw Error(error.message);
		}
	}

	private async executePurchase(itemId: number): Promise<boolean> {
		try {
			const storePurchaseResponse = (
				await signStorePurchase({
					itemId,
				})
			).data;

			const gasEstimate = await this.contract.estimateGas.buyItem(
				itemId,
				storePurchaseResponse.signature
			);

			// Sumbits the transaction to be mined on the blockchain
			const ongoingTransaction = await this.contract.buyItem(
				itemId,
				storePurchaseResponse.signature,
				{
					gasLimit: gasEstimate,
				}
			);

			setPendingTransaction(
				itemId,
				ongoingTransaction.hash,
				SessionKeys.PENDING_ITEM_PURCHASE_TRANSACTION
			);

			// Waits for the transaction to be mined on the blockchain
			const minedTransaction = await this.provider.waitForTransaction(
				ongoingTransaction.hash
			);

			setPendingTransaction(
				itemId,
				null,
				SessionKeys.PENDING_ITEM_PURCHASE_TRANSACTION
			);

			return (
				minedTransaction.status &&
				minedTransaction.status == TransactionState.SUCCESS
			);
			// eslint-disable-next-line
		} catch (error: any) {
			throw Error(error.message);
		}
	}

	public async reserveItem(selectedItem: StoreItemInterface): Promise<boolean> {
		try {
			const { id, price } = selectedItem;

			await isAllowedToPurchase(
				this.contract,
				this.wallet.address,
				id,
				this.skvllpvnkz,
				this.ammolite,
				price,
				false
			);

			await reserveItemForPurchase({
				walletAddress: this.wallet.address,
				itemId: id,
			});

			return true;
			// eslint-disable-next-line
		} catch (error: any) {
			console.error(error);
			throw Error(error.message);
		}
	}

	public async getItems(): Promise<[]> {
		return this.contract.getItems();
	}

	public async getTotalSupply(item: number): Promise<number> {
		return (await this.contract.totalSupply(item)).toNumber();
	}

	public balanceOf(walletAddress: string, itemId: number): Promise<number> {
		return this.contract.balanceOf(walletAddress, itemId);
	}

	public async balanceOfItems(): Promise<number[]> {
		const items = await this.getItems();
		const walletAddressArray = Array(items.length).fill(this.wallet.address);
		const itemIds = Array.from(Array(items.length).keys());
		return this.contract.balanceOfBatch(walletAddressArray, itemIds);
	}
}
