export const CONNECT_LOTTERY = 'CONNECT_LOTTERY';
export const RESET_LOTTERY_STATE = 'RESET_LOTTERY_STATE';
export const SET_CURRENT_LOTTERY = 'SET_CURRENT_LOTTERY';
export const SET_PREVIOUS_LOTTERY = 'SET_PREVIOUS_LOTTERY';
export const SET_ALL_PREVIOUS_WINNERS = 'SET_ALL_PREVIOUS_WINNERS';

export const SET_USER_PORTAL_KEYS = 'SET_USER_PORTAL_KEYS';
export const ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS =
	'ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS';
export const REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS =
	'REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS';
