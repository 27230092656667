import { BigNumber, ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { collection, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { Dispatch } from 'react';
import config from '../config';
import lotteryABI from '../contracts/lotteryABI.json';
import { db } from '../firebase';
import {
	getCurrentLottery,
	getLottery,
	getUserLotteryEntries,
	getWinningEntries,
	registerLotteryEntry,
	signLotteryEntry,
} from '../firebase/functions';
import {
	getPendingTransaction,
	setPendingTransaction,
} from '../helpers/session';
import { setLoaderSubtitle } from '../redux/actions/loader';
import {
	addLotteryEntryPurchaseToPendingTransactions,
	removeLotteryEntryPurchaseFromPendingTransactions,
	setAllPreviousWinners,
	setCurrentLottery,
	setPreviousLottery,
	setUserPortalKeys,
} from '../redux/actions/lottery';
import { MINIMUM_SKVLLPVNKZ_OWNED } from '../resources/constants/limits';
import { GenericError } from '../resources/enums/GenericError';
import { LotteryError } from '../resources/enums/LotteryError';
import ProgressInfo from '../resources/enums/ProgressInfo';
import SessionKeys from '../resources/enums/SessionKeys';
import { TransactionState } from '../resources/enums/states';
import { LotteryInterface } from '../resources/interfaces/LotteryInterface';

import Ammolite from './Ammolite';
import Skvllpvnkz from './Skvllpvnkz';
import Wallet from './Wallet';

export default class Lottery {
	private contract;
	private provider;
	private skvllpvnkz;
	private ammolite;
	private wallet;
	private dispatch;
	private dbListenerUnsubscribers: Unsubscribe[] = [];

	constructor(
		dispatch: Dispatch<unknown>,
		ammolite: Ammolite,
		skvllpvnkz: Skvllpvnkz,
		wallet: Wallet
	) {
		this.provider = wallet.library;
		this.skvllpvnkz = skvllpvnkz;
		this.ammolite = ammolite;
		this.wallet = wallet;
		this.contract = new ethers.Contract(
			config.LOTTERY_CONTRACT_ADDRESS,
			lotteryABI,
			this.provider.getSigner()
		);
		this.dispatch = dispatch;
		this.fetchCurrentLottery();
		this.initializeDBListeners();
		this.fetchUserPortalKeys();
		this.fetchAllWinningEntries();
	}

	public async fetchUserPortalKeys() {
		this.dispatch(setUserPortalKeys(await getUserLotteryEntries()));
	}

	public initializeDBListeners(): void {
		const lotteryUnsubscriber = onSnapshot(
			collection(db, 'lotteries'),
			async () => {
				await this.fetchCurrentLottery();
			}
		);
		this.dbListenerUnsubscribers.push(lotteryUnsubscriber);
	}

	private async fetchAllWinningEntries() {
		try {
			const allPreviousWinners = await getWinningEntries();
			allPreviousWinners.sort(
				(a, b) => b.entryTime.getTime() - a.entryTime.getTime()
			);
			this.dispatch(setAllPreviousWinners(allPreviousWinners));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			console.error(error);
		}
	}

	private async fetchPreviousLottery(lotteryId: number) {
		try {
			const previousLottery = await getLottery(lotteryId - 1);
			this.dispatch(setPreviousLottery(previousLottery));
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			console.error(error);
		}
	}

	private async fetchCurrentLottery() {
		try {
			const currentLottery = await getCurrentLottery();
			this.dispatch(setCurrentLottery(currentLottery));
			this.fetchPreviousLottery(currentLottery.id);
		} catch (error: any) {
			console.error(error);
		}
	}

	public destroyDBListeners(): void {
		this.dbListenerUnsubscribers.forEach((unsubscribe) => unsubscribe());
	}

	public async buyEntry(
		activeLottery: LotteryInterface,
		numberOfEntries: number
	): Promise<void> {
		try {
			const { id, endTime, maxEntriesPerWallet } = activeLottery;

			this.dispatch(setLoaderSubtitle(ProgressInfo.CHECK_FOR_EXISTING_TX));
			const isPurchasePending =
				!!(await this.doesHavePendingLotteryEntryPurchase(id));
			this.updateLotteryPurchaseStatus(activeLottery, isPurchasePending);
			if (isPurchasePending) {
				throw new Error(GenericError.EXISTING_TX_IN_PROGRESS);
			}

			this.dispatch(setLoaderSubtitle(ProgressInfo.CHECK_PORTAL_IS_OPEN));
			if (endTime <= new Date().getTime()) {
				throw new Error(LotteryError.LOTTERY_ENDED);
			}

			this.dispatch(setLoaderSubtitle(ProgressInfo.SCANNING_EXISTING_KEYS));
			const userLotteryEntries = (
				await getUserLotteryEntries({
					lotteryId: id,
				})
			).length;

			this.dispatch(setLoaderSubtitle(ProgressInfo.CHECK_PORTAL_LIMITS));
			if (userLotteryEntries + numberOfEntries > maxEntriesPerWallet) {
				throw new Error(GenericError.WALLET_LIMIT);
			}
			this.dispatch(setLoaderSubtitle(ProgressInfo.CALCULATING_COST));
			const price = await this.contract.entryPrice();
			const totalPrice = (price as BigNumber).mul(
				BigNumber.from(numberOfEntries)
			);

			this.dispatch(setLoaderSubtitle(ProgressInfo.CHECKING_WALLET_BALANCE));
			const ammoBalance = await this.ammolite.fetchBalanceOfWallet();
			if (ammoBalance < parseInt(formatUnits(totalPrice))) {
				throw new Error(GenericError.NOT_ENOUGH_AMMO);
			}

			this.dispatch(setLoaderSubtitle(ProgressInfo.VALIDATE_MEMBERSHIP));
			const skvllpvnkzBalance = await this.skvllpvnkz.fetchBalanceOfWallet();
			if (skvllpvnkzBalance < MINIMUM_SKVLLPVNKZ_OWNED) {
				throw new Error(GenericError.NOT_AN_OWNER);
			}

			this.dispatch(setLoaderSubtitle(ProgressInfo.VERIFY_SPEND_ALLOWANCE));
			const allowance = await this.ammolite.checkAllowance(
				config.LOTTERY_CONTRACT_ADDRESS
			);

			if (!allowance.gte(totalPrice)) {
				throw new Error(GenericError.ALLOWANCE_LIMIT_EXCEEDED);
			}

			this.dispatch(setLoaderSubtitle(ProgressInfo.BROADCAST_TX));
			const transactionHash = await this.enterDraw(
				activeLottery,
				numberOfEntries
			);

			this.dispatch(setLoaderSubtitle(ProgressInfo.REGISTER_TX));
			await registerLotteryEntry(id, transactionHash);

			this.dispatch(setLoaderSubtitle(ProgressInfo.FETCH_USER_PORTAL_KEYS));
			this.fetchUserPortalKeys();
		} catch (error: any) {
			console.error(error);
			throw error;
		}
	}

	private async enterDraw(
		activeLottery: LotteryInterface,
		numberOfEntries: number
	): Promise<string> {
		try {
			const { id } = activeLottery;

			this.dispatch(setLoaderSubtitle(ProgressInfo.SIGNING_TX));
			const { expiryDate, signature } = await signLotteryEntry(
				id,
				numberOfEntries
			);

			this.dispatch(setLoaderSubtitle(ProgressInfo.ESTIMATING_GAS));
			const gasEstimate = await this.contract.estimateGas.enterDraw(
				numberOfEntries,
				expiryDate,
				id,
				signature
			);

			this.dispatch(setLoaderSubtitle(ProgressInfo.AWAITING_CONFIRMATION));
			const ongoingTransaction = await this.contract.enterDraw(
				numberOfEntries,
				expiryDate,
				id,
				signature,
				{
					gasLimit: gasEstimate,
				}
			);

			this.dispatch(
				addLotteryEntryPurchaseToPendingTransactions(activeLottery)
			);

			setPendingTransaction(
				id,
				ongoingTransaction.hash,
				SessionKeys.PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION
			);

			// Waits for the transaction to be mined on the blockchain
			const minedTransaction = await this.provider.waitForTransaction(
				ongoingTransaction.hash
			);

			this.dispatch(setLoaderSubtitle(ProgressInfo.TX_CONFIRMED));

			setPendingTransaction(
				id,
				null,
				SessionKeys.PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION
			);

			this.dispatch(
				removeLotteryEntryPurchaseFromPendingTransactions(activeLottery)
			);

			const transactionSuccess =
				minedTransaction.status &&
				minedTransaction.status == TransactionState.SUCCESS;

			if (!transactionSuccess) {
				throw new Error(GenericError.ETHERSCAN_TRANSACTION_FAILED);
			}
			this.dispatch(setLoaderSubtitle(ProgressInfo.EXECUTION_SUCCESS));
			return ongoingTransaction.hash;
		} catch (error: any) {
			console.error(error);
			throw error;
		}
	}

	public async updateLotteryPurchaseStatus(
		activeLottery: LotteryInterface,
		isPurchasePending: boolean
	): Promise<void> {
		if (isPurchasePending) {
			this.dispatch(
				addLotteryEntryPurchaseToPendingTransactions(activeLottery)
			);
		} else {
			this.dispatch(
				removeLotteryEntryPurchaseFromPendingTransactions(activeLottery)
			);
		}
	}

	public async doesHavePendingLotteryEntryPurchase(
		activeLotteryId: number
	): Promise<boolean> {
		const transactionHash = getPendingTransaction(
			activeLotteryId,
			SessionKeys.PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION
		);

		if (!transactionHash) {
			return false;
		}

		const transactionReceipt = await this.provider.getTransactionReceipt(
			transactionHash
		);

		const isPending = !transactionReceipt;
		if (!isPending) {
			setPendingTransaction(
				activeLotteryId,
				null,
				SessionKeys.PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION
			);
		} else {
			setPendingTransaction(
				activeLotteryId,
				transactionHash,
				SessionKeys.PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION
			);
		}

		return isPending;
	}
}
