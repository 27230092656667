import { Dispatch } from 'react';
import { DiscordProfileInterface } from '../../resources/interfaces/DiscordInterface';
import Discord from '../../services/Discord';
import {
	RESET_DISCORD_STATE,
	SETUP_DISCORD,
	UPDATE_DISCORD_PROFILE,
} from './types/discord';

export const setupDiscord =
	(discord: Discord) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: SETUP_DISCORD,
			payload: {
				discord,
			},
		});
	};

export const updateDiscordProfile =
	(profile: DiscordProfileInterface | null) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: UPDATE_DISCORD_PROFILE,
			payload: {
				id: profile?.id,
				username: profile?.username,
			},
		});
	};

export const resetDiscordState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_DISCORD_STATE });
	};
