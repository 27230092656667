import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {
	ContentHeader,
	MainModalView,
	PropertyButtonsContainer,
} from '../../../resources/styles/NFTModalView.styles';
import { ButtonWrapper } from '../../../resources/styles/ButtonWrappers.style';

import Traits from './components/Traits';
import Activity from './components/Activity';
import { useDispatch, useSelector } from 'react-redux';
import { setIsModalOpen } from '../../../redux/actions/gallery';
import { Collections } from '../../../resources/enums/Collections';
import NFTImageInterface from '../../../resources/interfaces/NFTImageInterface';
import NFTImage from './components/NFTImage';
import NFTDetails from './components/NFTDetails';
import RewardCollection from '../../../resources/enums/RewardCollection';
import AssetRewardsInterface from '../../../resources/interfaces/AssetRewardsInterface';
import { useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../../../resources/enums/SiteRoutes';
import { fetchAssetHistory } from '../../../firebase/functions/collections';
import NFTAssetHistoryInterface from '../../../resources/interfaces/NFTAssetHistoryInterface';
import { fetchAndUpdateTokenRewards } from '../../../firebase/functions/rewards';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import RaffleLoader from '../../../pages/RafflePage/components/RaffleLoader';
import SweetAlert from '../../SweetAlert';
import config from '../../../config';
import closeIcon from '../../../resources/images/icons/close.svg';

interface PropsInterface {
	selectedCollection: Collections;
	isModalOpen: boolean;
	selectedNFT: NFTImageInterface;
	filterByWallet: string;
}

const NFTModalView = (props: PropsInterface): JSX.Element => {
	const { selectedCollection, isModalOpen, selectedNFT } = props;
	const { owner, token_id, virgin, animation_url, thumb } = selectedNFT;
	const dispatch = useDispatch();
	const navigateHook = useNavigate();

	const { opensea } = useSelector(
		(state: RootReducerStateInterface) => state.opensea
	);
	const { loaderSubtitleText } = useSelector(
		(state: RootReducerStateInterface) => state.loader
	);

	const [isTraitPropertySelected, setIsTraitPropertySelected] =
		useState<boolean>(true);

	const [isPurchasing, setIsPurchasing] = useState<boolean>(false);
	const [assetHistory, setAssetHistory] = useState<NFTAssetHistoryInterface[]>(
		[]
	);
	const [assetRewards, setAssetRewards] = useState<AssetRewardsInterface[]>([]);

	const fetchHistory = useCallback(async () => {
		const history = await fetchAssetHistory(selectedCollection, token_id);
		setAssetHistory(history);
	}, [isModalOpen]);

	const fetchAndUpdateRewards = useCallback(async () => {
		const rewards = await fetchAndUpdateTokenRewards(
			selectedCollection === Collections.skvllpvnkz_hideout
				? RewardCollection.hideout_rewards
				: RewardCollection.daycare_staking,
			[String(token_id)]
		);
		setAssetRewards(rewards);
	}, [isModalOpen]);

	const URL = window.location.href;

	const page = '/' + (URL.split('/')[3] as SiteRoutes);

	const handleToggleModal = () => {
		dispatch(setIsModalOpen(!isModalOpen));

		const path = `${page}${
			page === SiteRoutes.profile ? '/collections' : ''
		}/${selectedCollection.replace('_', '-')}`;

		if (!isModalOpen) navigateHook(path + token_id);
		else navigateHook(path);
	};

	const handleOpenSeaPurchase = async () => {
		if (opensea) {
			try {
				setIsPurchasing(true);
				await opensea?.buyAsset(selectedCollection, token_id);
				SweetAlert({
					title: 'Purchase Successful',
					text: `You have successfully purchased ${selectedNFT.name}`,
				});
				setIsPurchasing(false);
			} catch (error) {
				const formattedErrorMessage = String(error).includes('insufficient')
					? 'You have insufficient funds to complete this transaction.'
					: String(error).split('err:')[1];

				const redirectFunction = () =>
					window.open(
						`https://opensea.io/assets/
					${
						selectedCollection === Collections.skvllpvnkz_hideout
							? config.SKVLLPVNKZ_CONTRACT_ADDRESS
							: config.SKVLLBABIEZ_CONTRACT_ADDRESS
					}/${token_id}`,
						'_blank'
					);
				const needsToCompleteOnOpenSea = String(error).includes(
					'Complete purchase on OpenSea'
				);
				SweetAlert({
					title: needsToCompleteOnOpenSea
						? 'Complete purchase on OpenSea'
						: 'Purchase Failed',
					text: needsToCompleteOnOpenSea
						? 'You will be redirected to finalise your purchase on OpenSea.'
						: formattedErrorMessage,
					confirmCallbackFunction: needsToCompleteOnOpenSea
						? redirectFunction
						: undefined,
				});
				setIsPurchasing(false);
			}
		}
	};

	useEffect(() => {
		if (isModalOpen) {
			fetchHistory();
			fetchAndUpdateRewards();
		}
	}, [isModalOpen]);

	const loader = (
		<div style={{ marginTop: 80 }}>
			<div style={{ width: 400, margin: 'auto' }}>
				<NFTImage
					virgin={virgin}
					animation_url={animation_url}
					image={thumb}
					owner={owner}
				/>
			</div>

			<div style={{ textAlign: 'center', fontSize: 24, marginTop: 20 }}>
				<b>{selectedNFT.name}</b>
			</div>

			<RaffleLoader
				height="auto"
				marginTop="25px"
				title="Executing OpenSea Purchase"
				subtitle={loaderSubtitleText}
			/>
		</div>
	);

	const nftView = (
		<>
			<MainModalView>
				<div className="close-icon-container">
					<div
						className="close-icon"
						onClick={() => dispatch(setIsModalOpen(!isModalOpen))}
					>
						<img src={closeIcon} />{' '}
					</div>
				</div>
				<ContentHeader>
					<NFTImage
						virgin={virgin}
						animation_url={animation_url}
						image={thumb}
						owner={owner}
					/>

					<NFTDetails
						selectedCollection={selectedCollection}
						selectedNFT={selectedNFT}
						assetHistory={assetHistory}
						assetRewards={assetRewards}
						handleOpenSeaPurchase={handleOpenSeaPurchase}
					/>
				</ContentHeader>
				<ButtonWrapper
					gap="28px"
					marginBottom="0px"
					marginTop="20px"
					fontSize="12px"
				>
					<PropertyButtonsContainer
						onClick={() => setIsTraitPropertySelected(!isTraitPropertySelected)}
						display={isTraitPropertySelected ? 'block' : 'none'}
					>
						<p>Traits</p>
						<div></div>
					</PropertyButtonsContainer>
					<PropertyButtonsContainer
						onClick={() => setIsTraitPropertySelected(!isTraitPropertySelected)}
						display={isTraitPropertySelected ? 'none' : 'block'}
					>
						<p>Activity</p>
						<div></div>
					</PropertyButtonsContainer>
				</ButtonWrapper>
				<hr />
				{isTraitPropertySelected ? (
					<Traits nft={selectedNFT} />
				) : (
					<Activity assetHistory={assetHistory} />
				)}
			</MainModalView>
		</>
	);

	return (
		<Modal centered isOpen={isModalOpen} toggle={handleToggleModal} size="lg">
			<ModalBody id="view-nft-modal">
				{isPurchasing ? loader : nftView}
			</ModalBody>
		</Modal>
	);
};

export default React.memo(NFTModalView);
