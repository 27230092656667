import { BigNumber, ethers } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import {
	MINIMUM_SKVLLPVNKZ_OWNED,
	STORE_MAX_NUMBER_OF_ITEMS,
} from '../resources/constants/limits';
import { GenericError } from '../resources/enums/GenericError';
import ReservedItemInterface from '../resources/interfaces/ReservedItemsInterface';
import StoreItemBalance from '../resources/interfaces/StoreItemBalance';
import StoreItemInterface from '../resources/interfaces/StoreItemInterface';
import Ammolite from '../services/Ammolite';
import Skvllpvnkz from '../services/Skvllpvnkz';

export const generateCardTagText = (
	soldOut: boolean,
	status: string,
	reservationsOpen?: boolean
): string => {
	if (soldOut) return 'Sold Out';
	else if (reservationsOpen) return 'On Sale Now';
	else return status;
};

export const isAllowedToPurchase = async (
	contract: ethers.Contract,
	address: string,
	id: number,
	skvllpvnkz: Skvllpvnkz,
	ammolite: Ammolite,
	price: number,
	isBuying: boolean
): Promise<boolean> => {
	const userItemBalance = await contract.balanceOf(address, id);
	if (userItemBalance >= STORE_MAX_NUMBER_OF_ITEMS) {
		throw new Error(GenericError.WALLET_LIMIT);
	}

	const skvllpvnkzBalance = await skvllpvnkz.fetchBalanceOfWallet();
	if (skvllpvnkzBalance < MINIMUM_SKVLLPVNKZ_OWNED) {
		throw new Error(GenericError.NOT_AN_OWNER);
	}

	const ammoBalance = await ammolite.fetchBalanceOfWallet();
	if (ammoBalance < parseInt(formatUnits(BigNumber.from(price), 18))) {
		throw new Error(GenericError.NOT_ENOUGH_AMMO);
	}

	if (isBuying) {
		const allowance = await ammolite.checkAllowance();
		if (!allowance.gte(BigNumber.from(price))) {
			throw new Error(GenericError.ALLOWANCE_LIMIT_EXCEEDED);
		}
	}

	return true;
};

export const castToStoreItemInterface = (
	// eslint-disable-next-line
	fetchedItem: any
): StoreItemInterface => ({
	name: fetchedItem.name,
	project: fetchedItem.project,
	image: fetchedItem.image,
	link: fetchedItem.link,
	description: fetchedItem.description,
	description2: fetchedItem.description2,
	description3: fetchedItem.description3,
	status: fetchedItem.status,
	type: fetchedItem.type,
	reservationsOpen: fetchedItem.reservationsOpen,
	expiryPeriodInSeconds: fetchedItem.expiryPeriodInSeconds,
	salesOpen: fetchedItem.salesOpen,
	purchaseList: fetchedItem.purchaseList,
	id: parseInt(fetchedItem.id),
	totalSupply: fetchedItem.totalSupply,
	maxSupply: fetchedItem.maxSupply,
	active: fetchedItem.active,
	price: fetchedItem.price,
	twitter: fetchedItem.twitter,
});

export const castToReservedItemInterface = (
	// eslint-disable-next-line
	fetchedItem: any
): ReservedItemInterface => {
	const { itemId, isItemReserved, isExpired, entryTime, purchaseCompleted } =
		fetchedItem;
	return {
		itemId: parseInt(itemId),
		isItemReserved: isItemReserved,
		isExpired: isExpired,
		entryTime: entryTime,
		purchaseCompleted: purchaseCompleted,
	};
};

export const countActiveReservations = (
	reservedItems: ReservedItemInterface[],
	purchasedItems: StoreItemBalance[]
): number =>
	reservedItems.reduce((previousValue, currentValue) => {
		if (
			!currentValue.isExpired &&
			!purchasedItems.some(
				(purchasedItem) => purchasedItem.id === currentValue.itemId
			)
		) {
			return previousValue + 1;
		}
		return previousValue;
	}, 0);
