import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCollectionBalance } from '../../../../helpers/balance';
import { Collections } from '../../../../resources/enums/Collections';
import { ExternalRoutes } from '../../../../resources/enums/SiteRoutes';
import AmmoliteIcon from '../../../../resources/images/icons/AmmoliteIcon.png';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	BalanceIconDiv,
	SidebarBalanceDiv,
	SidebarDetails,
} from '../../../../resources/styles/Sidebar.style';
import Skvllbabiez from '../../../../services/Skvllbabiez';
import Skvllpvnkz from '../../../../services/Skvllpvnkz';
import Loader from '../../../Loader';
import EthIcon from '../../../../resources/images/icons/eth.png';
import ReactTooltip from 'react-tooltip';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import { handleSearchByOwner } from '../../../../helpers/gallery';
import { NUMBER_OF_RETRIES } from '../../../../resources/constants/limits';
import axiosRetry from 'axios-retry';
import { useNavigate } from 'react-router-dom';

interface PropsInterface {
	address: string;
	balance: number | null;
	handleSidebar: () => void;
}

const SidebarWallet = (props: PropsInterface): JSX.Element => {
	const { address, balance, handleSidebar } = props;
	const { skvllpvnkz } = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz
	);
	const { skvllbabiez } = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez
	);

	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite || {}
	);
	const { selectedCollection } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);

	const [isWalletLoading, setIsWalletLoading] = useState(true);
	const [isSkvllpvnkzLoading, setIsSkvllpvnkzLoading] = useState(true);
	const [isSkvllbabiezLoading, setIsSkvllbabiezLoading] = useState(true);

	const [ethBalance, setETHBalance] = useState<string>();
	const [usdBalance, setUSDBalance] = useState<string>();
	const [ammoBalance, setAMMOBalance] = useState<number>();
	const [skvllpvnkzBalance, setSkvllpvnkzBalance] = useState<number>();
	const [skvllbabiezBalance, setSkvllbabiezBalance] = useState<number>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleWalletBalance = async (walletBalance: number) => {
		axiosRetry(axios, { retries: NUMBER_OF_RETRIES });
		const response = await axios.get(ExternalRoutes.ethConversion);
		const convertRate = response.data.data.rateUsd;
		const convertedBalance = walletBalance * parseFloat(convertRate);
		setUSDBalance(Number(convertedBalance).toFixed(3));
		setIsWalletLoading(false);
	};

	const handleCollectionBalance = async (
		collection: Collections,
		collectionContract: Skvllpvnkz | Skvllbabiez
	) => {
		const tokenBalance = await fetchCollectionBalance(
			collectionContract,
			address
		);
		if (collection === Collections.skvllpvnkz_hideout) {
			setSkvllpvnkzBalance(tokenBalance);
			setIsSkvllpvnkzLoading(false);
		} else if (collection === Collections.skvllpvnkz_daycare) {
			setSkvllbabiezBalance(tokenBalance);
			setIsSkvllbabiezLoading(false);
		}
	};

	const handleAmmoliteBalance = async () => {
		const fetchedBalance = await ammolite?.fetchBalanceOfWallet();
		setAMMOBalance(fetchedBalance);
	};

	useEffect(() => {
		if (address && balance) {
			setETHBalance(Number(balance).toFixed(3));
			handleWalletBalance(balance);
		}
		if (skvllpvnkz) {
			handleCollectionBalance(Collections.skvllpvnkz_hideout, skvllpvnkz);
		}
		if (skvllbabiez) {
			handleCollectionBalance(Collections.skvllpvnkz_daycare, skvllbabiez);
		}
		if (ammolite) {
			handleAmmoliteBalance();
		}
	}, [address, balance, skvllpvnkz, skvllbabiez]);

	return (
		<>
			<SidebarBalanceDiv>
				{typeof ammoBalance !== 'undefined' ? (
					<div
						style={{ paddingLeft: 50, paddingRight: 50 }}
						data-tip="Your Ammolite balance"
						data-delay-show="1000"
					>
						<ReactTooltip />
						<BalanceIconDiv>
							<img src={AmmoliteIcon} alt="ammolite" height="18px" />
							<h3>
								<b className="balance-text">{ammoBalance.toFixed(2)} AMMO</b>
							</h3>
						</BalanceIconDiv>
						<p className="grayed-out">
							<b className="balance-text">
								({ammoBalance.toFixed(8)} Ammolite)
							</b>
						</p>
					</div>
				) : (
					<Loader size="50px" />
				)}
				{isWalletLoading ? (
					<Loader size="50px" />
				) : (
					<div
						data-tip="Your Ethereum balance"
						data-delay-show="1000"
						data-type="dark"
					>
						<ReactTooltip />
						<BalanceIconDiv>
							<img src={EthIcon} alt="eth" height="20px" />
							<h3>{ethBalance} ETH</h3>
						</BalanceIconDiv>
						<p className="grayed-out">${usdBalance} USD</p>
					</div>
				)}
			</SidebarBalanceDiv>
			<SidebarDetails marginTop="24px" marginBottom="22px">
				<b>SKVLLPVNKZ</b>
				{isSkvllpvnkzLoading ? (
					<Loader size="10px" />
				) : (
					<b className="balance-text">{skvllpvnkzBalance}</b>
				)}
			</SidebarDetails>
			<hr />
			<SidebarDetails marginTop="24px" marginBottom="22px">
				<b>SKVLLBABIEZ</b>
				{isSkvllbabiezLoading ? (
					<Loader size="10px" />
				) : (
					<b className="balance-text">{skvllbabiezBalance}</b>
				)}
			</SidebarDetails>

			<div className="sidebar-btn" onClick={handleSidebar}>
				<CustomButton
					marginTop="30px"
					marginTopResponsive="30px"
					marginBottom="30px"
					marginBottomResponsive="40px"
					borderRadius="5px"
					width="80%"
					position="absolute"
					bottom="2.5rem"
					onClick={() =>
						handleSearchByOwner(dispatch, selectedCollection, address, navigate)
					}
				>
					My Collections
				</CustomButton>
			</div>
		</>
	);
};

export default SidebarWallet;
