import { ethers } from 'ethers';

import config from '../config';
import skullpunkzABI from '../contracts/skvllbabiezABI.json';
import { fetchSkvllpvnkzDetails } from '../helpers/skvllpvnk';
import NFTAssetInterface from '../resources/interfaces/NFTAssetInterface';
import Wallet from './Wallet';

export default class Skvllpvnkz {
	private contract;
	private wallet;
	private provider;

	constructor(wallet: Wallet) {
		this.wallet = wallet;
		this.provider = wallet.library;
		this.contract = new ethers.Contract(
			config.SKVLLPVNKZ_CONTRACT_ADDRESS,
			skullpunkzABI,
			this.provider.getSigner()
		);
	}

	public async getSkvllpvnkz(): Promise<NFTAssetInterface[]> {
		return fetchSkvllpvnkzDetails();
	}

	public async fetchSkvllpvnkIdsOfWallet(): Promise<number[]> {
		return this.contract.walletOfOwner(this.wallet.address);
	}

	public async fetchBalanceOfWallet(): Promise<number> {
		return Number(
			ethers.utils.formatUnits(
				await this.contract.balanceOf(this.wallet.address),
				0
			)
		);
	}
}
