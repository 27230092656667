import { AbstractConnector } from '@web3-react/abstract-connector';

import { connectSkvllbabiez, resetSkvllbabiezState } from './skvllbabiez';
import { connectAmmolite, resetAmmoliteState } from './ammolite';
import { CONNECT_WALLET, RESET_WALLET_STATE } from './types/wallet';
import Wallet from '../../services/Wallet';
import { Dispatch } from 'react';
import {
	connectRewardsAndStaking,
	resetRewardsState,
} from './rewardsAndStaking';
import { connectCollection } from './collection';
import { Collections } from '../../resources/enums/Collections';
import { connectSkvllpvnkz, resetSkvllpvnkzState } from './skvllpvnkz';
import { batch } from 'react-redux';
import { connectStore, resetStoreState } from './store';
import Skvllpvnkz from '../../services/Skvllpvnkz';
import Ammolite from '../../services/Ammolite';
import Discord from '../../services/Discord';
import { resetDiscordState, setupDiscord } from './discord';
import { resetPendingRewardsState } from './pendingRewards';
import { connectLottery } from './lottery';
import { connectOpenSea } from './opensea';

export const authenticateWallet =
	(
		connector: AbstractConnector,
		account: string,
		// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
		library: any // eslint-disable-line @typescript-eslint/no-explicit-any
	) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		const wallet = new Wallet(connector, account, library);
		const skvllpvnkz = new Skvllpvnkz(wallet);
		const ammolite = new Ammolite(wallet);
		const balance = await wallet.getBalance();
		const discord = new Discord(dispatch, wallet.address);
		batch(() => {
			dispatch({
				type: CONNECT_WALLET,
				payload: {
					wallet,
					address: wallet.address,
					balance: balance,
					isConnected: true,
				},
			});
			dispatch(setupDiscord(discord));
			dispatch(connectSkvllbabiez(wallet));
			dispatch(connectOpenSea(wallet));
			dispatch(connectSkvllpvnkz(skvllpvnkz));
			dispatch(connectAmmolite(ammolite));
			dispatch(connectRewardsAndStaking(wallet, ammolite));
			dispatch(connectStore(wallet, ammolite, skvllpvnkz));
			dispatch(connectLottery(wallet, ammolite, skvllpvnkz));
			dispatch(connectCollection(Collections.skvllpvnkz_hideout));
		});
	};

export const resetReduxState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		batch(() => {
			dispatch({ type: RESET_WALLET_STATE });
			dispatch(resetAmmoliteState());
			dispatch(resetRewardsState());
			dispatch(resetDiscordState());
			dispatch(resetPendingRewardsState());
			dispatch(resetSkvllbabiezState());
			dispatch(resetSkvllpvnkzState());
			dispatch(resetStoreState());
		});
	};
