import styled from 'styled-components';
import {
	PRIMARY_MAIN,
	TEXT_DARK,
	TEXT_GREEN,
	TEXT_SECONDARY_PURPLE,
	TEXT_WHITE,
} from '../constants/colors';

interface PropsInterface {
	paddingTop?: string;
	isVisible?: boolean;
	noBackgroundImage?: boolean;
	backgroundImage?: string;
	opacity?: string;
}

export const PageContainer = styled.div<PropsInterface>`
	padding-top: ${(props) => props.paddingTop};
	color: white;
	text-align: center;
	min-height: calc(100vh - 80px);

	#store-header {
		font-size: 46px;
		font-weight: 600;
		text-align: left;
		margin-left: 300px;
		padding-top: 50px;
	}
	.filter-mobile-icon {
		width: 70px;
		height: 70px;
		background-color: ${TEXT_SECONDARY_PURPLE};
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		padding-top: 17px;
		border-radius: 50%;
		color: ${TEXT_WHITE};
		font-size: 20px;
		font-weight: bold;
		box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
		position: fixed;
		right: 15px;
		bottom: 40px;
		z-index: 1;
		cursor: pointer;
	}

	@media screen and (min-width: 310px) {
		.filter-mobile-icon {
			display: initial;
		}
	}

	@media screen and (min-width: 990px) {
		.filter-mobile-icon {
			display: none;
		}
	}

	.new-filter {
		transition: all 0.5s ease;
		position: fixed;
		z-index: 10;
		left: 3px;
		background-color: ${PRIMARY_MAIN};

		@media screen and (min-width: 310px) {
			width: 100%;
			top: 0;
			position: fixed;
		}
		@media screen and (min-width: 990px) {
			height: 700px;
			top: 15%;
		}
	}

	@media screen and (min-width: 310px) {
		.new-filter {
			display: initial;
			overflow-y: scroll;
		}
	}
	@media screen and (min-width: 990px) {
		.new-filter {
			display: none;
		}
	}

	video {
		width: 100vw;
		height: 100vh;
		object-fit: cover;
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: -1;
	}

	#overlay-logo {
		position: relative;
		margin: auto;
		z-index: 1;
		width: 300px;
		height: 50px;
	}

	#overlay-button-border {
		position: absolute;
		width: 500px;
		padding: 20px;
		left: calc(50% - 250px);
		top: 40%;
		z-index: 1;
	}

	#overlay-button {
		position: relative;
		padding: 10px;
		margin: auto;
		border-radius: 10px;
		background-color: ${TEXT_GREEN};
		cursor: pointer;
	}

	// Fixed-position background image
	&::before {
		${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')}
		background-position: center center;
		content: ' ';
		position: fixed; // instead of background-attachment
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		background-color: #0a0c10;

		${(props) =>
			props.backgroundImage
				? `background-image: url(${props.backgroundImage})`
				: props.noBackgroundImage
				? ''
				: null};
		background-size: cover;
		will-change: transform; // creates a new paint layer
		z-index: -1;
	}

	.discover-header-wrapper {
		padding-block-start: 0;
		@media screen and (min-width: 1024px) {
			padding-block-start: 14.5rem;
		}
	}
`;

PageContainer.defaultProps = {
	paddingTop: '0px',
};

export const MainnetWarningMessage = styled.div<PropsInterface>`
	z-index: 2;
	display: ${(props) => (props.isVisible ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	background-color: orange;
	color: ${TEXT_DARK};
	width: 100%;
	height: 2.5em;
`;

MainnetWarningMessage.defaultProps = {
	isVisible: true,
};
