import styled from 'styled-components';
import {
	BUTTON_PRIMARY_MAIN,
	PRIMARY_MAIN,
	HIGHLIGHT_COLOR,
	SCROLLBAR_THUMB,
	SCROLLBAR_TRACK,
	SECONDARY_MAIN,
	TEXT_DARK,
	TEXT_WHITE,
	NEW_SECONDARY_MAIN,
	TERTIARY_MAIN,
} from '../../../constants/colors';
import {
	MOBILE_DEVICE_WIDTH_SMALL,
	MOBILE_DEVICE_HEADER_WIDTH,
} from '../../../constants/dimensions';
import ArrowLeft from '../../../images/scrollbar/scrollbar-arrow-left.png';
import ArrowRight from '../../../images/scrollbar/scrollbar-arrow-right.png';

interface Props {
	marginTop?: string;
	marginBottom?: string;
	backgroundColor?: string;
	lineHeight?: string;
	justifyContent?: string;
	disabled?: boolean;
	display?: string;
	padding?: string;
	gap?: string;
	fontSize?: string;
	color?: string;
	isAllImagesLoaded?: boolean;
}

export const ArrowButton = styled.button`
	color: ${TEXT_WHITE};
	background: ${TEXT_DARK};
	border: none;
	outline: none;
	cursor: pointer;
`;

export const FlexDiv = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
`;

export const TitleDiv = styled.div`
	width: 340px;
	text-align: left;
	margin-top: 70px;

	hr {
		margin-top: 10px;
		margin-bottom: 20px;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_HEADER_WIDTH}px) {
		width: 86%;
		margin-top: 30px;
	}
`;

export const TitleHeader = styled.h1`
	font-weight: bold;
	font-size: 40px;
	margin-bottom: 20px;
`;

export const SubtitleDiv = styled.div<Props>`
	font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
	color: ${(props) => (props.color ? props.color : TEXT_WHITE)};

	display: flex;
	justify-content: ${(props) => props.justifyContent};
	padding: 0px ${(props) => props.padding};

	@media screen and (min-width: 768px) {
		margin-bottom: ${(props) =>
			props.marginBottom ? props.marginBottom : '10px'};
	}
`;

SubtitleDiv.defaultProps = {
	justifyContent: 'space-between',
};

export const MintDiv = styled.div`
	background-color: ${PRIMARY_MAIN};
	width: 90vw;
	max-width: 1400px;
	border-radius: 8px;
	margin-top: 65px;
	margin-bottom: 50px;

	height: 695px;
	// box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);

	@media screen and (max-width: ${MOBILE_DEVICE_HEADER_WIDTH}px) {
		width: 86%;
		margin-top: 20px;
	}
`;

export const MintHeader = styled.div`
	display: none;
	@media screen and (min-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: ${TERTIARY_MAIN};
		height: 67px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	p {
		margin: 0;
	}
`;

export const MintContentDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const MintContentTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	font-weight: 600;
	font-size: 24px;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH_SMALL}px) {
		font-size: 20px;
		gap: 5px;
	}
`;

export const ChoosePvnkzDiv = styled.div`
	width: 93%;
	height: 97%;
	margin-top: 3%;
	text-align: left;

	hr {
		margin: 0;
		margin-bottom: 5px;
	}
`;

export const SkvllpvnkzHorizontalSlider = styled.div`
	width: 100%;
	height: 300px;

	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	gap: 15px;

	.breading-slider {
		display: flex;
	}

	::-webkit-scrollbar {
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background: ${SCROLLBAR_TRACK};
	}
	::-webkit-scrollbar-thumb {
		background: ${SCROLLBAR_THUMB};
		border-radius: 2px;
	}

	::-webkit-scrollbar-button:single-button {
		background-color: ${HIGHLIGHT_COLOR};
		display: block;
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-button:horizontal:increment {
		background-image: url(${ArrowRight});
		background-size: 5px 7px;
		background-repeat: no-repeat;
		background-position: center;
	}

	::-webkit-scrollbar-button:horizontal:decrement {
		background-image: url(${ArrowLeft});
		background-size: 5px 7px;
		background-repeat: no-repeat;
		background-position: center;
	}
`;

export const ShowButtonDiv = styled.div`
	display: flex;

	label {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 11px;

		margin-left: 10px;

		input {
			opacity: 0;
			width: 0;
			height: 0;
		}

		span {
			position: absolute;
			cursor: pointer;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: white;
			-webkit-transition: 0.4s;
			transition: 0.4s;
			border-radius: 34px;
		}

		span:before {
			position: absolute;
			content: '';
			height: 7px;
			width: 7px;
			top: 2px;
			left: 2px;
			background-color: black;
			transition: 0.4s;
			border-radius: 50%;
		}

		input:checked + span {
			background-color: ${NEW_SECONDARY_MAIN};
		}

		input:checked + span:before {
			transform: translateX(8px);
		}
	}
`;

export const MintDetails = styled.div<Props>`
	font-size: 16px;
	line-height: ${(props) => props.lineHeight};

	display: flex;
	justify-content: ${(props) => props.justifyContent};

	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
`;

MintDetails.defaultProps = {
	marginBottom: '0px',
	marginTop: '0px',
	lineHeight: '20px',
	justifyContent: 'space-between',
};

export const SkvllpvnkContainer = styled.div`
	display: inline-block;
	vertical-align: top;
	width: 137px;
	height: 177px;
	background-color: ${SECONDARY_MAIN};
	margin-right: 10px;
	border-radius: 5px;
	padding: 15px;

	font-size: 10px;
	line-height: 10px;
	color: ${BUTTON_PRIMARY_MAIN};
`;

export const DetailText = styled.div<Props>`
	display: ${(props) => props.display};
	gap: ${(props) => props.gap};
	font-size: 13px;
	margin-bottom: 15px;
	color: ${TEXT_WHITE};

	p {
		margin: 0;
	}

	.red {
		color: red;
	}
`;
