enum SessionKeys {
	PENDING_ITEM_PURCHASE_TRANSACTION = 'pendingItemPurchaseTransaction',
	PENDING_OPENSEA_PURCHASE_TRANSACTION = 'pendingOpenSeaPurchaseTransaction',
	PENDING_LOTTERY_ENTRY_PURCHASE_TRANSACTION = 'pendingItemPurchaseTransaction',
	SELECTED_ADDRESS = 'selectedAddress',
	SELECTED_CHAIN_ID = 'selectedChainId',
	SELECTED_WALLET_OPTION = 'selectedWalletOption',
}

export default SessionKeys;
