import { combineReducers } from 'redux';
import walletReducer from './wallet';
import skvllbabiezReducer from './skvllbabiez';
import skvllpvnkzReducer from './skvllpvnkz';
import collectionReducer from './collection';
import ammoliteReducer from './ammolite';
import rewardsAndStakingReducer from './rewardsAndStaking';
import filterReducer from './filter';
import galleryReducer from './gallery';
import pendingRewardsReducer from './pendingRewards';
import storeReducer from './store';
import authReducer from './auth';
import discordReducer from './discord';
import lotteryReducer from './lottery';
import loaderReducer from './loader';
import opensea from './opensea';
import stats from './stats';

export default combineReducers({
	auth: authReducer,
	discord: discordReducer,
	wallet: walletReducer,
	skvllbabiez: skvllbabiezReducer,
	skvllpvnkz: skvllpvnkzReducer,
	collection: collectionReducer,
	ammolite: ammoliteReducer,
	rewards_and_staking: rewardsAndStakingReducer,
	filter: filterReducer,
	gallery: galleryReducer,
	store: storeReducer,
	lottery: lotteryReducer,
	pendingRewards: pendingRewardsReducer,
	loader: loaderReducer,
	opensea: opensea,
	stats: stats,
});
