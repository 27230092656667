enum ProgressInfo {
	CHECK_FOR_EXISTING_TX = 'Checking if a transaction is already in progress...',
	CHECK_PORTAL_IS_OPEN = 'Checking if portal is still open...',
	SCANNING_EXISTING_KEYS = 'Scanning existing portal keys...',
	CHECK_PORTAL_LIMITS = 'Assessing portal key limits...',
	SELECTED_WALLET_OPTION = 'selectedWalletOption',
	CALCULATING_COST = 'Calculating total cost...',
	CHECKING_WALLET_BALANCE = 'Checking wallet Ammo balance...',
	VALIDATE_MEMBERSHIP = 'Validating Hideout membership...',
	VERIFY_SPEND_ALLOWANCE = 'Verifying spend allowance...',
	BROADCAST_TX = 'Broadcasting network transaction...',
	SIGNING_TX = 'Signing network transaction...',
	ESTIMATING_GAS = 'Estimating gas...',
	AWAITING_CONFIRMATION = 'Awaiting user & network confirmation...',
	TX_CONFIRMED = 'Transaction confirmed...',
	EXECUTION_SUCCESS = 'Execution successful. Please wait for final server confirmation...',
	FETCH_USER_PORTAL_KEYS = 'Fetching portal key codes...',
	REGISTER_TX = 'Registering key codes with server...',
	FAILED = 'The transaction failed...',
}

export default ProgressInfo;
