import React from 'react';
import { BLUE, ORANGE, TEXT_GREEN } from '../../../resources/constants/colors';
import {
	ChangeType,
	NotesListItemsInterface,
} from '../../../resources/placeholders/releaseNotes';
import {
	Notes,
	ReleaseNotesTextContainer,
	ReleaseVersion,
} from '../../../resources/styles/ReleaseNotes.styles';
import ChangedItems from './ChangedItems';

interface PropsInterface {
	changes: NotesListItemsInterface[] | undefined;
}

const Changes = (props: PropsInterface): JSX.Element => {
	const { changes } = props;
	return (
		<>
			{changes?.map((change) => {
				let versionColor = ORANGE;
				if (change.type === ChangeType.IMPROVED) {
					versionColor = BLUE;
				} else if (change.type === ChangeType.NEW) {
					versionColor = TEXT_GREEN;
				}
				return (
					<ReleaseNotesTextContainer key={change.type}>
						<ReleaseVersion bgColor={versionColor}>
							<b>{change.type}</b>
						</ReleaseVersion>
						<Notes>
							{change.description || ''}
							<ChangedItems changes={change} />
						</Notes>
					</ReleaseNotesTextContainer>
				);
			})}
		</>
	);
};

export default Changes;
