import { Dispatch } from 'react';

import { SET_STATS } from './types/stats';

export const setStatsState =
	(stats: any) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: SET_STATS,
			payload: { stats },
		});
	};
