import React, { useCallback, useEffect, useState } from 'react';
import Loader from '../../../../Loader';
import UserStatsInterface from '../../../../../resources/interfaces/UserStatsInterface';
import StatsContent from './StatsContent';
import RootReducerStateInterface from '../../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useSelector } from 'react-redux';

const SidebarStats = (): JSX.Element => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [userStats, setUserStats] = useState<UserStatsInterface>({});
	const { stats } = useSelector(
		(state: RootReducerStateInterface) => state.stats
	);

	const handleFetchUserStats = async () => {
		setUserStats(() => stats);
		setIsLoading(false);
	};

	useEffect(() => {
		handleFetchUserStats();
	}, []);

	const isEth = (key: string) =>
		key === 'totalVolume' ||
		key === 'totalBuyVolume' ||
		key === 'totalSellVolume' ||
		key === 'averageCollectionsValue' ||
		key === 'totalGas';

	const gweiToEth = useCallback(
		(value: string) => parseFloat(value) / 1000000000,
		[]
	);
	const formatName = useCallback(
		(key: string) => key.replace(/([A-Z])/g, ' $1').toUpperCase(),
		[]
	);

	return (
		<>
			{isLoading ? (
				<Loader size="30px" marginTop="50px" />
			) : (
				Object.entries(userStats).map(([key, value]) => {
					if (key !== 'account' && key !== 'collections') {
						const formattedName = formatName(key);
						const gwei = key === 'totalGas' ? gweiToEth(value) : undefined;
						const statValue = parseFloat(
							parseFloat(value).toFixed(4)
						).toString();
						return (
							<div key={key}>
								<StatsContent
									formattedName={formattedName}
									gwei={gwei}
									value={statValue}
									isEth={isEth(key)}
								/>
							</div>
						);
					}
				})
			)}
		</>
	);
};

export default SidebarStats;
