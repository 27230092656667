import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { Button, Collapse } from 'reactstrap';
import {
	setBuyNowFilter,
	setFilterCollapseStates,
	setVirginFilter,
} from '../../../redux/actions/filter';
import { FilterTypes } from '../../../resources/enums/FilterTypes';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	FilterCollapse,
	FilterCollapseContent,
	FilterParagraph,
	FilterSection,
	SwitchButtonNo,
	SwitchButtonOff,
	SwitchButtonWrapper,
	SwitchButtonYes,
} from '../../../resources/styles/FilterPanel.styles';
import OpenseaIcon from '../../../resources/images/logos/opensea.png';
import { Collections } from '../../../resources/enums/Collections';
interface Props {
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
}

const StatusFilter = (props: Props): JSX.Element => {
	const { filterSectionIcon } = props;
	const dispatch = useDispatch();
	const {
		filterCollapseStates,
		virginFilter,
		buyNowFilter,
		selectedCollection,
	} = useSelector((state: RootReducerStateInterface) => state.filter);
	const isFilterOpen = filterCollapseStates[FilterTypes.STATUS].isOpen;
	const isVirginNoButtonActive = !virginFilter && virginFilter !== undefined;

	return (
		<div
			data-tip="Virgin Skvllpvnkz can be used to mint Skvllbabiez"
			data-delay-show="1000"
		>
			<ReactTooltip />
			<FilterSection
				onClick={() => dispatch(setFilterCollapseStates(FilterTypes.STATUS))}
				active={isFilterOpen}
				paddingRight="20px"
			>
				Status
				{filterSectionIcon(isFilterOpen)}
			</FilterSection>
			<FilterCollapse>
				<Collapse className="filter-collapse" isOpen={isFilterOpen}>
					<FilterCollapseContent>
						{selectedCollection === Collections.skvllpvnkz_hideout && (
							<SwitchButtonWrapper>
								<FilterParagraph paddingLeft="35px">Virgin</FilterParagraph>
								<div>
									<SwitchButtonOff
										isBackgroundColorActive={virginFilter === undefined}
										onClick={() => dispatch(setVirginFilter(undefined))}
									>
										<Button>Off</Button>
									</SwitchButtonOff>
									<SwitchButtonYes
										isBackgroundColorActive={virginFilter}
										onClick={() => dispatch(setVirginFilter(true))}
									>
										<Button>Yes</Button>
									</SwitchButtonYes>
									<SwitchButtonNo
										isBackgroundColorActive={isVirginNoButtonActive}
										onClick={() => dispatch(setVirginFilter(false))}
									>
										<Button>No</Button>
									</SwitchButtonNo>
								</div>
							</SwitchButtonWrapper>
						)}
						<SwitchButtonWrapper>
							<FilterParagraph paddingLeft="35px">
								Buy Now
								<img
									style={{ margin: 'auto', paddingLeft: 10, marginTop: -2 }}
									height={20}
									src={OpenseaIcon}
								></img>
							</FilterParagraph>
							<div>
								<SwitchButtonOff
									isBackgroundColorActive={buyNowFilter === undefined}
									onClick={() => dispatch(setBuyNowFilter(undefined))}
								>
									<Button>Off</Button>
								</SwitchButtonOff>
								<SwitchButtonNo
									isBackgroundColorActive={buyNowFilter}
									onClick={() => dispatch(setBuyNowFilter(true))}
								>
									<Button>Yes</Button>
								</SwitchButtonNo>
							</div>
						</SwitchButtonWrapper>
					</FilterCollapseContent>
				</Collapse>
			</FilterCollapse>
		</div>
	);
};

export default StatusFilter;
