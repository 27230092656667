import React, { useEffect, useState } from 'react';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import {
	SidebarDetails,
	SidebarMenuDiv,
} from '../../../resources/styles/Sidebar.style';

import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import Loader from '../../Loader';
import Identicon from 'react-identicons';
import SidebarWallet from './components/SidebarWallet';
import SidebarRewards from './components/SidebarRewards';
import ReactTooltip from 'react-tooltip';
import SidebarStats from './components/SidebarStats/SidebarStats';
import { SidebarTabIds } from '../../../resources/enums/SidebarTabs';
import SidebarStorePurchases from './components/SidebarStorePurchases/SidebarStorePurchases';
import StoreItemModalView from '../../modals/StoreItemModalView/StoreItemModalView';
import SidebarUserDropdown from './components/SidebarUserDropdown';
import { formatAddress } from '../../../helpers/address';
import { countActiveReservations } from '../../../helpers/store';

interface PropsInterface {
	handleSidebar: () => void;
}

const Sidebar = (props: PropsInterface): JSX.Element => {
	const { handleSidebar } = props;
	const { address, balance, wallet } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);
	const {
		selectedItem,
		isStoreModalOpen,
		reservedItems,
		purchasedItems,
		storeData,
	} = useSelector((state: RootReducerStateInterface) => state.store);
	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite
	);
	const { username } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);
	const [isWalletAddressLoading, setIsWalletAddressLoading] = useState(true);
	const [formattedWalletAddress, setFormattedWalletAddress] =
		useState<string>();

	const [selectedMenu, setSelectedMenu] = useState<SidebarTabIds>(
		SidebarTabIds.IN_WALLET_TAB
	);
	const [numberOfReservations, setNumberOfReservations] = useState(0);

	useEffect(() => {
		setNumberOfReservations(
			countActiveReservations(reservedItems, purchasedItems)
		);

		if (address) {
			setFormattedWalletAddress(formatAddress(address));
			setIsWalletAddressLoading(false);
		}
	}, [address, reservedItems, purchasedItems]);

	const formattedDiscordUsername = username?.split('#')[0];
	return (
		<>
			<SidebarDetails
				padding="20px"
				cursor="pointer"
				onClick={handleSidebar}
				id="back-button"
			>
				<p className='close'>{closeIconSVG}</p>
			</SidebarDetails>
			<SidebarDetails marginBottom="0px">
				<SidebarDetails>
					<UncontrolledDropdown>
						<DropdownToggle caret nav id="WalletDropdown">
							<div id="WalletDropdownIcon">
								<Identicon string={address} size={35} />
							</div>
							<p>{formattedDiscordUsername || 'My wallet'}</p>
						</DropdownToggle>
						<SidebarUserDropdown
							wallet={wallet}
							ammolite={ammolite}
							handleSidebar={handleSidebar}
						/>
					</UncontrolledDropdown>
				</SidebarDetails>
				{isWalletAddressLoading ? (
					<Loader size="10px" />
				) : (
					<p
						className="grayed-out"
						id="wallet-address"
						data-tip={address + ' | Click to copy'}
						data-delay-show="500"
						onClick={() => navigator.clipboard.writeText(address)}
					>
						<ReactTooltip />
						{formattedWalletAddress}
					</p>
				)}
			</SidebarDetails>
			<SidebarMenuDiv selectedMenu={selectedMenu} marginBottom="0px">
				<SidebarDetails width="90%">
					<div>
						<p
							id="wallet-menu"
							onClick={() => setSelectedMenu(SidebarTabIds.IN_WALLET_TAB)}
						>
							Wallet
						</p>
						<div id="wallet-marker"></div>
					</div>
					<div>
						<p
							id="rewards-menu"
							onClick={() => setSelectedMenu(SidebarTabIds.IN_REWARDS_TAB)}
						>
							Rewards
						</p>
						<div id="rewards-marker"></div>
					</div>
					<div id="purchases-container">
						<p
							id="purchases-menu"
							onClick={() => setSelectedMenu(SidebarTabIds.IN_PURCHASES_TAB)}
						>
							Purchases
						</p>
						{!!numberOfReservations && <div id="purchases-notification"></div>}

						<div id="purchases-marker"></div>
					</div>
					<div>
						<p
							id="stats-menu"
							onClick={() => setSelectedMenu(SidebarTabIds.IN_STATS_TAB)}
						>
							Stats
						</p>
						<div id="stats-marker"></div>
					</div>
				</SidebarDetails>
			</SidebarMenuDiv>
			<hr />
			{selectedMenu === SidebarTabIds.IN_WALLET_TAB && (
				<SidebarWallet address={address} balance={balance} handleSidebar={handleSidebar} />
			)}
			{selectedMenu === SidebarTabIds.IN_REWARDS_TAB && <SidebarRewards />}
			{selectedMenu === SidebarTabIds.IN_PURCHASES_TAB && (
				<SidebarStorePurchases />
			)}
			{selectedMenu === SidebarTabIds.IN_STATS_TAB && <SidebarStats />}
			<StoreItemModalView
				selectedItem={selectedItem}
				isStoreModalOpen={isStoreModalOpen}
				reservedItemsCount={
					storeData[selectedItem.id]?.purchaseList?.length || 0
				}
			/>
		</>
	);
};

export default Sidebar;

const closeIconSVG = (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
			fill="white"
		/>
	</svg>
);