// import { ethers } from 'ethers';
import { Dispatch } from 'react';
import { setIsModalOpen, setSelectedNFT } from '../redux/actions/gallery';
import {
	DAYCARE_ID_LIMIT,
	HIDEOUT_ID_LIMIT,
} from '../resources/constants/limits';
import { Collections } from '../resources/enums/Collections';
import { SiteRoutes } from '../resources/enums/SiteRoutes';
// import { SiteRoutes } from '../resources/enums/SiteRoutes';
import NFTImageInterface from '../resources/interfaces/NFTImageInterface';

// export const isDiscoverURLInvalid = (URL: string): boolean => {
// 	const isURLAnInvalidDiscoverPage =
// 		!URL.includes(SiteRoutes.discoverHideout + '/') &&
// 		!URL.includes(SiteRoutes.discoverDaycare + '/');
// 	return isURLAnInvalidDiscoverPage || !processURLParams(URL);
// };

export const parseUrlPath = () => {
	const URL = window.location.href;
	const urlSplit = URL.split('/');
	const page = '/' + (urlSplit[3] as SiteRoutes);
	const hasTokenInUrl =
		(page === SiteRoutes.profile &&
			urlSplit.length === 7 &&
			urlSplit[6] !== '') ||
		(page === SiteRoutes.discover &&
			urlSplit.length === 6 &&
			urlSplit[5] !== '');
	const startFrom =
		Number(URL.split('/')[page === SiteRoutes.profile ? 6 : 5]) - 1;

	return { urlSplit, page, hasTokenInUrl, startFrom };
};
export const convertToValidNFTID = (
	URLParam: string,
	discoverCollection: string
): number => {
	const upperLimit =
		discoverCollection === Collections.skvllpvnkz_hideout
			? HIDEOUT_ID_LIMIT
			: DAYCARE_ID_LIMIT;
	if (parseInt(URLParam) > 0 && parseInt(URLParam) < upperLimit)
		return parseInt(URLParam);
	return 0;
};

interface URLParams {
	valid: boolean;
	id: number | null;
	address: string | null;
}

// export const processURLParams = (path: string): URLParams => {
// 	const urlParams: URLParams = {
// 		valid: false,
// 		id: null,
// 		address: null,
// 	};
// 	try {
// 		if (
// 			(path.includes(SiteRoutes.discover +  + '/') ||
// 				path.includes(SiteRoutes.discoverHideout + '/')) &&
// 			path.split('/').length === 4
// 		) {
// 			const param = path.split('/')[3];
// 			const discoverCollection = path.split('/')[2].replace('-', '_');
// 			if (ethers.utils.isAddress(param)) {
// 				urlParams.valid = true;
// 				urlParams.address = param;
// 			} else if (convertToValidNFTID(param, discoverCollection)) {
// 				urlParams.valid = true;
// 				urlParams.id = convertToValidNFTID(param, discoverCollection);
// 			}
// 		}
// 	} catch {
// 		return urlParams;
// 	}
// 	return urlParams;
// };

export const handleValidURLParam = (
	dispatch: Dispatch<unknown>,
	urlParams: URLParams,
	selectedNFT: NFTImageInterface
): void => {
	if (urlParams.id) {
		dispatch(setSelectedNFT(selectedNFT));
		dispatch(setIsModalOpen(true));
	}
};
