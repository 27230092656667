import React from 'react';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { SidebarStorePurchaseContainer } from '../../../../../resources/styles/Sidebar.style';
import StoreItem from './StoreItem';
import LoadPurchase from './LoadPurchase';

const SidebarStorePurchases = (): JSX.Element => {
	const { storeData, purchasedItems, reservedItems } = useSelector(
		(state: RootReducerStateInterface) => state.store
	);

	const ItemsPurchased = (): JSX.Element => {
		return (
			<>
				{purchasedItems.map((item, key) => (
					<StoreItem key={key} item={storeData[item.id]} />
				))}
			</>
		);
	};

	const ItemsReserved = (): JSX.Element => {
		return (
			<>
				{reservedItems.map(
					(item, key) =>
						!purchasedItems.some(
							(purchasedItem) => purchasedItem.id === item.itemId
						) && (
							<StoreItem
								isReserved
								key={key}
								item={storeData[item.itemId]}
								isExpired={item.isExpired}
							/>
						)
				)}
			</>
		);
	};

	return (
		<SidebarStorePurchaseContainer>
			<LoadPurchase />
			<ItemsPurchased />
			<ItemsReserved />
		</SidebarStorePurchaseContainer>
	);
};

export default SidebarStorePurchases;
