import React from 'react';
import {
	FilterCollapse,
	FilterSection,
} from '../../../../resources/styles/FilterPanel.styles';
import TraitFilterSection from './TraitFilterSection';
import { FilterTypes } from '../../../../resources/enums/FilterTypes';
import Select, { MultiValue } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { Collapse } from 'reactstrap';
import {
	setFilterCollapseStates,
	setSelectedTraits,
} from '../../../../redux/actions/filter';
import { customStyles } from '../../../../resources/constants/filters';
import TraitObjectInterface from '../../../../resources/interfaces/TraitObjectInterface';
import { processSelectedTraits } from '../../../../helpers/gallery';

interface Props {
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
}

const TraitFilter = (props: Props): JSX.Element => {
	const { filterSectionIcon } = props;
	const dispatch = useDispatch();
	const { selectedTraits, filterCollapseStates, traitsArray } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);
	const isFilterOpen = filterCollapseStates[FilterTypes.TRAIT].isOpen;

	const replaceAll = (text: string, find: string, replace: string) =>
		text.replace(new RegExp(find, 'g'), replace);

	const getFormattedTraits = () => {
		const filters: { value: string; label: string; traitType: string }[] = [];
		selectedTraits.flatMap((traitCategory) =>
			traitCategory.values.map((traitFilter) =>
				filters.push({
					value: traitFilter,
					label: replaceAll(traitFilter, '_', ' '),
					traitType: traitCategory.trait_type,
				})
			)
		);
		return filters;
	};

	const handleSelectComponent = (
		remainingTraits: MultiValue<{
			value: string;
			label: string;
			traitType: string;
		}>
	) => {
		let newSelectedTraits: TraitObjectInterface[] = [];
		remainingTraits.forEach(
			(trait) =>
				(newSelectedTraits = processSelectedTraits(newSelectedTraits, trait))
		);
		dispatch(setSelectedTraits(newSelectedTraits));
	};

	return (
		<>
			<FilterSection
				onClick={() => dispatch(setFilterCollapseStates(FilterTypes.TRAIT))}
				active={isFilterOpen}
				paddingRight="20px"
			>
				Traits
				{filterSectionIcon(isFilterOpen)}
			</FilterSection>
			<FilterCollapse hasNoLine={true}>
				<Collapse className="filter-collapse" isOpen={isFilterOpen}>
					<div id="filter-multiselects">
						{getFormattedTraits().length > 0 && (
							<Select
								isMulti
								value={getFormattedTraits()}
								name="colors"
								className="filter-selection"
								openMenuOnClick={false}
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
								}}
								styles={customStyles}
								onChange={(selected) => handleSelectComponent(selected)}
							/>
						)}
					</div>
					{traitsArray && (
						<>
							{traitsArray.map((trait, id) => {
								if (trait.name !== 'trait_count' && trait.name !== 'none')
									return (
										<TraitFilterSection
											key={trait.name}
											filterSectionIcon={filterSectionIcon}
											trait={trait}
											traitIndex={id}
										/>
									);
							})}
						</>
					)}
				</Collapse>
			</FilterCollapse>
		</>
	);
};

export default TraitFilter;
