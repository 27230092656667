import { Network, OpenSeaPort } from 'opensea-js';
import { OrderSide } from 'opensea-js/lib/types';
import { Dispatch } from 'react';
import config from '../config';
import { setLoaderSubtitle } from '../redux/actions/loader';
import { Collections } from '../resources/enums/Collections';
import Wallet from './Wallet';
import ProgressInfo from '../resources/enums/ProgressInfo';

export default class OpenSea {
	private wallet;
	private provider;
	private seaport;
	private dispatch;

	constructor(wallet: Wallet, dispatch: Dispatch<unknown>) {
		this.wallet = wallet;
		this.dispatch = dispatch;
		this.provider = wallet.library;

		this.seaport = new OpenSeaPort(
			this.provider.getSigner().provider.provider,
			{
				networkName: Network.Main,
				apiKey: config.OPENSEA_API_KEY,
			}
		);
	}

	public async buyAsset(collection: Collections, token_id: number) {
		this.dispatch(setLoaderSubtitle('Fetching contract address...'));
		const getCollectionContractAddress = () => {
			switch (collection) {
				case Collections.skvllpvnkz_hideout:
					return config.SKVLLPVNKZ_CONTRACT_ADDRESS;
				case Collections.skvllpvnkz_daycare:
					return config.SKVLLBABIEZ_CONTRACT_ADDRESS;
				default:
					throw new Error('Unsupported Collection');
			}
		};
		this.dispatch(setLoaderSubtitle('Fetching orders...'));
		const { orders } = await this.seaport.api.getOrders({
			asset_contract_address: getCollectionContractAddress(),
			token_id,
			side: OrderSide.Sell,
		});

		if (orders.length == 0) {
			throw new Error('Complete purchase on OpenSea');
		}

		this.dispatch(setLoaderSubtitle(ProgressInfo.AWAITING_CONFIRMATION));
		const txHash = await this.seaport.fulfillOrder({
			order: orders[0],
			accountAddress: this.wallet.address,
		});
		this.dispatch(setLoaderSubtitle(ProgressInfo.TX_CONFIRMED));
		this.dispatch(setLoaderSubtitle(ProgressInfo.EXECUTION_SUCCESS));
		return txHash;
	}
}
