import React, { useEffect, useState } from 'react';
import { RafflePageTabIds } from '../../../../resources/enums/RafflePageTabIds';
import {
	TitleDiv,
	SubtitleDiv,
	PrizeContainer,
	EntryHeader,
	EntriesButtonLink,
} from '../../../../resources/styles/RafflePage/RafflePage.styles';
import CountdownTimer from './CountdownTimer';
import EntriesTable from './EntriesTable';
import SkvllpvnkzImagePlaceholder from '../../../../resources/images/placeholders/skvllpvnkz-image-placeholder.png';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import {
	PRIMARY_MAIN,
	SECONDARY_DARK_GRAY,
	TEXT_WHITE,
} from '../../../../resources/constants/colors';
import {
	LotteryEntryInterface,
	LotteryInterface,
} from '../../../../resources/interfaces/LotteryInterface';
import WalletNotConnected from '../../../../components/WalletNotConnected';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import key from '../../../../resources/images/icons/key.svg';
import PurchaseEntry from '../PurchaseEntry/PurchaseEntry';
import Lottery from '../../../../services/Lottery';

interface PropsInterface {
	changeRaffleTab: (tabId: RafflePageTabIds) => void;
	currentLottery: LotteryInterface | undefined;
	previousLottery?: LotteryInterface | undefined;
	allPreviousWinners: LotteryEntryInterface[] | undefined;
	isLotteryOpen: boolean;
	userPortalkeysCount: number;
	setIsDrawInProgressLocally: React.Dispatch<React.SetStateAction<boolean>>;
	lottery: Lottery | undefined;
}

const MainPage = (props: PropsInterface): JSX.Element => {
	const {
		changeRaffleTab,
		isLotteryOpen,
		currentLottery,
		allPreviousWinners,
		userPortalkeysCount,
		setIsDrawInProgressLocally,
		lottery,
	} = props;
	const totalEntriesOfCurrentLottery = currentLottery?.numberOfEntries || 0;

	const hasEntries =
		(currentLottery && currentLottery?.entries.length > 0) || false;

	const [showCurrentEntries, setShowCurrentEntries] = useState(hasEntries);
	const [showCraftPortalKey, setShowCraftPortalKey] = useState(false);
	const [isExecutingATransaction, setIsExecutingATransaction] = useState(false);
	let entries = undefined;

	if (showCurrentEntries) {
		if (isLotteryOpen) {
			entries = currentLottery?.entries;
		} else {
			entries = currentLottery?.winningEntries;
		}
	} else {
		entries = allPreviousWinners;
	}

	// this function will be use to change between current entries and previous
	const showCurrentEntriesTable = () => {
		if (isLotteryOpen) {
			entries = currentLottery?.entries;
		} else {
			entries = currentLottery?.winningEntries;
		}
		setShowCurrentEntries(true);
	};

	const showPreviousEntriesTable = () => {
		entries = allPreviousWinners;
		setShowCurrentEntries(false);
	};

	useEffect(() => {
		setShowCurrentEntries(hasEntries);
	}, [currentLottery]);

	const subtitleText = isLotteryOpen
		? 'Craft Keys and Open Portals'
		: 'Portal is closed';

	const prizeImage =
		currentLottery?.prizeImageResource || SkvllpvnkzImagePlaceholder;

	const chanceOfWinning =
		totalEntriesOfCurrentLottery > 0
			? (userPortalkeysCount / totalEntriesOfCurrentLottery) * 100
			: 0;

	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);

	if (!isAuthenticated) {
		return (
			<WalletNotConnected
				height="211px"
				subtitle="Connect your wallet to buy tickets."
			/>
		);
	}

	return (
		<>
			<TitleDiv>Animus Beacon</TitleDiv>
			<SubtitleDiv>{subtitleText}</SubtitleDiv>
			<EntryHeader>
				<div className="timer">
					<CountdownTimer
						setIsDrawInProgressLocally={setIsDrawInProgressLocally}
					/>
				</div>
				<PrizeContainer order={'3'}>
					<header className="title">
						<h3>Loot</h3>
					</header>
					<div className="portal-content">
						<img src={prizeImage} alt="prize" />
						<p id="item">{currentLottery?.prizeLabel}</p>
						<p id="worth">{currentLottery?.portalName} Portal</p>
					</div>
				</PrizeContainer>
				<PrizeContainer order={'-1'}>
					{showCraftPortalKey && (
						<>
							<PurchaseEntry
								changeRaffleTab={changeRaffleTab}
								currentLottery={currentLottery}
								lottery={lottery}
								isExecutingATransaction={isExecutingATransaction}
								setIsExecutingATransaction={setIsExecutingATransaction}
							/>
							{showCraftPortalKey && !isExecutingATransaction && (
								<p
									id="green-text"
									onClick={() => setShowCraftPortalKey(!showCraftPortalKey)}
								>
									Maybe later
								</p>
							)}
						</>
					)}
					{!showCraftPortalKey && (
						<>
							<header className="title">
								<h3>Crafted Keys</h3>
								<p>
									<img src={key} alt="key" />
								</p>
							</header>
							<div className="content">
								<h1>{userPortalkeysCount}</h1>
								{isLotteryOpen && (
									<p>({chanceOfWinning.toFixed(2)}% chance of winning)</p>
								)}
							</div>

							<CustomButton
								borderRadius="5px"
								marginBottom={!isLotteryOpen ? '50px' : '-50px'}
								width="160px"
								fontSize="16px"
								backgroundColor={PRIMARY_MAIN}
								color={TEXT_WHITE}
								backgroundColorHover={SECONDARY_DARK_GRAY}
								onClick={() =>
									changeRaffleTab(RafflePageTabIds.USER_ENTRIES_PAGE)
								}
							>
								<b>View your keys</b>
							</CustomButton>

							{isLotteryOpen && (
								<CustomButton
									borderRadius="5px"
									width="160px"
									color={TEXT_WHITE}
									fontSize="16px"
									borderWidth="0"
									onClick={() => setShowCraftPortalKey(!showCraftPortalKey)}
								>
									<b>Craft Portal Key </b>
								</CustomButton>
							)}
						</>
					)}
				</PrizeContainer>
			</EntryHeader>
			{isLotteryOpen && hasEntries && (
				<>
					<EntriesButtonLink active={`${showCurrentEntries}`}>
						<b onClick={showCurrentEntriesTable}>Current entries</b>
						<b onClick={showPreviousEntriesTable}>Previous winners</b>
					</EntriesButtonLink>
				</>
			)}
			<EntriesTable
				entries={entries}
				showAllEntries={showCurrentEntries}
				isLotteryOpen={isLotteryOpen}
			/>
		</>
	);
};

export default MainPage;
