import styled from 'styled-components';
import {
	TEXT_GRAYED_OUT,
	LINE_COLOR,
	FILTER_MAIN,
	SWITCH_BUTTON_OFF,
	SWITCH_BUTTON_ON,
} from '../constants/colors';

interface Props {
	active?: boolean;
	hasNoLine?: boolean;
	isBackgroundColorActive?: boolean;
	marginLeft?: string;
	marginRight?: string;
	marginBottom?: string;
	paddingRight?: string;
	paddingLeft?: string;
	width?: string;
	fontSize?: string;
	height?: string;
}

export const SwitchButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	div {
		display: flex;
	}
`;

export const SwitchButtonAll = styled.div<Props>`
	Button {
		margin-top: 2px;
		height: 44px;
		width: 50px;
		color: ${(props) =>
			props.isBackgroundColorActive ? TEXT_GRAYED_OUT : 'white'};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
			props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
			border-color: ${LINE_COLOR};
		}

		:focus {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
			border-color: LINE_COLOR;
			box-shadow: none;
		}
	}
`;

export const SwitchButtonPending = styled.div<Props>`
	Button {
		margin-top: 2px;
		height: 44px;
		width: 110px;
		color: ${(props) =>
			props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
			props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
		border-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
		}

		:focus {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
			box-shadow: none;
		}
	}
`;

export const SwitchButtonActivated = styled.div<Props>`
	Button {
		margin-top: 2px;
		height: 44px;
		width: 110px;
		color: ${(props) =>
			props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
			props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
		border-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
		}

		:focus {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
			box-shadow: none;
		}
	}
`;

export const SwitchButtonCorrupted = styled.div<Props>`
	Button {
		margin-top: 2px;
		height: 44px;
		width: 110px;
		color: ${(props) =>
			props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
			props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
		}

		:focus {
			background-color: ${(props) =>
				props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
				props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
			box-shadow: none;
		}
	}
`;
