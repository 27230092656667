import React, { useCallback, useEffect, useState } from 'react';
import {
	FilterCollapse,
	FilterSection,
} from '../../../resources/styles/FilterPanel.styles';
import { FilterSearch } from '../../../resources/styles/DiscoverPage/Gallery.styles';
import { FilterTypes } from '../../../resources/enums/FilterTypes';
import { Collapse } from 'reactstrap';
import { batch, useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	setFilterByWallet,
	setFilterCollapseStates,
	setSelectedTraits,
} from '../../../redux/actions/filter';
import {
	setCollectionData,
	setIsGalleryLoading,
	setIsLastAsset,
	setNewStartAt,
} from '../../../redux/actions/gallery';
import { ethers } from 'ethers';
import { OrderByKeys } from '../../../resources/enums/OrderByKeys';
import { BATCH_LIMIT } from '../../../resources/constants/limits';
import { useNavigate, useLocation } from 'react-router-dom';
import { SiteRoutes } from '../../../resources/enums/SiteRoutes';
import { fetchAssets } from '../../../firebase/functions/collections';
import { NFTCollectionQueryInterface } from '../../../resources/interfaces/NFTCollectionQueryInterface';

interface Props {
	handleFetchCollectionAssets: (
		isScrolling: boolean,
		clearAddress?: boolean
	) => Promise<void>;
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
}
const SearchFilter = (props: Props): JSX.Element => {
	const { handleFetchCollectionAssets, filterSectionIcon } = props;
	const {
		filterCollapseStates,
		selectedCollection,
		filterByWallet,
		selectedTraits,
		buyNowFilter,
		orderByFilter,
		virginFilter,
	} = useSelector((state: RootReducerStateInterface) => state.filter);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [searchValue, setSearchValue] = useState<string>('');
	const isFilterOpen = filterCollapseStates[FilterTypes.SEARCH].isOpen;

	useEffect(() => {
		if (filterByWallet) {
			setSearchValue(filterByWallet);
		}
	}, [filterByWallet]);

	const setGalleryLoading = useCallback(() => {
		batch(() => {
			dispatch(setIsGalleryLoading(true));
			dispatch(setCollectionData([]));
		});
	}, []);

	const handleEmptySearchString = useCallback(() => {
		navigate(`${SiteRoutes.discover}/${selectedCollection.replace('_', '-')}`);
		batch(() => {
			dispatch(setNewStartAt(0));
			dispatch(setIsLastAsset(false));
			dispatch(setFilterByWallet(''));
			if (selectedTraits.length === 0) {
				handleFetchCollectionAssets(false, true);
			} else {
				dispatch(setSelectedTraits([]));
			}
		});
	}, [selectedCollection, selectedTraits, location.pathname]);

	const handleSearch = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setGalleryLoading();

		if (searchValue === '') {
			handleEmptySearchString();
			return;
		}

		const isVerifiedAddress = ethers.utils.isAddress(searchValue);
		const params = {
			collectionName: selectedCollection,
			startAt: 0,
			buyNow: buyNowFilter,
			orderByField: orderByFilter,
			orderByDirection: 'asc',
			virgin: virginFilter,
			walletAddress: '',
			filters: selectedTraits,
			limit: BATCH_LIMIT,
		} as NFTCollectionQueryInterface;
		if (isVerifiedAddress) {
			dispatch(setFilterByWallet(searchValue));
			params.walletAddress = searchValue;
			navigate(`${location.pathname}/${searchValue}`);
		} else {
			params.startAt = parseInt(searchValue) - 1;
			params.limit = 1;
			params.orderByField = OrderByKeys.TOKEN;
			params.filters = [];
		}

		const { data, newStartAt } = await fetchAssets(params);
		const isLastAsset = data.length < BATCH_LIMIT || !isVerifiedAddress;

		batch(() => {
			dispatch(setCollectionData(data));
			dispatch(setNewStartAt(newStartAt));
			dispatch(setIsLastAsset(isLastAsset));
			dispatch(setIsGalleryLoading(false));
		});
	};

	const handleOnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSearchValue(event.target.value);
			if (event.target.value === '') {
				setGalleryLoading();
				handleEmptySearchString();
			}
		},
		[selectedCollection, selectedTraits, location.pathname]
	);

	return (
		<>
			<FilterSection
				onClick={() => dispatch(setFilterCollapseStates(FilterTypes.SEARCH))}
				active={isFilterOpen}
				paddingRight="20px"
			>
				Lookup Wallet <span className="lower-case">or</span> ID
				{filterSectionIcon(isFilterOpen)}
			</FilterSection>
			<FilterCollapse>
				<Collapse className="filter-collapse" isOpen={isFilterOpen}>
					<div className="select">
						<form onSubmit={handleSearch}>
							<FilterSearch
								placeholder="Search"
								type="search"
								value={searchValue}
								onChange={handleOnChange}
							/>
						</form>
					</div>
				</Collapse>
			</FilterCollapse>
		</>
	);
};

export default SearchFilter;
