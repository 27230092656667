import React, { useCallback } from 'react';
import { Col, Row, setConfiguration } from 'react-grid-system';
import { GalleryBreakPoints } from '../../resources/enums/breakpoints';
import StoreItemCard from './StoreItemCard';
import { ImageCard, CardTag } from '../../resources/styles/Store/Store.styles';
import StoreItemInterface from '../../resources/interfaces/StoreItemInterface';
import { generateCardTagText } from '../../helpers/store';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useSelector } from 'react-redux';
import Loader from '../Loader';

const StoreContent = (): JSX.Element => {
	const { storeData } = useSelector(
		(state: RootReducerStateInterface) => state.store
	);

	setConfiguration({
		breakpoints: [
			GalleryBreakPoints.xs,
			GalleryBreakPoints.sm,
			GalleryBreakPoints.md,
			GalleryBreakPoints.lg,
			GalleryBreakPoints.xl,
		],
	});

	const generateMedia = (item: StoreItemInterface): JSX.Element => {
		const { image, status, maxSupply, totalSupply, reservationsOpen } = item;
		const soldOut = totalSupply == maxSupply;

		return (
			<>
				{status && (
					<CardTag>
						{generateCardTagText(soldOut, status, reservationsOpen)}
					</CardTag>
				)}
				<ImageCard src={image} />
			</>
		);
	};

	const callbackGenerateMedia = useCallback(generateMedia, []);
	if (storeData.length === 0) {
		return <Loader />;
	}

	return (
		<Row>
			{storeData.map((item) => {
				return (
					<Col
						xs={12}
						sm={6}
						md={4}
						lg={3}
						xl={3}
						xxl={1}
						id="col"
						key={item.id}
					>
						<StoreItemCard
							item={item}
							callbackGenerateMedia={callbackGenerateMedia}
						/>
					</Col>
				);
			})}
		</Row>
	);
};

export default React.memo(StoreContent);
