import {
	CONNECT_AMMOLITE,
	RESET_AMMOLITE_STATE,
	UPDATE_AMMOLITE,
} from './types/ammolite';
import { Dispatch } from 'react';
import Ammolite from '../../services/Ammolite';

export const connectAmmolite =
	(ammolite: Ammolite) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (ammolite) {
			const balance = await ammolite.fetchBalanceOfWallet();
			dispatch({
				type: CONNECT_AMMOLITE,
				payload: {
					ammolite,
					balance,
				},
			});
		}
	};

export const updateAmmolite =
	(ammolite: Ammolite) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		const balance = await ammolite.fetchBalanceOfWallet();
		dispatch({
			type: UPDATE_AMMOLITE,
			payload: {
				balance,
			},
		});
	};

export const resetAmmoliteState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_AMMOLITE_STATE });
	};
