import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DaycareTabIds } from '../../../resources/enums/DaycareTabs';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	DaycareTabContainer,
	DaycareTabMenuDiv,
} from '../../../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import { FlexDiv } from '../../../resources/styles/DaycarePage/MintSkvllbabiezTab/MintSkvllbabiezTab.styles';
import ChooseBabiezToStake from './components/ChooseBabiezToStake';
import StakedBabiez from './components/StakedBabiez';
import PageHeaderContent from '../../../components/PageHeaderContent';
import { updateSkvllpvnkzList } from '../../../redux/actions/skvllpvnkz';
import bannerDesktop1 from '../../../resources/images/banner/bannerDesktop1.png';
import config from '../../../config/index';

const DaycareTab = (): JSX.Element => {
	const { skvllbabiez, ownedSkvllbabiezList } = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez
	);
	const dispatch = useDispatch();
	const { staked_babiez, not_staked_babiez } = ownedSkvllbabiezList;
	const [selectedMenu, setSelectedMenu] = useState(DaycareTabIds.IN_WALLET_TAB);

	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(updateSkvllpvnkzList());
		}
	}, [isAuthenticated]);

	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);

	const { totalDaycareRewards } = useSelector(
		(state: RootReducerStateInterface) => state.pendingRewards
	);

	const statsData = {
		totalSkvllbabiez: {
			title: 'Skvllbabiez',
			value:
				(staked_babiez ? staked_babiez.length : 0) +
				(not_staked_babiez ? not_staked_babiez.length : 0),
			toolTip: 'Total Skvllbabiez minted',
		},
		totalSkvllbabiezRewards: {
			title: 'Current Rewards',
			value: totalDaycareRewards ? totalDaycareRewards.toFixed(3) : 0,
			ammoIcon: true,
			toolTip: 'Total Skvllbabiez rewards',
		},
		dailySkvllbabiezRewards: {
			title: 'Daily Rewards',
			value: (staked_babiez ? staked_babiez.length : 0) * 4,
			ammoIcon: true,
			toolTip: 'Total AMMO generated per day by Skvllbabiez',
		},
	};

	const pageHeader = {
		user: user,
		title: 'Daycare Staking',
		subtitle: config.DAYCARE_CONTRACT_ADDRESS,
		textAlign: 'center',
		textTransform: 'capitalize',
	};

	const Stats = {
		areStatsLoading: false,
		statsData,
		stylesGrid: '3, 1fr',
	};

	return (
		<div>
			<PageHeaderContent
				pageHeader={pageHeader}
				stats={Stats}
				backgroundImage={bannerDesktop1}
			/>
			<FlexDiv>
				<DaycareTabContainer>
					<div id="container">
						<DaycareTabMenuDiv selected={selectedMenu}>
							<div className="daycare-selector">
								<p
									onClick={() => setSelectedMenu(DaycareTabIds.IN_DAYCARE_TAB)}
									className="available"
								>
									daycare
								</p>
								<p
									onClick={() => setSelectedMenu(DaycareTabIds.IN_WALLET_TAB)}
									className="daycare"
								>
									available
								</p>
							</div>
							<div className="selection-menu-wrapper">
								<div
									onClick={() => setSelectedMenu(DaycareTabIds.IN_WALLET_TAB)}
									className="daycare-menu-stats available-stats"
								>
									<p>
										<span id="available">Babies available</span>
										<span className="unit">
											{not_staked_babiez ? not_staked_babiez.length : 0}
										</span>
									</p>
								</div>
								<div
									onClick={() => setSelectedMenu(DaycareTabIds.IN_DAYCARE_TAB)}
									className="daycare-menu-stats day-care-stats"
								>
									<p>
										<span id="in-daycare">Babies in daycare</span>
										<span className="unit">
											{staked_babiez ? staked_babiez.length : 0}
										</span>
									</p>
								</div>
							</div>
						</DaycareTabMenuDiv>
						{selectedMenu === DaycareTabIds.IN_WALLET_TAB ? (
							<ChooseBabiezToStake
								skvllbabiezList={not_staked_babiez}
								skvllbabiezContract={skvllbabiez}
							/>
						) : (
							<StakedBabiez />
						)}
					</div>
				</DaycareTabContainer>
			</FlexDiv>
		</div>
	);
};

export default DaycareTab;
