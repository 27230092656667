import styled from 'styled-components';
import { HIGHLIGHT_COLOR } from '../../constants/colors';
import { MOBILE_DEVICE_WIDTH } from '../../constants/dimensions';

export const MainContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 120px);
	width: 100%;
`;

export const LoginContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${HIGHLIGHT_COLOR};
	width: 520px;
	height: 278px;
	box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	border-radius: 10px;
	padding: 40px;

	h3 {
		margin: 10px 0px;
		font-weight: 600;
	}

	p {
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		width: 90%;
		padding: 40px 10px;
	}
`;
