import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';
import { PRIMARY_MAIN } from '../resources/constants/colors';

interface SweetAlertParams {
	icon?: SweetAlertIcon;
	showConfirmButton?: boolean;
	text?: string;
	title?: string;
	confirmButtonText?: string;
	confirmCallbackFunction?: (inputValue: unknown) => unknown;
}

const defaultOptions: SweetAlertParams = {
	icon: 'error' as SweetAlertIcon,
	showConfirmButton: true,
	text: 'Please try again later.',
	title: 'Oops, an error occured!',
	confirmButtonText: 'Got it',
	confirmCallbackFunction: () => undefined,
};

const SweetAlert = (
	options: SweetAlertParams = defaultOptions
): Promise<SweetAlertResult> => {
	const { showConfirmButton, text, title, confirmCallbackFunction } = options;
	return Swal.fire({
		confirmButtonColor: PRIMARY_MAIN,
		showConfirmButton: showConfirmButton ?? defaultOptions.showConfirmButton,
		confirmButtonText: defaultOptions.confirmButtonText,
		text: text ?? defaultOptions.text,
		title: title ?? defaultOptions.title,
		preConfirm:
			confirmCallbackFunction ?? defaultOptions.confirmCallbackFunction,
	});
};

export default SweetAlert;
