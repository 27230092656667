import styled from 'styled-components';
import config from '../../config';
import {
	BUTTON_PRIMARY_GREEN,
	BUTTON_PRIMARY_MAIN,
	BUTTON_PRIMARY_PURPLE,
	BUTTON_SECONDARY,
	FILTER_MAIN,
	NEW_SECONDARY_MAIN,
	TERTIARY_MAIN,
	TEXT_GRAYED_OUT,
	HIGHLIGHT_COLOR,
	TEXT_WHITE,
} from '../constants/colors';
import { MOBILE_DEVICE_WIDTH } from '../constants/dimensions';
import ChainIds from '../enums/ChainIds';
import { SidebarTabIds } from '../enums/SidebarTabs';
import ScrollbarArrowUp from '../images/scrollbar/scrollbar-arrow-up.png';
import ScrollbarArrowDown from '../images/scrollbar/scrollbar-arrow-down.png';

interface PropsInterface {
	isSidebarOpen?: boolean;
	width?: string;
	marginTop?: string;
	marginBottom?: string;
	selectedMenu?: SidebarTabIds;
	chainId?: number | undefined;
	padding?: string;
	backgroundColor?: string;
	cursor?: string;
	isDiscordConnected?: boolean;
}

export const SidebarContainer = styled.div<PropsInterface>`
	color: ${TEXT_WHITE};
	display: ${(props) =>
		props.chainId === ChainIds.MAINNET || config.IS_ON_DEV_ENV
			? 'block'
			: 'none'};
	position: fixed;
	z-index: 12;
	right: ${(props) => (props.isSidebarOpen ? '0px' : '-350px')};
	overflow: hidden;
	height: 100%;
	width: 350px;
	top: 5rem;

	transition: 0.5s;

	background-color: ${TERTIARY_MAIN};
	box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);

	font-size: 12px;

	hr {
		width: 90%;
		margin: auto;
		height: 1px;
	}

	#back-button {
		display: none;
		text-align: left;
	}

	.grayed-out {
		color: ${TEXT_GRAYED_OUT};
		cursor: pointer;
	}

	#connect-discord {
		display: ${(props) => (props.isDiscordConnected ? 'none' : 'block')};
	}

	.sidebar-btn {
		position: absolute;
		bottom: 5.5rem;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		width: 100vw;
		inset: 0;
		transform: ${(props) =>
			props.isSidebarOpen ? 'translateY(0px)' : 'translateY(-100%)'};
		overflow-y: scroll;

		#back-button {
			display: block;
		}

		.sidebar-btn {
			bottom: 0.5rem;
		}
	}
`;

SidebarContainer.defaultProps = {
	isSidebarOpen: false,
};

export const SidebarDetails = styled.div<PropsInterface>`
	display: flex;
	cursor: ${(props) => props.cursor};
	align-items: center;
	justify-content: space-between;
	background-color: ${(props) => props.backgroundColor};

	width: ${(props) => props.width};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	padding: ${(props) => props.padding};
	padding-inline: calc(0.5rem + 5vw);

	@media screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
		padding-inline: 20px;
	}

	p,
	b {
		margin: 0;
	}

	.close {
		float: right;
	}

	#WalletDropdown {
		display: flex;
		align-items: center;

		p {
			margin: 0px 5px 0px 0px;
			font-size: 14px;
		}

		img {
			border-radius: 50%;
		}

		#WalletDropdownIcon {
			margin-left: -30px;
			margin-right: 10px;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			overflow: hidden;
		}
	}

	.sidebar-dropdown {
		:hover {
			color: ${NEW_SECONDARY_MAIN} !important;
		}
	}

	.balance-text {
		color: ${BUTTON_PRIMARY_MAIN};
	}
`;

SidebarDetails.defaultProps = {
	padding: '0px',
	width: '100%',
	marginTop: '10px',
	marginBottom: '10px',
};

export const SidebarStorePurchaseContainer = styled.div`
	overflow: auto;
	height: calc(100vh - 210px);

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		height: auto;
	}

	::-webkit-scrollbar {
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-track {
		background-color: ${FILTER_MAIN};
	}

	::-webkit-scrollbar-corner {
		background-color: ${FILTER_MAIN};
	}

	::-webkit-scrollbar-thumb {
		background-color: ${BUTTON_SECONDARY};
		border-radius: 2px;
	}

	::-webkit-scrollbar-button:single-button {
		background-color: ${TERTIARY_MAIN};
		display: block;
		height: 10px;
		width: 10px;
	}

	::-webkit-scrollbar-button:vertical:increment {
		background-image: url(${ScrollbarArrowDown});
		background-size: 5px 5px;
		background-repeat: no-repeat;
		background-position: center;
	}

	::-webkit-scrollbar-button:vertical:decrement {
		background-image: url(${ScrollbarArrowUp});
		background-size: 5px 5px;
		background-repeat: no-repeat;
		background-position: center;
	}
`;

export const SidebarTitle = styled.p`
	font-size: 16px;
`;

export const SidebarBalanceDiv = styled.div`
	width: 100%;
	margin: auto;
	margin-top: 1px;
	margin-bottom: 2rem;
	padding-top: 15px;
	padding-bottom: 5px;
	border-radius: 10px;

	display: grid;
	grid-template-rows: 50% 50%;
	gap: 0.5rem;
	justify-content: center;
	align-items: center;

	h3 {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
	}

	p {
		margin-bottom: 0px;
	}

	> * {
		background-color: ${HIGHLIGHT_COLOR};
		padding: 1.2rem 1rem;
		border-radius: 0.5rem;
		width: 100%;
	}
`;

export const NavbarIconDiv = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	color: ${TEXT_WHITE};
	width: 100%;

	#wallet {
		cursor: pointer;
		:hover {
			color: ${NEW_SECONDARY_MAIN} !important;
		}
	}
`;

export const WalletIconDiv = styled.div`
	position: relative;

	#notification {
		background-color: ${BUTTON_PRIMARY_PURPLE};
		position: absolute;
		height: 9px;
		width: 9px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		right: -2px;
		top: -2px;
		font-size: 12px;
	}
`;

export const SidebarMenuDiv = styled.div<PropsInterface>`
	display: flex;
	justify-content: center;
	p {
		cursor: pointer;
		font-size: 12px;
		font-weight: 600;
	}

	#wallet-menu {
		color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_WALLET_TAB
				? TEXT_WHITE
				: TEXT_GRAYED_OUT};
	}
	#wallet-marker {
		display: 'block';
		height: 3px;
		width: 38px;
		background-color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_WALLET_TAB
				? BUTTON_PRIMARY_GREEN
				: 'transparent'};
		background-color: ;
		margin-top: 8px;
		margin-bottom: -10px;
		margin-left: auto;
		margin-right: auto;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	#purchases-container {
		position: relative;
	}
	#purchases-menu {
		color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_PURCHASES_TAB
				? TEXT_WHITE
				: TEXT_GRAYED_OUT};
	}
	#purchases-notification {
		background-color: ${BUTTON_PRIMARY_PURPLE};
		position: absolute;
		height: 9px;
		width: 9px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		right: -5px;
		top: -2px;
	}
	#purchases-marker {
		display: 'block';
		height: 3px;
		width: 52px;
		background-color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_PURCHASES_TAB
				? BUTTON_PRIMARY_GREEN
				: 'transparent'};
		margin-top: 8px;
		margin-bottom: -10px;
		margin-left: auto;
		margin-right: auto;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	#rewards-menu {
		color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_REWARDS_TAB
				? TEXT_WHITE
				: TEXT_GRAYED_OUT};
	}

	#rewards-marker {
		display: 'block';
		height: 3px;
		width: 52px;
		background-color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_REWARDS_TAB
				? BUTTON_PRIMARY_GREEN
				: 'transparent'};
		margin-top: 8px;
		margin-bottom: -10px;
		margin-left: auto;
		margin-right: auto;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}

	#stats-menu {
		color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_STATS_TAB
				? TEXT_WHITE
				: TEXT_GRAYED_OUT};
	}
	#stats-marker {
		display: 'block';
		height: 3px;
		width: 52px;
		background-color: ${(props) =>
			props.selectedMenu === SidebarTabIds.IN_STATS_TAB
				? BUTTON_PRIMARY_GREEN
				: 'transparent'};
		margin-top: 8px;
		margin-bottom: -10px;
		margin-left: auto;
		margin-right: auto;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
`;

export const BalanceIconDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	margin-bottom: 0px;
	padding: 0px;
`;
