import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StakeNFT from '../../../../components/StakeNFT/StakeNFT';
import { updateSkvllbabiezList } from '../../../../redux/actions/skvllbabiez';
import { CollectionTitles } from '../../../../resources/enums/CollectionTitles';
import RewardCollection from '../../../../resources/enums/RewardCollection';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';

const StakedBabiez = (): JSX.Element => {
	const { skvllbabiez, ownedSkvllbabiezList } = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(updateSkvllbabiezList());
	}, []);

	return (
		<StakeNFT
			nftList={ownedSkvllbabiezList.staked_babiez}
			contract={skvllbabiez}
			collectionTitle={CollectionTitles.Skvllbabiez}
			rewardCollection={RewardCollection.daycare_staking}
			title="baby"
		/>
	);
};

export default StakedBabiez;
