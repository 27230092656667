import styled from 'styled-components';
import {
	BUTTON_PRIMARY_MAIN,
	BUTTON_PRIMARY_WHITE,
	HIGHLIGHT_COLOR,
	SECONDARY_MAIN,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
} from '../constants/colors';
import CheckedIcon from '../images/icons/CheckedIcon.png';
import CheckedMobile from '../images/icons/CheckedMobile.svg';

interface Props {
	backgroundColor?: string;
	disabled?: boolean;
	display?: string;
	gap?: string;
	marginRight?: string;
	width?: string;
}

export const NFTCardContainer = styled.div<Props>`
	display: flex;
	gap: 1.2rem;
	align-items: center;
	width: ${(props) => props.width};
	height: auto;
	background-color: ${(props) =>
		props.disabled ? TEXT_GRAYED_OUT : props.backgroundColor};
	margin-right: ${(props) => props.marginRight};
	border-radius: 5px;
	padding: 16px;
	// box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
	font-size: 10px;
	text-align: left;
	line-height: 5px;
	color: ${BUTTON_PRIMARY_MAIN};

	@media screen and (min-width: 768px) {
		display: ${(props) => props.display};
		vertical-align: top;
	}

	cursor: ${(props) => (props.disabled ? 'hand' : 'pointer')};

	:hover {
		background-color: ${(props) => !props.disabled && HIGHLIGHT_COLOR};
	}

	input[type='checkbox'] {
		display: ${(props) => props.disabled && 'none'};
	}

	img {
		opacity: ${(props) => props.disabled && 0.4};
	}

	#large-font {
		font-size: 15px;
	}

	.token-id-mobile {
		display: block;
		@media screen and (min-width: 768px) {
			display: none;
		}
	}
`;

NFTCardContainer.defaultProps = {
	backgroundColor: SECONDARY_MAIN,
	disabled: false,
	display: 'inline-block',
	marginRight: '10px',
};
export const NFTImageDiv = styled.div`
	position: relative;
	width: 85px;
	border-radius: 5px;

	@media screen and (min-width: 768px) {
		margin-bottom: 15px;
		width: 100%;
	}

	input[type='checkbox'] {
		appearance: none;
		--webkit-appearance: none;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: transparent;
		border-radius: 6px;
		cursor: pointer;

		@media screen and (min-width: 768px) {
			width: 21px;
			height: 21px;
			right: 5px;
			top: 6px;
			border-radius: 3px;
			border: 2.5px solid ${BUTTON_PRIMARY_WHITE};
		}
	}

	input[type='checkbox']:checked {
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(${CheckedMobile});
		background-repeat: no-repeat;
		background-position: center;
		background-size: 24px;

		background-color: rgba(28, 165, 149, 0.2);
		@media screen and (min-width: 768px) {
			background: url(${CheckedIcon});
			background-size: unset;
		}
	}
`;

export const DetailText = styled.div<Props>`
	display: ${(props) => props.display};
	gap: ${(props) => props.gap};
	font-size: 13px;
	margin-bottom: 15px;
	font-weight: 100;
	color: ${TEXT_WHITE};

	p {
		margin: 0;
	}

	.red {
		color: red;
	}

	#large-font {
		font-size: 16px;
	}
`;

export const DaycareSkvllbabyInfo = styled.div`
	.token-id-desktop {
		display: none;
		@media screen and (max-width: 768px) {
			display: block;
		}
	}
`;
export const RankAndAmmoliteDiv = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		margin: 0;
	}

	.token-id-desktop {
		display: none;
		@media screen and (min-width: 768px) {
			display: block;
		}
	}

	.ammolite {
		color: ${TEXT_WHITE};
		font-size: 13px;
	}
`;

export const NFTCardContainerBreed = styled.div<Props>`
	display: ${(props) => props.display};
	vertical-align: top;
	width: ${(props) => props.width};
	height: auto;
	max-height: 270px;
	background-color: ${(props) =>
		props.disabled ? TEXT_GRAYED_OUT : props.backgroundColor};
	margin-right: ${(props) => props.marginRight};
	border-radius: 5px;
	padding: 10px;
	font-size: 10px;
	text-align: left;
	line-height: 5px;
	color: ${BUTTON_PRIMARY_MAIN};

	cursor: ${(props) => (props.disabled ? 'hand' : 'pointer')};

	:hover {
		background-color: ${(props) => !props.disabled && HIGHLIGHT_COLOR};
	}

	input[type='checkbox'] {
		display: ${(props) => props.disabled && 'none'};
	}

	img {
		opacity: ${(props) => props.disabled && 0.4};
	}

	#large-font {
		font-size: 15px;
	}
`;

NFTCardContainerBreed.defaultProps = {
	backgroundColor: SECONDARY_MAIN,
	disabled: false,
	display: 'inline-block',
	marginRight: '10px',
};

export const NFTImageDivBreed = styled.div`
	position: relative;
	width: 100%;
	margin-bottom: 15px;
	border-radius: 5px;

	input[type='checkbox'] {
		appearance: none;
		--webkit-appearance: none;
		width: 21px;
		height: 21px;
		position: absolute;
		right: 5px;
		top: 6px;
		background-color: transparent;
		border: 2px solid ${TEXT_GRAYED_OUT};
		border-radius: 6px;

		cursor: pointer;
	}

	input[type='checkbox']:checked {
		display: flex;
		align-items: center;
		justify-content: center;
		background: url(${CheckedIcon});
	}
`;
