import React, { useState, ChangeEvent } from 'react';
import {
	ButtonWrapper,
	ProfileBtn,
	SelectionWrapper,
	Selection,
	Option,
} from '../resources/styles/Profile.styles';
import TabsInterface from '../resources/interfaces/TabsInterface';

const Tabs = (props: TabsInterface): JSX.Element => {
	const { activeTab, buttonParameters, handleContentChange } = props;
	const [activeButton, setActiveButton] = useState(activeTab);

	const handleClick = (active: number) => {
		setActiveButton(active);
		handleContentChange?.(active);
	};

	const selections = Object.values(buttonParameters);

	const handleMobileChange = (e: ChangeEvent<HTMLSelectElement>) => {
		handleClick(selections.indexOf(e.target.value.replace(' ', '_')));
	};

	const desktopTab = (
		<ButtonWrapper active={activeButton}>
			{Object.values(buttonParameters).map((button, index) => {
				return (
					<ProfileBtn
						key={index}
						className={`button${index + 1}`}
						onClick={() => handleClick(index)}
					>
						{`${button}`.replace('_', ' ')}
					</ProfileBtn>
				);
			})}
		</ButtonWrapper>
	);

	const mobileSelection = (
		<SelectionWrapper>
			<Selection onChange={handleMobileChange}>
				{Object.values(buttonParameters).map((button, index) => {
					return (
						<Option key={index} value={`${button}`.replace('_', ' ')}>
							{`${button}`.replace('_', ' ')}
						</Option>
					);
				})}
			</Selection>
		</SelectionWrapper>
	);

	return (
		<>
			{desktopTab}
			{mobileSelection}
		</>
	);
};

export default React.memo(Tabs);
