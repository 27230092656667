import styled from 'styled-components';

export const DividerDiv = styled.div`
	padding-top: 2rem;
	position: relative;
	display: none;
	@media screen and (min-width: 990px) {
		padding: 0;
		display: flex;
		justify-content: center;
	}
`;
export const DividerButtonWrapper = styled.div``;

interface IMGProps {
	IMG?: string;
	banner?: number;
}
export const DividerDisplayIMGMobile = styled.div<IMGProps>`
	display: flex;
	width: 100%;
	height: 100%;
	filter: brightness(30%);
	-webkit-filter: brightness(30%);
	-moz-filter: brightness(30%);
	transition: all 0.2s ease;

	& img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
`;
export const DividerDisplayIMG = styled.div<IMGProps>`
	opacity: 0.4;
	display: flex;
	width: 100%;
	height: 100%;
	& img {
		mask-image: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #0a0c10 100%);
		width: 100%;
		height: 466px;
		object-fit: cover;
		object-position: center;
	}
`;
export const DividerCollectionWrapper = styled.div`
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
`;
export const DividerDisplayTextOnIMG = styled.div``;

export const GalleryCollectionContainer = styled.div`
	display: grid;
	grid-column-gap: 43px;
	margin-top: 43px;
	grid-template-areas: 'mainfilter gallery';

	@media screen and (max-width: 990px) {
		margin-top: 0;
		grid-template-rows: 1fr;
		grid-template-areas: none;
	}

	@media screen and (min-width: 310px) {
		.old-filter {
			display: none;
		}
	}

	@media screen and (min-width: 990px) {
		.old-filter {
			display: initial;
		}
	}
`;
