import React from 'react';
import { LoadingContainer } from '../../../resources/styles/RafflePage/PurchaseEntry.styles';
import Loader from '../../../components/Loader';

interface PropsInterface {
	title: string;
	subtitle: string;
	marginTop?: string;
	height?: string;
}

const RaffleLoader = (props: PropsInterface): JSX.Element => {
	const { title, subtitle, marginTop, height } = props;
	return (
		<LoadingContainer height={height} marginTop={marginTop}>
			<div>
				<Loader size="50px" />
				<br></br>
				<b>
					<p>{title}</p>
				</b>
				<p>{subtitle}</p>
			</div>
		</LoadingContainer>
	);
};

export default RaffleLoader;
