import React from 'react';
import { formatAddress } from '../../../../helpers/address';
import { formatDate } from '../../../../helpers/date';
import { LotteryEntryInterface } from '../../../../resources/interfaces/LotteryInterface';
import {
	RafflePageTableContainer,
	Title,
} from '../../../../resources/styles/RafflePage/PreviousWinners.styles';
import Winner from './Winner';

interface PropsInterface {
	entries: LotteryEntryInterface[] | undefined;
	showAllEntries: boolean;
	isLotteryOpen: boolean;
}

const EntriesTable = (props: PropsInterface): JSX.Element => {
	const { entries, showAllEntries, isLotteryOpen } = props;

	const tableHeader = (
		<>
			<td className="entryId">
				<b>Pvnk</b>
			</td>
			<td className="center-text">
				<b>Date</b>
			</td>
			<td className="center-text">
				<b>Portal</b>
			</td>
			{(!showAllEntries || !isLotteryOpen) && (
				<td className="center-text">
					<b>Code</b>
				</td>
			)}
			{showAllEntries && isLotteryOpen && (
				<>
					<td className="center-text">
						<b>Keys</b>
					</td>
					<td className="center-text">
						<b>Chance</b>
					</td>
				</>
			)}

			<td className="center-text">
				<b>Hash</b>
			</td>
		</>
	);

	const aggregatedWalletEntryCounts: Record<string, any> = {};
	const totalEntriesOfCurrentLottery = entries?.length || 0;

	entries?.forEach((entry) => {
		const walletEntry = aggregatedWalletEntryCounts[entry.walletAddress];
		if (walletEntry?.entryCount) {
			walletEntry.entryCount++;
			const chanceOfWinning =
				(walletEntry.entryCount / totalEntriesOfCurrentLottery) * 100;
			if (!walletEntry.transactionHash.includes(entry.transactionHash))
				walletEntry.transactionHash.push(entry.transactionHash);
			walletEntry.chanceOfWinning = chanceOfWinning;
		} else {
			const chanceOfWinning = (1 / totalEntriesOfCurrentLottery) * 100;
			aggregatedWalletEntryCounts[entry.walletAddress] = {
				entryTime: entry.entryTime,
				portalName: entry.portalName,
				id: entry.id,
				transactionHash: [entry.transactionHash],
				walletAddress: entry.walletAddress,
				username: entry.username,
				entryPrice: entry.entryPrice,
				entryCount: 1,
				chanceOfWinning,
			};
		}
	});

	const sortedAggEntries = Object.values(aggregatedWalletEntryCounts)?.sort(
		(a: { entryCount: number }, b: { entryCount: number }) =>
			b.entryCount - a.entryCount
	);

	const tableContent = Object.values(sortedAggEntries)?.map((entry) => {
		const {
			entryTime,
			portalName,
			id,
			transactionHash,
			walletAddress,
			username,
			entryCount,
			chanceOfWinning,
		} = entry;
		const formattedDate = formatDate(new Date(entryTime));
		const formattedAddress = formatAddress(walletAddress);

		return (
			<Winner
				key={id}
				date={formattedDate}
				portalName={portalName}
				portalKeyCode={id}
				transactionHash={transactionHash}
				walletAddress={formattedAddress}
				username={username}
				entryCount={entryCount}
				chanceOfWinning={chanceOfWinning}
				showAllEntries={showAllEntries}
				isLotteryOpen={isLotteryOpen}
			/>
		);
	});

	const hasEntries = entries && entries?.length > 0;

	return (
		<>
			<Title>
				{showAllEntries && isLotteryOpen
					? 'Preparing for jump '
					: 'Previous winners '}
				{hasEntries && `(${entries?.length} keys)`}
			</Title>
			<RafflePageTableContainer>
				<table>
					<thead className="entries-table-head">
						<tr>{tableHeader}</tr>
					</thead>
					<tbody>{hasEntries ? tableContent : '......'}</tbody>
				</table>
			</RafflePageTableContainer>
		</>
	);
};

export default EntriesTable;
