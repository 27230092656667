import styled from 'styled-components';
import { TEXT_PURPLE } from '../../constants/colors';

export const TicketContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	margin-bottom: 45px;
	gap: 42px;
	text-align: left;

	@media screen and (min-width: 768px) {
		flex-direction: row;
	}

	p {
		margin: 0;
		font-weight: 600;
		font-size: 16px;
	}

	span {
		color: ${TEXT_PURPLE};
		font-weight: 600;
		font-size: 26px;
	}

	b {
		font-weight: 700;
		font-size: 36px;
	}

	#chance {
		font-weight: 400;
		font-size: 13px;
	}

	.portal-key-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media screen and (min-width: 768px) {
			all: unset;
		}
	}

	.mobile-portal-button {
		margin-block-start: 1.5rem;
		display: block;
		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	.mobile-portal-button b {
		font-size: 13px !important;
	}
`;

export const TableHeader = styled.div`
	display: flex;
	text-align: left;
	align-items: center;
	justify-content: center;
	margin-bottom: 28px;
	font-weight: 600;
	font-size: 16px;

	@media screen and (min-width: 768px) {
		justify-content: space-between;
	}

	#show-button {
		font-size: 13px;
		align-items: center;
	}

	.desktop-portal-button,
	.portal-desktop {
		display: none;
		@media screen and (min-width: 768px) {
			display: block;
		}
	}
`;
