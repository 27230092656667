import React, { useCallback, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { TEXT_GRAYED_OUT } from '../../../../resources/constants/colors';
import { Collections } from '../../../../resources/enums/Collections';
import { ExternalRoutes } from '../../../../resources/enums/SiteRoutes';
import NFTImageInterface from '../../../../resources/interfaces/NFTImageInterface';
import { EthereumIcon } from '../../../../resources/styles/DiscoverPage/Banner.styles';

import ShieldIcon from '../../../../resources/images/badges/shield.png';
import HachetsIcon from '../../../../resources/images/badges/hachets.png';
import HelmetIcon from '../../../../resources/images/badges/helmet.png';
import StarIcon from '../../../../resources/images/badges/star.png';
import BootIcon from '../../../../resources/images/badges/boot.png';

import {
	Ammolite,
	DetailFlexDiv,
	DetailGridDiv,
	Badge,
	BadgeIcon,
	BadgeContainer,
} from '../../../../resources/styles/NFTModalView.styles';
import AmmoliteIcon from '../../../../resources/images/icons/AmmoliteIcon.png';
import ETH from '../../../../resources/images/icons/eth.png';

import OpenseaIcon from '../../../../resources/images/logos/opensea.png';
import LooksrareIcon from '../../../../resources/images/logos/looksrare.png';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { handleSearchByOwner } from '../../../../helpers/gallery';
import { NUMBER_OF_RETRIES } from '../../../../resources/constants/limits';
import axiosRetry from 'axios-retry';
import AssetRewardsInterface from '../../../../resources/interfaces/AssetRewardsInterface';
import { useNavigate } from 'react-router-dom';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import NFTAssetHistoryInterface from '../../../../resources/interfaces/NFTAssetHistoryInterface';
import CustomButton from '../../../../resources/styles/CustomButton.styles';

interface PropsInterface {
	selectedCollection: Collections;
	selectedNFT: NFTImageInterface;
	assetHistory: NFTAssetHistoryInterface[];
	assetRewards: AssetRewardsInterface[];
	handleOpenSeaPurchase?: () => Promise<void>;
}

const NFTDetails = (props: PropsInterface): JSX.Element => {
	const {
		selectedCollection,
		selectedNFT,
		assetHistory,
		assetRewards,
		handleOpenSeaPurchase,
	} = props;
	const { token_id, name, rewards, owner, score, rank } = selectedNFT;
	const [priceUsd, setPriceUsd] = useState(0);
	const [lastSaleETH, setLastSalETH] = useState('0.00');
	const [lastSaleUsd, setLastSaleUsd] = useState(0);
	const [volumeETH, setVolumeETH] = useState('0.00');
	const [volumeUsd, setVolumeUsd] = useState(0);
	const [convertRate, setConvertRate] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleETHToUSD = useCallback(async () => {
		axiosRetry(axios, { retries: NUMBER_OF_RETRIES });
		const response = await axios.get(ExternalRoutes.ethConversion);
		const rate = response.data.data.rateUsd;
		setConvertRate(parseFloat(rate));
	}, [selectedNFT]);

	const handlePrice = useCallback(async () => {
		if (selectedNFT.price && selectedNFT.price !== '0') {
			const usdPrice = parseFloat(selectedNFT.price) * convertRate;
			setPriceUsd(usdPrice);
		}
	}, [assetHistory]);

	const handleLastSale = useCallback(async () => {
		if (assetHistory?.length) {
			const filtered = assetHistory.filter(
				(value) => value.type !== 'FREE_MINT' && value.type !== 'TRANSFER'
			);
			const lastSale = filtered.length ? filtered[0].value : 0;
			setLastSalETH(lastSale.toFixed(2).toString());
			const usdBalance = lastSale * convertRate;
			setLastSaleUsd(usdBalance);
		}
	}, [assetHistory]);

	const handleVolume = useCallback(async () => {
		const volume = assetHistory.reduce((sum, p) => sum + p.value, 0);
		setVolumeETH(volume.toFixed(2).toString());
		const usdBalance = volume * convertRate;
		setVolumeUsd(usdBalance);
	}, [assetHistory]);

	useEffect(() => {
		const handleAsyncUseEffect = async () => {
			await handleETHToUSD();
			handleLastSale();
			handleVolume();
			handlePrice();
		};

		handleAsyncUseEffect();
	}, [assetHistory]);

	const isRare = rank < 100;
	const isFreeMint = assetHistory.some((tx) => tx.type === 'FREE_MINT');
	const isHeavilyTraded = assetHistory.length > 2;
	const isHodl = assetHistory.length == 1;
	const isHighVolume = assetHistory.reduce((sum, p) => sum + p.value, 0) > 1;
	const hasBadges = isHodl || isHeavilyTraded || isFreeMint || isRare;
	const assetOwner =
		assetHistory && assetHistory.length > 0
			? assetHistory[0].to
			: '0x0000000000000000000000000000000000000000';
	const verifiedOwner = owner ? owner : assetOwner;
	const { address } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);

	const stakingRewards =
		assetRewards[0]?.rewards.toFixed(2) || rewards?.toFixed(2) || 0;
	const isListed = selectedNFT.price && selectedNFT.price !== '0';
	return (
		<div>
			<DetailFlexDiv fontSize="12px" color={TEXT_GRAYED_OUT} marginTop="5px">
				{selectedCollection.replace('_', ' ').toUpperCase()}
			</DetailFlexDiv>
			<DetailFlexDiv fontSize="24px" marginTop="5px" marginBottom="10px">
				<b>{name}</b>
			</DetailFlexDiv>
			<DetailFlexDiv fontSize="10px" gap="3px">
				<p>Owned by</p>
				<a
					onClick={() =>
						handleSearchByOwner(
							dispatch,
							selectedCollection,
							verifiedOwner,
							navigate
						)
					}
				>
					<p className="green long">
						{address === verifiedOwner ? 'YOU' : verifiedOwner}
					</p>
				</a>
			</DetailFlexDiv>
			<DetailGridDiv fontSize="12px" color={TEXT_GRAYED_OUT}>
				{score && (
					<div>
						<p>Rarity score</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<b>{score?.toFixed(2)}</b>
						</DetailFlexDiv>
					</div>
				)}
				{rank && (
					<div className="rank">
						<p>Rank</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<b>{rank}</b>
						</DetailFlexDiv>
					</div>
				)}
				{hasBadges && (
					<div id="badges-div">
						<p>Badges</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<BadgeContainer>
								{isHodl && (
									<Badge
										data-tip="HODL. This NFT has not been traded since mint"
										data-delay-show="500"
									>
										<ReactTooltip />
										<BadgeIcon
											src={HelmetIcon}
											alt="hodl"
											height="14px"
										></BadgeIcon>
									</Badge>
								)}
								{isHeavilyTraded && (
									<Badge
										data-tip="This NFT has been traded more than 3 times"
										data-delay-show="500"
									>
										<ReactTooltip />
										<BadgeIcon
											src={BootIcon}
											alt="boot"
											height="14px"
										></BadgeIcon>
									</Badge>
								)}
								{isFreeMint && (
									<Badge
										data-tip="This NFT was a free mint"
										data-delay-show="500"
									>
										<ReactTooltip />
										<BadgeIcon
											src={ShieldIcon}
											alt="shield"
											height="14px"
										></BadgeIcon>
									</Badge>
								)}
								{isRare && (
									<Badge
										data-tip="This NFT ranks in the top 1% of the collection"
										data-delay-show="500"
									>
										<ReactTooltip />
										<BadgeIcon
											src={StarIcon}
											alt="ammolite"
											height="14px"
										></BadgeIcon>
									</Badge>
								)}
								{isHighVolume && (
									<Badge
										data-tip="The traded volume surpassed 1 ETH"
										data-delay-show="500"
									>
										<ReactTooltip />
										<BadgeIcon
											src={HachetsIcon}
											alt="ammolite"
											height="14px"
										></BadgeIcon>
									</Badge>
								)}
							</BadgeContainer>
						</DetailFlexDiv>
					</div>
				)}
			</DetailGridDiv>
			<br></br>
			<DetailGridDiv fontSize="12px" gap="20px" color={TEXT_GRAYED_OUT}>
				{isListed && (
					<div>
						<p>Price</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<div>
								<div>
									<EthereumIcon
										src={ETH}
										alt="ethereum"
										height="18px"
									></EthereumIcon>
									<b>
										{Number(parseFloat(selectedNFT.price || '0').toFixed(3))}
									</b>
								</div>
								<div id="last-sale-usd">
									<b>$ {priceUsd.toFixed(2)}</b>
								</div>
							</div>
						</DetailFlexDiv>
					</div>
				)}
				{lastSaleETH !== '0.00' && (
					<div style={{ marginLeft: isListed ? 0 : 0 }}>
						<p>Last Sale</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<div>
								<div>
									<EthereumIcon
										src={ETH}
										alt="ethereum"
										height="18px"
									></EthereumIcon>
									<b>{lastSaleETH}</b>
								</div>
								<div id="last-sale-usd">
									<b>$ {lastSaleUsd.toFixed(2)}</b>
								</div>
							</div>
						</DetailFlexDiv>
					</div>
				)}
				{volumeETH !== '0.00' && (
					<div style={{ marginLeft: isListed ? -15 : 1 }}>
						<p>Volume</p>
						<DetailFlexDiv fontSize="20px" marginTop="-10px">
							<div>
								<div>
									<EthereumIcon
										src={ETH}
										alt="ethereum"
										height="18px"
									></EthereumIcon>
									<b>{volumeETH}</b>
								</div>
								<div id="last-sale-usd">
									<b>$ {volumeUsd.toFixed(2)}</b>
								</div>
							</div>
						</DetailFlexDiv>
					</div>
				)}
			</DetailGridDiv>

			<br></br>
			<DetailFlexDiv fontSize="12px" gap="40px" color={TEXT_GRAYED_OUT}>
				<div>
					<p>Ammolite</p>
					<DetailFlexDiv
						fontSize="20px"
						marginTop="-10px"
						data-tip="The amount of AMMO this Skvllpvnk/ baby is carrying"
						data-delay-show="1000"
					>
						<ReactTooltip />
						<Ammolite src={AmmoliteIcon} alt="ammolite" height="18px" />
						<b>{stakingRewards}</b>
					</DetailFlexDiv>
				</div>
				<div>
					{!isListed ? (
						<div style={{ marginLeft: -7 }}>
							<p>Markets</p>
							<DetailFlexDiv
								fontSize="12px"
								gap="0px"
								marginTop="-10px"
								color={TEXT_GRAYED_OUT}
							>
								<a
									target="_blank"
									rel="noreferrer"
									href={`https://opensea.io/assets/
									${
										selectedCollection === Collections.skvllpvnkz_hideout
											? '0xb28a4fde7b6c3eb0c914d7b4d3ddb4544c3bcbd6'
											: '0x40bca1eddf13b5ffa8f6f1d470cabc78ec2fc3bb'
									}/${token_id}`}
								>
									<Ammolite
										src={OpenseaIcon}
										alt="ammolite"
										height="24px"
									></Ammolite>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href={`https://looksrare.org/collections/
									${
										selectedCollection === Collections.skvllpvnkz_hideout
											? '0xb28a4fde7b6c3eb0c914d7b4d3ddb4544c3bcbd6'
											: '0x40bca1eddf13b5ffa8f6f1d470cabc78ec2fc3bb'
									}/${token_id}`}
								>
									<Ammolite
										src={LooksrareIcon}
										alt="ammolite"
										height="32px"
									></Ammolite>
								</a>
							</DetailFlexDiv>
						</div>
					) : (
						<div style={{ textAlign: 'center' }}>
							<CustomButton
								marginTop="10px"
								borderRadius="8px"
								width="200px"
								onClick={handleOpenSeaPurchase}
							>
								Buy Now
								<Ammolite
									style={{ paddingLeft: 10 }}
									src={OpenseaIcon}
									alt="ammolite"
									height="20px"
								></Ammolite>
							</CustomButton>
							<div style={{ marginTop: 5 }}>
								<a
									target="_blank"
									rel="noreferrer"
									href={`https://opensea.io/assets/
						${
							selectedCollection === Collections.skvllpvnkz_hideout
								? '0xb28a4fde7b6c3eb0c914d7b4d3ddb4544c3bcbd6'
								: '0x40bca1eddf13b5ffa8f6f1d470cabc78ec2fc3bb'
						}/${token_id}`}
								>
									View on OpenSea
								</a>
							</div>
						</div>
					)}
				</div>
			</DetailFlexDiv>
		</div>
	);
};
export default React.memo(NFTDetails);
