import styled from 'styled-components';
import {
	TEXT_PURPLE,
	TEXT_WHITE,
	TEXT_GRAYED_OUT,
	FILTER_MAIN,
} from '../constants/colors';
import bannerDesktop1 from '../../resources/images/banner/bannerDesktop1.png';

export const SectionContainer = styled.section`
	padding-block-start: 3rem;
	h3,
	h4,
	h5 {
		color: ${TEXT_WHITE};
	}

	h3 {
		font-size: 24px;
		font-weight: 600;
	}

	min-height: 100vh;
`;
interface headerProps {
	mobile?: boolean;
	backgroundImage?: string;
	discover?: boolean;
	textTransform?: string;
	textAlign?: string;
}

export const HeaderContainer = styled.div<headerProps>`
	padding-block: 4.5rem 2.5rem;
	background-image: url(${({ backgroundImage }) => backgroundImage});
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: relative;

	@media screen and (min-width: 990px) {
		background-size: cover;
		background-image: url(${({ backgroundImage }) => backgroundImage});
	}

	::before {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0a0c10 90%),
			linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, #0a0c10 100%);
		z-index: 1;
	}
`;

HeaderContainer.defaultProps = {
	backgroundImage: `${bannerDesktop1}`,
};

export const HeaderWrapper = styled.div<headerProps>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: ${({ textAlign }) => textAlign};
	position: relative;
	z-index: 3;

	@media screen and (min-width: 990px) {
		gap: 1rem;
	}

	* {
		margin: 0;
		box-sizing: border-box;
	}

	.avatar {
		width: 85px;
		height: 85px;
		border-radius: 5px;
		display: none;

		> * {
			width: 100% !important;
			height: 100% !important;
		}

		@media screen and (min-width: 990px) {
			display: block;
		}
	}

	.header-wrapper {
		display: flex;
		gap: 1rem;
		align-items: center;
		padding-inline: 1rem;
	}
`;

export const Header = styled.div<headerProps>`
	.wallet-address,
	.secondary-subtitle {
		display: none;
		word-break: break-all;

		@media screen and (min-width: 990px) {
			display: block;
		}
	}

	.wallet-address {
		text-decoration: none;
		color: ${TEXT_PURPLE};
		font-size: 13px;
	}

	.secondary-subtitle {
		font-size: 10px;
		color: ${TEXT_WHITE};
	}

	.title {
		background-color: ${TEXT_PURPLE};
		padding: 0.4rem 1rem;
		font-weight: 700;
		text-transform: ${({ textTransform }) => textTransform};
		margin-block-end: 0.8rem;
		color: ${TEXT_WHITE};

		@media screen and (min-width: 990px) {
			background-color: unset;
			padding: unset;
			font-weight: unset;
		}
	}
`;

export const StatsWrapper = styled.div`
	color: ${TEXT_WHITE};
	width: 100%;

	> div {
		height: unset;
	}
`;

interface profileBtn {
	active?: number;
	mobile?: boolean;
}

export const ButtonWrapper = styled.div<profileBtn>`
	display: none;
	max-width: 80vw;
	margin: auto;
	margin-block-start: 1rem;
	text-transform: uppercase;

	.button1 {
		background-color: ${(props) =>
			props.active === 0 ? `${TEXT_PURPLE}` : 'transparent'};
		color: ${(props) => props.active === 0 && `${TEXT_WHITE}`};
	}

	.button2 {
		background-color: ${(props) =>
			props.active === 1 ? `${TEXT_PURPLE}` : 'transparent'};
		color: ${(props) => props.active === 1 && `${TEXT_WHITE}`};
	}

	.button3 {
		background-color: ${(props) =>
			props.active === 2 ? `${TEXT_PURPLE}` : 'transparent'};
		color: ${(props) => props.active === 2 && `${TEXT_WHITE}`};
	}

	.button4 {
		background-color: ${(props) =>
			props.active === 3 ? `${TEXT_PURPLE}` : 'transparent'};
		color: ${(props) => props.active === 3 && `${TEXT_WHITE}`};
	}

	@media screen and (min-width: 990px) {
		display: flex;
	}
`;

export const ProfileBtn = styled.button`
	border: none;
	outline: none;
	background-color: transparent;
	color: ${TEXT_GRAYED_OUT};
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 500;
	padding: 0.5rem 2rem;
	border: 1px solid ${TEXT_PURPLE};
	transition: all 0.3s ease-in-out;

	:hover {
		color: ${TEXT_WHITE};
	}

	:first-child {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		border-right: none;
	}

	:last-child {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
		border-left: none;
	}
`;

export const SelectionWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	padding-inline: 2rem;
	background-color: ${FILTER_MAIN};
`;

export const Selection = styled.select`
	border: none;
	outline: none;
	background-color: transparent;
	color: ${TEXT_GRAYED_OUT};
	font-size: 16px;
	text-transform: capitalize;
	font-weight: 500;
	padding: 0.5rem 2rem;
	border: 1px solid ${TEXT_PURPLE};
	border-radius: 5px;
	width: 100%;
	cursor: pointer;

	@media screen and (min-width: 768px) {
		width: 50%;
	}

	@media screen and (min-width: 990px) {
		display: none;
	}
`;

export const Option = styled.option``;

export const ProfileSection = styled.div`
	margin-block: 2rem;
	h4 {
		display: flex;
		gap: 0.5rem;
		align-items: center;
		font-size: 1rem;
		font-weight: 600;
	}
`;
