import { ethers } from 'ethers';
import { Dispatch } from 'react';
import { batch } from 'react-redux';
import config from '../config';
import hideoutRewardsABI from '../contracts/hideoutRewardsABI.json';
import { conditionalReloadByEvent } from '../helpers/session';
import { updateAmmolite } from '../redux/actions/ammolite';
import { setHideoutRewardState } from '../redux/actions/rewardsAndStaking';
import { updateSkvllpvnkzList } from '../redux/actions/skvllpvnkz';
import { NETWORK_TRANSACTION_SUCCESS } from '../resources/constants/codes';
import { TransactionState } from '../resources/enums/states';
import TransactionInterface from '../resources/interfaces/TransactionInterface';
import Ammolite from './Ammolite';
import Wallet from './Wallet';

export default class Hideout {
	private contract;
	private provider;
	private ammolite;
	private wallet;
	private dispatch;

	constructor(wallet: Wallet, ammolite: Ammolite, dispatch: Dispatch<unknown>) {
		this.wallet = wallet;
		this.provider = wallet.library;
		this.ammolite = ammolite;
		this.dispatch = dispatch;
		this.contract = new ethers.Contract(
			config.HIDEOUT_REWARD_ADDRESS,
			hideoutRewardsABI,
			this.provider.getSigner()
		);
		this.initializeContractListeners();
	}

	public initializeContractListeners(): void {
		this.contract.on('HideoutRewardsOpen', (event) =>
			conditionalReloadByEvent(event)
		);
		this.contract.on('HideoutRewardsClosed', (event) =>
			conditionalReloadByEvent(event)
		);
	}

	public isHideoutRewardsOpen(): boolean {
		return this.contract.isHideoutRewardsOpen();
	}

	private handleRewardState(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result: any
	): void {
		this.provider.once(
			result.hash,
			(ongoingTransaction: { status: number }) => {
				batch(() => {
					if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
						this.dispatch(updateSkvllpvnkzList());
						this.dispatch(updateAmmolite(this.ammolite));
						this.dispatch(setHideoutRewardState(TransactionState.SUCCESS));
					} else {
						this.dispatch(setHideoutRewardState(TransactionState.FAILED));
					}
				});
			}
		);
	}

	public async collectBatchSkvllpvnkzReward(
		skvllpvnkIds: number[]
	): Promise<void> {
		try {
			const tx = await this.getRewardsTransactionObject(
				this.provider,
				skvllpvnkIds
			);
			const result = await this.contract.collectRewards(skvllpvnkIds, tx);
			this.handleRewardState(result);
		} catch (er) {
			console.error(er);
			this.dispatch(setHideoutRewardState(TransactionState.FAILED));
		}
	}

	public getRewardsTransactionObject = async (
		provider: ethers.providers.Web3Provider,
		skvllpvnkIds: number[],
		isHardwareWallet = false
	): Promise<TransactionInterface> => {
		const gasEstimate = await this.contract.estimateGas.collectRewards(
			skvllpvnkIds
		);
		let transaction: TransactionInterface = isHardwareWallet
			? {
					gasPrice: await provider.getGasPrice(),
					gasLimit: gasEstimate.toNumber(),
			  }
			: {};
		if (config.USE_MANUAL_GAS_LIMIT) {
			const finalGasLimit =
				skvllpvnkIds.length == 1
					? config.COLLECT_REWARD_FUNCTION_GAS_COST
					: skvllpvnkIds.length *
							config.COLLECT_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST +
					  config.COLLECT_REWARDS_BATCH_BASE_FUNCTION_GAS_COST;
			transaction = isHardwareWallet
				? {
						gasLimit: finalGasLimit,
						gasPrice: await provider.getGasPrice(),
				  }
				: {
						gasLimit: finalGasLimit,
				  };
		}
		return transaction;
	};
}
