import NFTImageInterface from '../interfaces/NFTImageInterface';

const placeholderNFT: NFTImageInterface = {
	name: '',
	thumb: '',
	hash: '',
	image: '',
	owner: 'NEKO-NFT',
	collection: 'Skvllpvnkz Hideout',
	token_id: 515,
	rewards: 300,
	// price: 15,
	score: 201.65,
	rank: 27,
	attributes: [
		{ trait_type: 'gender', value: 'Male', points: 50, percentage: '20.32' },
		{ trait_type: 'type', value: 'Human', points: 96, percentage: '20.32' },
		{
			trait_type: 'outer clothing',
			value: 'White mage robe',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Inner clothing',
			value: 'Black tshirt',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Back',
			value: 'Gold scifi gun',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Mask',
			value: 'Gray demon mask',
			points: 96,
			percentage: '20.32',
		},
	],
	metaAttributes: [
		{ trait_type: 'gender', value: 'Male', points: 50, percentage: '20.32' },
		{ trait_type: 'type', value: 'Human', points: 96, percentage: '20.32' },
		{
			trait_type: 'outer clothing',
			value: 'White mage robe',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Inner clothing',
			value: 'Black tshirt',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Back',
			value: 'Gold scifi gun',
			points: 96,
			percentage: '20.32',
		},
		{
			trait_type: 'Mask',
			value: 'Gray demon mask',
			points: 96,
			percentage: '20.32',
		},
	],
};

export default placeholderNFT;
