import styled from 'styled-components';
import {
	BUTTON_PRIMARY_PURPLE,
	BUTTON_PRIMARY_MAIN,
	BUTTON_SECONDARY,
	FILTER_MAIN,
	PRIMARY_MAIN,
	TERTIARY_MAIN,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
	HIGHLIGHT_COLOR,
	TEXT_GREEN,
} from '../constants/colors';
import ScrollbarArrowUp from '../images/scrollbar/scrollbar-arrow-up.png';
import ScrollbarArrowDown from '../images/scrollbar/scrollbar-arrow-down.png';
import ScrollbarArrowRight from '../images/scrollbar/scrollbar-arrow-right.png';
import ScrollbarArrowLeft from '../images/scrollbar/scrollbar-arrow-left.png';
import CheckedMobile from '../images/icons/CheckedMobile.svg';
import { MOBILE_DEVICE_WIDTH } from '../constants/dimensions';
interface PropInterface {
	gap?: string;
	gapMobile?: string;
	justify?: string;
	clicked?: boolean;
	display?: string;
	align?: string;
}

export const StakeContainer = styled.div`
	width: 90vw;
	max-width: 1400px;

	.mobile-select-all {
		display: flex;
		justify-content: space-between;
		align-items: center;

		p {
			margin: unset;
		}

		button {
			box-shadow: unset !important;
		}

		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	.desktop-select-all {
		display: none;
		@media screen and (min-width: 768px) {
			display: block;
			border-radius: 8px;
		}
	}
`;

export const NFTVerticalScrollDiv = styled.div<PropInterface>`
	display: ${(props) => props.display};
	justify-content: ${(props) => props.justify};
	align-items: ${(props) => props.align};
	width: 100%;
	height: 100%;
	border-radius: 0px 0px 0px 0px;
	overflow: hidden;
	margin-block-end: 3.5rem;

	@media screen and (min-width: 768px) {
		padding: 10px;
		background-color: ${PRIMARY_MAIN};
		margin-block-end: unset;
	}
`;

NFTVerticalScrollDiv.defaultProps = {
	display: 'flex',
	justify: 'center',
	align: 'center',
};

interface dayCare {
	dayCareCheck?: number;
}

export const CheckInAndOutDiv = styled.div<dayCare>`
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: 15px;
	padding: 10px;

	width: 100%;
	height: 54px;
	background-color: ${TERTIARY_MAIN};
	border-radius: 0px 0px 6px 6px;
	position: fixed;
	bottom: 0;
	left: 0;

	> * {
		width: 100% !important;
		color: ${TEXT_WHITE} !important;
		background-color: ${BUTTON_PRIMARY_MAIN};

		@media screen and (min-width: 768px) {
			width: 116px !important;
			background-color: ${(props) =>
				props.dayCareCheck !== 0 ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR};
		}
	}

	> *:first-child {
		background-color: ${BUTTON_PRIMARY_PURPLE};
		@media screen and (min-width: 768px) {
			background-color: ${(props) =>
				props.dayCareCheck !== 0 ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR};
		}
	}

	@media screen and (min-width: 768px) {
		justify-content: flex-start;
		margin-bottom: 50px;
		position: unset;
	}
`;

export const StakeNFTTableContainer = styled.div<PropInterface>`
	font-size: 13px;
	overflow: auto;
	width: 100%;
	height: 100%;

	::--webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;
	-ms-overflow-style: none;

	table {
		width: 100%;
		min-width: 180px;

		@media screen and (min-width: 768px) {
			background-color: ${PRIMARY_MAIN};
		}
	}

	td {
		padding: 16px;
		@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
			padding: 12px;
		}
		p {
			margin: 0;
		}
	}

	thead tr {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
	}
	#headerStats {
		padding: 0;
		width: 100%;
		display: flex;
		justify-content: flex-end;

		td {
			width: 150px;

			&:last-child {
				width: 50px;
			}
		}
	}
	.stake-nft-table-header {
		display: none;
		@media screen and (min-width: 768px) {
			display: block;
		}
	}

	tbody tr {
		border-radius: 4px;
		background-color: ${TERTIARY_MAIN};
		margin-bottom: 5px;
		cursor: pointer;
		// box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
		position: relative;

		@media screen and (min-width: 768px) {
			display: table;
			width: 100%;
			min-width: 480px;
			height: 60px;
			table-layout: fixed;
			position: unset;
		}

		.table-content-info-mobile {
			display: flex;
			gap: 1.5rem;
			padding-block-start: 10px;
			td {
				padding: 0%;
				@media screen and (min-width: 768px) {
					padding: 16px;
				}
			}

			@media screen and (min-width: 768px) {
				display: none;
			}
		}

		.table-content-info {
			display: none;
			@media screen and (min-width: 768px) {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 100%;
				height: 100%;
				padding: 0;

				td {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 150px;

					&:last-child {
						width: 50px;
					}

					@media screen and (min-width: 790px) {
						padding: 8px 14px;
					}
				}

				@media screen and (max-width: 926px) {
					.content-info-desktop {
						min-width: 4.5rem;
					}
				}
			}
		}
	}

	tbody {
		display: flex;
		flex-direction: column;
		height: 501px;
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		height: 100%;

		::--webkit-scrollbar {
			display: none;
		}

		scrollbar-width: none;
		-ms-overflow-style: none;

		@media screen and (min-width: 768px) {
			display: block;

			::-webkit-scrollbar {
				height: 10px;
				width: 10px;
			}

			::-webkit-scrollbar-track {
				background-color: ${FILTER_MAIN};
			}

			::-webkit-scrollbar-corner {
				background-color: ${FILTER_MAIN};
			}

			::-webkit-scrollbar-thumb {
				background-color: ${BUTTON_SECONDARY};
				border-radius: 2px;
			}

			::-webkit-scrollbar-button:single-button {
				background-color: ${TERTIARY_MAIN};
				display: block;
				height: 10px;
				width: 10px;
			}

			::-webkit-scrollbar-button:vertical:increment {
				background-image: url(${ScrollbarArrowDown});
				background-size: 5px 5px;
				background-repeat: no-repeat;
				background-position: center;
			}

			::-webkit-scrollbar-button:vertical:decrement {
				background-image: url(${ScrollbarArrowUp});
				background-size: 5px 5px;
				background-repeat: no-repeat;
				background-position: center;
			}
			::-webkit-scrollbar-button:horizontal:increment {
				background-image: url(${ScrollbarArrowRight});
				background-size: 5px 5px;
				background-repeat: no-repeat;
				background-position: center;
			}

			::-webkit-scrollbar-button:horizontal:decrement {
				background-image: url(${ScrollbarArrowLeft});
				background-size: 5px 5px;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}

	@media screen and (min-width: 768px) {
		scrollbar-width: 10px;
		scrollbar-color: ${FILTER_MAIN} ${BUTTON_SECONDARY};

		::-webkit-scrollbar {
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-track {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-corner {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-thumb {
			background-color: ${BUTTON_SECONDARY};
			border-radius: 2px;
		}

		::-webkit-scrollbar-button:single-button {
			background-color: ${TERTIARY_MAIN};
			display: block;
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-button:horizontal:increment {
			background-image: url(${ScrollbarArrowRight});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}

		::-webkit-scrollbar-button:horizontal:decrement {
			background-image: url(${ScrollbarArrowLeft});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.item {
		width: 45%;
		text-align: left;
		@media screen and (min-width: 768px) {
			width: 300px;
		}
	}

	#header {
		background-color: ${PRIMARY_MAIN};
	}
`;

export const ActionTd = styled.td<PropInterface>`
	position: absolute;
	width: 120px;
	height: 120px;

	@media screen and (min-width: 768px) {
		position: unset;
		width: 50px;
		height: 50px;
	}

	.action-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		border: ${(props) => props.clicked && `2.5px solid ${BUTTON_PRIMARY_MAIN}`};
		border-radius: 0.3rem;

		@media screen and (min-width: 768px) {
			border-radius: 50%;
			width: 17px;
			height: 17px;
			border: 2px solid ${TEXT_GRAYED_OUT};
		}

		.inside-action-circle {
			background: ${(props) => props.clicked && `url(${CheckedMobile})`};
			background-repeat: no-repeat;
			background-position: center;
			background-size: 24px;
			background-color: ${(props) =>
				props.clicked && `rgba(28, 165, 149, 0.4)`};
			width: 100%;
			height: 100%;

			@media screen and (min-width: 768px) {
				background: unset;
				width: 9px;
				height: 9px;
				border-radius: 50%;
				background-color: ${(props) => props.clicked && BUTTON_PRIMARY_PURPLE};
			}
		}
	}
`;

export const DurationDetail = styled.div`
	font-size: 10px;
	font-weight: 500;
	color: ${TEXT_GREEN};
`;

export const FlexDetailDiv = styled.div<PropInterface>`
	display: flex;
	justify-content: ${(props) => props.justify};
	align-items: center;
	border-radius: 0.3rem;
	gap: ${(props) => (props.gapMobile ? props.gapMobile : props.gap)};

	@media screen and (min-width: 768px) {
		gap: ${(props) => props.gap};
	}

	.nft-image {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 85px;
		height: 85px;

		margin-left: -5px;
		border-radius: 0.3rem;
		flex: 1 1 30%;

		img {
			width: 85px;
			height: 85px;
			border-radius: 0.3rem;

			@media screen and (min-width: 312px) {
				border-radius: 0.3rem;
				width: 85px;
				height: 85px;
			}

			@media screen and (min-width: 990px) {
				border-radius: 19rem;
				width: 75px;
				height: 75px;
			}

			@media screen and (min-width: 768px) {
				border-radius: 19rem;
				width: 75px;
				height: 75px;
			}
		}

		@media screen and (min-width: 312px) {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 85px;
			height: 85px;

			border-radius: 0.3rem;
			margin-right: 0;
		}

		@media screen and (min-width: 990px) {
			display: flex;
			justify-content: left;
			align-items: center;
			width: 85px;
			height: 85px;

			border-radius: 0.3rem;
			margin-right: -30%;
		}

		@media screen and (min-width: 768px) {
			display: flex;
			justify-content: left;
			align-items: center;
			width: 63px;
			height: 63px;

			border-radius: 0.3rem;
			margin-right: -30%;
		}
	}

	.mobile-date {
		display: inline-block;
		font-size: 11px;
	}

	.claim {
		flex: 1 1 50%;

		.staked-nft-name {
			text-transform: capitalize;
		}

		@media screen and (max-width: 768px) {
			.staked-nft-name {
				margin-top: -3px;
				padding-bottom: 2px;
				flex: 1 1 60%;
			}
		}
	}

	.hideout-checkbox {
		@media screen and (min-width: 768px) {
			display: none;
		}
	}
`;

FlexDetailDiv.defaultProps = {
	justify: 'center',
};
