import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import AssetHistoryInterface from '../../resources/interfaces/NFTAssetHistoryInterface';
import CollectionStatsInterface from '../../resources/interfaces/CollectionStatsInterface';
import CollectionReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/CollectionReducerStateInterface';
import { Collections } from '../../resources/enums/Collections';
import NFTImageInterface from '../../resources/interfaces/NFTImageInterface';
import { NFTCollectionQueryInterface } from '../../resources/interfaces/NFTCollectionQueryInterface';
import { CollectionAssetsInterface } from '../../resources/interfaces/CollectionAssetsInterface';

export const fetchCollectionStats = async (
	collectionName: Collections
): Promise<CollectionStatsInterface> => {
	const fn = httpsCallable<
		{ collectionName: Collections },
		CollectionStatsInterface
	>(functions, 'getCollectionStats');
	return (await fn({ collectionName })).data;
};

export const fetchCollectionDetails = async (
	collectionName: Collections
): Promise<CollectionReducerStateInterface> => {
	const fn = httpsCallable<
		{ collectionName: Collections },
		CollectionReducerStateInterface
	>(functions, 'getCollection');
	return (await fn({ collectionName })).data;
};

export const fetchAssetHistory = async (
	collectionName: Collections,
	tokenId: number,
	limit = 50
): Promise<AssetHistoryInterface[]> => {
	const fn = httpsCallable<
		{ collectionName: string; tokenId: number; limit: number },
		AssetHistoryInterface[]
	>(functions, 'getCollectionAssetHistory');
	return (await fn({ collectionName, tokenId, limit })).data;
};

export const fetchAssets = async (
	query: NFTCollectionQueryInterface
): Promise<CollectionAssetsInterface> => {
	const fn = httpsCallable<NFTCollectionQueryInterface, NFTImageInterface[]>(
		functions,
		'getCollectionAssets'
	);

	const queryResult = (await fn(query)).data;

	const newStartAt =
		queryResult.length !== 0
			? // eslint-disable-next-line @typescript-eslint/no-explicit-any
			  (queryResult.slice(-1)[0] as Record<string, any>)[query.orderByField]
			: 0;

	return { data: queryResult, newStartAt };
};
