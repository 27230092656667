import { Dispatch } from 'react';
import NFTImageInterface from '../../resources/interfaces/NFTImageInterface';
import {
	SET_COLLECTION_DATA,
	SET_IS_GALLERY_LOADING,
	SET_IS_LAST_ASSET,
	SET_IS_MODAL_OPEN,
	SET_NEW_START_AT,
	SET_SELECTED_NFT,
} from './types/gallery';

export const setIsGalleryLoading =
	(isGalleryLoading: boolean) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_IS_GALLERY_LOADING,
			payload: { isGalleryLoading },
		});
	};

export const setNewStartAt =
	(newStartAt: number) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_NEW_START_AT,
			payload: { newStartAt },
		});
	};

export const setCollectionData =
	(collectionData: NFTImageInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_COLLECTION_DATA,
			payload: { collectionData },
		});
	};

export const setIsLastAsset =
	(isLastAsset: boolean) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_IS_LAST_ASSET,
			payload: { isLastAsset },
		});
	};

export const setIsModalOpen =
	(isModalOpen: boolean) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_IS_MODAL_OPEN,
			payload: { isModalOpen },
		});
	};

export const setSelectedNFT =
	(selectedNFT: NFTImageInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_SELECTED_NFT,
			payload: { selectedNFT },
		});
	};
