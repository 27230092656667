import styled from 'styled-components';

interface Props {
	marginTop?: string;
	marginBottom?: string;
	width?: string;
	gap?: string;
	fontSize?: string;
}

export const ButtonWrapper = styled.div<Props>`
	display: flex;
	gap: ${(props) => props.gap};
	justify-content: center;
	align-items: center;
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	width: ${(props) => props.width};
	font-size: ${(props) => props.fontSize};
`;

ButtonWrapper.defaultProps = {
	marginTop: '0px',
	marginBottom: '100px',
};
