import axios from 'axios';

export const fetchUsernameAndID = async (
	tokenType: string | null,
	accessToken: string | null
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{
	username: string;
	id: string;
}> => {
	try {
		const resp = await axios.get('https://discord.com/api/users/@me', {
			headers: {
				authorization: `${tokenType} ${accessToken}`,
			},
		});
		const { username, discriminator, id } = resp.data;
		const discordUsername = `${username}#${discriminator}`;
		return { username: discordUsername, id: id };
	} catch {
		return { username: '', id: '' };
	}
};
