import React from 'react';
import Loader from '../../../../components/Loader';
import {
	DetailText,
	NFTImageDivBreed,
} from '../../../../resources/styles/NFTCard.styles';

interface PropsInterface {
	isChecked: boolean;
	thumb: string;
	id: number;
	isAllImagesLoaded: boolean;
	handleImagesLoading: (index: number) => void;
	index: number;
}

const PvnkCard = (props: PropsInterface): JSX.Element => {
	const {
		isChecked,
		thumb,
		id,
		isAllImagesLoaded,
		handleImagesLoading,
		index,
	} = props;

	return (
		<>
			<NFTImageDivBreed>
				<input type="checkbox" checked={isChecked} readOnly />
				{!isAllImagesLoaded && <Loader size="15px" />}
				<img
					src={thumb}
					alt={id.toString()}
					height="100%"
					width="100%"
					style={{
						borderRadius: '5px',
						display: isAllImagesLoaded ? 'block' : 'none',
					}}
					onLoad={() => handleImagesLoading(index)}
				/>
			</NFTImageDivBreed>
			<b>
				<DetailText>Skvllpvnk</DetailText>
			</b>
			<p>#{id}</p>
		</>
	);
};

export default React.memo(PvnkCard);
