import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../resources/enums/SiteRoutes';
import CustomButton from '../resources/styles/CustomButton.styles';

interface PropsInterface {
	title: string;
	route: SiteRoutes;
}

const NoNFTFound = (props: PropsInterface): JSX.Element => {
	const { title, route } = props;
	const navigate = useNavigate();
	return (
		<div>
			<h3>
				<b>{title}</b>
			</h3>
			<CustomButton
				onClick={() => navigate(route)}
				width="120px"
				height="40px"
				marginTop="15px"
				borderRadius="5px"
			>
				<b>View Market</b>
			</CustomButton>
		</div>
	);
};

export default NoNFTFound;
