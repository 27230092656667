import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import {
	castToReservedItemInterface,
	castToStoreItemInterface,
} from '../../helpers/store';
import ReservedItemInterface from '../../resources/interfaces/ReservedItemsInterface';
import StoreItemInterface from '../../resources/interfaces/StoreItemInterface';
import { StorePurchaseResponse } from '../../resources/interfaces/StorePurchaseResponse';

export const signStorePurchase = httpsCallable<
	{ itemId: number },
	StorePurchaseResponse
>(functions, 'signStorePurchase');

export const getStoreItems = async (): Promise<StoreItemInterface[]> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fn = httpsCallable<unknown, any[]>(functions, 'getStoreItems');
	return (await fn()).data?.map((item) => castToStoreItemInterface(item));
};

export const getStoreItem = async (
	itemId: number
): Promise<StoreItemInterface> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fn = httpsCallable<{ itemId: number }, any>(functions, 'getStoreItem');
	return castToStoreItemInterface((await fn({ itemId: itemId })).data);
};

export const reserveItemForPurchase = httpsCallable<
	{ walletAddress: string; itemId: number },
	string
>(functions, 'reserveItemForPurchase');

export const getUserReservations = async (): Promise<
	ReservedItemInterface[]
> => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const fn = httpsCallable<unknown, any[]>(functions, 'getUserReservations');
	return (await fn()).data?.map((item) => castToReservedItemInterface(item));
};
