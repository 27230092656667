import {
	CONNECT_SKVLLBABIEZ,
	DECREMENT_SKVLLBABIEZ,
	RESET_SKVLLBABIEZ_STATE,
	SET_MINTING_STATE,
	UPDATE_SKVLLBABIEZ_LIST,
} from '../actions/types/skvllbabiez';
import SkvllbabiezReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/SkvllbabiezReducerStateInterface';
import { TransactionState } from '../../resources/enums/states';
import Skvllbabiez from '../../services/Skvllbabiez';
import SkvllbabiezListInterface from '../../resources/interfaces/SkvllbabiezInterface';

const initialState: SkvllbabiezReducerStateInterface = {
	isMintSkvllbabiezLive: undefined,
	mintableBabiezCount: undefined,
	mintingState: TransactionState.DEFAULT,
	skvllbabiez: undefined,
	totalBabiezMinted: undefined,
	ownedSkvllbabiezList: {
		staked_babiez: undefined,
		not_staked_babiez: undefined,
	},
};

interface PayloadInterface {
	isMintSkvllbabiezLive: boolean;
	mintableBabiezCount: number;
	mintingState: TransactionState;
	skvllbabiez: Skvllbabiez;
	totalBabiezMinted: number;
	decrementValue: number;
	ownedSkvllbabiezList: SkvllbabiezListInterface;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const skvllbabiezReducer = (
	state = initialState,
	action: ActionInterface
): SkvllbabiezReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_SKVLLBABIEZ:
			return {
				isMintSkvllbabiezLive: payload.isMintSkvllbabiezLive,
				mintableBabiezCount: payload.mintableBabiezCount,
				mintingState: TransactionState.DEFAULT,
				skvllbabiez: payload.skvllbabiez,
				totalBabiezMinted: payload.totalBabiezMinted,
				ownedSkvllbabiezList: payload.ownedSkvllbabiezList,
			};
		case DECREMENT_SKVLLBABIEZ:
			return {
				...state,
				mintingState: TransactionState.SUCCESS,
				mintableBabiezCount:
					Number(state.mintableBabiezCount) - payload.decrementValue,
				totalBabiezMinted:
					Number(state.totalBabiezMinted) + payload.decrementValue,
			};
		case SET_MINTING_STATE:
			return {
				...state,
				mintingState: payload.mintingState,
			};
		case UPDATE_SKVLLBABIEZ_LIST:
			return {
				...state,
				ownedSkvllbabiezList: payload.ownedSkvllbabiezList,
			};
		case RESET_SKVLLBABIEZ_STATE:
			return initialState;
		default:
			return state;
	}
};

export default skvllbabiezReducer;
