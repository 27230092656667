import styled from 'styled-components';
import { MOBILE_DEVICE_WIDTH } from '../constants/dimensions';

interface ReleasePropsInterface {
	bgColor: string;
}

export const ReleaseNotesText = styled.div`
	text-align: left;
`;

export const ReleaseVersion = styled.div<ReleasePropsInterface>`
	margin-top: 10px;
	margin-bottom: 10px;
	display: inline-block;
	height: 35px;
	border-radius: 8px;
	text-align: center;
	background-color: ${(props) => props.bgColor};
	color: black;
	padding: 5px 20px;
`;

export const ReleaseNotesContainer = styled.div`
	padding-bottom: 50px;
	margin: auto;
	width: 50%;
	text-align: left;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		width: 90%;
	}
`;

export const ReleaseNotesHeader = styled.div`
	display: flex;
`;

export const ReleaseNotesDate = styled.div`
	font-weight: 600;
	margin-top: 10px;
	font-size: 24px;
	padding-bottom: 20px;
	padding-left: 20px;
	text-align: left;
`;

export const Notes = styled.div`
	padding-top: 5px;
	li {
		padding-bottom: 5px;
	}
`;

export const ReleaseNotesTextContainer = styled.div`
	padding-top: 5px;
	padding-left: 20px;
	text-align: left;
`;
