import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from '../../../../config';
import {
	addItemToPendingTransactions,
	removeItemFromPendingTransactions,
	setIsStoreModalOpen,
} from '../../../../redux/actions/store';
import { HOUR_TO_SECONDS } from '../../../../resources/constants/conversions';
import { GenericError } from '../../../../resources/enums/GenericError';
import { SiteRoutes } from '../../../../resources/enums/SiteRoutes';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import StoreItemInterface from '../../../../resources/interfaces/StoreItemInterface';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import {
	DetailGridDiv,
	PurchaseButton,
} from '../../../../resources/styles/StoreItemModalView.styles';
import Loader from '../../../Loader';
import SweetAlert from '../../../SweetAlert';

interface PropsInterface {
	selectedItem: StoreItemInterface;
	reservedItemsCount: number;
}

const StoreItemButton = (props: PropsInterface): JSX.Element => {
	const { selectedItem, reservedItemsCount } = props;
	const { totalSupply, maxSupply, price, reservationsOpen, id } = selectedItem;
	const {
		isStoreModalOpen,
		purchasedItems,
		reservedItems,
		store,
		pendingItemTransactions,
	} = useSelector((state: RootReducerStateInterface) => state.store);
	const wallet = useSelector(
		(state: RootReducerStateInterface) => state.wallet.wallet
	);
	const ammolite = useSelector(
		(state: RootReducerStateInterface) => state.ammolite.ammolite
	);
	const skvllpvnkz = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz.skvllpvnkz
	);
	const { discord, id: discordId } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);
	const [isCaptchaCompleted, setIsCaptchaCompleted] = useState(
		!config.USE_CAPTCHA
	);
	const isOwnerOfItem = purchasedItems.some(
		(purchasedItem) => purchasedItem.id === id
	);
	const [isInReservationList, setIsInReservationList] = useState(
		!!reservedItems.find((reservedItem) => reservedItem.itemId === id)
			?.isItemReserved
	);

	const isExecutingTransaction = pendingItemTransactions.some(
		(pendingItemTransaction) => pendingItemTransaction.id === id
	);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const isExpired = !!reservedItems.find((item) => item.itemId === id)
		?.isExpired;
	const isSoldOut = totalSupply === maxSupply;
	const maxReservationsReached =
		String(reservedItemsCount) === String(maxSupply);

	const isButtonDisabled =
		(isAuthenticated || isSoldOut || maxReservationsReached) &&
		(isExecutingTransaction ||
			isOwnerOfItem ||
			(!isCaptchaCompleted && !isInReservationList) ||
			!reservationsOpen ||
			isExpired);
	const shouldShowCaptcha =
		!isCaptchaCompleted &&
		!maxReservationsReached &&
		!isSoldOut &&
		!isOwnerOfItem &&
		reservationsOpen &&
		!isExpired &&
		!isInReservationList &&
		isAuthenticated;

	useEffect(() => {
		const checkPurchaseStatus = async () => {
			const isPurchasePending = !!(await store?.doesHavePendingItemPurchase(
				id
			));
			if (isPurchasePending) {
				dispatch(addItemToPendingTransactions(selectedItem));
			} else {
				dispatch(removeItemFromPendingTransactions(selectedItem));
			}
		};
		checkPurchaseStatus();
	}, [isStoreModalOpen]);

	const handleButtonClick = () => {
		if (!isAuthenticated) {
			dispatch(setIsStoreModalOpen(false));
			return navigate(SiteRoutes.connectWallet);
		}

		const isDiscordLinked = !!discordId;

		if (!isDiscordLinked) {
			return SweetAlert({
				showConfirmButton: true,
				title: 'Connect Discord',
				text: 'You first need to link your Discord account before you are able to make purchases in the Hideout store.',
				confirmCallbackFunction: () => discord?.connectDiscord(),
			});
		}

		if (isInReservationList) {
			handleBuyItem();
		} else if (reservationsOpen) {
			handleReservation();
		}
	};

	const handleTransactionError = (error: unknown, isPurchasing: boolean) => {
		if (error instanceof Error) {
			if (error.message === GenericError.ALLOWANCE_LIMIT_EXCEEDED) {
				SweetAlert({
					showConfirmButton: true,
					title: 'Increase Spend',
					text: GenericError.ALLOWANCE_LIMIT_EXCEEDED,
					confirmCallbackFunction: () => ammolite?.increaseAllowance(price),
				});
			} else {
				SweetAlert({
					showConfirmButton: true,
					title: `${isPurchasing ? 'Purchase' : 'Reservation'} Failed`,
					text: error.message,
					confirmButtonText: 'Got it',
				});
			}
		}
	};

	const handleReservation = async () => {
		if (store && ammolite && wallet && skvllpvnkz && isCaptchaCompleted) {
			dispatch(addItemToPendingTransactions(selectedItem));

			try {
				await store.reserveItem(selectedItem);
				await store.fetchReservedItems();
				setIsInReservationList(true);

				SweetAlert({
					showConfirmButton: true,
					title: 'You got it!',
					text: `You made the list! You may now complete your purchase within ${
						selectedItem.expiryPeriodInSeconds / HOUR_TO_SECONDS
					} hour(s).`,
					confirmButtonText: 'Nice',
				});
			} catch (error) {
				console.error(error);
				handleTransactionError(error, false);
			}

			dispatch(removeItemFromPendingTransactions(selectedItem));
		}
	};

	const handleBuyItem = async () => {
		if (store && ammolite && wallet && skvllpvnkz && isInReservationList) {
			dispatch(addItemToPendingTransactions(selectedItem));

			SweetAlert({
				showConfirmButton: false,
				title: 'Executing Purchase',
				text: 'Please confirm the MetaMask transaction and wait. The transaction will run in the background and confirmation will be shown.',
			});
			try {
				await store.buyItem(selectedItem);
				await store.fetchPurchasedItems();
				SweetAlert({
					title: 'Purchase Successful',
					text: `You are now a proud owner of ${selectedItem.name}.`,
				});
			} catch (error) {
				console.error(error);
				handleTransactionError(error, true);
			}

			dispatch(removeItemFromPendingTransactions(selectedItem));
		}
	};

	if (shouldShowCaptcha) {
		return (
			<DetailGridDiv marginTop="20px" fontSize="12px" gap="25px">
				<ReCAPTCHA
					sitekey={config.CAPTCHA_KEY}
					onChange={() => setIsCaptchaCompleted(true)}
					theme="dark"
					style={{ width: '320px' }}
				/>
			</DetailGridDiv>
		);
	}

	const generateButtonText = () => {
		if (isExecutingTransaction) {
			return <Loader size="20px" />;
		} else if (isSoldOut) {
			return 'Sold Out';
		} else if (maxReservationsReached) {
			return 'Reservations Closed';
		} else if (!isAuthenticated) {
			return 'Connect Wallet';
		} else if (isExpired) {
			return 'Expired';
		} else if (isOwnerOfItem) {
			return 'You already own this';
		} else if (isInReservationList) {
			return 'Complete Purchase';
		} else if (reservationsOpen) {
			return 'Reserve Purchase';
		}
		return 'Not Available';
	};

	return (
		<PurchaseButton>
			<CustomButton
				marginTop="20px"
				marginTopResponsive="20px"
				borderRadius="5px"
				disabled={isButtonDisabled}
				width="320px"
				onClick={handleButtonClick}
				id="purchase-button"
			>
				{generateButtonText()}
			</CustomButton>
		</PurchaseButton>
	);
};

export default React.memo(StoreItemButton);
