import {
	CONNECT_AMMOLITE,
	RESET_AMMOLITE_STATE,
	UPDATE_AMMOLITE,
} from '../actions/types/ammolite';
import AmmoliteReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/AmmoliteReducerStateInterface';
import Ammolite from '../../services/Ammolite';

const initialState: AmmoliteReducerStateInterface = {
	ammolite: undefined,
	ammoBalance: undefined,
};

interface PayloadInterface {
	ammolite: Ammolite;
	balance: number;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const ammoliteReducer = (
	state = initialState,
	action: ActionInterface
): AmmoliteReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_AMMOLITE:
			return {
				ammolite: payload.ammolite,
				ammoBalance: payload.balance,
			};
		case UPDATE_AMMOLITE:
			return {
				...state,
				ammoBalance: payload.balance,
			};
		case RESET_AMMOLITE_STATE:
			return initialState;
		default:
			return state;
	}
};

export default ammoliteReducer;
