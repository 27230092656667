import { SET_AUTH_STATE, SET_AUTH_USER } from './types/auth';
import { User } from 'firebase/auth';
import { Dispatch } from 'react';

export const setAuthUser =
	(user: User | null) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: SET_AUTH_USER,
			payload: {
				user,
			},
		});
	};

export const setAuthState =
	(user: User | null, active: boolean, account: string) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: SET_AUTH_STATE,
			payload: {
				isAuthenticated: !!(user && active && account),
			},
		});
	};
