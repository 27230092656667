import React from 'react';
import { ButtonWrapper } from '../../../../../resources/styles/ButtonWrappers.style';
import { SidebarDetails } from '../../../../../resources/styles/Sidebar.style';

interface PropsInterface {
	formattedName: string;
	gwei?: number;
	value: string;
	isEth: boolean;
}

const StatsContent = (props: PropsInterface) => {
	const { formattedName, gwei, value, isEth } = props;
	return (
		<>
			<SidebarDetails marginTop="34px" marginBottom="22px">
				<b>{formattedName}</b>
				<ButtonWrapper marginBottom="0px" gap="20px">
					<b className="balance-text">
						{gwei ? gwei.toFixed(5) : value}
						{isEth && ' ETH'}
					</b>
				</ButtonWrapper>
			</SidebarDetails>
			<hr />
		</>
	);
};

export default React.memo(StatsContent);
