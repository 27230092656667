import { fetchCollectionDetails } from '../../firebase/functions/collections';
import { Collections } from '../../resources/enums/Collections';
import { CONNECT_COLLECTION } from './types/collection';

export const connectCollection =
	// eslint-disable-next-line
	(collection: Collections) => async (dispatch: any) => {
		const {
			collectionName,
			contractAddress,
			description,
			externalLink,
			feeRecipient,
			id,
			image,
			name,
			numberOfSales,
			sellerFeeBasisPoints,
			totalGas,
			totalItems,
			totalVolume,
			traitsArray,
			transactionCount,
		} = await fetchCollectionDetails(collection);

		dispatch({
			type: CONNECT_COLLECTION,
			payload: {
				collectionName,
				contractAddress,
				description,
				externalLink,
				feeRecipient,
				id,
				image,
				name,
				numberOfSales,
				sellerFeeBasisPoints,
				totalGas,
				totalItems,
				totalVolume,
				traitsArray,
				transactionCount,
			},
		});
	};
