import OpenSea from '../../services/OpenSea';
import { CONNECT_OPENSEA } from './types/opensea';
import { Dispatch } from 'react';
import { batch } from 'react-redux';
import Wallet from '../../services/Wallet';

export const connectOpenSea =
	(wallet: Wallet) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (wallet.library) {
			const opensea = new OpenSea(wallet, dispatch);
			batch(() => {
				dispatch({
					type: CONNECT_OPENSEA,
					payload: {
						opensea,
					},
				});
			});
		}
	};
