import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import styled from 'styled-components';
import { NEW_SECONDARY_MAIN, TEXT_WHITE } from '../constants/colors';

export const CustomNavLink = styled(NavLink)`
	color: ${TEXT_WHITE};
	text-decoration: none;
	:hover {
		color: ${NEW_SECONDARY_MAIN} !important;
	}

	#connect-wallet {
		margin-left: 12px;
	}
`;

export const ExternalNavLink = styled.a`
	color: ${TEXT_WHITE};
	text-decoration: none;
	:hover {
		color: ${NEW_SECONDARY_MAIN} !important;
	}
`;

export const CustomNavItem = styled(NavItem)`
	padding: 0.7vw !important;
	margin: auto;
	text-align: left;

	@media screen and (max-width: 768px) {
		margin: 0;
	}
`;

interface NavbarContainerPropsInterface {
	route: string;
}

export const NavbarContainer = styled.div<NavbarContainerPropsInterface>`
	display: ${(props) => (props.route !== '/' ? 'block' : 'none')};
`;
