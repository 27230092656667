import { updateDiscordProfile } from './../redux/actions/discord';
import { DiscordProfileInterface } from './../resources/interfaces/DiscordInterface';
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore';
import { Dispatch } from 'react';
import { db } from '../firebase';

export default class Discord {
	private walletAddress: string;
	private dbListenerUnsubscribers: Unsubscribe[] = [];
	private dispatch: Dispatch<unknown>;

	constructor(dispatch: Dispatch<unknown>, walletAddress: string) {
		this.dispatch = dispatch;
		this.walletAddress = walletAddress;
	}

	public initializeDBListeners(): void {
		const discordProfileUnsubscriber = onSnapshot(
			doc(db, 'profiles', this.walletAddress),
			(doc) => {
				const profile: DiscordProfileInterface = doc.data()?.discord;
				this.dispatch(updateDiscordProfile(profile));
			}
		);
		this.dbListenerUnsubscribers.push(discordProfileUnsubscriber);
	}

	public destroyDBListeners(): void {
		this.dbListenerUnsubscribers.forEach((unsubscribe) => unsubscribe());
	}

	public connectDiscord(): void {
		window.open(
			`https://discord.com/api/oauth2/authorize?response_type=token&client_id=958550468190150666&state=${this.walletAddress}&scope=identify`
		);
	}
}
