import React from 'react';
import {
	HeaderContainer,
	HeaderWrapper,
	StatsWrapper,
} from '../resources/styles/Profile.styles';
import Tabs from './/Tabs';
import PageHeader from './PageHeader';
import HeaderStats from './HeaderStats';
import StatsInterface from '../resources/interfaces/StatsInterface';
import TabsInterface from '../resources/interfaces/TabsInterface';
import PageHeaderInterface from '../resources/interfaces/PageHeaderInterface';

interface PropsInterface {
	stats: StatsInterface;
	tabs?: TabsInterface;
	pageHeader?: PageHeaderInterface;
	textAlign?: string;
	backgroundImage?: string;
}

const PageHeaderContent = (props: PropsInterface) => {
	const { pageHeader, stats, tabs, backgroundImage } = props;

	return (
		<HeaderContainer backgroundImage={backgroundImage}>
			<HeaderWrapper>
				<>
					{pageHeader && (
						<PageHeader
							img={pageHeader.img}
							secondarySubtitle={pageHeader.secondarySubtitle}
							title={pageHeader.title}
							subtitle={pageHeader.subtitle}
							textAlign={pageHeader.textAlign}
							textTransform={pageHeader.textTransform}
						/>
					)}
					{stats && (
						<StatsWrapper>
							<HeaderStats
								areStatsLoading={stats.areStatsLoading}
								statsData={stats.statsData}
								stylesGrid={stats.stylesGrid}
							/>
						</StatsWrapper>
					)}
					{tabs && (
						<Tabs
							activeTab={tabs?.activeTab}
							buttonParameters={tabs?.buttonParameters}
							handleContentChange={tabs?.handleContentChange}
						/>
					)}
				</>
			</HeaderWrapper>
		</HeaderContainer>
	);
};

export default PageHeaderContent;
