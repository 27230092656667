import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-image-lightbox/style.css';
import { BrowserRouter as Router } from 'react-router-dom';

import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './resources/styles/index.css';
import App from './App';
import store from './redux/store';
import { Web3ReactProvider } from '@web3-react/core';
import { ethers } from 'ethers';

const getLibrary = (
	provider:
		| ethers.providers.ExternalProvider
		| ethers.providers.JsonRpcFetchFunc
): ethers.providers.Web3Provider => {
	return new ethers.providers.Web3Provider(provider, 'any');
};

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Web3ReactProvider getLibrary={getLibrary}>
				<Provider store={store}>
					<App />
				</Provider>
			</Web3ReactProvider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);
