import { Dispatch } from 'react';
import NFTAssetInterface from '../../resources/interfaces/NFTAssetInterface';
import SkvllbabiezListInterface from '../../resources/interfaces/SkvllbabiezInterface';
import {
	CONNECT_BABIEZ_AMMOLITE_UPDATER,
	CONNECT_SKVLLPVNKZ_AMMOLITE_UPDATER,
	INCREMENT_DAYCARE_REWARDS,
	INCREMENT_HIDEOUT_REWARDS,
	RESET_PENDING_REWARDS_STATE,
} from './types/pendingRewards';

export const connectSkvllpvnkzAmmoliteUpdater =
	(skvllpvnkzList: NFTAssetInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		let hideoutIntervalId: null | number = null;
		if (skvllpvnkzList.length) {
			hideoutIntervalId = hideoutAmmoliteUpdater(
				dispatch,
				skvllpvnkzList.length
			);
		}
		const skvllpvnkzRewards = skvllpvnkzList.reduce(
			(sum, pvnk) => sum + parseFloat(pvnk.rewards),
			0
		);
		dispatch({
			type: CONNECT_SKVLLPVNKZ_AMMOLITE_UPDATER,
			payload: { skvllpvnkzRewards, intervalId: hideoutIntervalId },
		});
	};

const hideoutAmmoliteUpdater = (
	dispatch: Dispatch<unknown>,
	ammountMultiplier: number
) => {
	const intervalId = window.setInterval(() => {
		dispatch({
			type: INCREMENT_HIDEOUT_REWARDS,
			payload: { ammountMultiplier, intervalId },
		});
	}, 1000);
	return intervalId;
};

export const connectBabiezAmmoliteUpdater =
	(babiezList: SkvllbabiezListInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		window.clearInterval();
		const { not_staked_babiez, staked_babiez } = babiezList;
		let daycareIntervalId: null | number = null;
		if (staked_babiez?.length) {
			daycareIntervalId = daycareAmmoliteUpdater(
				dispatch,
				staked_babiez.length
			);
		}
		const notStakedBabiezRewards =
			not_staked_babiez?.reduce(
				(sum, baby) => sum + parseFloat(baby.rewards),
				0
			) || 0;
		const stakedBabiezRewards =
			staked_babiez?.reduce((sum, baby) => sum + parseFloat(baby.rewards), 0) ||
			0;
		const babiezRewards = notStakedBabiezRewards + stakedBabiezRewards;
		dispatch({
			type: CONNECT_BABIEZ_AMMOLITE_UPDATER,
			payload: { babiezRewards, intervalId: daycareIntervalId },
		});
	};

const daycareAmmoliteUpdater = (
	dispatch: Dispatch<unknown>,
	ammountMultiplier: number
) => {
	const intervalId = window.setInterval(() => {
		dispatch({
			type: INCREMENT_DAYCARE_REWARDS,
			payload: { ammountMultiplier, intervalId },
		});
	}, 1000);
	return intervalId;
};

export const resetPendingRewardsState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_PENDING_REWARDS_STATE });
	};
