import styled from 'styled-components';
import {
	BUTTON_PRIMARY_MAIN,
	TEXT_GREEN,
	TEXT_WHITE,
	TERTIARY_MAIN,
	TEXT_PURPLE,
	LINE_COLOR,
	PRIMARY_MAIN,
} from '../../constants/colors';

interface PropsInterface {
	marginTop?: string;
	marginBottom?: string;
	fontSize?: string;
}

export const TitleDiv = styled.div<PropsInterface>`
	color: ${TEXT_WHITE};
	font-weight: 700;
	font-size: ${(props) => props.fontSize};
	margin: auto;
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
`;

export const UsernameIcon = styled.td`
	display: flex;
	margin-left: -18px;
	word-wrap: anywhere;
	align-items: center;
	justify-content: center;

	@media only screen and (min-width: 768px) {
		justify-content: unset;
	}
`;

TitleDiv.defaultProps = {
	marginTop: '81px',
	marginBottom: '0px',
	fontSize: '42px',
};

export const SubtitleDiv = styled.div<PropsInterface>`
	margin: auto;
	font-weight: 400;
	font-size: 16px;
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
`;

SubtitleDiv.defaultProps = {
	marginTop: '10px',
	marginBottom: '20px',
};

export const Code = styled.td`
	font-family: courier;
	color: ${TEXT_GREEN};
	cursor: pointer;
	word-wrap: break-word;
`;
export const PortalKeyStatus = styled.td<{
	color: string;
}>`
	color: ${(props) => props.color};
`;

export const GreenLink = styled.a`
	font-family: courier;
	color: ${TEXT_GREEN};
	cursor: pointer;
	text-decoration: none;
`;

export const GreenLText = styled.span`
	font-family: courier;
	color: ${TEXT_GREEN};
	cursor: pointer;
`;

export const PrizeContainer = styled.div<{
	order: string;
}>`
	color: ${TEXT_WHITE};
	width: 350px;
	height: 500px;
	font-size: 16px;
	background-color: ${PRIMARY_MAIN};
	opacity: 0.9;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: ${(props) => (props.order === '-1' ? 'space-between' : '')};
	padding: 2rem;
	// box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	border-radius: 0.625rem;

	@media screen and (min-width: 768px) {
		order: ${(props) => props.order};
	}

	p,
	h1 {
		margin: 0;
	}

	h1 {
		font-size: 3.5rem;
	}

	img {
		max-width: 27ch;
		border-radius: 0.5rem;
		margin: auto;
		aspect-ratio: 1/1;
	}

	#item {
		font-weight: 600;
		font-size: 1.3rem;
		margin-block-start: 1.5rem;
	}

	#worth {
		color: ${BUTTON_PRIMARY_MAIN};
	}

	.username {
		text-align: left;
	}

	.content h1 {
		color: ${TEXT_PURPLE};
		font-size: 6.25rem;
		font-weight: 600;
	}

	.title {
		margin-bottom: -2.4rem;
		font-size: 1.5rem;
	}

	.title h3 {
		font-weight: 900;
	}

	#green-text {
		color: ${TEXT_GREEN};
		font-size: 12px;
		text-decoration: underline;
		cursor: pointer;
	}

	.portal-content {
		margin-block: 3.8rem;
		line-height: 1.7;
	}
`;

export const CraftedKeys = styled.div`
	background-color: ${TERTIARY_MAIN};
`;

export const EntriesButtonLink = styled.div<{
	active: string;
}>`
	display: flex;
	gap: 1.3rem;
	justify-content: center;
	border-bottom: 1px solid ${LINE_COLOR};

	b {
		padding-block-end: 0.5rem;
		transition: border-bottom 0.35s ease-in-out;
		cursor: pointer;
	}

	b:first-child {
		border-bottom: ${(props) =>
			props.active === 'true' ? ` 2px solid ${BUTTON_PRIMARY_MAIN}` : ''};
	}

	b:last-child {
		border-bottom: ${(props) =>
			props.active === 'false' ? ` 2px solid ${BUTTON_PRIMARY_MAIN}` : ''};
	}
`;

export const EntryHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	gap: 1.2rem;

	max-width: 1540px;
	margin: auto;
	margin-block-end: 2rem;
	.timer {
		margin-block: 4rem;
	}

	@media screen and (min-width: 1250px) {
		flex-direction: row;
		gap: unset;
		margin-blo > :nth-child(1) {
			order: 0;
		}
	}
`;

export const Hr = styled.hr`
	max-width: 404px;
	height: 0px;
	margin: 0 auto;
`;

export const GoBackButton = styled.div`
	position: absolute;
	top: -20px;

	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	cursor: pointer;
	font-weight: 600;
	font-size: 12px;
`;
