import {
	LotteryEntryInterface,
	LotteryInterface,
} from '../resources/interfaces/LotteryInterface';

export const castToLotteryInterface = (data: any): LotteryInterface => ({
	portalName: data.portalName,
	openTime: data.openTime,
	endTime: data.endTime,
	id: data.id,
	isOpen: data.isOpen,
	isDrawInProgress: data.isDrawInProgress,
	isComplete: data.isComplete,
	maxEntries: data.maxEntries,
	maxEntriesPerWallet: data.maxEntriesPerWallet,
	numberOfEntries: data.numberOfEntries,
	prizeBalance: data.prizeBalance,
	prizeImageResource: data.prizeImageResource,
	prizeLabel: data.prizeLabel,
	entryPrice: data.entryPrice,
	winningEntries: data.winningEntries,
	numberOfWinners: data.numberOfWinners,
	entries: data.entries,
});

export const castToLotteryEntryInterface = (
	data: any
): LotteryEntryInterface => ({
	portalName: data.portalName,
	entryPrice: data.entryPrice,
	entryTime: new Date(data.entryTime),
	lotteryId: data.lotteryId,
	id: data.id,
	transactionHash: data.transactionHash,
	walletAddress: data.walletAddress,
	status: data.status,
	username: data.username,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortEntriesByDate = (entries: any[]): any[] => {
	return entries.sort((a, b) => b.entryTime.getTime() - a.entryTime.getTime());
};
