import { HOUR_TO_SECONDS } from '../constants/conversions';
import StoreItemInterface from '../interfaces/StoreItemInterface';

const placeholderStoreItem: StoreItemInterface = {
	id: 1,
	name: 'Droopy Dingos WL',
	description: 'Gain whitelist access to mint the upcoming Droopy Dingos mint.',
	description2:
		'Gain whitelist access to mint the upcoming Droopy Dingos mint.',
	description3:
		'Gain whitelist access to mint the upcoming Droopy Dingos mint.',
	image: 'https://storage.googleapis.com/skvllpvnkz/thumbs_400/1486.jpg',
	price: 15,
	type: '',
	active: false,
	totalSupply: 0,
	maxSupply: 0,
	status: 'New',
	link: '',
	project: '',
	twitter: '',
	reservationsOpen: false,
	expiryPeriodInSeconds: HOUR_TO_SECONDS,
	salesOpen: false,
};

export default placeholderStoreItem;
