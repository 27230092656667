import { CONNECT_WALLET, RESET_WALLET_STATE } from '../actions/types/wallet';
import WalletReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/WalletReducerStateInterface';

const initialState: WalletReducerStateInterface = {
	isConnected: false,
	address: '',
	balance: null,
	wallet: null,
};

interface ActionInterface {
	type: string;
	payload: WalletReducerStateInterface;
}

const walletReducer = (
	state = initialState,
	action: ActionInterface
): WalletReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_WALLET:
			return {
				isConnected: payload.isConnected,
				address: payload.address,
				balance: payload.balance,
				wallet: payload.wallet,
			};
		case RESET_WALLET_STATE:
			return initialState;
		default:
			return state;
	}
};

export default walletReducer;
