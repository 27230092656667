import React from 'react';
import { useDispatch } from 'react-redux';
import Loader from '../../../../components/Loader';
import { setCheckInState } from '../../../../redux/actions/rewardsAndStaking';
import { TransactionState } from '../../../../resources/enums/states';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import { StakeProgressContainer } from '../../../../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import {
	SubtitleDiv,
	TitleDiv,
} from '../../../../resources/styles/WalletNotConnected.styles';

interface PropsInterface {
	stakeState: TransactionState;
}

const StakeProgress = (props: PropsInterface): JSX.Element => {
	const { stakeState } = props;
	const dispatch = useDispatch();

	let title: string;
	let content: string;
	switch (stakeState) {
		case TransactionState.SUCCESS:
			title = 'Skvllbabiez Checked Into Daycare!';
			content =
				'Your Skvllbabiez are now earning Ammolite! You can claim your AMMOLITE whenever you want.';
			break;
		case TransactionState.FAILED:
			title = 'Uh oh, it seems that the transaction failed.';
			content =
				'The transaction failed. Please check Etherscan for details and contact the Devs on discord if you have any questions.';
			break;
		case TransactionState.APPROVE_OPERATOR_IN_PROGRESS:
			title = 'Waiting For Approval…';
			content =
				'To transfer a batch of babiez and save gas, we need you to approve the Daycare Contract as an operator. You only need to do this once.';
			break;
		default:
			title = 'Checking Skvllbabiez into Daycare...';
			content =
				'Please wait while the professionals take care of your baby…';
	}

	const showOkButton =
		stakeState !== TransactionState.IN_PROGRESS &&
		stakeState !== TransactionState.APPROVE_OPERATOR_IN_PROGRESS;
	const showSpinner =
		stakeState === TransactionState.IN_PROGRESS ||
		stakeState === TransactionState.APPROVE_OPERATOR_IN_PROGRESS;

	return (
		<StakeProgressContainer padding="200px">
			<div>
				<TitleDiv>{title}</TitleDiv>
				<SubtitleDiv>{content}</SubtitleDiv>
				{showOkButton && (
					<CustomButton
						onClick={() => {
							dispatch(setCheckInState(TransactionState.DEFAULT));
						}}
						width="120px"
						height="40px"
						marginTop="15px"
						borderRadius="5px"
					>
						<b>Ok</b>
					</CustomButton>
				)}
				{showSpinner && <Loader color="text-secondary" marginTop="20px" />}
			</div>
		</StakeProgressContainer>
	);
};

export default StakeProgress;
