import {
	CONNECT_SKVLLPVNKZ,
	DISPOSE_USED_SKVLLPVNKZ,
	RESET_SKVLLPVNKZ_STATE,
	UPDATE_SKVLLPVNKZ_LIST,
} from './../actions/types/skvllpvnkz';
import SkvllpvnkzReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/SkvllpvnkzReducerStateInterface';
import { ethers } from 'ethers';
import Skvllpvnkz from '../../services/Skvllpvnkz';
import NFTAssetInterface from '../../resources/interfaces/NFTAssetInterface';

const initialState: SkvllpvnkzReducerStateInterface = {
	skvllpvnkz: undefined,
	ownedSkvllpvnkzList: [],
};

interface PayloadInterface {
	skvllpvnkz: Skvllpvnkz;
	ownedSkvllpvnkzList: NFTAssetInterface[];
	usedSkvllpvnkIds: number[];
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const skvllpvnkzReducer = (
	state = initialState,
	action: ActionInterface
): SkvllpvnkzReducerStateInterface => {
	const { type, payload } = action;

	switch (type) {
		case CONNECT_SKVLLPVNKZ:
			return {
				...state,
				skvllpvnkz: payload.skvllpvnkz,
				ownedSkvllpvnkzList: payload.ownedSkvllpvnkzList,
			};
		case DISPOSE_USED_SKVLLPVNKZ:
			return {
				...state,
				ownedSkvllpvnkzList: state.ownedSkvllpvnkzList.map((skvllpvnk) => {
					const isSkvllpvnkUsed = payload.usedSkvllpvnkIds.includes(
						Number(ethers.utils.formatUnits(skvllpvnk.id, 0))
					);
					return isSkvllpvnkUsed
						? {
								...skvllpvnk,
								virgin: false,
						  }
						: skvllpvnk;
				}),
			};
		case UPDATE_SKVLLPVNKZ_LIST:
			return {
				...state,
				ownedSkvllpvnkzList: payload.ownedSkvllpvnkzList,
			};
		case RESET_SKVLLPVNKZ_STATE:
			return initialState;
		default:
			return state;
	}
};

export default skvllpvnkzReducer;
