import React from 'react';
import {
	TEXT_GRAYED_OUT,
	TEXT_GREEN,
} from '../../../../resources/constants/colors';
import AmmoliteIcon from '../../../../resources/images/icons/AmmoliteIcon.png';

import {
	Ammolite,
	DetailFlexDiv,
	DetailGridDiv,
} from '../../../../resources/styles/StoreItemModalView.styles';

import StoreItemInterface from '../../../../resources/interfaces/StoreItemInterface';
import { formatUnits } from 'ethers/lib/utils';
import { IoLogoTwitter, IoMdGlobe } from 'react-icons/io';
import StoreItemButton from './StoreItemButton';

interface PropsInterface {
	selectedItem: StoreItemInterface;
	reservedItemsCount: number;
}

const StoreItemDetails = (props: PropsInterface): JSX.Element => {
	const { selectedItem, reservedItemsCount } = props;
	const { price, totalSupply, maxSupply, type, name, project, link, twitter } =
		selectedItem;

	const itemPriceElement = price && (
		<div>
			<p>Price</p>
			<DetailFlexDiv fontSize="20px" marginTop="-10px">
				<Ammolite src={AmmoliteIcon} alt="ammolite" height="18px"></Ammolite>
				<b>{parseInt(formatUnits(price, 18)).toString()}</b>
			</DetailFlexDiv>
		</div>
	);
	const itemMaxSupplyElement = maxSupply && (
		<div>
			<p style={{ marginLeft: `${reservedItemsCount ? '-50px' : ''}` }}>
				Purchased
			</p>
			<DetailFlexDiv
				fontSize="20px"
				marginTop="-10px"
				marginLeft={`${reservedItemsCount ? '-50px' : ''}`}
			>
				<b>
					{totalSupply} / {maxSupply}
				</b>
			</DetailFlexDiv>
		</div>
	);
	const itemReservationsCount = reservedItemsCount > 0 && (
		<div>
			<p style={{ marginLeft: '-15px' }}>Reserved</p>
			<DetailFlexDiv fontSize="20px" marginTop="-10px" marginLeft="-15px">
				<b>
					{reservedItemsCount} / {maxSupply}
				</b>
			</DetailFlexDiv>
		</div>
	);
	return (
		<div>
			<DetailFlexDiv fontSize="12px" color={TEXT_GRAYED_OUT} marginTop="5px">
				{type === 'WL' ? 'MINT WHITELIST' : type}
			</DetailFlexDiv>
			<DetailFlexDiv fontSize="24px" marginTop="5px" marginBottom="10px">
				<b>{name}</b>
			</DetailFlexDiv>
			<DetailFlexDiv fontSize="10px" gap="3px" marginBottom="15px">
				<p id="project-label">{project}</p>
			</DetailFlexDiv>
			<DetailGridDiv fontSize="12px" gap="20px" color={TEXT_GRAYED_OUT}>
				{itemPriceElement}
				{itemReservationsCount}
				{itemMaxSupplyElement}
			</DetailGridDiv>
			<StoreItemButton
				selectedItem={selectedItem}
				reservedItemsCount={reservedItemsCount}
			/>
			<DetailGridDiv
				marginTop="35px"
				fontSize="12px"
				gap="20px"
				color={TEXT_GREEN}
			>
				<div>
					<IoMdGlobe size={18} />
					<a id="link" href={link}>
						Official Link
					</a>
				</div>
			</DetailGridDiv>
			<DetailGridDiv
				marginTop="15px"
				fontSize="12px"
				gap="20px"
				color={TEXT_GREEN}
			>
				<div>
					<IoLogoTwitter size={18} />
					<a id="link" href={twitter}>
						Twitter
					</a>
				</div>
			</DetailGridDiv>
		</div>
	);
};
export default React.memo(StoreItemDetails);
