import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import UserStatsInterface from '../../resources/interfaces/UserStatsInterface';

export const getUserStats = async (): Promise<UserStatsInterface> => {
	const fn = httpsCallable<unknown, UserStatsInterface>(
		functions,
		'getUserStats'
	);
	return (await fn()).data;
};
