import React, { useEffect, useState } from 'react';
import { RafflePageTabIds } from '../../resources/enums/RafflePageTabIds';
import { PageContainer } from '../../resources/styles/Page.styles';
import MainPage from './components/MainPage/MainPage';

import UserEntries from './components/UserEntries/UserEntries';
import RaffleLoader from './components/RaffleLoader';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useSelector } from 'react-redux';
import { EntryFilterEnum } from '../../resources/enums/Entries';

import PortalWallpaper from '../../resources/images/portal_bg.png';

const RafflePage = (): JSX.Element => {
	const [rafflePageTabId, setRafflePageTabId] = useState<RafflePageTabIds>(
		RafflePageTabIds.MAIN_PAGE
	);
	const {
		lottery,
		currentLottery,
		userPortalKeys,
		previousLottery,
		allPreviousWinners,
	} = useSelector((state: RootReducerStateInterface) => state.lottery);
	const { address } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);
	const isLotteryOpen = currentLottery?.isOpen || false;

	const [isDrawInProgressLocally, setIsDrawInProgressLocally] = useState(false);
	const isDrawInProgress = currentLottery?.isDrawInProgress || false;

	if (isDrawInProgress) {
		setIsDrawInProgressLocally(false);
	}

	const [isLoading, setIsLoading] = useState(true);
	const [portalKeyFilter, setKeyFilter] = useState(EntryFilterEnum.all);

	const changeRaffleTab = (tabId: RafflePageTabIds) => {
		scroll(0, 0);
		setRafflePageTabId(tabId);
	};

	const setPortalKeyFilter = (filter: EntryFilterEnum) => {
		setKeyFilter(filter);
	};

	const userPortalKeysCount = currentLottery?.entries?.reduce(
		(previousValue, portalKey) => {
			if (portalKey.walletAddress === address) {
				return previousValue + 1;
			}
			return previousValue;
		},
		0
	);

	useEffect(() => {
		if (lottery) {
			setIsLoading(false);
		}
	}, [lottery]);

	let RafflePageContent = (
		<MainPage
			changeRaffleTab={changeRaffleTab}
			previousLottery={previousLottery}
			allPreviousWinners={allPreviousWinners}
			currentLottery={currentLottery}
			userPortalkeysCount={userPortalKeysCount || 0}
			isLotteryOpen={isLotteryOpen}
			setIsDrawInProgressLocally={setIsDrawInProgressLocally}
			lottery={lottery}
		/>
	);

	if (isLoading) {
		RafflePageContent = (
			<RaffleLoader
				title="Welcome to Animus Beacon"
				subtitle="Connecting to portal server..."
			/>
		);
	} else if (isDrawInProgress || isDrawInProgressLocally) {
		RafflePageContent = (
			<RaffleLoader
				title="Portal jump is underway..."
				subtitle="Succesful jumpers will be announced shortly..."
			/>
		);
	} else if (rafflePageTabId === RafflePageTabIds.USER_ENTRIES_PAGE) {
		RafflePageContent = (
			<UserEntries
				changeRaffleTab={changeRaffleTab}
				userPortalKeys={userPortalKeys}
				isLotteryOpen={isLotteryOpen}
				portalKeyFilter={portalKeyFilter}
				setPortalKeyFilter={setPortalKeyFilter}
			/>
		);
	}

	return (
		<PageContainer backgroundImage={PortalWallpaper} opacity="0.2">
			{RafflePageContent}
		</PageContainer>
	);
};

export default RafflePage;
