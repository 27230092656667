import React from 'react';
import { generateCardTagText } from '../../../../helpers/store';
import { ImageContainer } from '../../../../resources/styles/StoreItemModalView.styles';

interface PropsInterface {
	image: string;
	status: string;
	soldOut: boolean;
	id: number;
	reservationsOpen?: boolean;
}

const StoreItemImage = (props: PropsInterface): JSX.Element => {
	const { image, status, soldOut, reservationsOpen } = props;

	return (
		<ImageContainer cardTag={status ? true : false}>
			<div id="card-tag">
				{generateCardTagText(soldOut, status, reservationsOpen)}
			</div>
			{<img src={image} alt="NFTImage" />}
		</ImageContainer>
	);
};

export default StoreItemImage;
