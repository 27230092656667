import SweetAlert from '../components/SweetAlert';
import { ConnectorNames } from '../resources/enums/ConnectorNames';

export const validateWalletSelected = (connectorName: ConnectorNames): void => {
	if (connectorName === ConnectorNames.MetaMask) {
		if (typeof window.ethereum === 'undefined' || !window.ethereum.isMetaMask) {
			SweetAlert({
				text: 'It seems like you do not have metamask installed. Get metamask to continue.',
			});
			throw new Error();
		}
	}
};
