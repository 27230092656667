import styled from 'styled-components';
import { MOBILE_DEVICE_WIDTH } from '../../constants/dimensions';

export const ConnectWalletDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: calc(100vh - 100px);
	margin-top: -150px;
	padding-left: 25px;
	padding-right: 25px;

	b {
		font-size: 24px;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		display: block;
		margin-top: 41px;
	}
`;
