import { httpsCallable } from 'firebase/functions';
import { functions } from '..';

export const getAuthNonceToSign = httpsCallable<unknown, string>(
	functions,
	'getAuthNonceToSign'
);

export const verifyAuthSignature = httpsCallable<unknown, string>(
	functions,
	'verifyAuthSignature'
);
