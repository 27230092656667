import { httpsCallable } from 'firebase/functions';
import { functions } from '..';
import RewardCollection from '../../resources/enums/RewardCollection';

export const getVirginSkvllpvnkzIds = async (
	stakingContractName: RewardCollection
): Promise<number[]> => {
	const fn = httpsCallable<{ stakingContractName: RewardCollection }, number[]>(
		functions,
		'getVirginSkvllpvnkzIds'
	);
	return (await fn({ stakingContractName })).data;
};

export const updateVirginSkvllpvnkz = async (
	tokenIds: number[]
): Promise<void> => {
	const fn = httpsCallable<{ tokenIds: number[] }, void>(
		functions,
		'updateVirginSkvllpvnkz'
	);
	await fn({ tokenIds });
};
