import { ethers } from 'ethers';
import { Dispatch } from 'react';
import { batch } from 'react-redux';
import config from '../config';
import daycareStakingABI from '../contracts/daycareStakingABI.json';
import { updateAmmolite } from '../redux/actions/ammolite';
import {
	setCheckInState,
	setCheckOutState,
	setNotStakedDaycareRewardState,
	setStakedDaycareRewardState,
} from '../redux/actions/rewardsAndStaking';
import { updateSkvllbabiezList } from '../redux/actions/skvllbabiez';
import { NETWORK_TRANSACTION_SUCCESS } from '../resources/constants/codes';
import { TransactionState } from '../resources/enums/states';
import TransactionInterface from '../resources/interfaces/TransactionInterface';
import Ammolite from './Ammolite';
import { TransactionType } from '../resources/enums/TransactionType';
import { conditionalReloadByEvent } from '../helpers/session';
import Wallet from './Wallet';
import { updateStakedState } from '../firebase/functions/rewards';
import RewardCollection from '../resources/enums/RewardCollection';
export default class Daycare {
	private contract;
	private provider;
	private wallet;
	private ammolite;
	private dispatch;

	constructor(wallet: Wallet, ammolite: Ammolite, dispatch: Dispatch<unknown>) {
		this.dispatch = dispatch;
		this.wallet = wallet;
		this.provider = wallet.library;
		this.ammolite = ammolite;
		this.contract = new ethers.Contract(
			config.DAYCARE_CONTRACT_ADDRESS,
			daycareStakingABI,
			this.provider.getSigner()
		);
		this.initializeContractListeners();
	}

	public initializeContractListeners(): void {
		this.contract.on('DaycareOpen', (event) => conditionalReloadByEvent(event));
		this.contract.on('DaycareClosed', (event) =>
			conditionalReloadByEvent(event)
		);
	}

	public isDaycareOpen(): boolean {
		return this.contract.isDaycareOpen();
	}

	private handleCheckInState(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result: any,
		skvllbabyIds: number[]
	) {
		this.provider.once(
			result.hash,
			(ongoingTransaction: { status: number }) => {
				batch(() => {
					if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
						this.dispatch(updateSkvllbabiezList());
						this.dispatch(setCheckInState(TransactionState.SUCCESS));
						updateStakedState(skvllbabyIds, RewardCollection.daycare_staking);
					} else {
						this.dispatch(setCheckInState(TransactionState.FAILED));
					}
				});
			}
		);
	}

	private handleCheckOutState(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result: any,
		skvllbabyIds: number[]
	) {
		this.provider.once(
			result.hash,
			(ongoingTransaction: { status: number }) => {
				batch(() => {
					if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
						this.dispatch(updateSkvllbabiezList());
						this.dispatch(setCheckOutState(TransactionState.SUCCESS));
						updateStakedState(skvllbabyIds, RewardCollection.daycare_staking);
					} else {
						this.dispatch(setCheckOutState(TransactionState.FAILED));
					}
				});
			}
		);
	}

	public async checkInSkvllbabiez(skvllbabyIds: number[]): Promise<void> {
		try {
			const tx = await this.getTransactionObject(
				skvllbabyIds,
				TransactionType.CHECKIN_DAYCARE
			);
			const result = await this.contract.checkIn(skvllbabyIds, tx);
			this.handleCheckInState(result, skvllbabyIds);
		} catch (er) {
			console.error(er);
			this.dispatch(setCheckInState(TransactionState.FAILED));
		}
	}

	public async checkOutSkvllbabiesBatch(skvllbabyIds: number[]): Promise<void> {
		try {
			const tx = await this.getTransactionObject(
				skvllbabyIds,
				TransactionType.CHECKOUT_DAYCARE
			);
			const result = await this.contract.checkOut(skvllbabyIds, tx);
			this.handleCheckOutState(result, skvllbabyIds);
		} catch (er) {
			console.error(er);
			this.dispatch(setCheckOutState(TransactionState.FAILED));
		}
	}

	private handleRewardState(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		result: any,
		dispatchFunction: (transactionState: TransactionState) => void
	): void {
		this.provider.once(
			result.hash,
			(ongoingTransaction: { status: number }) => {
				batch(() => {
					if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
						this.dispatch(updateSkvllbabiezList());
						this.dispatch(updateAmmolite(this.ammolite));
						this.dispatch(dispatchFunction(TransactionState.SUCCESS));
					} else {
						this.dispatch(dispatchFunction(TransactionState.FAILED));
					}
				});
			}
		);
	}

	public async collectBatchBabyReward(
		skvllbabyIds: number[],
		isStaked: boolean
	): Promise<void> {
		const dispatchFunction = isStaked
			? setStakedDaycareRewardState
			: setNotStakedDaycareRewardState;
		try {
			const tx = await this.getTransactionObject(
				skvllbabyIds,
				TransactionType.COLLECT_DAYCARE_REWARDS
			);
			const result = await this.contract.collectRewards(skvllbabyIds, tx);
			this.handleRewardState(result, dispatchFunction);
		} catch (er) {
			console.error(er);
			this.dispatch(dispatchFunction(TransactionState.FAILED));
		}
	}

	public getTransactionObject = async (
		skvllbabyIds: number[],
		transactionType: TransactionType,
		isHardwareWallet = false
	): Promise<TransactionInterface> => {
		const getFunctionCosts = async (txType: TransactionType) => {
			switch (txType) {
				case TransactionType.CHECKIN_DAYCARE:
					return {
						baseFunctionCost: config.CHECKIN_DAYCARE_BASE_FUNCTION_GAS_COST,
						variableFunctionCost:
							config.CHECKIN_DAYCARE_VARIABLE_FUNCTION_GAS_COST,
						gasEstimate: await this.contract.estimateGas.checkIn(skvllbabyIds),
					};
				case TransactionType.CHECKOUT_DAYCARE:
					return {
						baseFunctionCost: config.CHECKOUT_DAYCARE_BASE_FUNCTION_GAS_COST,
						variableFunctionCost:
							config.CHECKIN_DAYCARE_VARIABLE_FUNCTION_GAS_COST,
						gasEstimate: await this.contract.estimateGas.checkOut(skvllbabyIds),
					};
				case TransactionType.COLLECT_DAYCARE_REWARDS:
					return {
						baseFunctionCost:
							config.COLLECT_DAYCARE_REWARDS_BATCH_BASE_FUNCTION_GAS_COST,
						variableFunctionCost:
							config.COLLECT_DAYCARE_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST,
						gasEstimate: await this.contract.estimateGas.collectRewards(
							skvllbabyIds
						),
					};
			}
		};

		const { baseFunctionCost, variableFunctionCost, gasEstimate } =
			await getFunctionCosts(transactionType);
		const finalGasLimit =
			skvllbabyIds.length * variableFunctionCost + baseFunctionCost;
		let transaction: TransactionInterface = isHardwareWallet
			? {
					gasPrice: await this.provider.getGasPrice(),
					gasLimit: gasEstimate.toNumber(),
			  }
			: {};

		if (config.USE_MANUAL_GAS_LIMIT) {
			transaction = isHardwareWallet
				? {
						gasLimit: finalGasLimit,
						gasPrice: await this.provider.getGasPrice(),
				  }
				: {
						gasLimit: finalGasLimit,
				  };
		}
		return transaction;
	};

	public async collectAllBabyReward(skvllbabyIds: number[]): Promise<void> {
		try {
			const tx = await this.getTransactionObject(
				skvllbabyIds,
				TransactionType.COLLECT_DAYCARE_REWARDS
			);
			const result = await this.contract.collectRewards(skvllbabyIds, tx);
			this.provider.once(
				result.hash,
				(ongoingTransaction: { status: number }) => {
					batch(() => {
						if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
							this.dispatch(updateSkvllbabiezList());
							this.dispatch(updateAmmolite(this.ammolite));
							this.dispatch(
								setStakedDaycareRewardState(TransactionState.DEFAULT)
							);
							this.dispatch(
								setNotStakedDaycareRewardState(TransactionState.DEFAULT)
							);
						} else {
							batch(() => {
								this.dispatch(
									setStakedDaycareRewardState(TransactionState.DEFAULT)
								);
								this.dispatch(
									setNotStakedDaycareRewardState(TransactionState.DEFAULT)
								);
							});
						}
					});
				}
			);
		} catch (er) {
			console.error(er);
			batch(() => {
				this.dispatch(setStakedDaycareRewardState(TransactionState.DEFAULT));
				this.dispatch(setNotStakedDaycareRewardState(TransactionState.DEFAULT));
			});
		}
	}
}
