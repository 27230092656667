import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StakeNFT from '../../components/StakeNFT/StakeNFT';
import { updateSkvllpvnkzList } from '../../redux/actions/skvllpvnkz';
import { CollectionTitles } from '../../resources/enums/CollectionTitles';
import RewardCollection from '../../resources/enums/RewardCollection';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { MintHeader } from '../../resources/styles/DaycarePage/MintSkvllbabiezTab/MintSkvllbabiezTab.styles';
import { HideoutContainer } from '../../resources/styles/HideoutPage/HideoutPage.styles';
import { PageContainer } from '../../resources/styles/Page.styles';
import PageHeaderContent from '../../components/PageHeaderContent';
import bannerDesktop1 from '../../resources/images/banner/bannerDesktop1.png';
import config from '../../config/index';

const HideoutPage = (): JSX.Element => {
	const { skvllpvnkz, ownedSkvllpvnkzList } = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz
	);
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);
	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(updateSkvllpvnkzList());
		}
	}, [isAuthenticated]);

	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);

	const { totalHideoutRewards } = useSelector(
		(state: RootReducerStateInterface) => state.pendingRewards
	);

	const statsData = {
		totalSkvllpvnkz: {
			title: 'Skvllpvnkz',
			value: ownedSkvllpvnkzList.length,
			toolTip: 'Total Skvllpvnkz owned',
		},
		totalSkvllpvnkzRewards: {
			title: 'Current Rewards',
			value: totalHideoutRewards.toFixed(3),
			ammoIcon: true,
			toolTip: 'Total Skvllpvnkz rewards',
		},
		dailySkvllpvnkzRewards: {
			title: 'Daily Rewards',
			value: ownedSkvllpvnkzList.length * 2,
			ammoIcon: true,
			toolTip: 'Total daily rewards by Skvllpvnkz',
		},
	};

	const pageHeader = {
		user: user,
		title: 'Hideout Rewards',
		subtitle: config.HIDEOUT_REWARD_ADDRESS,
		textAlign: 'center',
		textTransform: 'capitalize',
	};

	const Stats = {
		areStatsLoading: false,
		statsData,
		stylesGrid: '3, 1fr',
	};

	return (
		<PageContainer>
			<div>
				<PageHeaderContent
					pageHeader={pageHeader}
					stats={Stats}
					backgroundImage={bannerDesktop1}
				/>

				<HideoutContainer>
					<div id="container">
						<MintHeader>
							<b>Skvllpvnkz Hideout</b>
						</MintHeader>
						<StakeNFT
							nftList={ownedSkvllpvnkzList}
							contract={skvllpvnkz}
							collectionTitle={CollectionTitles.Skvllpvnkz}
							rewardCollection={RewardCollection.hideout_rewards}
							title="skvllpvnkz"
						/>
					</div>
				</HideoutContainer>
			</div>
		</PageContainer>
	);
};

export default HideoutPage;
