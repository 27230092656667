import React from 'react';
import { useDispatch } from 'react-redux';
import { setMintingState } from '../../../../redux/actions/skvllbabiez';
import { TransactionState } from '../../../../resources/enums/states';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import {
	MintContentTitle,
	SubtitleDiv,
} from '../../../../resources/styles/DaycarePage/MintSkvllbabiezTab/MintSkvllbabiezTab.styles';
import Loader from '../../../../components/Loader';
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import firebaseConfig from '../../../../config/firebaseConfig';
const analytics = getAnalytics(initializeApp(firebaseConfig));

interface Props {
	handleHeader: (header: string) => void;
	mintingState: TransactionState;
}

const MintProgress = (props: Props): JSX.Element => {
	const { mintingState, handleHeader } = props;
	const dispatch = useDispatch();

	let title: string;
	let content: string;
	switch (mintingState) {
		case TransactionState.IN_PROGRESS:
			title = 'Minting your Skvllbabiez...';
			content = 'Please wait while we mint your Skvllbabiez on the blockchain…';
			break;
		case TransactionState.SUCCESS:
			title = 'Mint successful!';
			content =
				'You’ve minted! Check your wallet on Opensea to see your newly minted Skvllbabiez. Note that it takes a few moments for the metadata and the image to be made public.';
			logEvent(analytics, "babyMint", { mintResult: 'SUCCESS'});
			break;
		case TransactionState.FAILED:
			title = 'Mint failed 😔';
			content =
				'The transaction failed. Please check Etherscan for details and contact the Devs on discord if you have any questions.';
			logEvent(analytics, "babyMint", { mintResult: 'FAILED'});
			break;
		default:
			title = 'Minting your Skvllbabiez';
			content = 'Please wait while we mint your Skvllbabiez on the blockchain…';
	}

	const showOkButton = mintingState !== TransactionState.IN_PROGRESS;
	const showSpinner = mintingState === TransactionState.IN_PROGRESS;

	return (
		<div>
			<MintContentTitle>{title}</MintContentTitle>
			<SubtitleDiv padding="30px">{content}</SubtitleDiv>
			{showOkButton && (
				<CustomButton
					onClick={() => {
						handleHeader('Loading your skvllpvnkz...');
						dispatch(setMintingState(TransactionState.DEFAULT));
					}}
					width="120px"
					height="40px"
					marginTop="15px"
					borderRadius="5px"
				>
					<b>Ok</b>
				</CustomButton>
			)}
			{showSpinner && <Loader color="text-secondary" marginTop="20px" />}
		</div>
	);
};

export default MintProgress;
