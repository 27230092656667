import React from 'react';
import { getIsBabyStaked } from '../../../../helpers/gallery';
import { ImageContainer } from '../../../../resources/styles/NFTModalView.styles';

interface PropsInterface {
	virgin: boolean | undefined;
	animation_url: string | undefined;
	image: string;
	owner: string;
}

const NFTImage = (props: PropsInterface): JSX.Element => {
	const { owner, virgin, animation_url, image } = props;
	const isVirgin = virgin === true;
	const isBabyStaked = getIsBabyStaked(owner);
	const generateGalleryCardTagText = () => {
		if (isVirgin) return 'Virgin';
		if (isBabyStaked) return 'Staked';
	};

	return (
		<div>
			<ImageContainer hasCardTag={isVirgin || isBabyStaked}>
				<div id="card-tag">{generateGalleryCardTagText()}</div>
				{!animation_url && <img src={image} alt="NFTImage" />}
				{animation_url && (
					<div id="video">
						<video autoPlay muted loop>
							<source src={animation_url} type="video/mp4" />
						</video>
					</div>
				)}
			</ImageContainer>
		</div>
	);
};

export default NFTImage;
