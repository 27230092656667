import React from 'react'
import { Header, HeaderWrapper } from '../resources/styles/Profile.styles'
import Identicon from 'react-identicons';
import { ExternalRoutes } from '../resources/enums/SiteRoutes';
import PageHeaderInterface from '../resources/interfaces/PageHeaderInterface'

const PageHeader = (props: PageHeaderInterface) => {
    const { img, title, subtitle, secondarySubtitle, textAlign, textTransform } = props;

    const formattedDiscordUsername = title?.split('#')[0];

    return (
        <HeaderWrapper textAlign={textAlign}>
            <div className="header-wrapper">
                {img && (
                    <div className="avatar">
                        <Identicon string={subtitle} size={35} />
                    </div>
                )}
                <Header
                    textTransform={textTransform}
                >
                    {title && (
                        <h1 className="title">{formattedDiscordUsername}</h1>
                    )}
                    {subtitle && (
                        <a
                            className="wallet-address"
                            href={`${ExternalRoutes.etherscanAddress}${subtitle}`}
                            target="none"
                        >
                            <p>{subtitle}</p>
                        </a>
                    )}
                    {secondarySubtitle && (
                        <p className="secondary-subtitle">
                            Member since:{' '}
                            {secondarySubtitle}
                        </p>
                    )}
                </Header>
            </div>
        </HeaderWrapper>
    )
}

export default React.memo(PageHeader)