import { Dispatch } from 'react';
import { batch } from 'react-redux';
import config from '../config';
import { fetchAssets } from '../firebase/functions/collections';
import {
	setFilterByWallet,
	setFilterCollapseStates,
} from '../redux/actions/filter';
import {
	setCollectionData,
	setIsGalleryLoading,
	setIsLastAsset,
	setIsModalOpen,
	setNewStartAt,
} from '../redux/actions/gallery';
import { BATCH_LIMIT } from '../resources/constants/limits';
import { Collections } from '../resources/enums/Collections';
import { FilterTypes } from '../resources/enums/FilterTypes';
import { OrderByKeys } from '../resources/enums/OrderByKeys';
import { SiteRoutes } from '../resources/enums/SiteRoutes';
import { NFTCollectionQueryInterface } from '../resources/interfaces/NFTCollectionQueryInterface';
import TraitObjectInterface from '../resources/interfaces/TraitObjectInterface';
import { parseUrlPath } from './url';

export const processSelectedTraits = (
	selectedTraits: TraitObjectInterface[],
	trait: {
		traitType: string;
		value: string;
	}
): TraitObjectInterface[] => {
	let traitTypeIndex = 0;

	const isTraitTypeInSelectedTraits = (): boolean => {
		return selectedTraits.some((type, index) => {
			traitTypeIndex = index;
			return type.trait_type === trait.traitType;
		});
	};

	const isValueInSelectedTraits = (): boolean => {
		return selectedTraits[traitTypeIndex].values.some(
			(value) => value === trait.value
		);
	};

	const handleValueInSelectedTraits = () => {
		selectedTraits[traitTypeIndex].values = selectedTraits[
			traitTypeIndex
		].values.filter((traitValue) => traitValue !== trait.value);
		selectedTraits = selectedTraits.filter(
			(selectedTrait) => selectedTrait.values.length > 0
		);
	};

	const handleTraitTypeInSelectedTraits = () => {
		if (isValueInSelectedTraits()) {
			handleValueInSelectedTraits();
		} else {
			selectedTraits[traitTypeIndex].values = [
				...selectedTraits[traitTypeIndex].values,
				trait.value,
			];
		}
	};

	if (isTraitTypeInSelectedTraits()) {
		handleTraitTypeInSelectedTraits();
	} else {
		selectedTraits = [
			...selectedTraits,
			{
				trait_type: trait.traitType,
				values: [trait.value],
			},
		];
	}
	return selectedTraits;
};

export const handleSearchByOwner = async (
	dispatch: Dispatch<unknown>,
	selectedCollection: Collections,
	owner: string,
	// eslint-disable-next-line
	navigate: any
): Promise<void> => {
	batch(() => {
		dispatch(setIsModalOpen(false));
		dispatch(setIsGalleryLoading(true));
		dispatch(setCollectionData([]));
	});
	const params = {
		collectionName: selectedCollection,
		startAt: 0,
		orderByField: OrderByKeys.TOKEN,
		orderByDirection: 'asc',
		virgin: undefined,
		walletAddress: owner,
		filters: [],
		limit: BATCH_LIMIT,
	} as NFTCollectionQueryInterface;
	const { data, newStartAt } = await fetchAssets(params);
	const isLastAsset = data.length < BATCH_LIMIT;
	const { page } = parseUrlPath();

	navigate(
		`${page}${
			page === SiteRoutes.profile ? '/collections' : ''
		}/${selectedCollection}`
	);

	batch(() => {
		dispatch(setFilterCollapseStates(FilterTypes.SEARCH, true));
		dispatch(setFilterByWallet(owner));
		dispatch(setCollectionData(data));
		dispatch(setNewStartAt(newStartAt));
		dispatch(setIsLastAsset(isLastAsset));
		dispatch(setIsGalleryLoading(false));
	});
};

export const getIsBabyStaked = (owner: string): boolean =>
	owner === config.DAYCARE_CONTRACT_ADDRESS;
