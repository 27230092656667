import React, { useState } from 'react';
import Select from 'react-select';
import {
	FilterCollapse,
	FilterSection,
} from '../../../resources/styles/FilterPanel.styles';
import {
	PRIMARY_MAIN,
	SECONDARY_DARK_GRAY,
	TEXT_GREEN,
	TEXT_PURPLE,
} from '../../../resources/constants/colors';
import { FilterTypes } from '../../../resources/enums/FilterTypes';
import { useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { Collapse } from 'reactstrap';
import {
	setFilterCollapseStates,
	setSelectedCollection,
} from '../../../redux/actions/filter';
import { Collections } from '../../../resources/enums/Collections';
import { useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../../../resources/enums/SiteRoutes';

interface Props {
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
}
const CollectionFilter = (props: Props): JSX.Element => {
	const { filterSectionIcon } = props;
	const filterCollapseStates = useSelector(
		(state: RootReducerStateInterface) => state.filter.filterCollapseStates
	);
	const [handle, setHandle] = useState<boolean>(false)
	const isFilterOpen =
		filterCollapseStates[FilterTypes.COLLECTION]?.isOpen || handle;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const customStyles = {
		control: () => ({
			display: 'flex',
			backgroundColor: 'transparent',
			borderWidth: '0px',
		}),
	};

	const options = Object.values(Collections).map((collection) => {
		return {
			label: collection.replace('_', ' ').toUpperCase(),
			value: collection,
		};
	});

	return (
		<>
			<FilterSection
				onClick={() => {
					dispatch(setFilterCollapseStates(FilterTypes.COLLECTION))
					setHandle(!handle)
				}
				}
				active={isFilterOpen}
				paddingRight="20px"
			>
				Collection
				{filterSectionIcon(isFilterOpen)}
			</FilterSection>
			<FilterCollapse>
				<Collapse
					className="filter-collapse"
					isOpen={isFilterOpen}>
					<div className="select">
						<Select
							onChange={(event) => {
								{
									if (event) {
										dispatch(setSelectedCollection(event?.value));
										navigate(
											`${SiteRoutes.profile}/collections/${event?.value.replace(
												'_',
												'-'
											)}`
										);
									}
								}
							}}
							options={options}
							components={{ IndicatorSeparator: () => null }}
							styles={customStyles}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								border: 'none',
								colors: {
									...theme.colors,
									primary25: TEXT_GREEN,
									primary50: TEXT_PURPLE,
									primary75: TEXT_GREEN,
									primary: SECONDARY_DARK_GRAY,
									neutral0: PRIMARY_MAIN,
									neutral40: TEXT_GREEN,
									neutral5: PRIMARY_MAIN,
								},
							})}
						/>
					</div>
				</Collapse>
			</FilterCollapse>
		</>
	);
};

export default CollectionFilter;
