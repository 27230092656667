import { Dispatch } from 'react';
import { SET_LOADER_SUBTITLE } from './types/loader';

export const setLoaderSubtitle =
	(loaderSubtitleText: string) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		dispatch({
			type: SET_LOADER_SUBTITLE,
			payload: { loaderSubtitleText },
		});
	};
