import StoreReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/StoreReducerStateInterface';
import ReservedItemInterface from '../../resources/interfaces/ReservedItemsInterface';
import StoreItemBalance from '../../resources/interfaces/StoreItemBalance';
import StoreItemInterface from '../../resources/interfaces/StoreItemInterface';
import placeholderStoreItem from '../../resources/placeholders/placeholderStoreItem';
import Store from '../../services/Store';
import {
	CONNECT_STORE,
	SET_STORE_DATA,
	SET_IS_STORE_MODAL_OPEN,
	SET_STORE_SELECTED_ITEM,
	SET_PURCHASED_ITEMS,
	ADD_ITEM_TO_STORE_DATA,
	UPDATE_STORE_ITEM,
	SET_RESERVED_ITEMS,
	RESET_STORE_STATE,
	ADD_ITEM_TO_PENDING_TRANSACTIONS,
	REMOVE_ITEM_FROM_PENDING_TRANSACTIONS,
} from '../actions/types/store';

const initialState: StoreReducerStateInterface = {
	store: undefined,
	storeData: [],
	isStoreModalOpen: false,
	selectedItem: placeholderStoreItem,
	purchasedItems: [],
	pendingItemTransactions: [],
	reservedItems: [],
};

interface PayloadInterface {
	store: Store;
	isStoreLoading: boolean;
	newStartAt: number;
	isLastAsset: boolean;
	storeData: StoreItemInterface[];
	isStoreModalOpen: boolean;
	selectedItem: StoreItemInterface;
	purchasedItems: StoreItemBalance[];
	newStoreItem: StoreItemInterface;
	updatedStoreItem: StoreItemInterface;
	pendingItem: StoreItemInterface;
	reservedItems: ReservedItemInterface[];
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const storeReducer = (
	state = initialState,
	action: ActionInterface
): StoreReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_STORE:
			return {
				...state,
				store: payload.store,
			};
		case SET_STORE_DATA:
			return {
				...state,
				storeData: payload.storeData,
			};
		case ADD_ITEM_TO_STORE_DATA:
			if (payload.newStoreItem.id >= state.storeData.length) {
				state.storeData.push(payload.newStoreItem);
			}
			return {
				...state,
			};
		case UPDATE_STORE_ITEM:
			state.storeData[payload.updatedStoreItem.id] = payload.updatedStoreItem;
			return {
				...state,
				selectedItem:
					state.selectedItem.id === payload.updatedStoreItem.id
						? payload.updatedStoreItem
						: state.selectedItem,
			};
		case SET_IS_STORE_MODAL_OPEN:
			return {
				...state,
				isStoreModalOpen: payload.isStoreModalOpen,
			};
		case SET_STORE_SELECTED_ITEM:
			return {
				...state,
				selectedItem: payload.selectedItem,
			};
		case SET_PURCHASED_ITEMS:
			return {
				...state,
				purchasedItems: payload.purchasedItems,
			};
		case SET_RESERVED_ITEMS:
			return {
				...state,
				reservedItems: payload.reservedItems,
			};
		case ADD_ITEM_TO_PENDING_TRANSACTIONS:
			return {
				...state,
				pendingItemTransactions: [
					...state.pendingItemTransactions,
					payload.pendingItem,
				],
			};
		case REMOVE_ITEM_FROM_PENDING_TRANSACTIONS:
			return {
				...state,
				pendingItemTransactions: state.pendingItemTransactions.filter(
					(entry) => entry.id != payload.pendingItem.id
				),
			};
		case RESET_STORE_STATE:
			return initialState;
		default:
			return state;
	}
};

export default storeReducer;
