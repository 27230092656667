import {
	CONNECT_SKVLLBABIEZ,
	DECREMENT_SKVLLBABIEZ,
	RESET_SKVLLBABIEZ_STATE,
	SET_MINTING_STATE,
	UPDATE_SKVLLBABIEZ_LIST,
} from './types/skvllbabiez';
import Skvllbabiez from '../../services/Skvllbabiez';
import { TransactionState } from '../../resources/enums/states';
import Wallet from '../../services/Wallet';
import { Dispatch } from 'react';
import { fetchSkvllbabiezDetails } from '../../helpers/skvllbabiez';
import { batch } from 'react-redux';
import { connectBabiezAmmoliteUpdater } from './pendingRewards';

export const connectSkvllbabiez =
	(wallet: Wallet) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (wallet.library) {
			const skvllbabiez = new Skvllbabiez(wallet, dispatch);
			const mintableBabiezCount = await skvllbabiez.getMintableCount();
			const isMintSkvllbabiezLive = await skvllbabiez.isMintSkvllbabiezLive();
			const totalBabiezMinted = await skvllbabiez.getTotalBabiezMinted();
			const ownedSkvllbabiezList = await skvllbabiez.getSkvllbabiez();

			batch(() => {
				dispatch({
					type: CONNECT_SKVLLBABIEZ,
					payload: {
						skvllbabiez,
						mintableBabiezCount,
						isMintSkvllbabiezLive,
						totalBabiezMinted,
						ownedSkvllbabiezList,
					},
				});
				dispatch(connectBabiezAmmoliteUpdater(ownedSkvllbabiezList));
			});
		}
	};

export const decrementBabiezCount =
	(value: number) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: DECREMENT_SKVLLBABIEZ,
			payload: {
				decrementValue: value,
			},
		});
	};

export const setMintingState =
	(mintingState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_MINTING_STATE,
			payload: {
				mintingState,
			},
		});
	};

export const updateSkvllbabiezList =
	() =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		const ownedSkvllbabiezList = await fetchSkvllbabiezDetails();
		batch(() => {
			dispatch({
				type: UPDATE_SKVLLBABIEZ_LIST,
				payload: {
					ownedSkvllbabiezList,
				},
			});
			dispatch(connectBabiezAmmoliteUpdater(ownedSkvllbabiezList));
		});
	};

export const resetSkvllbabiezState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_SKVLLBABIEZ_STATE });
	};
