import { Dispatch } from 'react';
import { TransactionState } from '../../resources/enums/states';
import Ammolite from '../../services/Ammolite';
import Daycare from '../../services/Daycare';
import Hideout from '../../services/HideoutRewards';
import Wallet from '../../services/Wallet';
import {
	CONNECT_REWARDS_AND_STAKING,
	SET_CHECKIN_STATE,
	SET_CHECKOUT_STATE,
	SET_STAKED_DAYCARE_REWARDS_STATE,
	SET_HIDEOUT_REWARDS_STATE,
	SET_NOT_STAKED_DAYCARE_REWARDS_STATE,
	RESET_REWARDS_STATE,
} from './types/daycare';

export const connectRewardsAndStaking =
	(wallet: Wallet, ammolite: Ammolite | undefined) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (wallet.library && ammolite) {
			const daycare = new Daycare(wallet, ammolite, dispatch);
			const isDaycareOpen = await daycare.isDaycareOpen();
			const hideout = new Hideout(wallet, ammolite, dispatch);
			const isHideoutRewardsOpen = await hideout.isHideoutRewardsOpen();
			dispatch({
				type: CONNECT_REWARDS_AND_STAKING,
				payload: { daycare, isDaycareOpen, hideout, isHideoutRewardsOpen },
			});
		}
	};

export const setCheckInState =
	(transactionState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_CHECKIN_STATE,
			payload: {
				transactionState,
			},
		});
	};

export const setCheckOutState =
	(transactionState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_CHECKOUT_STATE,
			payload: {
				transactionState,
			},
		});
	};

export const setStakedDaycareRewardState =
	(transactionState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_STAKED_DAYCARE_REWARDS_STATE,
			payload: {
				transactionState,
			},
		});
	};

export const setNotStakedDaycareRewardState =
	(transactionState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_NOT_STAKED_DAYCARE_REWARDS_STATE,
			payload: {
				transactionState,
			},
		});
	};

export const setHideoutRewardState =
	(transactionState: TransactionState) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_HIDEOUT_REWARDS_STATE,
			payload: {
				transactionState,
			},
		});
	};

export const resetRewardsState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: RESET_REWARDS_STATE,
		});
	};
