import React, { useEffect, useState } from 'react'
import RootReducerStateInterface from '../../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useSelector } from 'react-redux';
import { NoItemPurchased } from '../../../../../resources/styles/Store/Store.styles';
import Loader from '../../../../Loader';

const LoadPurchase = () => {
    const { store, storeData, purchasedItems, reservedItems } = useSelector(
        (state: RootReducerStateInterface) => state.store
    );
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchBalances = async () => {
        setIsLoading(true);
        store?.fetchPurchasedItems();
        setIsLoading(false);
    };

    useEffect(() => {
        fetchBalances();
    }, [storeData]);

    if (isLoading) return <Loader size="30px" marginTop="50px" />;
    if (purchasedItems.length < 1 && reservedItems.length < 1) {
        return (
            <NoItemPurchased>
                <h6>You don&apos;t have any..</h6>
            </NoItemPurchased>
        );
    }

    return (
        <></>
    )
}

export default LoadPurchase