import React from 'react';
import { PageContainer } from '../../resources/styles/Page.styles';
import DaycareTab from './DaycareTab/DaycareTab';

const DaycarePage = (): JSX.Element => {
	return (
		<PageContainer>
			<DaycareTab />
		</PageContainer>
	);
};

export default DaycarePage;
