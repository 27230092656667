import { SET_AUTH_STATE } from './../actions/types/auth';
import { User } from 'firebase/auth';
import AuthReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/AuthReducerStateInterface';
import { SET_AUTH_USER } from '../actions/types/auth';

const initialState: AuthReducerStateInterface = {
	user: null,
	isAuthenticated: false,
};

interface PayloadInterface {
	user: User;
	isAuthenticated: boolean;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const authReducer = (
	state = initialState,
	action: ActionInterface
): AuthReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SET_AUTH_USER:
			return {
				...state,
				user: payload.user,
			};
		case SET_AUTH_STATE:
			return {
				...state,
				isAuthenticated: payload.isAuthenticated,
			};
		default:
			return state;
	}
};

export default authReducer;
