import React from 'react';
import NFTAssetHistoryInterface from '../../../../resources/interfaces/NFTAssetHistoryInterface';
import {
	ActivityDiv,
	DetailFlexDiv,
	TransactionTypeActivityDiv,
} from '../../../../resources/styles/NFTModalView.styles';
import EthIcon from '../../../../resources/images/logos/ETH-logo.png';
import ListIcon from '../../../../resources/images/icons/event-list-icon.png';
import SaleIcon from '../../../../resources/images/icons/event-sale-icon.png';
import TransferIcon from '../../../../resources/images/icons/event-transfer-icon.png';
import { ExternalRoutes } from '../../../../resources/enums/SiteRoutes';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { formatAddress } from '../../../../helpers/address';
import { formatDate } from '../../../../helpers/date';

interface PropsInterface {
	assetHistory: NFTAssetHistoryInterface[];
}

const Activity = (props: PropsInterface): JSX.Element => {
	const { assetHistory } = props;

	const activityIcons: { [key: string]: string } = {
		SALE: SaleIcon,
		TRANSFER: TransferIcon,
		FREE_MINT: ListIcon,
		MINT: SaleIcon,
	};

	const { address } = useSelector(
		(state: RootReducerStateInterface) => state.wallet
	);

	return (
		<ActivityDiv>
			<table>
				<thead>
					<td>
						<b>Event</b>
					</td>
					<td>
						<b>Price</b>
					</td>
					<td>
						<b>From</b>
					</td>
					<td>
						<b>To</b>
					</td>
					<td>
						<b>Date</b>
					</td>
					<td>
						<b>TX Hash</b>
					</td>
				</thead>
				<tbody className="activity-table-desktop">
					{assetHistory.map((activity, index) => {
						const { type, value, from, to, timestamp, transactionHash } =
							activity;
						return (
							<tr key={index}>
								<td>
									<DetailFlexDiv fontSize="12px" marginTop="5px">
										<TransactionTypeActivityDiv>
											<img
												className="icon"
												src={activityIcons[type]}
												alt={type}
												height="13px"
											/>
											{type}
										</TransactionTypeActivityDiv>
									</DetailFlexDiv>
								</td>

								<td>
									<DetailFlexDiv fontSize="12px" marginTop="5px">
										<img src={EthIcon} alt="ETH" height="17px" />
										{value.toFixed(2)}
									</DetailFlexDiv>
								</td>

								<td className="green">
									<DetailFlexDiv fontSize="12px" marginTop="20px">
										<a href={`${ExternalRoutes.etherscanAddress}${from}`}>
											<p className="green">
												{address === from ? 'YOU' : formatAddress(from)}
											</p>
										</a>
									</DetailFlexDiv>
								</td>

								<td>
									<DetailFlexDiv fontSize="12px" marginTop="20px">
										<a href={`${ExternalRoutes.etherscanAddress}${to}`}>
											<p className="green">
												{address === to ? 'YOU' : formatAddress(to)}
											</p>
										</a>
									</DetailFlexDiv>
								</td>

								<td>
									<DetailFlexDiv fontSize="12px" marginTop="5px">
										{formatDate(new Date(timestamp))}
									</DetailFlexDiv>
								</td>

								<td>
									<DetailFlexDiv fontSize="12px" marginTop="20px">
										<a href={`${ExternalRoutes.etherscanTx}${transactionHash}`}>
											<p className="green">{formatAddress(transactionHash)}</p>
										</a>
									</DetailFlexDiv>
								</td>
							</tr>
						);
					})}
				</tbody>

				<tbody className="activity-table-mobile">
					{assetHistory.map((activity, index) => {
						const { type, value, from, to, timestamp, transactionHash } =
							activity;
						return (
							<tr key={index}>
								<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
									<div className="event">
										<b>Event</b>
									</div>
									<DetailFlexDiv
										fontSize="12px"
										marginTop="5px"
										marginLeft="5%"
									>
										<TransactionTypeActivityDiv>
											<img
												className="icon"
												src={activityIcons[type]}
												alt={type}
												height="13px"
											/>
											{type}
										</TransactionTypeActivityDiv>
									</DetailFlexDiv>
								</DetailFlexDiv>

								<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
									<div className="event">
										<b>Price</b>
									</div>
									<DetailFlexDiv
										fontSize="12px"
										marginTop="5px"
										marginLeft="5%"
									>
										<img src={EthIcon} alt="ETH" height="17px" />
										{value.toFixed(2)}
									</DetailFlexDiv>
								</DetailFlexDiv>

								<div className="green">
									<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
										<div className="event">
											<b>From</b>
										</div>
										<DetailFlexDiv
											fontSize="12px"
											marginTop="20px"
											marginLeft="5%"
										>
											<a href={`${ExternalRoutes.etherscanAddress}${from}`}>
												<p className="green">
													{address === from ? 'YOU' : formatAddress(from)}
												</p>
											</a>
										</DetailFlexDiv>
									</DetailFlexDiv>
								</div>

								<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
									<div className="event">
										<b>To</b>
									</div>
									<DetailFlexDiv
										fontSize="12px"
										marginTop="20px"
										marginLeft="5%"
									>
										<a href={`${ExternalRoutes.etherscanAddress}${to}`}>
											<p className="green">
												{address === to ? 'YOU' : formatAddress(to)}
											</p>
										</a>
									</DetailFlexDiv>
								</DetailFlexDiv>

								<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
									<div className="event">
										<b>Date</b>
									</div>
									<DetailFlexDiv
										fontSize="12px"
										marginTop="5px"
										marginLeft="5%"
									>
										{formatDate(new Date(timestamp))}
									</DetailFlexDiv>
								</DetailFlexDiv>

								<DetailFlexDiv fontSize="12px" marginTop="5px" display="flex">
									<div className="event">
										<b>Tx Hash</b>
									</div>
									<DetailFlexDiv
										fontSize="12px"
										marginTop="20px"
										marginLeft="5%"
									>
										<a href={`${ExternalRoutes.etherscanTx}${transactionHash}`}>
											<p className="green">{formatAddress(transactionHash)}</p>
										</a>
									</DetailFlexDiv>
								</DetailFlexDiv>
							</tr>
						);
					})}
				</tbody>
			</table>
		</ActivityDiv>
	);
};

export default Activity;
