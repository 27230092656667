import React from 'react'
import { SwitchButtonWrapper } from '../../../../resources/styles/FilterPanel.styles';
import { Button } from 'reactstrap';
import {
    SwitchButtonActivated,
    SwitchButtonAll,
    SwitchButtonCorrupted,
    SwitchButtonPending,
} from '../../../../resources/styles/PorttalKeyFilters.styles';
import Entry from './Entry';
import { LotteryEntryInterface } from '../../../../resources/interfaces/LotteryInterface';
import { EntryFilterEnum } from '../../../../resources/enums/Entries';
import { sortEntriesByDate } from '../../../../helpers/lottery';
import { RafflePageTabIds } from '../../../../resources/enums/RafflePageTabIds';

interface PropsInterface {
    changeRaffleTab?: (tabId: RafflePageTabIds) => void;
    setPortalKeyFilter: (portalKeyFilter: EntryFilterEnum) => void;
    portalKeyFilter: EntryFilterEnum;
    userPortalKeys: LotteryEntryInterface[];
}

const UserEntryTable = (props: PropsInterface): JSX.Element => {
    const {
        userPortalKeys,
        setPortalKeyFilter,
        portalKeyFilter,
    } = props;

    const filteredUserPortalKeys =
        portalKeyFilter === EntryFilterEnum.all
            ? userPortalKeys
            : userPortalKeys.filter(
                (portalKey) => portalKey.status === portalKeyFilter
            );

    const tableHeader = (
        <>
            <td className="center-text">
                <b>Date</b>
            </td>
            <td className="center-text">
                <b>Portal</b>
            </td>
            <td className="center-text">
                <b>Code</b>
            </td>
            <td className="center-text">
                <b>Amount</b>
            </td>
            <td className="center-text">
                <b>Hash</b>
            </td>
            <td className="center-text">
                <b>Status</b>
            </td>
        </>
    );

    return (
        <div className="userentry-table-wrapper">
            <div className="table-top">
                <div className="portal-desktop">
                    <span>Portal Keys</span>
                </div>
                <SwitchButtonWrapper>
                    <div>
                        <SwitchButtonAll
                            isBackgroundColorActive={
                                portalKeyFilter === EntryFilterEnum.all
                            }
                            onClick={() => setPortalKeyFilter(EntryFilterEnum.all)}
                        >
                            <Button>All</Button>
                        </SwitchButtonAll>
                        <SwitchButtonPending
                            isBackgroundColorActive={
                                portalKeyFilter === EntryFilterEnum.pending
                            }
                            onClick={() => setPortalKeyFilter(EntryFilterEnum.pending)}
                        >
                            <Button>Pending</Button>
                        </SwitchButtonPending>
                        <SwitchButtonActivated
                            isBackgroundColorActive={
                                portalKeyFilter === EntryFilterEnum.activated
                            }
                            onClick={() => setPortalKeyFilter(EntryFilterEnum.activated)}
                        >
                            <Button>Activated</Button>
                        </SwitchButtonActivated>
                        <SwitchButtonCorrupted
                            isBackgroundColorActive={
                                portalKeyFilter === EntryFilterEnum.corrupted
                            }
                            onClick={() => setPortalKeyFilter(EntryFilterEnum.corrupted)}
                        >
                            <Button>Corrupted</Button>
                        </SwitchButtonCorrupted>
                    </div>
                </SwitchButtonWrapper>
            </div>
            <table>
                <thead className="entries-table-head">
                    <tr>{tableHeader}</tr>
                </thead>
                <tbody>
                    {userPortalKeys.length < 1 && (
                        <>
                            <br />
                            <p>......</p>
                        </>
                    )}
                    {sortEntriesByDate(filteredUserPortalKeys).map(
                        (userPortalKey: LotteryEntryInterface) => {
                            const {
                                id,
                                entryTime,
                                transactionHash,
                                portalName,
                                entryPrice,
                                status,
                            } = userPortalKey;

                            return (
                                <Entry
                                    key={id}
                                    entryTime={entryTime}
                                    portalName={portalName}
                                    entryId={id}
                                    amount={entryPrice}
                                    transactionHash={transactionHash}
                                    status={status}
                                />
                            );
                        }
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default UserEntryTable