import React, { useCallback } from 'react';
import { TEXT_CYAN, SCROLLBAR_THUMB } from '../../resources/constants/colors';
import { Col, Row, setConfiguration } from 'react-grid-system';
import { GalleryBreakPoints } from '../../resources/enums/breakpoints';
import GalleryNFTCard from './GalleryNFTCard';
import NFTImageInterface from '../../resources/interfaces/NFTImageInterface';
import {
	ImageCard,
	GalleryCardTag,
	GalleryCardTagRight,
} from '../../resources/styles/DiscoverPage/Gallery.styles';
import { getIsBabyStaked } from '../../helpers/gallery';

interface PropsInterface {
	collectionData: NFTImageInterface[];
	loader: React.ReactElement | null | undefined;
	noData: React.ReactElement | null | undefined;
	isLastAsset: boolean;
}

const GalleryContent = (props: PropsInterface): JSX.Element => {
	const { collectionData, loader, noData, isLastAsset } = props;
	setConfiguration({
		breakpoints: [
			GalleryBreakPoints.xs,
			GalleryBreakPoints.sm,
			GalleryBreakPoints.md,
			GalleryBreakPoints.lg,
			GalleryBreakPoints.xl,
		],
	});

	const generateMedia = (nft: NFTImageInterface): JSX.Element => {
		const { owner, virgin, thumb, buyNow } = nft;
		const isVirgin = virgin === true;
		const isBabyStaked = getIsBabyStaked(owner);
		const generateGalleryCardTagText = () => {
			if (isVirgin) return 'Virgin';
			if (isBabyStaked) return 'Staked';
		};
		return (
			<>
				{(isVirgin || isBabyStaked) && (
					<GalleryCardTag>{generateGalleryCardTagText()}</GalleryCardTag>
				)}
				{buyNow && <GalleryCardTagRight>Buy Now</GalleryCardTagRight>}
				<ImageCard src={thumb} />
			</>
		);
	};

	const callbackGenerateMedia = useCallback(generateMedia, []);

	return (
		<Row>
			{collectionData.map((nft) => {
				const isRare = nft.rank < 100;
				const cardColor = isRare ? SCROLLBAR_THUMB : TEXT_CYAN;
				const shadowColor = isRare ? '90,90,90' : '10,10,10';
				const dataTip = isRare ? 'This is in the top 1%' : '';
				return (
					<Col
						xs={12}
						sm={6}
						md={4}
						lg={3}
						xl={2}
						xxl={1}
						id="col"
						key={nft.token_id}
					>
						<GalleryNFTCard
							nft={nft}
							cardColor={cardColor}
							shadowColor={shadowColor}
							dataTip={dataTip}
							callbackGenerateMedia={callbackGenerateMedia}
						/>
					</Col>
				);
			})}
			{!isLastAsset && loader}
			{noData}
		</Row>
	);
};

export default React.memo(GalleryContent);
