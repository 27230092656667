import { getVirginSkvllpvnkzIds } from '../firebase/functions/breeding';
import { getWalletRewardsReport } from '../firebase/functions/rewards';
import RewardCollection from '../resources/enums/RewardCollection';
import NFTAssetInterface from '../resources/interfaces/NFTAssetInterface';
import { ExternalRoutes } from '../resources/enums/SiteRoutes';

export const fetchSkvllpvnkzDetails = async (): Promise<
	NFTAssetInterface[]
> => {
	const skvllpvnkzList: NFTAssetInterface[] = [];

	try {
		const allAssets: NFTAssetInterface[] = await getWalletRewardsReport(
			RewardCollection.hideout_rewards
		);

		const virginAssetIDs: number[] = await getVirginSkvllpvnkzIds(
			RewardCollection.hideout_rewards
		);

		allAssets.forEach((asset) =>
			skvllpvnkzList.push({
				...asset,
				virgin: virginAssetIDs.includes(asset.id),
				link: `${ExternalRoutes.skvllpvnkzOS}/${asset.id}`,
			})
		);
	} catch (error) {
		console.error(error);
	}
	return skvllpvnkzList;
};

export const sortSkvllpvnkzByID = (
	skvllpvnkzList: NFTAssetInterface[]
): NFTAssetInterface[] => {
	return skvllpvnkzList.sort((a, b) => a.id - b.id);
};
