import React from 'react';
import { Spinner } from 'reactstrap';
import { SpinnerContainer } from '../resources/styles/Loader.styles';

interface Props {
	color?: string;
	marginTop?: string;
	size?: string;
	children?: string;
	marginRight?: string;
}
const Loader = (props: Props): JSX.Element => {
	const { color, marginTop, size, children, marginRight } = props;
	return (
		<SpinnerContainer>
			<Spinner
				color={color}
				style={{ marginTop, width: size, height: size, marginRight }}
			>
				{children}
			</Spinner>
		</SpinnerContainer>
	);
};

Loader.defaultProps = {
	color: 'success',
	marginTop: '0px',
	size: '40px',
	children: '',
};

export default Loader;
