import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { sortSkvllpvnkzByID } from '../../../../helpers/skvllpvnk';
import { setMintingState } from '../../../../redux/actions/skvllbabiez';
import { TransactionState } from '../../../../resources/enums/states';
import config from '../../../../config';

import Loader from '../../../../components/Loader';

import {
	TERTIARY_MAIN,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
} from '../../../../resources/constants/colors';
import { ButtonWrapper } from '../../../../resources/styles/ButtonWrappers.style';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import {
	ChoosePvnkzDiv,
	DetailText,
	SkvllpvnkzHorizontalSlider,
	MintDetails,
	ShowButtonDiv,
} from '../../../../resources/styles/DaycarePage/MintSkvllbabiezTab/MintSkvllbabiezTab.styles';
import { HORIZONTAL_SCROLL_CHANGE } from '../../../../resources/constants/dimensions';
import HorizontalSliderContent from './HorizontalSliderContent';
import NFTAssetInterface from '../../../../resources/interfaces/NFTAssetInterface';

interface Props {
	handleHeader: (header: string) => void;
}

const ChooseSkvllpvnkzToMint = (props: Props): JSX.Element => {
	const { handleHeader } = props;
	const scrollRef = useRef() as React.RefObject<HTMLDivElement>;
	const dispatch = useDispatch();

	const { ownedSkvllpvnkzList } = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz
	);

	const { skvllbabiez, mintableBabiezCount } = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez
	);

	const [isSkvllpvnkzLoaded, setIsSkvllpvnkzLoaded] = useState(false);
	const [allSkvllpvnkz, setAllSkvllpvnkz] = useState<NFTAssetInterface[]>([]);
	const [selectedPvnkIDs, setSelectedPvnkIDs] = useState<number[]>([]);
	const [showUsedPvnkz, setShowUsedPvnkz] = useState(ownedSkvllpvnkzList.length ? (mintableBabiezCount ? false : true) : false);
	const [isMinting, setIsMinting] = useState(false);

	const handleScroll = (direction: 'left' | 'right') => {
		if (scrollRef.current) {
			if (direction === 'left') {
				scrollRef.current.scrollLeft -= HORIZONTAL_SCROLL_CHANGE;
			} else {
				scrollRef.current.scrollLeft += HORIZONTAL_SCROLL_CHANGE;
			}
		}
	};

	const handleIncludeCheck = (skvllpvnkID: number): boolean =>
		selectedPvnkIDs.some((pvnk) => pvnk === skvllpvnkID);

	const handleSelectPvnkz = (disabled: boolean, clickedPvnkID: number) => {
		if (!disabled) {
			const skvllpvnkAlreadySelected = handleIncludeCheck(clickedPvnkID);
			if (skvllpvnkAlreadySelected) {
				setSelectedPvnkIDs(
					selectedPvnkIDs.filter((skvllpvnk) => skvllpvnk !== clickedPvnkID)
				);
			} else {
				setSelectedPvnkIDs([...selectedPvnkIDs, clickedPvnkID]);
			}
		}
	};

	const handleMint = async () => {
		handleHeader('Minting your Skvllbabiez...');
		setIsMinting(true);
		dispatch(setMintingState(TransactionState.IN_PROGRESS));
		await skvllbabiez?.mint(selectedPvnkIDs);
	};

	useEffect(() => {
		if (
			typeof mintableBabiezCount === 'number' &&
			ownedSkvllpvnkzList.length > 0
		) {
			handleHeader('Mint Skvllbabiez');
			setAllSkvllpvnkz(sortSkvllpvnkzByID(ownedSkvllpvnkzList));
			setIsSkvllpvnkzLoaded(true);
		} else {
			handleHeader('Loading your skvllpvnkz...');
		}
	}, [mintableBabiezCount, ownedSkvllpvnkzList]);

	const skvllpvnkzAmount = showUsedPvnkz
		? allSkvllpvnkz.length
		: (mintableBabiezCount || 0) * config.TOKENS_PER_MINT;

	const numberOfSelectedPvnkz = selectedPvnkIDs.length;

	const isMintButtonDisabled = !!(
		numberOfSelectedPvnkz % config.TOKENS_PER_MINT ||
		numberOfSelectedPvnkz === 0 ||
		isMinting
	);

	const warningMintMessage =
		numberOfSelectedPvnkz % config.TOKENS_PER_MINT ? (
			<p className="red">(Remove or select one more to mint)</p>
		) : (
			<></>
		);

	return (
		<>
			{isSkvllpvnkzLoaded ? (
				<ChoosePvnkzDiv>
					<MintDetails>
						<b>Select skvllpvnkz to mint</b>
						<p style={{ color: TEXT_GRAYED_OUT }}>{skvllpvnkzAmount} results</p>
					</MintDetails>
					<SkvllpvnkzHorizontalSlider ref={scrollRef}>
						<HorizontalSliderContent
							allSkvllpvnkz={allSkvllpvnkz}
							handleIncludeCheck={handleIncludeCheck}
							handleSelectPvnkz={handleSelectPvnkz}
							showUsedPvnkz={showUsedPvnkz}
						/>
					</SkvllpvnkzHorizontalSlider>
					<MintDetails marginTop="15px" lineHeight="10px">
						<div>
							<DetailText>Hide used pvnkz</DetailText>
							<ShowButtonDiv>
								<DetailText>Show</DetailText>
								<label>
									<input
										type="checkbox"
										checked={showUsedPvnkz}
										onClick={() => setShowUsedPvnkz(!showUsedPvnkz)}
									/>
									<span></span>
								</label>
							</ShowButtonDiv>
						</div>
						<ButtonWrapper marginBottom="15px">
							<CustomButton
								width="34px"
								height="34px"
								borderRadius="5px"
								color={TEXT_WHITE}
								backgroundColor={TERTIARY_MAIN}
								marginLeft="5px"
								marginRight="5px"
								onClick={() => handleScroll('left')}
							>
								{'<'}
							</CustomButton>
							<CustomButton
								width="34px"
								height="34px"
								borderRadius="5px"
								color={TEXT_WHITE}
								backgroundColor={TERTIARY_MAIN}
								marginLeft="5px"
								marginRight="5px"
								onClick={() => handleScroll('right')}
							>
								{'>'}
							</CustomButton>
						</ButtonWrapper>
					</MintDetails>
					<hr />
					<MintDetails marginTop="15px" marginBottom="10px">
						<b>Minting Result:</b>
					</MintDetails>
					<MintDetails>
						<DetailText>Selected:</DetailText>
						<b>
							<DetailText display="flex" gap="10px">
								{warningMintMessage}
								{numberOfSelectedPvnkz} Skvllpvnkz
							</DetailText>
						</b>
					</MintDetails>
					<hr />
					<MintDetails marginTop="15px">
						<DetailText>Minting:</DetailText>
						<b>
							<DetailText display="flex" gap="3px">
								<p>
									{parseInt(
										(numberOfSelectedPvnkz / config.TOKENS_PER_MINT).toString()
									)}
								</p>
								Skvllbabiez
							</DetailText>
						</b>
					</MintDetails>
					<hr />
					<MintDetails marginTop="25px" justifyContent="flex-end">
						<CustomButton
							width="94px"
							height="34px"
							borderRadius="5px"
							padding="0px"
							marginTop="-10px"
							marginTopResponsive="-20px"
							disabled={isMintButtonDisabled}
							onClick={handleMint}
						>
							<b>Mint</b>
						</CustomButton>
					</MintDetails>
				</ChoosePvnkzDiv>
			) : (
				<Loader color="text-secondary" />
			)}
		</>
	);
};

export default ChooseSkvllpvnkzToMint;
