import styled from 'styled-components';
import {
	LINE_COLOR,
	FILTER_MAIN,
	TEXT_DARK,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
	TEXT_GREEN,
	TEXT_PURPLE,
} from '../../constants/colors';
import ArrowDownImage from '../../images/icons/arrow-down.png';
import SearchIcon from '../../images/icons/search-icon.png';

interface Props {
	fontSize?: string;
	color?: string;
	lineHeight?: string;
	textAlign?: string;
	paddingBottom?: string;
	paddingTop?: string;
	shadowColor?: string;
}
export const StoreContainer = styled.div`
	text-align: center;
	grid-area: gallery;
	padding-right: 40px;
	width: 100%;

	#col {
		margin-bottom: 25px;
		@media screen and (max-width: 1980px) {
			min-width: 13vw;
		}
	}

	#p-results {
		text-align: left;
		font-size: 16px;
		color: ${TEXT_GRAYED_OUT};
	}

	span {
		font-size: 16px !important;
		color: ${TEXT_WHITE} !important;
	}

	#gallery-header {
		width: calc(100% + 50px);
		position: -webkit-sticky;
		position: sticky;
		top: 100px;
		padding-top: 35px;
		padding-right: 35px;
		padding-left: 35px;
		margin-left: -25px;
		z-index: 2;
		background-color: transparent;
		height: 138px;
		margin-bottom: 5px;
	}

	#filter-wrapper {
		display: flex;
		justify-content: space-between;
	}

	#loader {
		position: absolute;
		left: 0px;
		background-color: black;
		min-width: 100%;
	}
`;

export const FilterStoreSelect = styled.select`
	position: absolute;
	right: 0;
	margin-right: 35px;
	background-color: transparent;
	border-color: ${LINE_COLOR};
	color: white;
	width: 207px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	padding-left: 10px;
	margin-left: 28px;
	outline: none;
	appearance: none;
	background: url(${ArrowDownImage}) 94% / 6% no-repeat ${FILTER_MAIN};
	opacity: 0.7;

	@media screen and (max-width: 768px) {
		width: 150px;
	}

	@media screen and (max-width: 414px) {
		width: 100px;
	}
`;

export const FilterSearch = styled.input`
	background-color: ${FILTER_MAIN};
	color: white;
	width: 290px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px ${LINE_COLOR} solid;
	outline: none;
	margin-bottom: 20px;
	margin-left: -10px;
	padding-left: 30px;
	background: url(${SearchIcon}) 3% / 5% no-repeat ${FILTER_MAIN};

	::placeholder {
		color: ${TEXT_GRAYED_OUT};
	}
`;

export const StoreItem = styled.div<Props>`
	cursor: pointer;
	display: block;
	width: auto;
	height: auto;
	grid-template-rows: 70% 30%;
	padding-bottom: 0.5rem;
	background-color: #131b21;
	position: relative;
	margin-bottom: 20px;

	border-radius: 10px;
	:hover {
		top: -2px;
		// box-shadow: 2px 10px 10px 2px rgba(${(props) => props.shadowColor}, 1);
	}

	// box-shadow: 2px 8px 10px 2px rgba(${(props) => props.shadowColor}, 1);
`;

export const NoItemPurchased = styled.div`
	margin-top: 20px;
`;

export const ImageCard = styled.img`
	display: block;
	width: 100%;
	height: auto;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const ImageWrapper = styled.div``;

export const VideoWrapper = styled.div``;

export const Video = styled.source``;

export const CardTag = styled.div`
	background-color: ${TEXT_WHITE};
	color: ${TEXT_DARK};
	position: absolute;

	height: 26px;
	padding: 3px;
	padding-left: 7px;
	padding-right: 7px;
	left: 10px;
	top: 10px;
	font-size: 0.9rem;
	font-weight: 600;
	z-index: 1;
	border-radius: 5px;
`;

export const VideoCard = styled.video`
	display: block;
	width: 100%;
	height: auto;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const StoreItemDetails = styled.div`
	display: grid;
	padding-right: 15px;
	padding-left: 15px;
	padding-bottom: 5px;
	padding-top: 15px;
`;

export const StoreItemTitle = styled.div`
	font-size: 1rem;
	text-align: left;
	font-weight: 600;
	min-height: 2rem;
	max-height: 2rem;
`;

export const StoreItemSubtitle = styled.div`
	font-size: 0.8rem;
	color: ${TEXT_GREEN};
	text-align: left;
	min-height: 1rem;
	max-height: 1rem;
	margin-top: -10px;
	margin-bottom: 10px;
`;

export const StoreItemPrice = styled.div<Props>`
	line-height: 1.15rem;
	margin-top: 5px;
	display: grid;
	grid-template-columns: 50% 50%;
	padding-top: 0.6rem;
	#green-text {
		color: ${TEXT_GREEN};
	}
	#purple-text {
		color: ${TEXT_PURPLE};
	}
`;

export const Ammolite = styled.img<Props>`
	margin-top: -2px;
	padding-right: 5px;
`;

export const ItemSupply = styled.div<Props>`
	color: ${(props) => props.color};
	font-size: 0.9rem;
	text-align: left;
`;

export const ItemPrice = styled.div<Props>`
	font-size: 0.9rem;
	text-align: right;
`;

export const GalleryCardSkeleton = styled.div`
	background-color: #131b21;
	width: 193px;
	height: 300px;
	border-radius: 10px;
	animation: skeleton-loading 1s linear infinite alternate;

	@keyframes skeleton-loading {
		0% {
			background-color: rgba(19, 27, 33, 0.5);
		}

		100% {
			background-color: rgba(19, 27, 33, 0.95);
		}
	}
`;
