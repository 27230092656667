import { processSelectedTraits } from '../../helpers/gallery';
import { parseUrlPath } from '../../helpers/url';
import { defaultFilterCollapseStates } from '../../resources/constants/filters';
import { Collections } from '../../resources/enums/Collections';
import { FilterTypes } from '../../resources/enums/FilterTypes';
import { OrderByKeys } from '../../resources/enums/OrderByKeys';
import { SiteRoutes } from '../../resources/enums/SiteRoutes';
import FilterReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/FilterReducerStateInterface';
import TraitFiltersCollapseInterface from '../../resources/interfaces/TraitFiltersCollapseInterface';
import TraitInterface from '../../resources/interfaces/TraitInterface';
import TraitObjectInterface from '../../resources/interfaces/TraitObjectInterface';
import {
	ADD_SELECTED_TRAIT,
	SET_BUY_NOW_FILTER,
	SET_FILTER_BY_WALLET,
	SET_FILTER_COLLAPSE_STATES,
	SET_ORDER_BY_FILTER,
	SET_SELECTED_COLLECTION,
	SET_SELECTED_TRAITS,
	SET_SPECIFIC_TRAIT_COLLAPSE_STATE,
	SET_TRAITS_ARRAY,
	SET_UP_TRAIT_COLLAPSE_STATES,
	SET_VIRGIN_FILTER,
} from '../actions/types/filters';

const collectionIsValid = (collectionUrl: Collections) =>
	Object.values(Collections).some((collection) => collection === collectionUrl);

const { urlSplit, page, hasTokenInUrl } = parseUrlPath();

const getCollectionFromPath = () => {
	try {
		const seqNumber = page === SiteRoutes.profile ? 5 : 6;
		const collection = urlSplit[seqNumber].replace('-', '_') as Collections;
		if (!collectionIsValid(collection)) throw new Error('Invalid collection');
		return collection;
	} catch (e) {
		console.error(e);
		return Collections.skvllpvnkz_hideout;
	}
};

const initialState: FilterReducerStateInterface = {
	selectedCollection: getCollectionFromPath() || Collections.skvllpvnkz_hideout,
	selectedTraits: [],
	virginFilter: undefined,
	orderByFilter: hasTokenInUrl ? OrderByKeys.TOKEN : OrderByKeys.RANK,
	filterByWallet: '',
	filterCollapseStates: defaultFilterCollapseStates,
	traitFilterCollapseStates: [],
	traitsArray: [],
	buyNowFilter: undefined,
};

interface PayloadInterface {
	selectedCollection: Collections;
	selectedTraits: TraitObjectInterface[];
	selectedTrait: {
		traitType: string;
		value: string;
	};
	virginFilter: boolean | undefined;
	buyNowFilter: boolean | undefined;
	orderBy: OrderByKeys;
	walletAddress: string;
	collapseFilterType: FilterTypes;
	isOpen: boolean | undefined;
	traitFilterCollapseState: TraitFiltersCollapseInterface[];
	trait: TraitInterface;
	traitsArray: TraitInterface[];
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const filterReducer = (
	state = initialState,
	action: ActionInterface
): FilterReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SET_SELECTED_COLLECTION:
			return {
				...state,
				selectedCollection: payload.selectedCollection,
			};
		case SET_SELECTED_TRAITS:
			return {
				...state,
				selectedTraits: payload.selectedTraits,
			};
		case ADD_SELECTED_TRAIT: {
			const newSelectedTraits = processSelectedTraits(
				state.selectedTraits,
				payload.selectedTrait
			);
			return {
				...state,
				selectedTraits: newSelectedTraits,
			};
		}
		case SET_VIRGIN_FILTER:
			return {
				...state,
				virginFilter: payload.virginFilter,
			};
		case SET_BUY_NOW_FILTER:
			return {
				...state,
				buyNowFilter: payload.buyNowFilter,
			};

		case SET_ORDER_BY_FILTER:
			return {
				...state,
				orderByFilter: payload.orderBy,
			};
		case SET_FILTER_BY_WALLET: {
			return {
				...state,
				filterByWallet: payload.walletAddress,
			};
		}
		case SET_FILTER_COLLAPSE_STATES:
			return {
				...state,
				filterCollapseStates: state.filterCollapseStates.map((filter) => {
					if (filter.filter_type === payload.collapseFilterType) {
						return {
							filter_type: filter.filter_type,
							isOpen: payload.isOpen || !filter.isOpen,
						};
					}
					return filter;
				}),
			};
		case SET_UP_TRAIT_COLLAPSE_STATES:
			return {
				...state,
				traitFilterCollapseStates: payload.traitFilterCollapseState,
			};
		case SET_SPECIFIC_TRAIT_COLLAPSE_STATE:
			return {
				...state,
				traitFilterCollapseStates: state.traitFilterCollapseStates.map(
					(trait) => {
						if (trait.trait_type === payload.trait.name) {
							return {
								trait_type: trait.trait_type,
								isOpen: !trait.isOpen,
							};
						}
						return trait;
					}
				),
			};
		case SET_TRAITS_ARRAY:
			return {
				...state,
				traitsArray: payload.traitsArray,
			};
		default:
			return state;
	}
};

export default filterReducer;
