import { FilterTypes } from '../enums/FilterTypes';
import MainFiltersCollapseInterface from '../interfaces/MainFiltersCollapseInterface';
import TraitFiltersCollapseInterface from '../interfaces/TraitFiltersCollapseInterface';
import TraitInterface from '../interfaces/TraitInterface';
import {
	BUTTON_PRIMARY_PURPLE,
	PRIMARY_DARK_GRAY,
	TEXT_GRAYED_OUT,
} from './colors';

export const defaultFilterCollapseStates: MainFiltersCollapseInterface[] = [
	{ filter_type: FilterTypes.STATUS, isOpen: false },
	{ filter_type: FilterTypes.ORDER, isOpen: false },
	{ filter_type: FilterTypes.SEARCH, isOpen: false },
	{ filter_type: FilterTypes.TRAIT, isOpen: true },
	{ filter_type: FilterTypes.COLLECTION, isOpen: false },
];
export const defaultTraitFiltersCollapse: TraitFiltersCollapseInterface[] = [
	{ trait_type: '', isOpen: false },
];

export const defaultTraits: TraitInterface[] = [
	{ name: '', count: 0, values: [] },
];

const borderRadius = '5px';
// eslint-disable-next-line
export const customStyles = {
	// eslint-disable-next-line
	control: () => ({
		display: 'flex',
		width: '100%',
		backgroundColor: 'transparent',
		paddingRight: '10px',
	}),
	// eslint-disable-next-line
	multiValue: (provided: any) => {
		const backgroundColor = BUTTON_PRIMARY_PURPLE;
		return { ...provided, backgroundColor, borderRadius };
	},
	// eslint-disable-next-line
	multiValueLabel: (provided: any) => {
		const backgroundColor = BUTTON_PRIMARY_PURPLE;
		const paddingRight = '5px';
		return { ...provided, backgroundColor, paddingRight, borderRadius };
	},
	// eslint-disable-next-line
	multiValueRemove: (provided: any) => {
		const backgroundColor = TEXT_GRAYED_OUT;
		const backgroundColorHover = PRIMARY_DARK_GRAY;
		const backgroundColorActive = PRIMARY_DARK_GRAY;
		return {
			...provided,
			backgroundColorHover,
			backgroundColor,
			borderRadius,
			backgroundColorActive,
		};
	},
};
