import React, { useCallback, useEffect, useState } from 'react';

interface PropsInterface {
	rewards: string;
	incrementRate: number;
}

const TableRewards = (props: PropsInterface) => {
	const { rewards, incrementRate } = props;
	const [ammoReward, setAmmoReward] = useState(parseFloat(rewards));

	const handleRewardUpdate = useCallback(() => {
		setAmmoReward((prevAmmoReward) => prevAmmoReward + incrementRate);
	}, [rewards, incrementRate]);

	useEffect(() => {
		setAmmoReward(parseFloat(rewards));
		const updater = window.setInterval(handleRewardUpdate, 1000);

		return () => {
			window.clearInterval(updater);
		};
	}, [rewards, incrementRate]);

	return <p>{ammoReward.toFixed(4)}</p>;
};

export default React.memo(TableRewards);
