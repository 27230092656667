import React, { useState, useLayoutEffect, Dispatch } from 'react';
import { MainFilterContainer } from '../../resources/styles/FilterPanel.styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import StatusFilter from './components/StatusFilter';
import TraitFilter from './components/TraitFilter/TraitFilter';
import OrderFilter from './components/OrderFilter';
import SearchFilter from './components/SearchFilter';
import closeIcon from '../../resources/images/icons/close.svg';
import CollectionFilter from './components/CollectionFilter';

interface FilterPanelProps {
	handleFetchCollectionAssets: (
		isScrolling: boolean,
		clearAddress?: boolean
	) => Promise<void>;
	setShowFilter: Dispatch<React.SetStateAction<boolean>>;
	showSearchFilter: boolean;
}

const FilterPanel = (props: FilterPanelProps): JSX.Element => {
	const { handleFetchCollectionAssets, setShowFilter, showSearchFilter } =
		props;

	const filterSectionIcon = (isCollapseOpen: boolean) => {
		return isCollapseOpen ? (
			<IoIosArrowUp id="filter-section-icon" />
		) : (
			<IoIosArrowDown id="filter-section-icon" />
		);
	};

	const [size, setSize] = useState(window.innerHeight);
	useLayoutEffect(() => {
		const updateSize = () => setSize(window.innerHeight);
		window.addEventListener('resize', updateSize);
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	return (
		<MainFilterContainer height={`${size - 70}px`}>
			<div className="close-icon-container">
				<div className="close-icon" onClick={() => setShowFilter(false)}>
					<img src={closeIcon} />{' '}
				</div>
			</div>
			{!showSearchFilter && (
				<>
					<CollectionFilter filterSectionIcon={filterSectionIcon} />
					<hr />
				</>
			)}

			<StatusFilter filterSectionIcon={filterSectionIcon} />
			<hr />

			<OrderFilter filterSectionIcon={filterSectionIcon} />
			<hr />
			{showSearchFilter && (
				<SearchFilter
					handleFetchCollectionAssets={handleFetchCollectionAssets}
					filterSectionIcon={filterSectionIcon}
				/>
			)}
			<hr />
			<TraitFilter filterSectionIcon={filterSectionIcon} />
		</MainFilterContainer>
	);
};

export default FilterPanel;
