import React from 'react';
import NFTImageInterface from '../../../../resources/interfaces/NFTImageInterface';
import {
	DetailFlexDiv,
	TraitDiv,
	TraitsContainer,
} from '../../../../resources/styles/NFTModalView.styles';
import TraitGender from '../../../../resources/images/icons/trait-gender.png';
import TraitType from '../../../../resources/images/icons/trait-type.png';
import TraitOuter from '../../../../resources/images/icons/trait-outer_clothing.png';
import TraitInner from '../../../../resources/images/icons/trait-inner_clothing.png';
import TraitHat from '../../../../resources/images/icons/trait-hat.png';
import TraitHead from '../../../../resources/images/icons/trait-top_of_head.png';
import TraitTattoo from '../../../../resources/images/icons/trait-tattoo.png';
import TraitMask from '../../../../resources/images/icons/trait-mask.png';
import TraitBack from '../../../../resources/images/icons/trait-back.png';
import TraitHair from '../../../../resources/images/icons/trait-hair.png';
import { batch, useDispatch } from 'react-redux';
import { addSelectedTrait } from '../../../../redux/actions/filter';
import { setIsModalOpen } from '../../../../redux/actions/gallery';
import ReactTooltip from 'react-tooltip';
interface PropsInterface {
	nft: NFTImageInterface;
}

const Traits = (props: PropsInterface): JSX.Element => {
	const { nft } = props;
	const { metaAttributes, attributes } = nft;
	const traitIcons: { [key: string]: string } = {
		gender: TraitGender,
		type: TraitType,
		hero: TraitGender,
		tattoo: TraitTattoo,
		trait_count: TraitType,
		back: TraitBack,
		hat: TraitHat,
		hair: TraitHair,
		top_of_head: TraitHead,
		inner_clothing: TraitInner,
		outer_clothing: TraitOuter,
		mask: TraitMask,
		accessories: TraitGender,
	};
	const dispatch = useDispatch();

	const replaceAll = (text: string, find: string, replace: string) =>
		text.replace(new RegExp(find, 'g'), replace);

	const MetaAttributes =
		'metaAttributes' in nft
			? metaAttributes
					.map((trait) => trait.points || 0)
					.reduce((a, b) => a + b, 0)
					.toFixed(2)
			: 0;

	const handleSelectFilterTrait = (traitType: string, value: string) => {
		if (traitType === 'trait_count' || traitType === 'meta_attributes') return;

		batch(() => {
			dispatch(
				addSelectedTrait({
					traitType: traitType,
					value: value,
				})
			);
			dispatch(setIsModalOpen(false));
		});
	};

	const handleClick = (trait_type: string, value: string) => {
		// navigate(`${}/${token_id.toString()}`);
		handleSelectFilterTrait(trait_type, value);
	};

	return (
		<TraitsContainer>
			{attributes.map((trait) => {
				const { trait_type, value, points, percentage } = trait;
				return (
					<TraitDiv
						key={trait_type}
						onClick={() => handleClick(trait_type, value)}
					>
						<div>
							<DetailFlexDiv gap="6px" marginBottom="6px" justify="center">
								<img src={traitIcons[trait_type]} alt="gender" height={11} />{' '}
								<p className="type">
									{replaceAll(trait_type.toString(), '_', ' ')}
								</p>
							</DetailFlexDiv>
							<DetailFlexDiv marginBottom="2px" justify="center">
								<p className="value">
									{replaceAll(value.toString(), '_', ' ')}
								</p>
							</DetailFlexDiv>
							<b>
								<p className="rarity">{percentage && `${percentage}%`}</p>
								<p className="points">{points && `(+${points?.toFixed(2)})`}</p>
							</b>
						</div>
					</TraitDiv>
				);
			})}
			{MetaAttributes > 0 && (
				<TraitDiv>
					<div>
						<DetailFlexDiv
							gap="6px"
							marginBottom="6px"
							justify="center"
							data-tip="Derived attributes that provide extra points, i.e. 'no mask'"
							data-delay-show="1000"
						>
							<ReactTooltip />
							<img src={TraitGender} alt="gender" height={11} />{' '}
							<p className="type">Meta Attributes</p>
						</DetailFlexDiv>
						<DetailFlexDiv marginBottom="2px" justify="center">
							<p className="value">Derived Traits</p>
						</DetailFlexDiv>
						<b>
							<p className="rarity">-</p>
							<p className="points">(+{MetaAttributes})</p>
						</b>
					</div>
				</TraitDiv>
			)}
		</TraitsContainer>
	);
};

export default Traits;
