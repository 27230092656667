export const BUTTON_PRIMARY_MAIN = '#1CA595';
export const BUTTON_PRIMARY_LIGHT = '#7aecb5';
export const BUTTON_PRIMARY_WHITE = '#ffffff';
export const BUTTON_PRIMARY_DARK = '#509c78';
export const BUTTON_PRIMARY_GREEN = '#00C3AC';
export const BUTTON_PRIMARY_PURPLE = '#7663C3';
export const BUTTON_PRIMARY_PURPLE_LIGHT = '#9C8ED3';
export const BUTTON_PRIMARY_PURPLE_DARK = '#7468A5';
export const BUTTON_SECONDARY = '#987DFF';
export const BUTTON_EXPIRED = '#3A4955';

export const PRIMARY_MAIN = '#0F161C';
export const SECONDARY_MAIN = '#0B0F13';
export const TERTIARY_MAIN = '#131B21';
export const NEW_SECONDARY_MAIN = '#6c70ea';
export const PRIMARY_BLACK = '#0B0E11';

export const TEXT_WHITE = '#ffffff';
export const TEXT_DARK = '#000000';
export const TEXT_GRAYED_OUT = '#526579';
export const TEXT_GRAY = '#999999';
export const TEXT_CYAN = '#42BEB2';
export const TEXT_PURPLE = '#7663C3';
export const BLUE = '#64ADF4';
export const TEXT_SECONDARY_GREEN = '#C0FFD2';
export const TEXT_GREEN = '#00C3AC';
export const ORANGE = '#FBCEB1';
export const TEXT_SECONDARY = '#987DFF';
export const TEXT_SECONDARY_PURPLE = '#7663C2';

export const PRIMARY_DARK_GRAY = '#111212';
export const SECONDARY_DARK_GRAY = '#272a2a';

export const FOOTER_COLOR = '#262741';

export const SCROLLBAR_THUMB = '#6C70EA';
export const SCROLLBAR_TRACK = '#15151E';
export const HIGHLIGHT_COLOR = '#172128';
export const LINE_COLOR = '#202930';

export const SWITCH_BUTTON_ON = '#6AC9B5';
export const SWITCH_BUTTON_OFF = '#131B21';

export const FILTER_MAIN = '#0a0c10';

export const SCROLLBAR_ARROW_BACKGROUND = '#343557';
export const MODAL_MAIN = '#0e1419';

export const TWITTER_ICON = '#00D1FF';
export const DISCORD_ICON = '#5865F2';
