import React from 'react';
import { StakeProgressContainer } from '../../../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import {
	SubtitleDiv,
	TitleDiv,
} from '../../../resources/styles/WalletNotConnected.styles';

interface PropsInterface {
	title: string;
}

const RewardsClosed = (props: PropsInterface): JSX.Element => {
	const { title } = props;
	return (
		<StakeProgressContainer>
			<div>
				<TitleDiv>{title} is closed 🔒</TitleDiv>
				<SubtitleDiv>
					Please check Discord or Twitter for more information.{' '}
				</SubtitleDiv>
			</div>
		</StakeProgressContainer>
	);
};

export default RewardsClosed;
