import { ethers } from 'ethers';
import { ConnectorNames } from '../resources/enums/ConnectorNames';

import SessionKeys from '../resources/enums/SessionKeys';

export const registerSelectedWalletOption = (wallet: ConnectorNames): void => {
	window.localStorage.setItem(SessionKeys.SELECTED_WALLET_OPTION, wallet);
};

export const fetchSelectedWalletOption = (): string => {
	return window.localStorage.getItem(SessionKeys.SELECTED_WALLET_OPTION) || '';
};

export const conditionalReloadByEvent = (event: ethers.Event): void => {
	const { blockNumber } = event;
	if (Number(window.sessionStorage.getItem('reloadKey')) !== blockNumber) {
		window.sessionStorage.setItem('reloadKey', blockNumber.toString());
		window.location.reload();
	}
};

export const getPendingTransaction = (
	id: number,
	sessionkey: SessionKeys
): string => window.localStorage.getItem(`${sessionkey}-${id}`) || '';

export const setPendingTransaction = (
	id: number,
	transactionHash: string | null,
	sessionkey: SessionKeys
): void => {
	const key = `${sessionkey}-${id}`;
	if (!transactionHash) {
		window.localStorage.removeItem(key);
	} else {
		window.localStorage.setItem(key, transactionHash);
	}
};
