import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'reactstrap';
import { setSpecificTraitCollapseState } from '../../../../redux/actions/filter';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import TraitInterface from '../../../../resources/interfaces/TraitInterface';
import TraitValueInterface from '../../../../resources/interfaces/TraitValueInterface';
import {
	FilterCollapse,
	FilterSearch,
	FilterSection,
	TraitCollapseLabel,
} from '../../../../resources/styles/FilterPanel.styles';
import TraitFilterContent from './TraitFilterContent';

interface Props {
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
	trait: TraitInterface;
	traitIndex: number;
}
const TraitFilterSection = (props: Props): JSX.Element => {
	const { filterSectionIcon, trait, traitIndex } = props;
	const dispatch = useDispatch();
	const { traitFilterCollapseStates } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);
	const isCollapseOpen = traitFilterCollapseStates[traitIndex]?.isOpen;
	const traitValues: TraitValueInterface[] = trait.values;

	const [inputSearch, setInputSearch] = useState('');

	return (
		<div>
			<FilterSection
				fontSize="13px"
				onClick={() => dispatch(setSpecificTraitCollapseState(trait))}
				active={isCollapseOpen}
				hasNoLine={true}
				paddingRight="20px"
			>
				<TraitCollapseLabel>
					<label>{trait.name.replaceAll('_', ' ')}</label>
					<label id="count">
						{trait.count}&#160;&#160;&#160;
						{filterSectionIcon(isCollapseOpen)}
					</label>
				</TraitCollapseLabel>
			</FilterSection>
			<FilterCollapse hasNoLine={true}>
				<Collapse className="filter-collapse" isOpen={isCollapseOpen}>
					{traitValues.length > 4 && (
						<FilterSearch
							placeholder="Filter"
							width="90%"
							value={inputSearch}
							onChange={(e) => setInputSearch(e.target.value)}
						/>
					)}
				</Collapse>
			</FilterCollapse>
			<div id="traits-content">
				<FilterCollapse hasNoLine={true}>
					<Collapse className="filter-collapse" isOpen={isCollapseOpen}>
						<TraitFilterContent
							traitValues={traitValues}
							traitType={trait.name}
							inputSearch={inputSearch}
						/>
					</Collapse>
				</FilterCollapse>
			</div>
		</div>
	);
};

export default TraitFilterSection;
