import React from 'react';
import { RafflePageTabIds } from '../../../../resources/enums/RafflePageTabIds';
import {
	GoBackButton,
	TitleDiv,
} from '../../../../resources/styles/RafflePage/RafflePage.styles';
import goBackIcon from '../../../../resources/images/icons/go-back-arrow.png';
import {
	PortalKeyImage,
	RaffleEntryContainer,
} from '../../../../resources/styles/RafflePage/PurchaseEntry.styles';
import {
	TableHeader,
	TicketContainer,
} from '../../../../resources/styles/RafflePage/UserEntries.styles';
import raffleEntryIcon from '../../../../resources/images/raffle-entry.gif';
import { RafflePageTableContainer } from '../../../../resources/styles/RafflePage/PreviousWinners.styles';
import { LotteryEntryInterface } from '../../../../resources/interfaces/LotteryInterface';
import { EntryFilterEnum } from '../../../../resources/enums/Entries';
import UserEntryTable from './UserEntryTable';

interface PropsInterface {
	changeRaffleTab: (tabId: RafflePageTabIds) => void;
	setPortalKeyFilter: (portalKeyFilter: EntryFilterEnum) => void;
	portalKeyFilter: EntryFilterEnum;
	isLotteryOpen: boolean;
	userPortalKeys: LotteryEntryInterface[];
}

const UserEntries = (props: PropsInterface): JSX.Element => {
	const {
		changeRaffleTab,
		userPortalKeys,
		setPortalKeyFilter,
		portalKeyFilter,
	} = props;

	const keyStatusCounts = {
		pending: userPortalKeys.reduce((previousValue, portalKey) => {
			if (portalKey.status === EntryFilterEnum.pending) {
				return previousValue + 1;
			}
			return previousValue;
		}, 0),
		activated: userPortalKeys.reduce((previousValue, portalKey) => {
			if (portalKey.status === EntryFilterEnum.activated) {
				return previousValue + 1;
			}
			return previousValue;
		}, 0),
		corrupted: userPortalKeys.reduce((previousValue, portalKey) => {
			if (
				portalKey.status !== EntryFilterEnum.activated &&
				portalKey.status !== EntryFilterEnum.pending
			) {
				return previousValue + 1;
			}
			return previousValue;
		}, 0),
	};

	const { activated, corrupted, pending } = keyStatusCounts;

	return (
		<RaffleEntryContainer>
			<TitleDiv fontSize="36px">Your crafted keys</TitleDiv>

			<TicketContainer>
				<PortalKeyImage src={raffleEntryIcon} alt="entry" />

				<div className="portal-key-info">
					<p>You own</p>
					<span>
						<b>{userPortalKeys.length}</b> portal keys
					</span>
					<p id="chance">{activated} Activated</p>
					<p id="chance">{pending} Pending</p>
					<p id="chance">{corrupted} Corrupted</p>
				</div>
			</TicketContainer>
			<RafflePageTableContainer>
				<TableHeader>
					<GoBackButton
						onClick={() => changeRaffleTab(RafflePageTabIds.MAIN_PAGE)}
					>
						<img src={goBackIcon} alt="arrow" />
						Go back
					</GoBackButton>

				</TableHeader>
				<UserEntryTable
					userPortalKeys={userPortalKeys}
					setPortalKeyFilter={setPortalKeyFilter}
					portalKeyFilter={portalKeyFilter}
				/>
			</RafflePageTableContainer>
		</RaffleEntryContainer>
	);
};

export default UserEntries;
