import React from 'react';
import { PageContainer } from '../../../resources/styles/Page.styles';
import DiscoverContent from '../../DiscoverPage/components/DiscoverContent';

const CollectionSection = (): JSX.Element => {
	return (
		<PageContainer noBackgroundImage>
			<DiscoverContent showSearchFilter={false} />
		</PageContainer>
	);
};

export default CollectionSection;
