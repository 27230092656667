import React, { useEffect } from 'react';
import { PageContainer } from '../../resources/styles/Page.styles';
import Store from '../../components/Store/Store';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreItems } from '../../firebase/functions/store';
import { setStoreData } from '../../redux/actions/store';

import PageHeaderContent from '../../components/PageHeaderContent';
import bannerDesktop1 from '../../resources/images/banner/bannerDesktop1.png';

const StorePage = (): JSX.Element => {
	const { store } = useSelector(
		(state: RootReducerStateInterface) => state.store
	);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			const storeData = await getStoreItems();
			dispatch(setStoreData(storeData));
		};

		if (!store) {
			fetchData();
		}
	}, [store]);

	const pageHeader = {
		title: 'Hideout Store',
		subtitle:
			'Purchase whitelists and other digital items exclusively available',
		textAlign: 'center',
		textTransform: 'capitalize',
	};

	const Stats = {
		areStatsLoading: false,
		statsData: {},
	};

	return (
		<PageContainer>
			<div id="image-container">
				<PageHeaderContent
					pageHeader={pageHeader}
					stats={Stats}
					backgroundImage={bannerDesktop1}
				/>
				<hr id="divider-hr" />
				<Store />
			</div>
		</PageContainer>
	);
};

export default StorePage;
