import React from 'react';
import { StoreItemDescriptionContainer } from '../../../../resources/styles/StoreItemModalView.styles';
interface PropsInterface {
	description?: string;
	description2?: string;
	description3?: string;
	link?: string;
}

const StoreItemDescription = (props: PropsInterface): JSX.Element => {
	const { description, description2, description3 } = props;
	return (
		<StoreItemDescriptionContainer>
			{description && (
				<div>
					<p id="label">Description</p>
					{description}
				</div>
			)}
			<br></br>
			<hr />
			<br></br>
			<i>
				{description2 && <div>{description2}</div>}
				<br></br>
				{description3 && <div>{description3}</div>}
			</i>
		</StoreItemDescriptionContainer>
	);
};

export default StoreItemDescription;
