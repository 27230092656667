import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from './firebase';
import { setAuthUser } from './redux/actions/auth';
import RootReducerStateInterface from './resources/interfaces/ReducerInterfaces/RootReducerStateInterface';

interface PropsInterface {
	children: ReactElement;
}

const FirebaseHandler = (props: PropsInterface): JSX.Element => {
	const { children } = props;
	const dispatch = useDispatch();
	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);
	const { discord } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);
	const { store } = useSelector(
		(state: RootReducerStateInterface) => state.store
	);
	const { lottery } = useSelector(
		(state: RootReducerStateInterface) => state.lottery
	);

	// firebase auth state listener
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			dispatch(setAuthUser(user));
		});

		return () => unsubscribe();
	}, []);

	// firestore listeners
	useEffect(() => {
		if (discord && user) {
			discord.initializeDBListeners();

			return () => {
				discord.destroyDBListeners();
			};
		}
	}, [discord, user]);
	useEffect(() => {
		if (store) {
			store.initializeDBListeners();

			return () => {
				store.destroyDBListeners();
			};
		}
	}, [store]);
	useEffect(() => {
		if (lottery) {
			return () => {
				lottery.destroyDBListeners();
			};
		}
	}, [lottery]);

	return <>{children}</>;
};

export default FirebaseHandler;
