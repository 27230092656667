import React from 'react';
import {
	CollectionDetailsContainer,
	CollectionStatsContainer,
	CollectionStatsDetails,
	EthereumIcon,
	StatBox,
	StatValue,
	StatName,
} from '../resources/styles/DiscoverPage/Banner.styles';
import Ethereum from '../resources/images/icons/eth.png';
import Ammo from '../resources/images/icons/AmmoliteIcon.png';
import Loader from './Loader';
import ReactTooltip from 'react-tooltip';
import StatsInterface, { Stats } from '../resources/interfaces/StatsInterface';

const HeaderStats = (props: StatsInterface): JSX.Element => {
	const { areStatsLoading, statsData, stylesGrid } = props;

	return (
		<CollectionDetailsContainer>
			<CollectionStatsContainer>
				<CollectionStatsDetails stylesGrid={stylesGrid}>
					{Object.values(statsData).map((data: Stats, index: number) => {
						const { title, value, ethIcon, ammoIcon, toolTip } = data;
						return (
							<StatBox key={index}>
								<StatValue>
									{areStatsLoading ? (
										<Loader size="10px" />
									) : (
										<div data-tip={`${toolTip}`} data-delay-show="1000">
											<ReactTooltip />
											{ethIcon && (
												<EthereumIcon
													src={Ethereum}
													alt="ethereum"
													height="14px"
												></EthereumIcon>
											)}
											{ammoIcon && (
												<EthereumIcon
													src={Ammo}
													alt="ethereum"
													height="14px"
												></EthereumIcon>
											)}
											{value}
										</div>
									)}
								</StatValue>
								<StatName>{title}</StatName>
							</StatBox>
						);
					})}
				</CollectionStatsDetails>
			</CollectionStatsContainer>
		</CollectionDetailsContainer>
	);
};

export default React.memo(HeaderStats);
