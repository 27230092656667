export enum SiteRoutes {
	discover = '/discover',
	hideout = '/hideout',
	connectWallet = '/connect-wallet',
	changeWallet = '/change-wallet',
	breeding = '/breeding',
	daycare = '/daycare',
	releaseNotes = '/release-notes',
	store = '/store',
	discord = '/discord',
	raffle = '/raffle',
	profile = '/profile',
}

export enum ExternalRoutes {
	ethConversion = 'https://api.coincap.io/v2/rates/ethereum',
	skvllpvnkzOS = 'https://opensea.io/collection/skvllpvnkz-hideout',
	skvllpvnkzWebsite = '/',
	skvllpvnkzTwitter = 'https://twitter.com/skvllpvnkz',
	skvllpvnkzDiscord = 'https://discord.gg/skvllpvnkz',
	hideoutPaper = 'https://hideout.skvllpvnkz.io/',
	etherscan = 'https://etherscan.io',
	etherscanTx = 'https://etherscan.io/tx/',
	etherscanAddress = 'https://etherscan.io/address/',
}
