import React from 'react';

import { StoreCollectionContainer } from '../../resources/styles/Store/StorePage.styles';
import StoreContent from './StoreContent';

const Store = (): JSX.Element => {

	return (
		<StoreCollectionContainer>
			<StoreContent />
		</StoreCollectionContainer>
	);
};
export default Store;
