import React from 'react';
import CollectRewardProgress from '../../../../components/CollectRewardProgress';
import Loader from '../../../../components/Loader';
import NoNFTFound from '../../../../components/NoNFTFound';
import WalletNotConnected from '../../../../components/WalletNotConnected';
import RewardCollection from '../../../../resources/enums/RewardCollection';
import { SiteRoutes } from '../../../../resources/enums/SiteRoutes';
import { TransactionState } from '../../../../resources/enums/states';
import NFTAssetInterface from '../../../../resources/interfaces/NFTAssetInterface';
import AvailableBabiez from './AvailableBabiez';
import StakeProgress from './StakeProgress';

interface PropsInterface {
	checkInState: TransactionState;
	daycareRewardState: {
		stakedDaycare: TransactionState;
		notStakedDaycare: TransactionState;
	};
	isAllImagesLoaded: boolean;
	hasNoBabiez: boolean;
	skvllbabiezList: NFTAssetInterface[] | undefined;
	setIsAllImagesLoaded: (value: React.SetStateAction<boolean>) => void;
	selectedBabyIds: number[];
	setSelectedBabyIds: React.Dispatch<React.SetStateAction<number[]>>;
	isAuthenticated: boolean;
}

const ChooesBabiezContent = (props: PropsInterface): JSX.Element => {
	const {
		checkInState,
		daycareRewardState,
		isAllImagesLoaded,
		hasNoBabiez,
		skvllbabiezList,
		setIsAllImagesLoaded,
		selectedBabyIds,
		setSelectedBabyIds,
		isAuthenticated,
	} = props;

	const StakeContent = (
		<>
			{!isAllImagesLoaded && <Loader />}
			{hasNoBabiez && (
				<NoNFTFound
					title="No Idle Skvllbabiez Found"
					route={SiteRoutes.discover}
				/>
			)}
			{skvllbabiezList && (
				<AvailableBabiez
					skvllbabiezList={skvllbabiezList}
					isAllImagesLoaded={isAllImagesLoaded}
					setIsAllImagesLoaded={setIsAllImagesLoaded}
					selectedBabyIds={selectedBabyIds}
					setSelectedBabyIds={setSelectedBabyIds}
				/>
			)}
		</>
	);

	const ContentNotDefault =
		checkInState !== TransactionState.DEFAULT ? (
			<StakeProgress stakeState={checkInState} />
		) : (
			<CollectRewardProgress
				state={daycareRewardState.notStakedDaycare}
				isStaked={false}
				rewardCollection={RewardCollection.daycare_staking}
			/>
		);

	const WalletConnectedContent =
		checkInState === TransactionState.DEFAULT &&
		daycareRewardState.notStakedDaycare === TransactionState.DEFAULT
			? StakeContent
			: ContentNotDefault;
	return isAuthenticated ? (
		WalletConnectedContent
	) : (
		<WalletNotConnected subtitle="Connect your wallet to stake your babiez" />
	);
};

export default ChooesBabiezContent;
