import React, { useState } from 'react'
import UserEntryTable from '../../RafflePage/components/UserEntries/UserEntryTable'
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { EntryFilterEnum } from '../../../resources/enums/Entries';
import { RafflePageTableContainer } from '../../../resources/styles/RafflePage/PreviousWinners.styles';
import { PageContainer } from '../../../resources/styles/Page.styles';

const Animus = (): JSX.Element => {
    const [portalKeyFilter, setKeyFilter] = useState(EntryFilterEnum.all);

    const setPortalKeyFilter = (filter: EntryFilterEnum) => {
        setKeyFilter(filter);
    };

    const {
        userPortalKeys,
    } = useSelector((state: RootReducerStateInterface) => state.lottery);

    return (
        <PageContainer noBackgroundImage>
            <RafflePageTableContainer>
                <UserEntryTable
                    userPortalKeys={userPortalKeys}
                    setPortalKeyFilter={setPortalKeyFilter}
                    portalKeyFilter={portalKeyFilter}
                />
            </RafflePageTableContainer>
        </PageContainer>
    )
}

export default Animus