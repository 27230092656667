import styled from 'styled-components';
import { TEXT_WHITE } from '../constants/colors';
import { MOBILE_DEVICE_WIDTH_SMALL } from '../constants/dimensions';

interface PropsInterface {
	width?: string;
	height?: string;
}

export const Container = styled.div<PropsInterface>`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const TitleDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	font-weight: 600;
	font-size: 24px;
	margin-bottom: 15px;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH_SMALL}px) {
		font-size: 20px;
		gap: 5px;
	}
`;

export const SubtitleDiv = styled.div`
	font-size: 16px;
	color: ${TEXT_WHITE};
	margin-bottom: 10px;

	display: flex;
	justify-content: center;
`;
