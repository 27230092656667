interface NotesInterface {
	version: string;
	date?: string;
	description?: string;
	changes?: NotesListItemsInterface[];
}

export interface NotesListItemsInterface {
	description?: string;
	type: ChangeType;
	items?: string[];
}

export enum ChangeType {
	NEW = 'NEW',
	IMPROVED = 'IMPROVED',
	FIX = 'FIX',
}

interface ReleaseNotesInterface {
	description?: string;
	notes: NotesInterface[];
}

export const releaseNotes: ReleaseNotesInterface = {
	notes: [
		{
			version: '1.2.0',
			description:
				'No more gas wars for purchasing high-demand store items! We are introducing a new reservations system allowing you to reserve an item purchase off-chain and then complete the purchase within ime window of 24-48 hours. This will depend per item.',
			date: 'Friday May 6 2022',
			changes: [
				{
					type: ChangeType.NEW,
					items: [
						'Hideout store reservation system for the chance to reserve and pay later',
					],
				},
				{
					type: ChangeType.IMPROVED,
					items: [
						'Refreshing keeps you on the same page and does not redirect to the homepage',
						'All areas of the platform are now available to non-connected users',
					],
				},
			],
		},
		{
			version: '1.1.0',
			description:
				'Big release with a number of new features including a brand new Hideout store where you can spend Ammo in exchange for various digital (and maybe physical) items. We have also introduced a new login flow allowing to log in once an we do the rest.',
			date: 'Mon Apr 11 2022',
			changes: [
				{
					type: ChangeType.NEW,
					items: [
						'Hideout store where you can spend Ammo in exchange for various items',
						'Link your Discord account and control the spend limit of the store',
						'Brand new purchases tab under the wallet sidebar',
						'You can now link directly to a specific NFT',
					],
				},
				{
					type: ChangeType.IMPROVED,
					items: [
						'Brand new login flow - sign in once and we remeber you',
						'Babies in Daycare now have a "Staked" tag',
						'Mobile support has been significantly improved',
					],
				},
			],
		},
		{
			version: '1.0.1',
			description:
				'Patch release including a number of new features, optimizations and fixes.',
			date: 'Thu Mar 24 2022',
			changes: [
				{
					type: ChangeType.NEW,
					items: [
						"Release notes page - We've introduced a brand new release notes page (this one right here) where we will be sharing updates as we evolve our platform.",
						'You can now access the Hideout paper from the top-bar navigation',
						'We can now link directly to collections - direct links to NFTs will next',
					],
				},
				{
					type: ChangeType.IMPROVED,
					items: [
						'Load performance improvements by compressing background and asset images',
						'Rewards are now fetched fresh when you open an NFT in the gallery view',
					],
				},
				{
					type: ChangeType.FIX,
					items: [
						'Fixed an issue where gallery rewards could be incorrectly shown',
					],
				},
			],
		},
		{
			version: '1.0.0',
			description:
				'The first release of the new "Skvllpvnkz - The Hideout" platform.',
			date: 'Mon Mar 07 2022',
			changes: [
				{
					type: ChangeType.NEW,
					description: "Lot's of new features in this first deployment:",
					items: [
						'Discover - Dive into the world of Skvllpvnkz by exploring, searching and experiencing the current collections',
						'Hideout rewards - You can now claim Ammolite rewards just by owning a Skvllpvnk. Your Skvllpvnkz will generate up to 2 AMMO a day and can be collected at any point. Rewards are backtracked to mint day so Pvnkz will come fully loaded. Skvllpvnkz can also be traded whilst carrying Ammo.',
						'Daycare staking - You can now stake your Skvllbabiez to earn up to 4 Ammo a day',
					],
				},
				{
					type: ChangeType.IMPROVED,
					description: "Lot's of new features in this first deployment:",
					items: [
						'Skvllbaby minting - We now have an improved user experience for minting your Skvllbabiez',
					],
				},
			],
		},
	],
};
