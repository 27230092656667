import CollectionReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/CollectionReducerStateInterface';
import TraitInterface from '../../resources/interfaces/TraitInterface';
// import CollectionTraitInterface from '../../resources/interfaces/CollectionTraitInterface';
import { CONNECT_COLLECTION } from '../actions/types/collection';
// import CollectionTraitArrayInterface from '../../resources/interfaces/CollectionTraitArrayInterface';

const initialState: CollectionReducerStateInterface = {
	collectionName: undefined,
	contractAddress: undefined,
	description: undefined,
	externalLink: undefined,
	feeRecipient: undefined,
	id: undefined,
	image: undefined,
	name: undefined,
	numberOfSales: 0,
	sellerFeeBasisPoints: 0,
	totalGas: 0,
	totalItems: 0,
	totalVolume: 0,
	// traits: undefined,
	traitsArray: [],
	transactionCount: 0,
};

interface Payload {
	collectionName: string;
	contractAddress: string;
	description: string;
	externalLink: string;
	feeRecipient: string;
	id: string;
	image: string;
	name: string;
	numberOfSales: number;
	sellerFeeBasisPoints: number;
	totalGas: number;
	totalItems: number;
	totalVolume: number;
	// traits: CollectionTraitInterface;
	traitsArray: TraitInterface[];
	transactionCount: number;
}

interface Action {
	type: string;
	payload: Payload;
}

const collectionReducer = (
	state = initialState,
	action: Action
): CollectionReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_COLLECTION:
			return {
				collectionName: payload.collectionName,
				contractAddress: payload.contractAddress,
				description: payload.description,
				externalLink: payload.externalLink,
				feeRecipient: payload.feeRecipient,
				id: payload.id,
				image: payload.image,
				name: payload.image,
				numberOfSales: payload.numberOfSales,
				sellerFeeBasisPoints: payload.sellerFeeBasisPoints,
				totalGas: payload.totalGas,
				totalItems: payload.totalItems,
				totalVolume: payload.totalVolume,
				// traits: payload.traits,
				traitsArray: payload.traitsArray,
				transactionCount: payload.transactionCount,
			};
		default:
			return state;
	}
};

export default collectionReducer;
