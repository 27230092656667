import { ethers } from 'ethers';
import config from '../config';
import { getWalletRewardsReport } from '../firebase/functions/rewards';
import RewardCollection from '../resources/enums/RewardCollection';
import { ExternalRoutes } from '../resources/enums/SiteRoutes';
import NFTAssetInterface from '../resources/interfaces/NFTAssetInterface';
import SkvllbabiezListInterface from '../resources/interfaces/SkvllbabiezInterface';
import TransactionInterface from '../resources/interfaces/TransactionInterface';

export const getMintTransactionObject = async (
	provider: ethers.providers.Web3Provider,
	mintCount: number,
	isHardwareWallet = false
): Promise<TransactionInterface> => {
	let transaction: TransactionInterface = isHardwareWallet
		? {
				gasPrice: await provider.getGasPrice(),
		  }
		: {};
	if (config.USE_MANUAL_GAS_LIMIT) {
		const gasLimit =
			mintCount * config.FUNCTION_GAS_COST + config.BASE_FUNCTION_GAS_COST;
		const finalGasLimit = gasLimit * config.GAS_LIMIT_ALLOWANCE_MULTIPLIER;
		transaction = isHardwareWallet
			? {
					gasLimit: finalGasLimit,
					gasPrice: await provider.getGasPrice(),
			  }
			: {
					gasLimit: finalGasLimit,
			  };
	}
	return transaction;
};

export const fetchSkvllbabiezDetails =
	async (): Promise<SkvllbabiezListInterface> => {
		const stakedSkvllbabiez: NFTAssetInterface[] = [];
		const notStakedSkvllbabiez: NFTAssetInterface[] = [];
		const skvllbabiezList: SkvllbabiezListInterface = {
			staked_babiez: stakedSkvllbabiez,
			not_staked_babiez: notStakedSkvllbabiez,
		};
		try {
			const allAssets: NFTAssetInterface[] = await getWalletRewardsReport(
				RewardCollection.daycare_staking
			);

			allAssets.forEach((asset) => {
				const { id, staked } = asset;
				const values = {
					...asset,
					link: `${ExternalRoutes.skvllpvnkzOS}/${id}`,
				};
				if (staked) {
					stakedSkvllbabiez.push(values);
				} else {
					notStakedSkvllbabiez.push(values);
				}
			});
		} catch (error) {
			console.error(error);
		}
		return skvllbabiezList;
	};

export const conditionalReloadByEvent = (event: ethers.Event): void => {
	const { blockNumber } = event;
	if (Number(window.sessionStorage.getItem('reloadKey')) !== blockNumber) {
		window.sessionStorage.setItem('reloadKey', blockNumber.toString());
		window.location.reload();
	}
};
