export const formatDate = (date: Date): string =>
	date
		.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		})
		.toString();

export const calculateMillisecondDifference = (date: number): number =>
	new Date(date).getTime() - new Date().getTime();

export const convertMillisecondDifference = (
	milliseconds: number,
	conversion: number,
	modulo?: number
): number => {
	let convertedDate = milliseconds / conversion;
	if (modulo) {
		convertedDate %= modulo;
	}
	return Math.floor(convertedDate);
};
