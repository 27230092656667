import { ethers } from 'ethers';
import {
	decrementBabiezCount,
	setMintingState,
	updateSkvllbabiezList,
} from '../redux/actions/skvllbabiez';
import config from '../config';
import {
	HARDWARE_WALLET_ERROR_CODE,
	NETWORK_TRANSACTION_SUCCESS,
} from '../resources/constants/codes';
import {
	conditionalReloadByEvent,
	fetchSkvllbabiezDetails,
	getMintTransactionObject,
} from '../helpers/skvllbabiez';
import skvllbabiezABI from '../contracts/skvllbabiezABI.json';
import { TransactionState } from '../resources/enums/states';
import { disposeUsedSkvllpvnkz } from '../redux/actions/skvllpvnkz';
import { Dispatch } from 'react';
import SkvllbabiezListInterface from '../resources/interfaces/SkvllbabiezInterface';
import { setCheckInState } from '../redux/actions/rewardsAndStaking';
import { batch } from 'react-redux';
import TransactionInterface from '../resources/interfaces/TransactionInterface';
import Wallet from './Wallet';

export default class Skvllbabiez {
	private contract;
	private provider;
	private wallet;
	private dispatch;

	constructor(wallet: Wallet, dispatch: Dispatch<unknown>) {
		this.wallet = wallet;
		this.provider = wallet.library;
		this.dispatch = dispatch;
		this.contract = new ethers.Contract(
			config.SKVLLBABIEZ_CONTRACT_ADDRESS,
			skvllbabiezABI,
			this.provider.getSigner()
		);
		this.initializeContractListeners();
	}

	public initializeContractListeners(): void {
		this.contract.on('SkvllbabiezPublicSaleStarted', (event) =>
			conditionalReloadByEvent(event)
		);
		this.contract.on('SkvllbabiezPublicSalePaused', (event) =>
			conditionalReloadByEvent(event)
		);
	}

	public async getMintableCount(): Promise<number> {
		const unclaimedSkvllpvnkzCount = (
			await this.contract.unclaimedSkvllpvnkzCount(this.wallet.address)
		).toNumber();
		return Math.floor(unclaimedSkvllpvnkzCount / 2);
	}

	public async getAvailableTokens(): Promise<number[]> {
		return this.contract.unclaimedSkvllpvnkzCountIDs(this.wallet.address);
	}

	public async getTotalBabiezMinted(): Promise<number> {
		return (await this.contract.totalSupply()).toNumber();
	}

	public async getSkvllbabiez(): Promise<SkvllbabiezListInterface> {
		return fetchSkvllbabiezDetails();
	}

	public async mint(
		skvllpvnkzIds: number[],
		isHardwareWallet = false
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	): Promise<any> {
		try {
			const mintCount = skvllpvnkzIds.length / config.TOKENS_PER_MINT;
			const gasEstimate = await this.contract.estimateGas.makeBabiez(
				skvllpvnkzIds
			);
			const transaction = await getMintTransactionObject(
				this.provider,
				mintCount,
				isHardwareWallet
			);

			const finalTx = config.USE_MANUAL_GAS_LIMIT
				? transaction
				: { ...transaction, gasLimit: gasEstimate };
			const result = await this.contract.makeBabiez(skvllpvnkzIds, finalTx);

			this.provider.once(
				result.hash,
				(ongoingTransaction: { status: number }) => {
					batch(() => {
						if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
							this.dispatch(decrementBabiezCount(mintCount));
							this.dispatch(disposeUsedSkvllpvnkz(skvllpvnkzIds));
							this.dispatch(updateSkvllbabiezList());
						} else {
							this.dispatch(setMintingState(TransactionState.FAILED));
							console.error('An error occurred');
						}
					});
				}
			);

			return result;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any | unknown) {
			console.error(err);
			if (err.code === HARDWARE_WALLET_ERROR_CODE) {
				this.mint(skvllpvnkzIds, true);
			} else {
				this.dispatch(setMintingState(TransactionState.FAILED));
			}
		}
	}

	public checkSkvllpvnkID(id: number): boolean {
		return this.contract.isUsed(id);
	}

	public isMintSkvllbabiezLive(): boolean {
		return this.contract._publicSale();
	}

	public async fetchBalanceOfWallet(walletAddress: string): Promise<number> {
		return Number(
			ethers.utils.formatUnits(await this.contract.balanceOf(walletAddress), 0)
		);
	}

	public async setApprovalForAll(
		approved: boolean,
		callbackHandleCheckIn: () => Promise<void>
	): Promise<void> {
		try {
			const result = await this.contract.setApprovalForAll(
				config.DAYCARE_CONTRACT_ADDRESS,
				approved
			);

			this.provider.once(
				result.hash,
				(ongoingTransaction: { status: number }) => {
					if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
						this.dispatch(setCheckInState(TransactionState.IN_PROGRESS));
						callbackHandleCheckIn();
					} else {
						this.dispatch(setCheckInState(TransactionState.FAILED));
					}
				}
			);
		} catch (er) {
			console.error(er);
			this.dispatch(setCheckInState(TransactionState.FAILED));
		}
	}

	public async isApprovedForAll(owner: string): Promise<boolean> {
		return this.contract.isApprovedForAll(
			owner,
			config.DAYCARE_CONTRACT_ADDRESS
		);
	}

	private getTransactionObject = async (
		count: number,
		isHardwareWallet = false
	): Promise<TransactionInterface> => {
		let transaction: TransactionInterface = isHardwareWallet
			? {
					gasPrice: await this.provider.getGasPrice(),
			  }
			: {};
		if (config.USE_MANUAL_GAS_LIMIT) {
			const gasLimit = config.CHECKIN_DAYCARE_VARIABLE_FUNCTION_GAS_COST;
			const finalGasLimit =
				count * gasLimit + config.CHECKIN_DAYCARE_BASE_FUNCTION_GAS_COST;
			transaction = isHardwareWallet
				? {
						gasLimit: finalGasLimit,
						gasPrice: await this.provider.getGasPrice(),
				  }
				: {
						gasLimit: finalGasLimit,
				  };
		}
		return transaction;
	};

	public async stakeOneBaby(from: string, tokenId: number): Promise<void> {
		try {
			const tx = this.getTransactionObject(tokenId);
			const result = await this.contract[
				'safeTransferFrom(address,address,uint256)'
			](from, config.DAYCARE_CONTRACT_ADDRESS, tokenId, tx);

			this.provider.once(
				result.hash,
				(ongoingTransaction: { status: number }) => {
					batch(() => {
						if (ongoingTransaction.status === NETWORK_TRANSACTION_SUCCESS) {
							this.dispatch(updateSkvllbabiezList());
							this.dispatch(setCheckInState(TransactionState.SUCCESS));
						} else {
							this.dispatch(setCheckInState(TransactionState.FAILED));
						}
					});
				}
			);
		} catch (er) {
			console.error(er);
			this.dispatch(setCheckInState(TransactionState.FAILED));
		}
	}
}
