import React from 'react';
import {
	FlexDetailDiv,
	DurationDetail,
	ActionTd,
} from '../../../resources/styles/StakeNFT.styles';
import AmmoliteIcon from '../../../resources/images/icons/AmmoliteIcon.png';
import DateIcon from '../../../resources/images/icons/DateIcon.png';
import TableRewards from './TableRewards';
import { CollectionTitles } from '../../../resources/enums/CollectionTitles';

interface PropsInterface {
	collection: CollectionTitles;
	thumb: string;
	name: string;
	staked_on: string;
	held_for: number;
	rewards: string;
	clicked: boolean;
	incrementRate: number;
}

const TableContent = (props: PropsInterface): JSX.Element => {
	const {
		thumb,
		staked_on,
		name,
		held_for,
		rewards,
		clicked,
		incrementRate,
		collection,
	} = props;

	return (
		<>
			<td className="item">
				<FlexDetailDiv gap="25px" gapMobile="0" justify="flex-start">
					<div className="nft-image">
						<img src={thumb} alt="NFT" height="53px" />
						<ActionTd clicked={clicked} className="hideout-checkbox">
							<div className="action-circle">
								<div className="inside-action-circle"></div>
							</div>
						</ActionTd>
					</div>
					<div className="claim">
						<p className="staked-nft-name">{name}</p>
						<DurationDetail>
							<b>
								{collection === CollectionTitles.Skvllpvnkz
									? 'Last claim: '
									: 'Staked on: '}
								{staked_on}
							</b>
						</DurationDetail>
						<div className="table-content-info-mobile">
							<td>
								<FlexDetailDiv gap="6px">
									<img src={DateIcon} alt="date" height="17px" />
									<p className="mobile-date">{held_for} days</p>
								</FlexDetailDiv>
							</td>
							<td>
								<FlexDetailDiv gap="4px">
									<img src={AmmoliteIcon} alt="ammolite" height="15px" />
									<TableRewards
										rewards={rewards}
										incrementRate={incrementRate}
									/>
								</FlexDetailDiv>
							</td>
						</div>
					</div>
				</FlexDetailDiv>
			</td>

			<td className="table-content-info">
				<td className="content-info-desktop">
					<FlexDetailDiv gap="8px">
						<img src={DateIcon} alt="date" height="17px" />
						<p>{held_for} days</p>
					</FlexDetailDiv>
				</td>
				<td>
					<FlexDetailDiv gap="8px">
						<img src={AmmoliteIcon} alt="ammolite" height="18px" />
						<TableRewards rewards={rewards} incrementRate={incrementRate} />
					</FlexDetailDiv>
				</td>
				<ActionTd clicked={clicked}>
					<div className="action-circle">
						<div className="inside-action-circle"></div>
					</div>
				</ActionTd>
			</td>
		</>
	);
};

export default React.memo(TableContent);
