import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import {
	ContentHeader,
	MobileCloseButton,
} from '../../../resources/styles/StoreItemModalView.styles';
import { useDispatch } from 'react-redux';
import { setIsStoreModalOpen } from '../../../redux/actions/store';
import StoreItemDetails from './components/StoreItemDetails';
import StoreItemImage from './components/StoreItemImage';
import StoreItemDescription from './components/Description';
import StoreItemInterface from '../../../resources/interfaces/StoreItemInterface';
import { IoClose } from 'react-icons/io5';

interface PropsInterface {
	selectedItem: StoreItemInterface;
	isStoreModalOpen: boolean;
	reservedItemsCount: number;
}

const StoreItemModalView = (props: PropsInterface): JSX.Element => {
	const { selectedItem, isStoreModalOpen, reservedItemsCount } = props;
	const dispatch = useDispatch();

	const {
		image,
		description,
		description2,
		description3,
		status,
		maxSupply,
		totalSupply,
		reservationsOpen,
		id,
	} = selectedItem;
	const isSoldOut = maxSupply === totalSupply;
	return (
		<Modal
			centered
			isOpen={isStoreModalOpen}
			toggle={() => dispatch(setIsStoreModalOpen(!isStoreModalOpen))}
			size="lg"
		>
			<ModalBody id="store-item-modal">
				<MobileCloseButton onClick={() => dispatch(setIsStoreModalOpen(false))}>
					<IoClose size={25} />
				</MobileCloseButton>
				<ContentHeader>
					<StoreItemImage
						image={image}
						status={status}
						soldOut={isSoldOut}
						id={id}
						reservationsOpen={reservationsOpen}
					/>
					<StoreItemDetails
						selectedItem={selectedItem}
						reservedItemsCount={reservedItemsCount}
					/>
				</ContentHeader>
				<StoreItemDescription
					description={description}
					description2={description2}
					description3={description3}
				/>
			</ModalBody>
		</Modal>
	);
};

export default React.memo(StoreItemModalView);
