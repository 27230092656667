import React, { useCallback } from 'react';
import { TERTIARY_MAIN } from '../../../../resources/constants/colors';
import { AvailableBabiezDiv } from '../../../../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import { NFTCardContainer } from '../../../../resources/styles/NFTCard.styles';
import NFTAssetInterface from '../../../../resources/interfaces/NFTAssetInterface';
import BabyCardContent from './BabyCardContent';

interface PropsInterface {
	skvllbabiezList: NFTAssetInterface[];
	isAllImagesLoaded: boolean;
	selectedBabyIds: number[];
	setIsAllImagesLoaded: (value: React.SetStateAction<boolean>) => void;
	setSelectedBabyIds: React.Dispatch<React.SetStateAction<number[]>>;
}

const AvailableBabiez = (props: PropsInterface): JSX.Element => {
	const {
		skvllbabiezList,
		isAllImagesLoaded,
		selectedBabyIds,
		setIsAllImagesLoaded,
		setSelectedBabyIds,
	} = props;

	const handleImagesLoading = useCallback(
		(index: number) => {
			if (skvllbabiezList) {
				if (skvllbabiezList.length === index + 1) {
					setIsAllImagesLoaded(true);
				}
			}
		},
		[skvllbabiezList]
	);

	const handleSelectBaby = (selectedBabyID: number) => {
		const babyAlreadySelected = handleIncludeCheck(selectedBabyID);
		if (babyAlreadySelected) {
			setSelectedBabyIds(selectedBabyIds.filter((id) => id !== selectedBabyID));
			return;
		}
		setSelectedBabyIds([...selectedBabyIds, selectedBabyID]);
	};

	const handleIncludeCheck = (selectedBabyID: number): boolean =>
		selectedBabyIds.some((id) => id === selectedBabyID);

	return (
		<AvailableBabiezDiv display={skvllbabiezList.length > 0 ? 'grid' : 'none'}>
			{skvllbabiezList.map((baby, index) => {
				const { id: token_id, thumb, rewards, staked_on } = baby;
				return (
					<NFTCardContainer
						key={token_id}
						backgroundColor={TERTIARY_MAIN}
						display={isAllImagesLoaded ? 'inline-block' : 'none'}
						marginRight="0px"
						onClick={() => handleSelectBaby(token_id)}
					>
						<BabyCardContent
							isAllImagesLoaded={isAllImagesLoaded}
							checked={handleIncludeCheck(token_id)}
							token_id={token_id}
							thumb={thumb}
							rewards={parseFloat(rewards).toFixed(4)}
							handleImagesLoading={handleImagesLoading}
							index={index}
							staked_on={staked_on}
						/>
					</NFTCardContainer>
				);
			})}
		</AvailableBabiezDiv>
	);
};

export default AvailableBabiez;
