import styled from 'styled-components';
import {
	TEXT_DARK,
	TEXT_GRAYED_OUT,
	TEXT_GREEN,
	TEXT_PURPLE,
	TEXT_WHITE,
} from '../constants/colors';
import { MOBILE_DEVICE_WIDTH } from '../constants/dimensions';

interface PropsInterface {
	fontSize?: string;
	color?: string;
	marginTop?: string;
	marginBottom?: string;
	marginLeft?: string;
	gap?: string;
	isResponsive?: boolean;
	display?: string;
	justify?: string;
	cardTag?: boolean | undefined;
}

export const ContentHeader = styled.div`
	display: flex;
	gap: 30px;

	@media screen and (max-width: 992px) {
		display: block;
	}
`;

export const StoreItemDescriptionContainer = styled.div<PropsInterface>`
	padding: 5px;
	padding-right: 15px;
	hr {
		height: 1px;
		margin: auto;
		width: 10%;
	}
	i {
		color: gray;
	}
	#label {
		font-size: 12px;
		color: ${TEXT_GRAYED_OUT};
		margin-bottom: 8px;
		padding-top: 8px;
	}
	#link {
		text-decoration: none;
		color: ${TEXT_GREEN};
		margin-bottom: 8px;
		padding-top: 8px;
	}
`;

export const ImageContainer = styled.div<PropsInterface>`
	width: 380px;
	margin: auto;
	border-radius: 10px;

	#card-tag {
		cursor: pointer;
		display: ${(props) => (props.cardTag ? 'flex' : 'none')};
		position: absolute;
		align-items: center;
		justify-content: center;
		padding-left: 8px;
		padding-right: 8px;
		height: 28px;
		background-color: white;
		border-radius: 5px;
		margin-top: 10px;
		margin-left: 10px;
		color: ${TEXT_DARK};
		font-size: 12px;
		font-weight: 600;
	}

	img {
		height: 100%;
		width: 100%;
		border-radius: 10px;
	}
	video {
		height: 100%;
		width: 100%;
		border-radius: 10px;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		width: 95%;
	}
`;

export const DetailGridDiv = styled.div<PropsInterface>`
	display: grid;
	grid-template-columns: 30% 40% 20%;
	text-items: left;
	gap: ${(props) => props.gap};
	font-size: ${(props) => props.fontSize};
	color: ${(props) => props.color};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};

	#link {
		color: ${TEXT_GREEN};
		text-decoration: none;
		padding-left: 8px;
		:hover {
			color: ${TEXT_PURPLE};
		}
	}
`;

export const DetailFlexDiv = styled.div<PropsInterface>`
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.justify};
	gap: ${(props) => props.gap};
	font-size: ${(props) => props.fontSize};
	color: ${(props) => props.color};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	margin-left: ${(props) => props.marginLeft};
	.green {
		color: ${TEXT_GREEN};
		cursor: pointer;
	}

	#project-label {
		background-color: ${TEXT_PURPLE};
		padding: 1px;
		padding-left: 5px;
		padding-right: 5px;
		border-radius: 8px;
	}

	a:link {
		text-decoration: none;
	}
`;

DetailFlexDiv.defaultProps = {
	fontSize: '13px',
	color: TEXT_WHITE,
	marginTop: '0px',
	marginBottom: '0px',
	marginLeft: '0px',
	gap: '0px',
	isResponsive: false,
};

export const Ammolite = styled.img<PropsInterface>`
	margin-top: 0px;
	padding-right: 5px;
`;

export const MobileCloseButton = styled.div<PropsInterface>`
	cursor: pointer;
	margin-bottom: 10px;
	display: none;
	float: right;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		display: block;
	}
`;

export const PurchaseButton = styled.div<PropsInterface>`
	display: grid;
	grid-template-columns: 30% 40% 20%;
	text-items: left;
	gap: 25px;
	font-size: 12px;
	margin-top: 20px;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		grid-template-columns: 100%;

		#purchase-button {
			width: 100%;
		}
	}
`;
