import React, { useLayoutEffect } from 'react';
import { Col, Row, setConfiguration } from 'react-grid-system';
import { GalleryBreakPoints } from '../../resources/enums/breakpoints';
import { GalleryContainer } from '../../resources/styles/DiscoverPage/Gallery.styles';
import GalleryContent from './GalleryContent';
import Loader from '../Loader';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGalleryLoading } from '../../redux/actions/gallery';

interface GalleryProps {
	handleFetchCollectionAssets: (isScrolling: boolean) => Promise<void>;
}

const Gallery = (props: GalleryProps): JSX.Element => {
	setConfiguration({
		breakpoints: [
			GalleryBreakPoints.xs,
			GalleryBreakPoints.sm,
			GalleryBreakPoints.md,
			GalleryBreakPoints.lg,
			GalleryBreakPoints.xl,
		],
	});

	const { handleFetchCollectionAssets } = props;

	const { isGalleryLoading, collectionData, isLastAsset } = useSelector(
		(state: RootReducerStateInterface) => state.gallery
	);
	const dispatch = useDispatch();

	const handleScroll = () => {
		const { innerHeight, scrollY } = window;
		const { scrollHeight } = document.body;
		if (Math.ceil(innerHeight + scrollY) >= scrollHeight) {
			if (!isGalleryLoading && !isLastAsset) {
				dispatch(setIsGalleryLoading(true));
				handleFetchCollectionAssets(true);
			}
		}
	};

	useLayoutEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [isGalleryLoading, isLastAsset]);

	const loader =
		collectionData.length === 0 ? (
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
					<Loader />
				</Col>
			</Row>
		) : (
			<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
				<Loader />
			</Col>
		);

	const noData =
		isGalleryLoading === false && collectionData.length === 0 ? (
			<Row>
				<Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
					<h6>Nothing here...</h6>
				</Col>
			</Row>
		) : null;

	return (
		<GalleryContainer>
			<GalleryContent
				collectionData={collectionData}
				loader={loader}
				noData={noData}
				isLastAsset={isLastAsset}
			/>
		</GalleryContainer>
	);
};
export default Gallery;
