export const ADD_ITEM_TO_PENDING_TRANSACTIONS =
	'ADD_ITEM_TO_PENDING_TRANSACTIONS';
export const SET_STORE_DATA = 'SET_STORE_DATA';
export const SET_IS_STORE_MODAL_OPEN = 'SET_IS_STORE_MODAL_OPEN';
export const SET_STORE_SELECTED_ITEM = 'SET_STORE_SELECTED_ITEM';
export const CONNECT_STORE = 'CONNECT_STORE';
export const SET_PURCHASED_ITEMS = 'SET_PURCHASED_ITEMS';
export const SET_RESERVED_ITEMS = 'SET_RESERVED_ITEMS';
export const ADD_ITEM_TO_STORE_DATA = 'ADD_ITEM_TO_STORE_DATA';
export const UPDATE_STORE_ITEM = 'UPDATE_STORE_ITEM';
export const REMOVE_ITEM_FROM_PENDING_TRANSACTIONS =
	'REMOVE_ITEM_FROM_PENDING_TRANSACTIONS';
export const RESET_STORE_STATE = 'RESET_STORE_STATE';
