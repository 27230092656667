import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../resources/styles/CustomButton.styles';

import WalletLogo from '../resources/images/logos/wallet-logo.png';
import { SiteRoutes } from '../resources/enums/SiteRoutes';
import {
	Container,
	SubtitleDiv,
	TitleDiv,
} from '../resources/styles/WalletNotConnected.styles';

interface PropsInterface {
	height?: string;
	width?: string;
	subtitle: string;
}

const WalletNotConnected = (props: PropsInterface): JSX.Element => {
	const { height, width, subtitle } = props;
	const navigate = useNavigate();

	return (
		<Container width={width} height={height}>
			<div>
				<TitleDiv>
					Connect Wallet <img src={WalletLogo} alt="wallet" height="20px" />
				</TitleDiv>
				<SubtitleDiv>{subtitle}</SubtitleDiv>
				<CustomButton
					onClick={() => {
						navigate(SiteRoutes.connectWallet);
					}}
					width="151px"
					height="40px"
					marginTop="10px"
					borderRadius="5px"
				>
					<b>Connect</b>
				</CustomButton>
			</div>
		</Container>
	);
};

export default WalletNotConnected;
