import React from 'react';
import StoreItemInterface from '../../../resources/interfaces/StoreItemInterface';
import { batch, useDispatch } from 'react-redux';
import {
	setIsStoreModalOpen,
	setSelectedItem,
} from '../../../redux/actions/store';
import { formatUnits } from 'ethers/lib/utils';
import AmmoliteIcon from '../../../resources/images/icons/AmmoliteIcon.png';
import { GreenLText } from '../../../resources/styles/RafflePage/RafflePage.styles';

interface PropsInterface {
	item: StoreItemInterface;
	isReserved?: boolean;
	tableHeader: JSX.Element;
	isExpired?: boolean;
}

const PurchaseTableRow = (props: PropsInterface) => {
	const { tableHeader, item, isReserved, isExpired } = props;
	const { name, type, project, price } = item;
	const dispatch = useDispatch();

	const handleShowModal = async () => {
		batch(() => {
			dispatch(setSelectedItem(item));
			dispatch(setIsStoreModalOpen(true));
		});
	};

	const buttonText = isReserved
		? 'Reserved'
		: isExpired
		? 'Expired'
		: 'Purchased';

	return (
		<tr className="table-wrapper" onClick={handleShowModal}>
			<tr className="mobile">{tableHeader}</tr>
			<tr className="table-row table-entries">
				<td>{name}</td>
				<td>{type}</td>
				<td>{project}</td>
				<td>
					<span>
						{Number(formatUnits(price))}{' '}
						<img src={AmmoliteIcon} alt="Ammolite" height="12px" />
					</span>
				</td>
				<td>
					<GreenLText>
						<span
							className={`indicator ${
								buttonText === 'Reserved' && 'reserved'
							} ${buttonText === 'Expired' && 'expired'}`}
						></span>
						{buttonText}
					</GreenLText>
				</td>
			</tr>
		</tr>
	);
};

export default PurchaseTableRow;
