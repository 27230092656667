import React from 'react';
import ReactTooltip from 'react-tooltip';
import { TEXT_WHITE } from '../../resources/constants/colors';
import {
	Ammolite,
	CardRank,
	RankDetails,
	CardScore,
	CardPrice,
	CardScoreAndPrice,
	GalleryCard,
	GalleryRankAndPriceDetails,
	GalleryCardRank,
	CardRankAndScore,
	GalleryCardTitle,
	ImageWrapper,
} from '../../resources/styles/DiscoverPage/Gallery.styles';
import AmmoliteIcon from '../../resources/images/icons/AmmoliteIcon.png';
import { batch, useDispatch, useSelector } from 'react-redux';
import { setIsModalOpen, setSelectedNFT } from '../../redux/actions/gallery';
import NFTImageInterface from '../../resources/interfaces/NFTImageInterface';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';
import { SiteRoutes } from '../../resources/enums/SiteRoutes';
import LazyLoadSpinner from '../LazyLoadSpinner';
import EthereumIcon from '../../resources/images/icons/eth.png';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { parseUrlPath } from '../../helpers/url';

interface PropsInterface {
	nft: NFTImageInterface;
	cardColor: '#6C70EA' | '#42BEB2';
	shadowColor: '90,90,90' | '10,10,10';
	dataTip: '' | 'This is in the top 1%';
	callbackGenerateMedia: (nft: NFTImageInterface) => JSX.Element;
}

const GalleryNFTCard = (props: PropsInterface): JSX.Element => {
	const { nft, cardColor, shadowColor, dataTip, callbackGenerateMedia } = props;
	const { name, rank, rewards, token_id, price } = nft;
	const { selectedCollection } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const roundToTwo = (num: number) => {
		return +Math.round(num);
	};

	const { page } = parseUrlPath();

	const handleClick = () => {
		batch(() => {
			dispatch(setSelectedNFT(nft));
			dispatch(setIsModalOpen(true));
		});

		const path = `${page}${
			page === SiteRoutes.profile ? '/collections' : ''
		}/${selectedCollection.replace('_', '-')}/${token_id}`;

		navigate(path);
	};

	const formattedPrice = Number(parseFloat(price || '0').toFixed(3));
	return (
		<LazyLoad
			throttle
			debounce
			unmountIfInvisible
			offset={500}
			placeholder={<LazyLoadSpinner boxShadow={shadowColor} />}
		>
			<GalleryCard
				onClick={handleClick}
				shadowColor={shadowColor}
				data-tip={dataTip}
				data-delay-show="1000"
			>
				<ReactTooltip />

				<ImageWrapper>{callbackGenerateMedia(nft)}</ImageWrapper>

				<GalleryRankAndPriceDetails>
					<GalleryCardTitle color={TEXT_WHITE}>{name}</GalleryCardTitle>
					<CardRankAndScore>
						{rank !== undefined ? (
							<RankDetails
								color={cardColor}
								data-tip="Skvllpvnkz ranks based on trait rarity scores"
								data-delay-show="1000"
							>
								<div className="rank"> Rank</div>
								<ReactTooltip />
								<div className="rank-number">{rank} </div>
							</RankDetails>
						) : (
							<CardRank></CardRank>
						)}
						<CardScoreAndPrice>
							<Ammolite
								src={AmmoliteIcon}
								alt="ammolite"
								height="14px"
							></Ammolite>
							{roundToTwo(parseFloat(String(rewards))) || 0}

							<GalleryCardRank>
								{formattedPrice !== 0 ? (
									<CardPrice>
										<Ammolite
											src={EthereumIcon}
											alt="ammolite"
											height="14px"
										></Ammolite>
										{formattedPrice}
									</CardPrice>
								) : (
									<CardScore />
								)}
							</GalleryCardRank>
						</CardScoreAndPrice>
					</CardRankAndScore>
				</GalleryRankAndPriceDetails>
			</GalleryCard>
		</LazyLoad>
	);
};

export default React.memo(GalleryNFTCard);
