import React, { useState, useEffect } from 'react';
import { GalleryCollectionContainer } from '../../../resources/styles/DiscoverPage/DiscoverPage.styles';
import FilterPanel from '../../../components/FilterPanel/FilterPanel';
import Gallery from '../../../components/Gallery/Gallery';
import filterIcon from '../../../../src/resources/images/icons/Vector.png';
import { Collections } from '../../../resources/enums/Collections';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
	setBuyNowFilter,
	setSelectedTraits,
	setTraitsArray,
	setUpTraitCollapseStates,
	setVirginFilter,
} from '../../../redux/actions/filter';
import {
	setCollectionData,
	setIsGalleryLoading,
	setIsLastAsset,
	setIsModalOpen,
	setNewStartAt,
	setSelectedNFT,
} from '../../../redux/actions/gallery';
import { BATCH_LIMIT } from '../../../resources/constants/limits';
import { OrderByKeys } from '../../../resources/enums/OrderByKeys';
import { ethers } from 'ethers';
import {
	fetchAssets,
	fetchCollectionDetails,
} from '../../../firebase/functions/collections';
import { NFTCollectionQueryInterface } from '../../../resources/interfaces/NFTCollectionQueryInterface';
import NFTModalView from '../../../components/modals/NFTModalView/NFTModalView';
import { SiteRoutes } from '../../../resources/enums/SiteRoutes';
import { parseUrlPath } from '../../../helpers/url';

interface Props {
	showSearchFilter: boolean;
}
const DiscoverContent = (props: Props): JSX.Element => {
	const { showSearchFilter } = props;
	const dispatch = useDispatch();
	const {
		selectedCollection,
		selectedTraits,
		virginFilter,
		buyNowFilter,
		orderByFilter,
		filterByWallet,
	} = useSelector((state: RootReducerStateInterface) => state.filter);
	const {
		startAt,
		collectionData,
		isGalleryLoading,
		isLastAsset,
		isModalOpen,
		selectedNFT,
	} = useSelector((state: RootReducerStateInterface) => state.gallery);

	const address = useSelector(
		(state: RootReducerStateInterface) => state.wallet.address
	);

	const handleChangeCollection = async (collection: Collections) => {
		const collectionDetails = await fetchCollectionDetails(collection);
		batch(() => {
			dispatch(setVirginFilter(undefined));
			dispatch(setBuyNowFilter(undefined));
			dispatch(setSelectedTraits([]));
			dispatch(setTraitsArray(collectionDetails.traitsArray));
			dispatch(setUpTraitCollapseStates(collectionDetails.traitsArray));
		});
	};

	const { page, hasTokenInUrl, startFrom } = parseUrlPath();

	const getParams = (startNumber: number, clearAddress: boolean | undefined) =>
		({
			collectionName: selectedCollection.replace('-', '_'),
			startAt: startNumber,
			orderByField: orderByFilter,
			orderByDirection: orderByFilter === OrderByKeys.REWARDS ? 'desc' : 'asc',
			virgin: virginFilter,
			buyNow: buyNowFilter,
			walletAddress:
				page === SiteRoutes.profile
					? address
					: ethers.utils.isAddress(filterByWallet) && !clearAddress
					? filterByWallet
					: '',
			filters: selectedTraits,
			limit: BATCH_LIMIT,
		} as NFTCollectionQueryInterface);

	const handleFetchCollectionAssets = async (
		isScrolling: boolean,
		clearAddress?: boolean
	) => {
		try {
			let startNumber = isScrolling
				? startAt
				: orderByFilter === OrderByKeys.REWARDS
				? 10000
				: 0;

			if (hasTokenInUrl) {
				startNumber = startFrom;
			}

			const params = getParams(startNumber, clearAddress);
			const { data, newStartAt } = await fetchAssets(params);
			batch(() => {
				if (isScrolling) {
					dispatch(setCollectionData([...collectionData, ...data]));
				} else dispatch(setCollectionData(data));
				if (data.length < BATCH_LIMIT) dispatch(setIsLastAsset(true));
				dispatch(setNewStartAt(newStartAt));
				dispatch(setIsGalleryLoading(false));
				if (hasTokenInUrl) {
					dispatch(setSelectedNFT(data[0]));
					dispatch(setIsModalOpen(true));
				}
			});
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		batch(() => {
			if (!isGalleryLoading) dispatch(setIsGalleryLoading(true));
			if (collectionData.length > 0) dispatch(setCollectionData([]));
			if (isLastAsset) dispatch(setIsLastAsset(false));
		});
		handleFetchCollectionAssets(false);
	}, [
		selectedCollection,
		JSON.stringify(selectedTraits),
		virginFilter,
		buyNowFilter,
		orderByFilter,
	]);

	useEffect(() => {
		window.scrollTo(0, 0);
		handleChangeCollection(selectedCollection);
	}, [selectedCollection]);

	useEffect(() => {
		return () => {
			batch(() => {
				dispatch(setCollectionData([]));
				dispatch(setIsLastAsset(false));
				dispatch(setSelectedTraits([]));
			});
		};
	}, []);

	const [showFilter, setShowFilter] = useState(false);

	const filter = (
		<FilterPanel
			handleFetchCollectionAssets={handleFetchCollectionAssets}
			setShowFilter={setShowFilter}
			showSearchFilter={showSearchFilter}
		/>
	);

	return (
		<>
			<div className="filter-mobile-icon" onClick={() => setShowFilter(true)}>
				<img src={filterIcon}></img>
			</div>
			{showFilter && (
				<div className="new-filter">
					<div>{filter}</div>
				</div>
			)}
			<GalleryCollectionContainer>
				<div className="old-filter">{filter}</div>

				<Gallery handleFetchCollectionAssets={handleFetchCollectionAssets} />
			</GalleryCollectionContainer>
			{isModalOpen && (
				<NFTModalView
					selectedCollection={selectedCollection}
					isModalOpen={isModalOpen}
					selectedNFT={selectedNFT}
					filterByWallet={filterByWallet}
				/>
			)}
		</>
	);
};

export default DiscoverContent;
