import { Dispatch } from 'react';
import LotteryDBInterface, {
	LotteryEntryInterface,
	LotteryInterface,
} from '../../resources/interfaces/LotteryInterface';
import Ammolite from '../../services/Ammolite';
import Lottery from '../../services/Lottery';
import Skvllpvnkz from '../../services/Skvllpvnkz';
import Wallet from '../../services/Wallet';
import {
	ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
	CONNECT_LOTTERY,
	REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
	SET_ALL_PREVIOUS_WINNERS,
	SET_CURRENT_LOTTERY,
	SET_PREVIOUS_LOTTERY,
	SET_USER_PORTAL_KEYS,
} from './types/lottery';

export const connectLottery =
	(
		wallet: Wallet,
		ammolite: Ammolite | undefined,
		skvllpvnkz: Skvllpvnkz | undefined
	) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (wallet.library && ammolite && skvllpvnkz) {
			const lottery = new Lottery(dispatch, ammolite, skvllpvnkz, wallet);
			dispatch({
				type: CONNECT_LOTTERY,
				payload: { lottery },
			});
		}
	};
export const setCurrentLottery =
	(currentLottery: LotteryDBInterface | undefined) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_CURRENT_LOTTERY,
			payload: { currentLottery },
		});
	};

export const setPreviousLottery =
	(previousLottery: LotteryDBInterface | undefined) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_PREVIOUS_LOTTERY,
			payload: { previousLottery },
		});
	};

export const setAllPreviousWinners =
	(allPreviousWinners: LotteryEntryInterface[] | undefined) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_ALL_PREVIOUS_WINNERS,
			payload: { allPreviousWinners },
		});
	};
export const setUserPortalKeys =
	(userPortalKeys: LotteryEntryInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_USER_PORTAL_KEYS,
			payload: { userPortalKeys },
		});
	};

export const addLotteryEntryPurchaseToPendingTransactions =
	(pendingEntry: LotteryInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
			payload: { pendingEntry },
		});
	};

export const removeLotteryEntryPurchaseFromPendingTransactions =
	(pendingEntry: LotteryInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
			payload: { pendingEntry },
		});
	};
