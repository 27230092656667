import React from 'react';
import { SidebarDetails } from '../../../../resources/styles/Sidebar.style';
import { ButtonWrapper } from '../../../../resources/styles/ButtonWrappers.style';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import { batch, useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import ReactTooltip from 'react-tooltip';
import { TransactionState } from '../../../../resources/enums/states';
import {
	setHideoutRewardState,
	setNotStakedDaycareRewardState,
	setStakedDaycareRewardState,
} from '../../../../redux/actions/rewardsAndStaking';
import PendingRewards from '../../../PendingRewards';

const SidebarRewards = (): JSX.Element => {
	const dispatch = useDispatch();
	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite
	);
	const {
		daycare,
		isDaycareOpen,
		hideout,
		isHideoutRewardsOpen,
		hideoutRewardState,
		daycareRewardState,
		checkInState,
		checkOutState,
	} = useSelector(
		(state: RootReducerStateInterface) => state.rewards_and_staking
	);
	const { stakedDaycare, notStakedDaycare } = daycareRewardState;
	const { skvllpvnkz } = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz
	);
	const ownedSkvllbabiezList = useSelector(
		(state: RootReducerStateInterface) => state.skvllbabiez.ownedSkvllbabiezList
	);
	const { not_staked_babiez, staked_babiez } = ownedSkvllbabiezList;

	const handleHideoutCollectRewards = async () => {
		if (hideout && ammolite && skvllpvnkz) {
			dispatch(setHideoutRewardState(TransactionState.IN_PROGRESS));
			const ids = await skvllpvnkz.fetchSkvllpvnkIdsOfWallet();
			await hideout.collectBatchSkvllpvnkzReward(ids);
		}
	};
	const handleDaycareCollectRewards = async () => {
		if (daycare && not_staked_babiez && staked_babiez && ammolite) {
			batch(() => {
				dispatch(setStakedDaycareRewardState(TransactionState.IN_PROGRESS));
				dispatch(setNotStakedDaycareRewardState(TransactionState.IN_PROGRESS));
			});
			await daycare.collectAllBabyReward(
				not_staked_babiez.concat(staked_babiez).map((b) => b.id)
			);
		}
	};

	const transactionDefaultState = TransactionState.DEFAULT;

	const isHideoutCollectButtonDisabled =
		hideoutRewardState !== transactionDefaultState || !isHideoutRewardsOpen;

	const isDaycareCollectButtonDisabled =
		notStakedDaycare !== transactionDefaultState ||
		stakedDaycare !== transactionDefaultState ||
		checkInState !== transactionDefaultState ||
		checkOutState !== transactionDefaultState ||
		!isDaycareOpen;

	return (
		<>
			<SidebarDetails marginTop="26px" marginBottom="14px">
				<b>HIDEOUT REWARDS</b>
				<ButtonWrapper marginBottom="0px" gap="20px">
					<b className="balance-text">
						<PendingRewards isHideoutRewards={true} decimalPrecision={2} />
					</b>
					<CustomButton
						width="80px"
						height="34px"
						borderRadius="5px"
						fontSize="12px"
						data-tip="Claim the Ammolite rewards from all your Skvllpvnkz"
						data-delay-show="1000"
						disabled={isHideoutCollectButtonDisabled}
						onClick={handleHideoutCollectRewards}
					>
						<ReactTooltip />
						<b>Claim</b>
					</CustomButton>
				</ButtonWrapper>
			</SidebarDetails>
			<hr />
			<SidebarDetails marginTop="26px" marginBottom="14px">
				<b>DAYCARE STAKING</b>
				<ButtonWrapper marginBottom="0px" gap="20px">
					<b className="balance-text">
						<PendingRewards decimalPrecision={2} />
					</b>
					<CustomButton
						width="80px"
						height="34px"
						borderRadius="5px"
						fontSize="12px"
						data-tip="Claim the Ammolite rewards from all your Skvllbabiez staked in Daycare"
						data-delay-show="1000"
						disabled={isDaycareCollectButtonDisabled}
						onClick={handleDaycareCollectRewards}
					>
						<ReactTooltip />
						<b>Claim</b>
					</CustomButton>
				</ButtonWrapper>
			</SidebarDetails>
			<hr />
		</>
	);
};

export default SidebarRewards;
