import React from 'react';
import { batch, useDispatch } from 'react-redux';
import {
	setIsStoreModalOpen,
	setSelectedItem,
} from '../../../../../redux/actions/store';
import {
	BUTTON_EXPIRED,
	BUTTON_PRIMARY_MAIN,
	BUTTON_PRIMARY_PURPLE,
} from '../../../../../resources/constants/colors';
import StoreItemInterface from '../../../../../resources/interfaces/StoreItemInterface';
import { ButtonWrapper } from '../../../../../resources/styles/ButtonWrappers.style';
import CustomButton from '../../../../../resources/styles/CustomButton.styles';
import { SidebarDetails } from '../../../../../resources/styles/Sidebar.style';

interface PropsInterface {
	item: StoreItemInterface;
	isReserved?: boolean;
	isExpired?: boolean;
}

const StoreItem = (props: PropsInterface): JSX.Element => {
	const { item, isReserved, isExpired } = props;
	const dispatch = useDispatch();

	const handleShowModal = async () => {
		batch(() => {
			dispatch(setSelectedItem(item));
			dispatch(setIsStoreModalOpen(true));
		});
	};

	const reservedButtonColor = isExpired
		? BUTTON_EXPIRED
		: BUTTON_PRIMARY_PURPLE;
	const buttonColor = isReserved ? reservedButtonColor : BUTTON_PRIMARY_MAIN;
	const reservedButtonText = isExpired ? 'Expired' : 'Needs Payment';
	const buttonText = isReserved ? reservedButtonText : 'View';

	return (
		<div>
			<SidebarDetails marginTop="26px" marginBottom="14px">
				<b>{item?.name}</b>
				<ButtonWrapper marginBottom="0px" gap="20px">
					<CustomButton
						width="110px"
						height="34px"
						borderRadius="5px"
						fontSize="12px"
						disabled={isExpired}
						onClick={handleShowModal}
						backgroundColor={buttonColor}
					>
						<b>{buttonText}</b>
					</CustomButton>
				</ButtonWrapper>
			</SidebarDetails>
			<hr />
		</div>
	);
};

export default StoreItem;
