import { BigNumber, ethers } from 'ethers';
import config from '../config';
import ammoliteABI from '../contracts/ammoliteABI.json';
import { STORE_SPEND_LIMIT } from '../resources/constants/limits';
import Wallet from './Wallet';

export default class Ammolite {
	private contract;
	private provider;
	private wallet;

	constructor(wallet: Wallet) {
		this.wallet = wallet;
		this.provider = wallet.library;
		this.contract = new ethers.Contract(
			config.AMMOLITE_CONTRACT_ADDRESS,
			ammoliteABI,
			this.provider.getSigner()
		);
	}

	public async checkAllowance(
		spender = config.STORE_CONTRACT_ADDRESS
	): Promise<BigNumber> {
		const allowance = await this.contract.allowance(
			this.wallet.address,
			spender
		);
		return allowance;
	}

	public async increaseAllowance(
		amount: number | BigNumber,
		spender = config.STORE_CONTRACT_ADDRESS
	): Promise<boolean> {
		const bigAmount = ethers.BigNumber.from(String(amount));
		const allowance = await this.contract.allowance(
			this.wallet.address,
			spender
		);
		if (allowance.gte(bigAmount)) return true;
		const finalIncreaseAmount = bigAmount.sub(allowance);
		const gasEstimate = await this.contract.estimateGas.increaseAllowance(
			spender,
			bigAmount
		);
		const result = await this.contract.increaseAllowance(
			spender,
			finalIncreaseAmount,
			{
				gasLimit: gasEstimate,
			}
		);
		return (await this.provider.waitForTransaction(result.hash)).status === 1
			? true
			: false;
	}

	public async approveSpender(
		spender = config.STORE_CONTRACT_ADDRESS,
		amount = STORE_SPEND_LIMIT
	): Promise<boolean> {
		const bigAmount = ethers.BigNumber.from(amount);
		const gasEstimate = await this.contract.estimateGas.approve(
			spender,
			bigAmount
		);
		const result = await this.contract.approve(spender, bigAmount, {
			gasLimit: gasEstimate,
		});
		return (await this.provider.waitForTransaction(result.hash)).status === 1
			? true
			: false;
	}

	public async fetchBalanceOfWallet(): Promise<number> {
		return Number(
			ethers.utils.formatUnits(
				await this.contract.balanceOf(this.wallet.address),
				18
			)
		);
	}
}
