import React from 'react';
import { TEXT_WHITE } from '../../resources/constants/colors';
import {
	Ammolite,
	ItemSupply,
	ItemPrice,
	StoreItem,
	StoreItemDetails,
	StoreItemPrice,
	StoreItemTitle,
	ImageWrapper,
	StoreItemSubtitle,
} from '../../resources/styles/Store/Store.styles';
import AmmoliteIcon from '../../resources/images/icons/AmmoliteIcon.png';
import { batch, useDispatch } from 'react-redux';
import LazyLoad from 'react-lazyload';
import StoreItemInterface from '../../resources/interfaces/StoreItemInterface';
import {
	setIsStoreModalOpen,
	setSelectedItem,
} from '../../redux/actions/store';
import LazyLoadSpinner from '../LazyLoadSpinner';
import { formatUnits } from 'ethers/lib/utils';

interface PropsInterface {
	item: StoreItemInterface;
	callbackGenerateMedia: (nft: StoreItemInterface) => JSX.Element;
}

const StoreItemCard = (props: PropsInterface): JSX.Element => {
	const { item, callbackGenerateMedia } = props;
	const { price, maxSupply, totalSupply, name, project } = item;
	const dispatch = useDispatch();

	return (
		<LazyLoad
			throttle
			debounce
			unmountIfInvisible
			offset={500}
			placeholder={<LazyLoadSpinner marginBottom="20px" />}
		>
			<StoreItem
				shadowColor="10,10,10"
				onClick={() => {
					batch(() => {
						dispatch(setSelectedItem(item));
						dispatch(setIsStoreModalOpen(true));
					});
				}}
			>
				<ImageWrapper>{callbackGenerateMedia(item)}</ImageWrapper>

				<StoreItemDetails>
					<StoreItemTitle color={TEXT_WHITE}>{name}</StoreItemTitle>
					<StoreItemSubtitle color={TEXT_WHITE}>{project}</StoreItemSubtitle>
					<StoreItemPrice>
						{
							<ItemSupply>
								<span id="purple-text">
									{totalSupply} of {maxSupply}
								</span>
							</ItemSupply>
						}
						<ItemPrice>
							<Ammolite
								src={AmmoliteIcon}
								alt="ammolite"
								height="14px"
							></Ammolite>
							<b>{parseInt(formatUnits(price, 18)).toString() || 0}</b>
						</ItemPrice>
					</StoreItemPrice>
				</StoreItemDetails>
			</StoreItem>
		</LazyLoad>
	);
};

export default React.memo(StoreItemCard);
