import { TEXT_GRAYED_OUT } from './../../constants/colors';
import styled from 'styled-components';
import {
	BUTTON_SECONDARY,
	FILTER_MAIN,
	HIGHLIGHT_COLOR,
	PRIMARY_MAIN,
	TERTIARY_MAIN,
	TEXT_WHITE,
	TEXT_SECONDARY_GREEN,
	ORANGE,
} from '../../constants/colors';
import ScrollbarArrowUp from '../../images/scrollbar/scrollbar-arrow-up.png';
import ScrollbarArrowDown from '../../images/scrollbar/scrollbar-arrow-down.png';

export const Title = styled.div`
	margin: auto;
	margin-top: 40px;
	margin-bottom: 21px;
	font-weight: 600;
	font-size: 16px;
`;

interface PropsInterface {
	isLotteryOpen?: boolean;
}

export const RafflePageTableContainer = styled.div<PropsInterface>`
	font-size: 13px;
	opacity: 0.95;
	max-width: 1400px;
	width: 90%;
	padding: 0 15px;
	margin: auto;
	margin-bottom: 71px;
	color: ${TEXT_WHITE};

	@media screen and (min-width: 768px) {
		overflow: auto;
	}

	img {
		border-radius: 50%;
	}

	table {
		width: 100%;
		@media screen and (min-width: 768px) {
			background-color: ${HIGHLIGHT_COLOR};
		}
	}

	.table-top {
		margin-block-end: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 768px) {
			justify-content: space-between;
		}

		.portal-desktop {
			display: none;
			@media screen and (min-width: 768px) {
				display: block;
			}
		}
	}

	tr > td {
		padding: 16px;
		margin-block-end: 0.2rem;

		p {
			margin: 0;
		}
	}

	tr > td div {
		padding-inline-start: 16px;
	}

	.prize {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 5px;
	}

	.entryId {
		text-align: ${(props) => (props.isLotteryOpen ? 'left' : 'right')};
	}

	.mobile {
		display: flex;
		flex-direction: column;
		background-color: ${HIGHLIGHT_COLOR};
		gap: 0.6rem;
		padding-block: 1.05rem;

		td {
			padding-block: 0.7rem;
			border-bottom: 1px solid ${PRIMARY_MAIN};

			:last-child {
				border-bottom: none;
			}
		}

		@media screen and (min-width: 768px) {
			background-color: ${TERTIARY_MAIN};
			display: none;
		}
	}

	.table-wrapper {
		display: flex;
		flex-direction: row;
		margin-block-end: 1rem;
		background-color: ${TERTIARY_MAIN} !important;

		> *:first-child {
			flex: 1 1 30%;
			text-align: left;
		}

		> *:last-child {
			flex: 1 1 70%;
			text-align: left;
		}

		.table-row > td {
			text-align: center;
			border-bottom: 1px solid ${PRIMARY_MAIN};
			:last-child {
				border-bottom: none;
			}
		}

		@media screen and (min-width: 768px) {
			border-bottom: 1px solid ${PRIMARY_MAIN};
			margin-block-end: unset;

			.table-row > td {
				border: none;
			}
		}
	}

	.table-entries {
		cursor: pointer;
		padding-block: 0.45rem;
	}

	.table-row {
		background-color: ${TERTIARY_MAIN};
		@media screen and (min-width: 768px) {
			background-color: unset;
		}

		@media screen and (min-width: 768px) {
			td:first-child {
				width: 12rem;
			}
		}
	}

	.center-text {
		text-align: center;
	}

	.entries-table-head {
		display: none;
		@media screen and (min-width: 768px) {
			display: table;
		}
	}

	.ammo-gem {
		@media screen and (min-width: 768px) {
			display: flex;
			gap: 0.4rem;
			padding-block-start: 1.5rem;
		}
	}

	.image-left {
		margin-inline-end: 0.5rem;
	}

	.eth-left {
		width: 10px;
		height: 17px;
	}

	tr {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		@media screen and (min-width: 768px) {
			display: table;
			width: 100%;
			min-width: 480px;
			table-layout: fixed;
		}
	}

	thead tr {
		background-color: ${HIGHLIGHT_COLOR} !important;
		width: 100%;
		border: 0;
		text-align: left;
		@media screen and (min-width: 768px) {
			td:first-child {
				width: 12rem;
			}
		}
	}

	tbody {
		display: block;
		overflow-y: auto;
		overflow-x: hidden;

		@media screen and (min-width: 768px) {
			.table-wrapper:nth-child(even) {
				background-color: ${HIGHLIGHT_COLOR} !important;
			}
		}

		@media screen and (min-width: 768px) {
			.table-wrapper:nth-child(even) {
				background-color: ${HIGHLIGHT_COLOR} !important;
			}
		}

		::-webkit-scrollbar {
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-track {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-corner {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-thumb {
			background-color: ${BUTTON_SECONDARY};
			border-radius: 2px;
		}

		::-webkit-scrollbar-button:single-button {
			background-color: ${TERTIARY_MAIN};
			display: block;
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-button:vertical:increment {
			background-image: url(${ScrollbarArrowDown});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}

		::-webkit-scrollbar-button:vertical:decrement {
			background-image: url(${ScrollbarArrowUp});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.indicator {
		display: inline-block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background-color: ${TEXT_SECONDARY_GREEN};
		margin-inline-end: 0.3rem;
	}

	.reserved {
		background-color: ${ORANGE};
	}

	.expired {
		background-color: ${TEXT_GRAYED_OUT};
	}
`;

RafflePageTableContainer.defaultProps = {
	isLotteryOpen: true,
};
