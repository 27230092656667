import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { batchedSubscribe } from 'redux-batched-subscribe';

const initialState = {};
const middlewares = [thunk];

const enhancer = compose(
	applyMiddleware(...middlewares),
	batchedSubscribe((notify) => {
		notify();
	})
);

export default createStore(
	rootReducer,
	initialState,
	composeWithDevTools(enhancer)
);
