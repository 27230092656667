const config = {
	OPENSEA_API_KEY: process.env.REACT_APP_OPENSEA_API_KEY as string,

	AUTH_SIGNATURE_LABEL: process.env.REACT_APP_AUTH_SIGNATURE_LABEL as string,
	BASE_FUNCTION_GAS_COST: Number(process.env.REACT_APP_BASE_FUNCTION_GAS_COST),
	COINBASE_INFURA_URL: `https://${process.env.REACT_APP_COINBASE_INFURA_DOMAIN}/v3/${process.env.REACT_APP_COINBASE_INFURA_PROJECT}`,
	FUNCTION_GAS_COST: Number(process.env.REACT_APP_FUNCTION_GAS_COST),
	GAS_LIMIT_ALLOWANCE_MULTIPLIER: Number(
		process.env.REACT_APP_GAS_LIMIT_ALLOWANCE_MULTIPLIER
	),

	SKVLLBABIEZ_CONTRACT_ADDRESS: process.env
		.REACT_APP_SKVLLBABIEZ_CONTRACT_ADDRESS as string,
	SKVLLPVNKZ_CONTRACT_ADDRESS: process.env
		.REACT_APP_SKVLLPVNKZ_CONTRACT_ADDRESS as string,
	AMMOLITE_CONTRACT_ADDRESS: process.env
		.REACT_APP_AMMOLITE_CONTRACT_ADDRESS as string,
	DAYCARE_CONTRACT_ADDRESS: process.env
		.REACT_APP_DAYCARE_CONTRACT_ADDRESS as string,
	HIDEOUT_REWARD_ADDRESS: process.env
		.REACT_APP_HIDEOUT_CONTRACT_ADDRESS as string,
	TOKENS_PER_MINT: Number(process.env.REACT_APP_TOKENS_PER_MINT),
	IS_ON_DEV_ENV: !!Number(process.env.REACT_APP_IS_ON_DEV_ENV),
	USE_MANUAL_GAS_LIMIT: !!Number(process.env.REACT_APP_USE_MANUAL_GAS_LIMIT),
	USE_CAPTCHA: !!Number(process.env.REACT_APP_USE_CAPTCHA),
	CAPTCHA_KEY: process.env.REACT_APP_CAPTCHA_KEY as string,
	BASE_URL_API: process.env.REACT_APP_BASE_URL_API as string,

	// Manual Gas estimates
	// Hideout rewards
	COLLECT_REWARD_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_COLLECT_REWARD_FUNCTION_GAS_COST
	),
	COLLECT_REWARDS_BATCH_BASE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_COLLECT_REWARDS_BATCH_BASE_FUNCTION_GAS_COST
	),
	COLLECT_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_COLLECT_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST
	),
	// Daycare rewards
	COLLECT_DAYCARE_REWARDS_BATCH_BASE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_COLLECT_DAYCARE_REWARDS_BATCH_BASE_FUNCTION_GAS_COST
	),
	COLLECT_DAYCARE_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST: Number(
		process.env
			.REACT_APP_COLLECT_DAYCARE_REWARDS_BATCH_VARIABLE_FUNCTION_GAS_COST
	),
	// Daycare staking
	CHECKIN_DAYCARE_BASE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_CHECKIN_DAYCARE_BASE_FUNCTION_GAS_COST
	),
	CHECKIN_DAYCARE_VARIABLE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_CHECKIN_DAYCARE_VARIABLE_FUNCTION_GAS_COST
	),
	CHECKOUT_DAYCARE_BASE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_CHECKOUT_DAYCARE_BASE_FUNCTION_GAS_COST
	),
	CHECKOUT_DAYCARE_VARIABLE_FUNCTION_GAS_COST: Number(
		process.env.REACT_APP_CHECKOUT_DAYCARE_VARIABLE_FUNCTION_GAS_COST
	),
	// Store
	STORE_CONTRACT_ADDRESS: process.env
		.REACT_APP_STORE_CONTRACT_ADDRESS as string,
	// Lottery
	LOTTERY_CONTRACT_ADDRESS: process.env
		.REACT_APP_LOTTERY_CONTRACT_ADDRESS as string,
	USE_LOCAL_FUNCTIONS_ENDPOINT: !!Number(
		process.env.REACT_APP_USE_LOCAL_FUNCTIONS_ENDPOINT
	),
};

export default config;
