import styled from 'styled-components';
import {
	PRIMARY_BLACK,
	TEXT_GRAYED_OUT,
	TEXT_SECONDARY_GREEN,
} from '../../constants/colors';
import { MOBILE_DEVICE_WIDTH } from '../../constants/dimensions';

export const RaffleEntryContainer = styled.div`
	padding: 0 0px;
	position: relative;

	#ammo {
		height: 12px;
	}

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		padding: 0 20px;
	}
`;

export const PortalKeyImage = styled.img`
	width: 180px;
	border-radius: 10px;
	// box-shadow: 2px 8px 10px 2px rgba(10, 10, 10, 1);
`;

export const PurchaseEntryAmountDiv = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 11px;

	input {
		width: 85px;
		height: 46px;
		color: ${TEXT_SECONDARY_GREEN};
		background-color: ${PRIMARY_BLACK};
		border: 1px solid ${TEXT_GRAYED_OUT};
		border-radius: 8px;
		text-align: center;

		::-webkit-inner-spin-button,
		::-webkit-outer-spin-button {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
	}
`;

export const AmountArrows = styled.div`
	width: 25px;

	#up {
		transform: rotate(180deg);
	}

	img {
		cursor: pointer;
	}
`;

interface PropsInterface {
	height?: string;
	marginTop?: string;
}

export const LoadingContainer = styled.div<PropsInterface>`
	height: ${(props) => (props.height ? props.height : 'calc(90vh - 110px)')};
	${(props) => props.marginTop && `margin-top: ${props.marginTop};`}
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	img {
		margin: auto;
		margin-bottom: 5px;
	}

	b {
		margin: 0;
		p {
			font-weight: 600 !important;
			font-size: 24px !important;
			margin: 0 0 10px 0;
		}
	}

	p {
		font-weight: 400;
		font-size: 13px;
	}
`;

export const MoreInfoDiv = styled.div`
	max-width: 400px;
	margin: auto;
	margin-bottom: 65px;
`;
