import React, { useCallback, useState } from 'react';
import {
	HIGHLIGHT_COLOR,
	TERTIARY_MAIN,
} from '../../../../resources/constants/colors';
import NFTAssetInterface from '../../../../resources/interfaces/NFTAssetInterface';
import { NFTCardContainerBreed } from '../../../../resources/styles/NFTCard.styles';
import PvnkCard from './PvnkCard';

interface Props {
	allSkvllpvnkz: NFTAssetInterface[];
	handleIncludeCheck: (skvllpvnkID: number) => boolean;
	handleSelectPvnkz: (disabled: boolean, clickedPvnkID: number) => void;
	showUsedPvnkz: boolean;
}

const HorizontalSliderContent = (props: Props): JSX.Element => {
	const {
		allSkvllpvnkz,
		handleIncludeCheck,
		handleSelectPvnkz,
		showUsedPvnkz,
	} = props;

	const [isAllImagesLoaded, setIsAllImagesLoaded] = useState(false);

	const handleImagesLoading = useCallback(
		(index: number) => {
			if (index + 1 === allSkvllpvnkz.length) {
				setIsAllImagesLoaded(true);
			}
		},
		[allSkvllpvnkz]
	);

	return (
		<div>
			{allSkvllpvnkz.map((skvllpvnk, index) => {
				const { id, thumb, virgin } = skvllpvnk;

				const notVirginPvnk = showUsedPvnkz ? 'inline-block' : 'none';

				const isPvnkVisible = skvllpvnk.virgin ? 'inline-block' : notVirginPvnk;
				const backgroundColor = handleIncludeCheck(id)
					? HIGHLIGHT_COLOR
					: TERTIARY_MAIN;
				const isChecked = handleIncludeCheck(id);

				return (
					<NFTCardContainerBreed
						key={id}
						onClick={() => handleSelectPvnkz(!virgin, id)}
						width="220px"
						backgroundColor={backgroundColor}
						disabled={!virgin}
						display={isPvnkVisible}
					>
						<PvnkCard
							isChecked={isChecked}
							thumb={thumb}
							id={id}
							isAllImagesLoaded={isAllImagesLoaded}
							handleImagesLoading={handleImagesLoading}
							index={index}
						/>
					</NFTCardContainerBreed>
				);
			})}
		</div>
	);
};

export default HorizontalSliderContent;
