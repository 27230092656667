import React from 'react';
import {
	DaycareSkvllbabyInfo,
	DetailText,
	NFTImageDiv,
	RankAndAmmoliteDiv,
} from '../../../../resources/styles/NFTCard.styles';
import AmmoliteIcon from '../../../../resources/images/icons/AmmoliteIcon.png';
import Loader from '../../../../components/Loader';

interface PropsInterface {
	isAllImagesLoaded: boolean;
	checked: boolean;
	token_id: number;
	thumb: string;
	rewards: string;
	staked_on: string;
	handleImagesLoading: (index: number) => void;
	index: number;
}

const BabyCardContent = (props: PropsInterface): JSX.Element => {
	const {
		checked,
		token_id,
		thumb,
		rewards,
		isAllImagesLoaded,
		handleImagesLoading,
		index,
	} = props;

	return (
		<>
			<NFTImageDiv>
				<input type="checkbox" checked={checked} readOnly />
				{!isAllImagesLoaded && <Loader size="15px" />}
				<img
					src={thumb}
					alt={token_id.toString()}
					height="100%"
					width="100%"
					style={{
						borderRadius: '5px',
						display: isAllImagesLoaded ? 'block' : 'none',
					}}
					onLoad={() => handleImagesLoading(index)}
				/>
			</NFTImageDiv>
			<DaycareSkvllbabyInfo>
				<b>
					<DetailText style={{ paddingBottom: 15 }}>
						Skvllbaby #{token_id}
					</DetailText>
				</b>
				<RankAndAmmoliteDiv>
					<span className="ammolite">
						<img src={AmmoliteIcon} alt="ammolite" height="15px" /> {rewards}
					</span>
				</RankAndAmmoliteDiv>
			</DaycareSkvllbabyInfo>
		</>
	);
};

export default React.memo(BabyCardContent);
