import {
	LotteryEntryInterface,
	LotteryInterface,
} from '../../resources/interfaces/LotteryInterface';
import LotteryReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/LotteryReducerStateInterface';
import Lottery from '../../services/Lottery';
import {
	ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
	CONNECT_LOTTERY,
	REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS,
	RESET_LOTTERY_STATE,
	SET_ALL_PREVIOUS_WINNERS,
	SET_CURRENT_LOTTERY,
	SET_PREVIOUS_LOTTERY,
	SET_USER_PORTAL_KEYS,
} from '../actions/types/lottery';

const initialState: LotteryReducerStateInterface = {
	lottery: undefined,
	currentLottery: undefined,
	previousLottery: undefined,
	allPreviousWinners: [],
	pendingEntryTransactions: [],
	userPortalKeys: [],
};

interface PayloadInterface {
	lottery: Lottery;
	currentLottery: LotteryInterface;
	previousLottery: LotteryInterface;
	allPreviousWinners: LotteryEntryInterface[];
	pendingEntry: LotteryInterface;
	userPortalKeys: LotteryEntryInterface[];
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const lotteryReducer = (
	state = initialState,
	action: ActionInterface
): LotteryReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_LOTTERY:
			return {
				...state,
				lottery: payload.lottery,
			};
		case SET_CURRENT_LOTTERY:
			return {
				...state,
				currentLottery: payload.currentLottery,
			};
		case SET_PREVIOUS_LOTTERY:
			return {
				...state,
				previousLottery: payload.previousLottery,
			};
		case SET_ALL_PREVIOUS_WINNERS:
			return {
				...state,
				allPreviousWinners: payload.allPreviousWinners,
			};
		case SET_USER_PORTAL_KEYS:
			return {
				...state,
				userPortalKeys: payload.userPortalKeys,
			};
		case ADD_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS:
			return {
				...state,
				pendingEntryTransactions: [
					...state.pendingEntryTransactions,
					payload.pendingEntry,
				],
			};
		case REMOVE_LOTTERY_ENTRY_PURCHASE_TO_PENDING_TRANSACTIONS:
			return {
				...state,
				pendingEntryTransactions: state.pendingEntryTransactions.filter(
					(entry) => entry.id != payload.pendingEntry.id
				),
			};
		case RESET_LOTTERY_STATE:
			return initialState;
		default:
			return state;
	}
};

export default lotteryReducer;
