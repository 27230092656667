import { useWeb3React } from '@web3-react/core';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import ChainIds from '../resources/enums/ChainIds';
import { Collections } from '../resources/enums/Collections';
import { SiteRoutes } from '../resources/enums/SiteRoutes';
import RootReducerStateInterface from '../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import CustomButton from '../resources/styles/CustomButton.styles';
import {
	MainnetWarningMessage,
	PageContainer,
} from '../resources/styles/Page.styles';

const LandingPage = (): JSX.Element => {
	const navigate = useNavigate();
	const web3Context = useWeb3React();
	const { chainId } = web3Context;
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);

	useEffect(() => {
		navigate('/');
	}, []);

	const isAllowed =
		chainId === ChainIds.MAINNET || config.IS_ON_DEV_ENV || !isAuthenticated;

	return (
		<PageContainer noBackgroundImage>
			<div id="video-container">
				<MainnetWarningMessage isVisible={!isAllowed}>
					Switch to Ethereum Mainnet to enter the Hideouts
				</MainnetWarningMessage>
				<div id="overlay-button-border">
					<p id="overlay-logo">{skvllpvnkzLogoSVG}</p>
					<CustomButton
						borderWidth="0px"
						borderRadius="50px"
						disabled={!isAllowed}
						onClick={() =>
							navigate(
								`${
									SiteRoutes.discover
								}/${Collections.skvllpvnkz_hideout.replace('_', '-')}`
							)
						}
						backgroundColor="white"
						marginTop="30px"
						fontSize="12px"
					>
						<b>ENTER THE HIDEOUT</b>
					</CustomButton>
				</div>
				<video autoPlay muted loop>
					<source
						src="https://storage.googleapis.com/skvllz-public/Landing_site_Smallsize.mp4"
						type="video/mp4"
					/>
				</video>
			</div>
		</PageContainer>
	);
};

export default LandingPage;

const skvllpvnkzLogoSVG = (
	<svg
		width="300"
		viewBox="0 0 196 38"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M5.17435 0.00195312L4.11072 5.99446H10.4677L10.4702 5.98951H20.0428L21.1015 0.00195312H5.17435Z"
			fill="white"
		/>
		<path
			d="M10.2297 7.33838H3.87269L1.99585 17.9275H11.5685L10.552 23.8754H1.05867L0 29.8233H15.9271L19.066 11.94H9.40901L10.2297 7.33838Z"
			fill="white"
		/>
		<path
			d="M22.3957 15.3911L19.8395 29.8232H26.2758L28.8344 15.3911H22.3957Z"
			fill="white"
		/>
		<path
			d="M41.0624 0.00195312H34.6658L30.1039 8.06717L31.5295 0.00195312H23.4643L22.4453 5.90769H24.0742L22.6411 13.9977L29.0849 13.9754H32.3452L28.108 37.8066H34.5047L39.8005 7.94569H36.4608L41.0624 0.00195312Z"
			fill="white"
		/>
		<path
			d="M67.5961 29.8232L71.8333 5.91017H70.1622L71.1812 0.00195312H79.2886L75.0936 23.8332H83.1191L82.0605 29.8207H67.5961V29.8232Z"
			fill="white"
		/>
		<path
			d="M84.5423 29.8232L88.7794 5.91017H87.1084L88.1274 0.00195312H96.2347L92.0397 23.8332H100.065L99.0066 29.8207H84.5423V29.8232Z"
			fill="white"
		/>
		<path
			d="M105.217 7.50928L101.285 29.8231H107.764L111.686 7.50928H105.217Z"
			fill="white"
		/>
		<path
			d="M106.541 0.00195312L105.495 5.93L112.38 5.94984H115.055L113.302 15.9316H111.755L110.697 21.8795H118.601L122.468 0.00195312H106.541Z"
			fill="white"
		/>
		<path
			d="M124.792 0.00195312H131.106L131.067 14.7911L135.914 0.00195312H140.885L130.782 29.8232H124.467L124.792 0.00195312Z"
			fill="white"
		/>
		<path
			d="M143.002 0.00195312H149.275L150.904 9.90187L152.657 0.00195312H159.053L153.797 29.8232H147.4L148.174 25.3827L146.627 15.8498L144.182 29.8232H137.746L143.002 0.00195312Z"
			fill="white"
		/>
		<path
			d="M160.352 15.1309L157.749 29.8233H164.185L166.791 15.1309H160.352Z"
			fill="white"
		/>
		<path
			d="M178.972 0.00195312H172.578L168.013 8.06717L169.442 0.00195312H161.374L160.355 5.90769H161.986L160.553 13.9927L166.997 13.9754H170.255L166.018 37.8066H172.414L177.71 7.94569H174.37L178.972 0.00195312Z"
			fill="white"
		/>
		<path
			d="M181.417 0.00195312H196L194.941 5.98951L183.698 23.8728H191.723L190.665 29.8207H176.081L177.14 23.8728L188.465 5.98951H180.358L181.417 0.00195312Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M52.0806 21.2719C52.2591 19.9876 53.3326 18.3761 53.7591 17.7662V17.7637C53.8756 17.5951 53.9425 17.3943 53.95 17.1786L54.1681 10.4175C54.1731 10.2637 54.126 10.1175 54.0392 10.0034C53.5161 9.32656 52.9855 8.65218 52.4599 7.98525C52.264 7.73979 52.1723 7.50178 52.1971 7.06046C52.2244 6.5646 52.3111 3.97619 52.4128 0.889439L52.4227 0.624152C52.4351 0.217544 52.1053 -0.0799741 51.7508 0.0191987C51.0963 0.205148 50.3698 0.477872 49.6186 0.889439C48.5748 1.45224 47.4765 2.27538 46.455 3.47041C42.8624 7.66046 42.9988 11.8902 42.9988 11.8902C42.9988 11.8902 43.0236 12.5249 43.0682 13.2216C43.098 13.715 43.1377 14.2406 43.1922 14.5902C43.217 14.7463 43.2467 14.9819 43.2765 15.2695C43.4153 16.5463 43.5988 18.8422 43.4947 19.5959C43.3633 20.5207 41.6997 22.8289 44.06 24.2149C46.4277 25.6008 46.5318 25.3157 47.1294 24.6066C47.7244 23.8926 48.9169 23.2554 48.7707 24.7826C48.6616 25.9033 47.7814 28.0628 49.864 29.1561C50.5607 29.5231 51.5897 29.771 53.0896 29.8181C53.3301 29.8256 53.5458 29.6099 53.5533 29.3347L53.5582 29.1537L53.7838 22.405C53.7417 22.3802 53.6971 22.3678 53.645 22.3678C51.9318 22.3281 52.0359 21.4975 52.0657 21.3413C52.0706 21.324 52.0756 21.2992 52.0806 21.2719ZM52.1772 15.1976C52.088 15.2149 52.0012 15.2422 51.9244 15.272C51.612 15.3934 51.3814 15.6141 51.2004 15.9364C50.7541 16.7645 49.9731 16.9405 49.1525 17.0174C48.9393 17.0372 48.7533 17.062 48.5426 17.062C48.2252 17.0348 47.8707 17.0248 47.5608 16.9777C46.45 16.7943 45.7161 16.2091 45.436 15.272C45.3442 14.9868 45.3021 14.667 45.2996 14.3174C45.2971 13.953 45.3244 13.586 45.369 13.224C45.4013 12.9216 45.4459 12.6191 45.4955 12.3241C45.6195 11.6051 45.9839 11.3596 46.698 11.4365C47.836 11.5629 48.8872 11.9621 49.9384 12.3736C50.5285 12.6042 51.0888 12.8819 51.6169 13.2216C51.907 13.4075 52.1872 13.6158 52.4574 13.8389C52.5814 13.9406 52.7053 14.0571 52.7996 14.191C53.0252 14.5158 52.9161 14.8901 52.5516 15.0637C52.4326 15.1257 52.3037 15.1703 52.1772 15.1976Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M65.7565 23.5107C65.5482 23.7636 65.2606 24.0016 64.8788 24.2173C62.4243 25.6008 62.3375 25.3182 61.7896 24.6091C61.2367 23.8975 60.0838 23.2579 60.1309 24.7851C60.178 26.2777 61.5987 29.6322 55.5987 29.8231C55.3557 29.8305 55.1599 29.6198 55.1648 29.3495L55.2739 22.4124C55.3161 22.3851 55.3632 22.3727 55.4128 22.3727C57.1309 22.3331 57.0789 21.505 57.059 21.3488C57.0541 21.3265 57.0516 21.2992 57.0491 21.2719C56.9524 19.9901 55.9805 18.3761 55.5962 17.7662V17.7637C55.4896 17.5951 55.4351 17.3893 55.4425 17.181L55.6582 10.41C55.6632 10.2563 55.7177 10.11 55.8144 9.99598C56.3846 9.31665 56.9524 8.64475 57.5251 7.98029C57.7334 7.73732 57.8425 7.4993 57.8475 7.06047C57.8549 6.54973 57.9367 3.82743 58.0384 0.629115C58.0508 0.222507 58.4003 -0.0725323 58.7474 0.0266405C59.5309 0.252258 60.4185 0.60928 61.316 1.18696C61.5565 1.3382 61.6854 1.64316 61.6408 1.95555L60.8995 7.04807L60.8722 7.2365C60.7978 7.75715 61.192 8.18608 61.6532 8.09186L64.6011 7.48195C65.0697 7.38526 65.4664 7.82905 65.3796 8.34971L65.3176 8.73648L64.9061 11.0051C64.8416 11.4018 64.5267 11.6968 64.1697 11.6968H58.8392C58.4103 11.6968 58.0012 11.9522 57.7755 12.3687C57.4012 13.0455 57.0962 13.6034 56.8458 14.067C56.2954 15.0761 56.9673 16.6182 58.0879 16.6182H63.2028C63.6441 16.6182 63.9689 17.0546 63.8895 17.5455L63.6937 18.7653C63.6614 18.9637 63.6986 19.1695 63.8003 19.3356L65.8284 22.5413C66.0094 22.8339 65.9796 23.238 65.7565 23.5107Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M64.7746 13.4297H59.4243C59.2011 13.4297 59.0152 13.6206 59.0078 13.8537L58.983 14.6371C58.9755 14.8727 59.1491 15.0611 59.3722 15.0611H64.7251C64.9482 15.0611 65.1341 14.8702 65.1391 14.6371L65.1614 13.8537C65.1713 13.6206 64.9978 13.4297 64.7746 13.4297Z"
			fill="#1CA595"
		/>
	</svg>
);
