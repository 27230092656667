import React from 'react';
import {
	Code,
	GreenLink,
	UsernameIcon,
} from '../../../../resources/styles/RafflePage/RafflePage.styles';
import Identicon from 'react-identicons';
import { ExternalRoutes } from '../../../../resources/enums/SiteRoutes';
import { formatAddress } from '../../../../helpers/address';
import { formatDate } from '../../../../helpers/date';

interface PropsInterface {
	date: string;
	portalName: string;
	portalKeyCode: string;
	transactionHash: string[];
	walletAddress: string;
	username: string;
	entryPrice?: number;
	entryCount?: number;
	chanceOfWinning?: number;
	showAllEntries: boolean;
	isLotteryOpen: boolean;
}

const Winner = (props: PropsInterface): JSX.Element => {
	const {
		date,
		portalName,
		portalKeyCode,
		transactionHash,
		walletAddress,
		username,
		entryCount,
		chanceOfWinning,
		showAllEntries,
		isLotteryOpen,
	} = props;

	const tableHeaderMobile = (
		<>
			<td className="entryId">
				<b>Pvnk</b>
			</td>
			<td>
				<b>Date</b>
			</td>
			<td>
				<b>Portal</b>
			</td>
			{(!showAllEntries || !isLotteryOpen) && (
				<td>
					<b>Code</b>
				</td>
			)}
			{showAllEntries && isLotteryOpen && (
				<>
					<td>
						<b>Keys</b>
					</td>
					<td>
						<b>Chance</b>
					</td>
				</>
			)}

			<td>
				<b>Hash</b>
			</td>
		</>
	);

	const tableHeader = (
		<tr className="table-wrapper">
			<tr className="mobile">{tableHeaderMobile}</tr>
			<tr className="table-row">
				<td>
					<UsernameIcon>
						<div>
							<Identicon string={walletAddress} size={20} />
						</div>
						<div>{username?.split('#')[0] || walletAddress}</div>
					</UsernameIcon>
				</td>
				<td>{formatDate(new Date(date))}</td>
				<td>{portalName}</td>
				{(!showAllEntries || !isLotteryOpen) && <Code>{portalKeyCode}</Code>}

				{showAllEntries && isLotteryOpen && (
					<>
						<td>{entryCount}</td>
						<td>{chanceOfWinning?.toFixed(2)} %</td>
					</>
				)}
				<td style={{ wordWrap: 'break-word' }}>
					{transactionHash.map((txHash) => {
						return (
							<GreenLink
								key={txHash}
								href={`${ExternalRoutes.etherscanTx}${txHash}`}
							>
								{formatAddress(txHash)}
								<br></br>
							</GreenLink>
						);
					})}
				</td>
			</tr>
		</tr>
	);

	return <>{tableHeader}</>;
};

export default React.memo(Winner);
