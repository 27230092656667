import { Dispatch } from 'react';
import { Collections } from '../../resources/enums/Collections';
import { FilterTypes } from '../../resources/enums/FilterTypes';
import { OrderByKeys } from '../../resources/enums/OrderByKeys';
import TraitFiltersCollapseInterface from '../../resources/interfaces/TraitFiltersCollapseInterface';
import TraitInterface from '../../resources/interfaces/TraitInterface';
import TraitObjectInterface from '../../resources/interfaces/TraitObjectInterface';
import {
	ADD_SELECTED_TRAIT,
	SET_BUY_NOW_FILTER,
	SET_FILTER_BY_WALLET,
	SET_FILTER_COLLAPSE_STATES,
	SET_ORDER_BY_FILTER,
	SET_SELECTED_COLLECTION,
	SET_SELECTED_TRAITS,
	SET_SPECIFIC_TRAIT_COLLAPSE_STATE,
	SET_TRAITS_ARRAY,
	SET_UP_TRAIT_COLLAPSE_STATES,
	SET_VIRGIN_FILTER,
} from './types/filters';

export const setSelectedCollection =
	(selectedCollection: Collections) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_SELECTED_COLLECTION,
			payload: { selectedCollection },
		});
	};

export const setSelectedTraits =
	(selectedTraits: TraitObjectInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_SELECTED_TRAITS,
			payload: { selectedTraits },
		});
	};

export const addSelectedTrait =
	(selectedTrait: { traitType: string; value: string }) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: ADD_SELECTED_TRAIT,
			payload: { selectedTrait },
		});
	};

export const setVirginFilter =
	(virginFilter: boolean | undefined) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_VIRGIN_FILTER,
			payload: { virginFilter },
		});
	};

export const setBuyNowFilter =
	(buyNowFilter: boolean | undefined) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_BUY_NOW_FILTER,
			payload: { buyNowFilter },
		});
	};

export const setOrderByFilter =
	(orderBy: OrderByKeys) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_ORDER_BY_FILTER,
			payload: { orderBy },
		});
	};

export const setFilterByWallet =
	(walletAddress: string | null) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_FILTER_BY_WALLET,
			payload: { walletAddress },
		});
	};

export const setFilterCollapseStates =
	(collapseFilterType: FilterTypes, isOpen?: boolean) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_FILTER_COLLAPSE_STATES,
			payload: { collapseFilterType, isOpen },
		});
	};

export const setUpTraitCollapseStates =
	(traits: TraitInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		const traitFilterCollapseState: TraitFiltersCollapseInterface[] =
			traits.map((trait) => {
				const filter: TraitFiltersCollapseInterface = {
					trait_type: trait.name,
					isOpen: false,
				};
				return filter;
			});
		dispatch({
			type: SET_UP_TRAIT_COLLAPSE_STATES,
			payload: { traitFilterCollapseState },
		});
	};

export const setSpecificTraitCollapseState =
	(trait: TraitInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_SPECIFIC_TRAIT_COLLAPSE_STATE,
			payload: { trait },
		});
	};

export const setTraitsArray =
	(traitsArray: TraitInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_TRAITS_ARRAY,
			payload: { traitsArray },
		});
	};
