import styled from 'styled-components';
import {
	PRIMARY_MAIN,
	LINE_COLOR,
	SWITCH_BUTTON_ON,
	TEXT_GRAYED_OUT,
	FILTER_MAIN,
	SWITCH_BUTTON_OFF,
} from '../constants/colors';
import ArrowDownImage from '../images/icons/arrow-down.png';
import SearchIcon from '../images/icons/search-icon.png';
import CheckedIcon from '../images/icons/Checked.png';

interface Props {
	active?: boolean;
	hasNoLine?: boolean;
	isBackgroundColorActive?: boolean;
	marginLeft?: string;
	marginRight?: string;
	marginBottom?: string;
	paddingRight?: string;
	paddingLeft?: string;
	width?: string;
	fontSize?: string;
	height?: string;
}

export const MainFilterContainer = styled.div<Props>`
	position: sticky;
	top: 70px;
	background-color: ${PRIMARY_MAIN};
	opacity: 0.9;
	border-radius: 10px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	width: 327px;
	height: ${(props) => props.height};
	padding-block-end:2rem;

	scrollbar-width: none;

	::-webkit-scrollbar {
		display: none;
	}

	.close-icon {
		padding-top: 10px;
		padding-bottom: 10px;
		margin-left: 80%;
		cursor: pointer;

		@media screen and (min-width: 990px) {
			display: none;
		}
	}

	.close-icon-container {
		width: 100%;
		height: 30px;
		margin-bottom: 10px;
		background-color: ${PRIMARY_MAIN};
		cursor: pointer;
		@media screen and (min-width: 990px) {
			display: none;
		}
	}

	@media screen and (min-width: 310px) {
		width: 100%;
		height: 100vh;
		top: 0;
		opacity: 1;
	}

	@media screen and (min-width: 990px) {
		top: 70px;
		width: 327px;
		opacity: 0.9;
		height: ${(props) => props.height};
		overflow-y: scroll;
	}

	#traits-content {
		border: 1px solid LINE_COLOR;
		max-height: 300px;
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		::-webkit-scrollbar {
			display: none;
		}
	}

	hr {
		margin: 0;
		color: 1px ${LINE_COLOR} solid;
	}
`;

export const FilterSection = styled.button<Props>`
	text-transform: capitalize;
	font-size: ${(props) => props.fontSize};
	background-color: transparent;
	border-top: none;
	border-bottom: none;
	border-left: none;
	border-right: none;

	.lower-case {
		text-transform: lowercase;
	}

	width: 100%;
	height: 69px;
	padding-left: 20px;
	padding-right: ${(props) => props.paddingRight};
	margin-left: ${(props) => props.marginLeft};

	text-align: left;
	color: ${(props) => (props.active ? TEXT_GRAYED_OUT : 'white')};

	:hover {
		background-color: transparent;
	}
`;

export const TraitCollapseLabel = styled.div<Props>`
	display: grid;
	grid-template-columns: 50% 50%;

	#count {
		text-align: right;
	}
`;

const line = `1px ${LINE_COLOR} solid`;
export const FilterCollapse = styled.div<Props>`
	.filter-collapse {
		cursor: pointer;
		border-bottom: ${(props) => !props.hasNoLine && line};

		#filter-multiselects {
			padding-left: 10px;
		}
		.select {
			padding-bottom: 25px;
			padding-left: 15px;
			padding-right: 10px;
		}
	}
`;

export const FilterCollapseContent = styled.div`
	margin-top: 10px;
	margin-bottom: 20px;
	padding-right: 20px;
	border-bottom: none;
`;

export const FilterParagraph = styled.p<Props>`
	padding-left: ${(props) => props.paddingLeft};
	margin-top: 10px;
`;

export const SwitchButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	div {
		display: flex;
	}
`;

export const SwitchButtonOff = styled.div<Props>`
	Button {
		margin-bottom: 10px;
		height: 46px;
		width: 50px;
		color: ${(props) =>
		props.isBackgroundColorActive ? TEXT_GRAYED_OUT : 'white'};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
		props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
			border-color: ${LINE_COLOR};
		}

		:focus {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? FILTER_MAIN : SWITCH_BUTTON_OFF};
			border-color: LINE_COLOR;
			box-shadow: none;
		}
	}
`;

export const SwitchButtonYes = styled.div<Props>`
	Button {
		margin-bottom: 10px;
		height: 46px;
		width: 50px;
		color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
		border-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
		}

		:focus {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
			box-shadow: none;
		}
	}
`;

export const SwitchButtonNo = styled.div<Props>`
	Button {
		margin-bottom: 10px;
		height: 46px;
		width: 50px;
		color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
		border-color: ${LINE_COLOR};
		background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
		box-shadow: none;

		:hover {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
		}

		:focus {
			background-color: ${(props) =>
		props.isBackgroundColorActive ? SWITCH_BUTTON_ON : FILTER_MAIN};
			color: ${(props) =>
		props.isBackgroundColorActive ? 'black' : TEXT_GRAYED_OUT};
			border-color: ${SWITCH_BUTTON_ON};
			box-shadow: none;
		}
	}
`;

export const FilterSelect = styled.select`
	background-color: ${FILTER_MAIN};
	border-color: ${LINE_COLOR};
	color: white;
	width: 237px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	padding-left: 10px;
	margin-bottom: 14px;
	outline: none;
	appearance: none;
	background: url(${ArrowDownImage}) 94% / 6% no-repeat ${FILTER_MAIN};
`;

export const FilterInput = styled.input<Props>`
	background-color: ${FILTER_MAIN};
	color: white;
	width: 100px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px ${LINE_COLOR} solid;
	outline: none;
	margin-bottom: 20px;
	padding-left: 10px;
	margin-right: ${(props) => props.marginRight};
	margin-left: ${(props) => props.marginLeft};

	::placeholder {
		color: ${TEXT_GRAYED_OUT};
	}
`;

export const FilterSearch = styled.input<Props>`
	background-color: ${FILTER_MAIN};
	color: white;
	width: ${(props) => props.width};
	height: 46px;
	border-radius: 8px;
	border-color: transparent !important;
	font-size: 13px;
	border: 1px ${LINE_COLOR} solid;
	outline: none;
	padding-left: 40px;
	margin-bottom: 10px;
	background: url(${SearchIcon}) 4% / 5% no-repeat ${FILTER_MAIN};

	::placeholder {
		color: ${TEXT_GRAYED_OUT};
	}

	@media screen and (max-width: 768px) {
		width: 150px;
	}

	@media screen and (max-width: 414px) {
		width: 100px;
	}
`;

export const FilterCheckboxWrapper = styled.div`
	cursor: pointer;
	text-transform: capitalize;
	width: 327px;
	padding-left: 20px;
	padding-right: 20px;
	margin-top: 30px;
	margin-bottom: 30px;

	input[type='checkbox'] {
		cursor: pointer;
		appearance: none;
		--webkit-appearance: none;
		height: 18px;
		width: 18px;
		background-color: transparent;
		border: 1.5px solid ${TEXT_GRAYED_OUT};
		float: left;
		border-radius: 4px;
	}

	input[type='checkbox']:checked {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1.5px solid white;
		background: url(${CheckedIcon});
		background-repeat: no-repeat;
		background-size: 100%;
	}

	input[type='checkbox']:disabled {
		border: 1px solid ${FILTER_MAIN};
	}
	#label {
		cursor: pointer;
		padding-left: 10px;
		float: left;
		font-size: 13px;
	}

	#trait {
		cursor: pointer;
		float: right;
		font-size: 13px;
	}
`;
