import styled from 'styled-components';

export const HideoutContainer = styled.div`
	display: flex;
	justify-content: center;

	@media screen and (min-width: 768px) {
		margin-top: 65px;
	}

	#container {
		height: 93%;
		border-radius: 10px;
		box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	}
`;
