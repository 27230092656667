import config from '../../config';

export const tokenParams = {
	type: 'ERC20',
	options: {
		address: config.AMMOLITE_CONTRACT_ADDRESS,
		symbol: 'AMMO',
		decimals: 18,
		image: 'https://storage.googleapis.com/skvllz-public/AmmoLogoTrans.png',
	},
};
