import styled from 'styled-components';
import {
	PRIMARY_MAIN,
	HIGHLIGHT_COLOR,
	TEXT_WHITE,
	TEXT_PURPLE,
} from '../constants/colors';

export const NavBar = styled.nav`
	position: relative;

	.nav-elements {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		position: fixed;
		top: 0;
		background-color: ${PRIMARY_MAIN};
		padding: 1rem 1.5rem;
		z-index: 5;
	}

	.hamburger-section {
		display: flex;
		align-items: center;
		gap: 2rem;
		@media only screen and (min-width: 1024px) {
			gap: unset;
		}
	}

	.hamburger {
		cursor: pointer;
	}

	@media screen and (min-width: 1024px) {
		.hamburger {
			display: none;
		}
	}

	li {
		list-style: none;
		text-align: center;
	}

	.mobile-logo {
		display: block;
		color: white;

		@media only screen and (min-width: 1024px) {
			display: none;
		}
	}

	.desktop-logo {
		display: none;
		@media only screen and (min-width: 1024px) {
			display: block;
		}
	}

	.icon-wrapper {
		display: flex;
		flex-direction: row-reverse;
		gap: 1.5rem;
		color: ${TEXT_WHITE};

		.nav-icon {
		}
	}
`;

interface PropsShow {
	isOpen?: boolean;
}

export const NavMenu = styled.div<PropsShow>`
	position: fixed;
	top: 0;
	background-color: ${PRIMARY_MAIN};
	inset: 0;
	z-index: 10;
	padding-block: 1rem 3rem;

	transform: ${(props) =>
		props.isOpen === false ? 'translateY(-100%)' : 'translateY(0)'};
	transition: transform 0.35s ease-in-out;

	@media screen and (min-width: 1024px) {
		all: unset;
	}

	.close {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-block-end: 2.5rem;
		color: ${TEXT_WHITE};
		cursor: pointer;

		@media screen and (min-width: 1024px) {
			display: none;
		}
	}

	.nav-content-wrapper {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		@media screen and (min-width: 1024px) {
			flex-direction: row;
		}
	}

	.nav-list {
		padding-inline: 1.5rem;
		@media screen and (min-width: 1024px) {
			all: unset;
		}
	}

	.nav-wallet-auth {
		background-color: ${HIGHLIGHT_COLOR};
		width: 100%;
		display: flex;
		justify-content: center;
		border-radius: 0.5rem;
		padding-block: 1rem;
		margin-block-end: 4rem;
		position: relative;
		cursor: pointer;

		.nav-icon {
			display: none;
		}

		@media screen and (min-width: 1024px) {
			display: none;
		}
	}

	.nav-wallet-auth::before {
		content: '>';
		position: absolute;
		color: ${TEXT_WHITE};
		right: 1rem;
		font-size: 1.5rem;
		font-weight: 700;
		top: 22%;
	}

	li .wallet-text:hover,
	li svg:hover,
	li a:hover {
		color: ${TEXT_PURPLE} !important;
	}

	.nav-wallet-auth li {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	.nav-page-link {
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		@media screen and (min-width: 1024px) {
			flex-direction: row;
			gap: unset;
		}
	}

	.nav-icons {
		position: absolute;
		bottom: 2.5rem;

		display: flex;
		gap: 1rem;
		@media screen and (min-width: 1024px) {
			all: unset;
			display: flex;
		}
	}
`;

export const NavIcon = styled.div`
	cursor: pointer;
`;
