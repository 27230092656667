import OpenSeaReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/OpenSeaReducerStateInterface';
import OpenSea from '../../services/OpenSea';
import { CONNECT_OPENSEA } from '../actions/types/opensea';

const initialState: OpenSeaReducerStateInterface = {
	opensea: undefined,
};

interface PayloadInterface {
	opensea: OpenSea;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const skvllpvnkzReducer = (
	state = initialState,
	action: ActionInterface
): OpenSeaReducerStateInterface => {
	const { type, payload } = action;

	switch (type) {
		case CONNECT_OPENSEA:
			return {
				...state,
				opensea: payload.opensea,
			};
		default:
			return state;
	}
};

export default skvllpvnkzReducer;
