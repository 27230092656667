import { getAnalytics } from 'firebase/analytics';
import {
	browserLocalPersistence,
	getAuth,
	setPersistence,
} from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

import { initializeApp } from 'firebase/app';
import firebaseConfig from '../config/firebaseConfig';
import config from '../config';

const firebase = initializeApp(firebaseConfig);

export const analytics = getAnalytics(firebase);
export const auth = getAuth(firebase);
export const functions = getFunctions(firebase);
export const db = getFirestore(firebase);

if (config.USE_LOCAL_FUNCTIONS_ENDPOINT) {
	connectFunctionsEmulator(functions, 'localhost', 5001);
}
setPersistence(auth, browserLocalPersistence);

export default firebase;
