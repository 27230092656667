import styled from 'styled-components';
import {
	BUTTON_PRIMARY_MAIN,
	BUTTON_PRIMARY_LIGHT,
	BUTTON_PRIMARY_DARK,
	TEXT_DARK,
} from '../constants/colors';

interface Props {
	display?: string;
	float?: string;
	marginTop?: string;
	marginBottom?: string;
	width?: string;
	height?: string;
	fontSize?: string;
	marginBottomResponsive?: string;
	marginTopResponsive?: string;
	marginRight?: string;
	marginLeft?: string;
	borderRadius?: string;
	backgroundColor?: string;
	backgroundColorHover?: string;
	backgroundColorActive?: string;
	fontColorHover?: string;
	fontColorActive?: string;
	borderWidth?: string;
	color?: string;
	padding?: string;
	position?: string;
	bottom?: string;
}

const CustomButton = styled.button<Props>`
	display: ${(props) => props.display};
	align-items: center;
	justify-content: space-between;
	border: solid;
	font-size: ${(props) => props.fontSize};
	background-color: ${(props) => props.backgroundColor};
	color: ${(props) => props.color};
	border-width: ${(props) => props.borderWidth};
	background-color: ${(props) => props.backgroundColor};
	box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	color: ${(props) => props.color};
	float: ${(props) => props.float};
	padding: ${(props) => props.padding};
	border-radius: ${(props) => props.borderRadius};
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	margin-top: ${(props) => props.marginTop};
	margin-bottom: ${(props) => props.marginBottom};
	margin-right: ${(props) => props.marginRight};
	margin-left: ${(props) => props.marginLeft};
	margin-left: ${(props) => props.marginLeft};

	#ammolite-icon {
		margin-top: -2px;
	}
	b {
		font-size: 11px;
		display: inline-block;
	}
	:hover {
		background-color: ${(props) =>
			!props.disabled && props.backgroundColorHover};
		color: ${(props) => !props.disabled && props.fontColorHover};
	}

	:active {
		background-color: ${(props) =>
			!props.disabled && props.backgroundColorActive};
		color: ${(props) => !props.disabled && props.fontColorActive};
	}

	@media screen and (max-width: 280px) {
		font-size: 12px;
		margin-left: 5px;
	}

	:disabled {
		opacity: 0.5;
	}

	@media screen and (max-height: 1020px) {
		margin-bottom: ${(props) => props.marginBottomResponsive};
	}

	@media screen and (max-width: 414px) {
		margin-top: ${(props) => props.marginTopResponsive};
	}
`;

CustomButton.defaultProps = {
	display: 'inline-block',
	float: 'none',
	marginTop: '0px',
	marginBottom: '0px',
	marginRight: '0px',
	marginLeft: '0px',
	width: '200px',
	height: '44px',
	fontSize: 'calc((0.9 - 1) * 1.2vw + 1rem)',
	marginBottomResponsive: '0px',
	marginTopResponsive: '0px',
	borderRadius: '300px',
	backgroundColor: `${BUTTON_PRIMARY_MAIN}`,
	backgroundColorHover: `${BUTTON_PRIMARY_LIGHT}`,
	backgroundColorActive: `${BUTTON_PRIMARY_DARK}`,
	borderWidth: '1px',
	color: `${TEXT_DARK}`,
};

export default CustomButton;
