export const SET_SELECTED_COLLECTION = 'SET_SELECTED_COLLECTION';
export const SET_SELECTED_TRAITS = 'SET_SELECTED_TRAITS';
export const ADD_SELECTED_TRAIT = 'ADD_SELECTED_TRAIT';
export const SET_VIRGIN_FILTER = 'SET_VIRGIN_FILTER';
export const SET_BUY_NOW_FILTER = 'SET_BUY_NOW_FILTER';
export const SET_ORDER_BY_FILTER = 'SET_ORDER_BY_FILTER';
export const SET_FILTER_BY_WALLET = 'SET_FILTER_BY_WALLET';

export const SET_FILTER_COLLAPSE_STATES = 'SET_FILTER_COLLAPSE_STATES';
export const SET_UP_TRAIT_COLLAPSE_STATES = 'SET_UP_TRAIT_COLLAPSE_STATES';
export const SET_SPECIFIC_TRAIT_COLLAPSE_STATE =
	'SET_SPECIFIC_TRAIT_COLLAPSE_STATE';
export const SET_TRAITS_ARRAY = 'SET_TRAITS_ARRAY';
