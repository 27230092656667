import { TransactionState } from '../../resources/enums/states';
import RewardsAndStakingReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RewardsAndStakingReducerStateInterface';
import Daycare from '../../services/Daycare';
import Hideout from '../../services/HideoutRewards';
import {
	CONNECT_REWARDS_AND_STAKING,
	SET_CHECKIN_STATE,
	SET_CHECKOUT_STATE,
	SET_STAKED_DAYCARE_REWARDS_STATE,
	SET_HIDEOUT_REWARDS_STATE,
	SET_NOT_STAKED_DAYCARE_REWARDS_STATE,
	RESET_REWARDS_STATE,
} from '../actions/types/daycare';

const initialState: RewardsAndStakingReducerStateInterface = {
	daycare: null,
	isDaycareOpen: undefined,
	hideout: null,
	isHideoutRewardsOpen: undefined,
	checkInState: TransactionState.DEFAULT,
	checkOutState: TransactionState.DEFAULT,
	daycareRewardState: {
		stakedDaycare: TransactionState.DEFAULT,
		notStakedDaycare: TransactionState.DEFAULT,
	},
	hideoutRewardState: TransactionState.DEFAULT,
};

interface PayloadInterface {
	daycare: Daycare;
	hideout: Hideout;
	transactionState: TransactionState;
	isDaycareOpen: boolean;
	isHideoutRewardsOpen: boolean;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const rewardsAndStakingReducer = (
	state = initialState,
	action: ActionInterface
): RewardsAndStakingReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_REWARDS_AND_STAKING:
			return {
				...state,
				daycare: payload.daycare,
				isDaycareOpen: payload.isDaycareOpen,
				hideout: payload.hideout,
				isHideoutRewardsOpen: payload.isHideoutRewardsOpen,
			};
		case SET_CHECKIN_STATE:
			return {
				...state,
				checkInState: payload.transactionState,
			};
		case SET_CHECKOUT_STATE:
			return {
				...state,
				checkOutState: payload.transactionState,
			};
		case SET_STAKED_DAYCARE_REWARDS_STATE:
			return {
				...state,
				daycareRewardState: {
					...state.daycareRewardState,
					stakedDaycare: payload.transactionState,
				},
			};
		case SET_NOT_STAKED_DAYCARE_REWARDS_STATE:
			return {
				...state,
				daycareRewardState: {
					...state.daycareRewardState,
					notStakedDaycare: payload.transactionState,
				},
			};
		case SET_HIDEOUT_REWARDS_STATE:
			return {
				...state,
				hideoutRewardState: payload.transactionState,
			};
		case RESET_REWARDS_STATE:
			return initialState;
		default:
			return state;
	}
};

export default rewardsAndStakingReducer;
