import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { ConnectorNames } from '../resources/enums/ConnectorNames';
import { AbstractConnector } from '@web3-react/abstract-connector';
import config from '.';

const MetaMask = new InjectedConnector({ supportedChainIds: [1, 4] });
const Coinbase = new WalletLinkConnector({
	appName: 'Skvllpvnkz Market',
	darkMode: true,
	supportedChainIds: [1, 4],
	url: config.COINBASE_INFURA_URL,
});

export const ConnectionUrls: { [connectorName in ConnectorNames]: string } = {
	[ConnectorNames.Coinbase]: config.COINBASE_INFURA_URL,
	[ConnectorNames.MetaMask]: 'metamask',
	[ConnectorNames.None]: 'None',
};

export const connectorsByName: {
	[connectorName in ConnectorNames]: AbstractConnector;
} = {
	[ConnectorNames.Coinbase]: Coinbase,
	[ConnectorNames.MetaMask]: MetaMask,
	[ConnectorNames.None]: MetaMask,
};
