import React from 'react';
import { LazyLoadSpinnerDiv } from '../resources/styles/LazyLoadSpinner.style';
import Loader from './Loader';

interface PropsInterface {
	boxShadow?: string;
	marginBottom?: string;
}

const LazyLoadSpinner = (props: PropsInterface): JSX.Element => {
	const { boxShadow, marginBottom } = props;
	return (
		<LazyLoadSpinnerDiv boxShadow={boxShadow} marginBottom={marginBottom}>
			<Loader color="white" size="15px" />
		</LazyLoadSpinnerDiv>
	);
};

export default LazyLoadSpinner;
