import { Dispatch } from 'react';
import ReservedItemInterface from '../../resources/interfaces/ReservedItemsInterface';
import StoreItemBalance from '../../resources/interfaces/StoreItemBalance';
import StoreItemInterface from '../../resources/interfaces/StoreItemInterface';
import Ammolite from '../../services/Ammolite';
import Skvllpvnkz from '../../services/Skvllpvnkz';
import Store from '../../services/Store';
import Wallet from '../../services/Wallet';
import {
	CONNECT_STORE,
	SET_STORE_DATA,
	SET_IS_STORE_MODAL_OPEN,
	SET_STORE_SELECTED_ITEM,
	SET_PURCHASED_ITEMS,
	ADD_ITEM_TO_STORE_DATA,
	UPDATE_STORE_ITEM,
	SET_RESERVED_ITEMS,
	RESET_STORE_STATE,
	ADD_ITEM_TO_PENDING_TRANSACTIONS,
	REMOVE_ITEM_FROM_PENDING_TRANSACTIONS,
} from './types/store';

export const connectStore =
	(
		wallet: Wallet,
		ammolite: Ammolite | undefined,
		skvllpvnkz: Skvllpvnkz | undefined
	) =>
	async (dispatch: Dispatch<unknown>): Promise<void> => {
		if (wallet.library && ammolite && skvllpvnkz) {
			const store = new Store(dispatch, ammolite, skvllpvnkz, wallet);
			dispatch({
				type: CONNECT_STORE,
				payload: { store },
			});
		}
	};

export const setPurchasedItems =
	(purchasedItems: StoreItemBalance[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_PURCHASED_ITEMS,
			payload: { purchasedItems },
		});
	};

export const addItemToPendingTransactions =
	(pendingItem: StoreItemInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: ADD_ITEM_TO_PENDING_TRANSACTIONS,
			payload: { pendingItem },
		});
	};

export const removeItemFromPendingTransactions =
	(pendingItem: StoreItemInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: REMOVE_ITEM_FROM_PENDING_TRANSACTIONS,
			payload: { pendingItem },
		});
	};

export const setReservedItems =
	(reservedItems: ReservedItemInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_RESERVED_ITEMS,
			payload: { reservedItems },
		});
	};

export const setStoreData =
	(storeData: StoreItemInterface[]) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_STORE_DATA,
			payload: { storeData },
		});
	};

export const addNewStoreItem =
	(newStoreItem: StoreItemInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: ADD_ITEM_TO_STORE_DATA,
			payload: { newStoreItem },
		});
	};

export const updateStoreItem =
	(updatedStoreItem: StoreItemInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: UPDATE_STORE_ITEM,
			payload: { updatedStoreItem },
		});
	};

export const setIsStoreModalOpen =
	(isStoreModalOpen: boolean) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_IS_STORE_MODAL_OPEN,
			payload: { isStoreModalOpen },
		});
	};

export const setSelectedItem =
	(selectedItem: StoreItemInterface) =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({
			type: SET_STORE_SELECTED_ITEM,
			payload: { selectedItem },
		});
	};

export const resetStoreState =
	() =>
	(dispatch: Dispatch<unknown>): void => {
		dispatch({ type: RESET_STORE_STATE });
	};
