import React, { useEffect, useState } from 'react';
import { ConnectionUrls, connectorsByName } from '../../config/connectors';
import {
	registerSelectedWalletOption,
	fetchSelectedWalletOption,
} from '../../helpers/session';
import CustomButton from '../../resources/styles/CustomButton.styles';
import MetaMaskLogo from '../../resources/images/logos/metamask-logo.png';
import CoinbaseLogo from '../../resources/images/logos/coinbase-logo.png';

import { useWeb3React } from '@web3-react/core';
import { validateWalletSelected } from '../../helpers/wallet';
import { ButtonWrapper } from '../../resources/styles/ButtonWrappers.style';
import { PageContainer } from '../../resources/styles/Page.styles';
import {
	PRIMARY_MAIN,
	SECONDARY_MAIN,
	TEXT_WHITE,
} from '../../resources/constants/colors';
import { ConnectWalletDiv } from '../../resources/styles/ConnectWalletPage/ConnectWalletPage.styles';
import { ConnectorNames } from '../../resources/enums/ConnectorNames';
import { SiteRoutes } from '../../resources/enums/SiteRoutes';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import Loader from '../../components/Loader';
import Auth from '../../services/Auth';
import { useNavigate } from 'react-router-dom';

interface PropsInterface {
	isChangeWallets?: boolean;
}

const ConnectWalletPage = (props: PropsInterface): JSX.Element => {
	const { isChangeWallets } = props;
	const web3Context = useWeb3React();
	const { activate, active, account, library } = web3Context;
	const navigate = useNavigate();
	const { user } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);
	const isAuthenticated = user?.uid === account;
	const [isLoading, setIsLoading] = useState(false);
	const [isLoginPending, setIsLoginPending] = useState(false);

	const handleLoginSuccess = () => {
		const connectorName: ConnectorNames =
			fetchSelectedWalletOption() as ConnectorNames;
		const isWalletConnected = active && account;
		const isSameAuthenticatedConnector =
			library?.connection?.url === ConnectionUrls[connectorName];

		const isSameSelectedWallet = fetchSelectedWalletOption() === connectorName;

		if (
			isSameSelectedWallet &&
			isSameAuthenticatedConnector &&
			isWalletConnected
		) {
			navigate(SiteRoutes.discover);
		}
	};

	const login = async () => {
		try {
			setIsLoading(true);
			await Auth.loginWithFirebase(account as string, library);
			handleLoginSuccess();
		} catch (err) {
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleWalletAuth = async (connectorName: ConnectorNames) => {
		try {
			validateWalletSelected(connectorName);
			registerSelectedWalletOption(connectorName);
			await activate(connectorsByName[connectorName]);
			setIsLoginPending(true);
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		if (isLoginPending && !isAuthenticated && account && library) {
			login();
			setIsLoginPending(false);
		}
	}, [isLoginPending, isAuthenticated, account, library]);

	useEffect(() => {
		const isWalletConnected = active && account;
		if (isWalletConnected && !isChangeWallets && isAuthenticated) {
			navigate(SiteRoutes.discover);
			logEvent(analytics, 'login', { method: 'Web3', account: account });
		}
	}, [isAuthenticated && active && account]);

	if (isLoading) {
		return (
			<PageContainer>
				<ConnectWalletDiv>
					<div>
						<Loader />
						<br />
						<br />
						<br />
						<h3>Connecting Wallet...</h3>
					</div>
				</ConnectWalletDiv>
			</PageContainer>
		);
	}

	return (
		<PageContainer>
			<ConnectWalletDiv>
				<div>
					<b>
						<p>Connect wallet</p>
					</b>
					<ButtonWrapper marginBottom="0px">
						<CustomButton
							onClick={() => handleWalletAuth(ConnectorNames.MetaMask)}
							display="flex"
							padding="20px"
							width="600px"
							height="84px"
							fontSize="16px"
							borderWidth="0px"
							borderRadius="10px"
							color={TEXT_WHITE}
							backgroundColor={PRIMARY_MAIN}
							backgroundColorHover={SECONDARY_MAIN}
						>
							<div>
								<img
									src={MetaMaskLogo}
									alt="metamask-logo"
									height="50px"
									style={{ marginRight: '17px' }}
								/>
								MetaMask Wallet
							</div>
							{'>'}
						</CustomButton>
					</ButtonWrapper>
					<ButtonWrapper marginBottom="0px">
						<CustomButton
							onClick={() => handleWalletAuth(ConnectorNames.Coinbase)}
							display="flex"
							padding="20px"
							width="600px"
							height="84px"
							fontSize="16px"
							marginTop="15px"
							marginTopResponsive="15px"
							borderWidth="0px"
							borderRadius="10px"
							color={TEXT_WHITE}
							backgroundColor={PRIMARY_MAIN}
							backgroundColorHover={SECONDARY_MAIN}
						>
							<div>
								<img
									src={CoinbaseLogo}
									alt="coinbase-logo"
									height="50px"
									style={{ marginRight: '17px' }}
								/>
								Coinbase Wallet
							</div>
							{'>'}
						</CustomButton>
					</ButtonWrapper>
				</div>
			</ConnectWalletDiv>
		</PageContainer>
	);
};

export default ConnectWalletPage;
