import styled from 'styled-components';
import {
	BUTTON_SECONDARY,
	FILTER_MAIN,
	TERTIARY_MAIN,
	TEXT_GRAYED_OUT,
	TEXT_PURPLE,
	BUTTON_PRIMARY_MAIN,
	TEXT_WHITE,
	LINE_COLOR,
} from '../../../constants/colors';
import ScrollbarArrowUp from '../../../images/scrollbar/scrollbar-arrow-up.png';
import ScrollbarArrowDown from '../../../images/scrollbar/scrollbar-arrow-down.png';

interface PropsInterface {
	selected?: number;
	display?: string;
	padding?: string;
}

export const DaycareTabContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 62px;

	#container {
		height: 93%;
		border-radius: 10px;
		// box-shadow: 2px 2px 38px rgba(0, 0, 0, 0.5);
	}
`;

export const DaycareTabMenuDiv = styled.div<PropsInterface>`
	display: flex;
	flex-direction: column;
	text-align: left;
	font-size: 16px;

	@media screen and (min-width: 768px) {
		background-color: ${TERTIARY_MAIN};
		gap: 40px;
		border-radius: 10px 8px 0px 0px;
		padding-top: 20px;
		padding-left: 25px;
		padding-bottom: 5px;
		text-align: left;
	}

	.daycare-selector {
		display: flex;
		justify-content: center;
		gap: 4rem;
		border-bottom: 1px solid rgba(250, 250, 250, 0.1);
		text-transform: capitalize;

		p {
			padding-block-end: 1rem;
		}

		.daycare {
			color: ${(props) =>
				props.selected === 1 ? TEXT_WHITE : TEXT_GRAYED_OUT};
			border-bottom: ${(props) =>
				props.selected === 1 ? `2px solid ${BUTTON_PRIMARY_MAIN}` : ''};
		}

		.available {
			color: ${(props) =>
				props.selected === 0 ? TEXT_WHITE : TEXT_GRAYED_OUT};
			border-bottom: ${(props) =>
				props.selected === 0 ? `2px solid ${BUTTON_PRIMARY_MAIN}` : ''};
		}

		@media screen and (min-width: 768px) {
			display: none;
		}
	}

	.selection-menu-wrapper {
		display: flex;
		justify-content: center;
		flex-direction: row-reverse;
		gap: 1rem;

		p {
			display: flex;
			flex-direction: column-reverse;
			gap: 0;

			@media screen and (min-width: 768px) {
				all: unset;
			}
		}

		.unit {
			font-size: 45px;
			font-weight: 700;

			@media screen and (min-width: 768px) {
				all: unset;
				margin-left: 0.5rem;
				color: ${TEXT_GRAYED_OUT};
				font-size: 0.85rem;
			}
		}

		@media screen and (min-width: 768px) {
			justify-content: flex-end;
			gap: 2.5rem;
		}
	}

	.daycare-menu-stats {
		width: 160px;
		height: 160px;
		color: ${TEXT_WHITE} !important;
		border-radius: 0.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		@media screen and (min-width: 768px) {
			all: unset;
		}
	}

	.available-stats {
		background-color: ${(props) =>
			props.selected === 0 ? LINE_COLOR : BUTTON_PRIMARY_MAIN};
		@media screen and (min-width: 768px) {
			all: unset;
		}
	}

	.day-care-stats {
		background-color: ${(props) =>
			props.selected === 1 ? LINE_COLOR : TEXT_PURPLE};
		@media screen and (min-width: 768px) {
			all: unset;
		}
	}

	#tab-label {
		color: ${TEXT_PURPLE};
	}

	div {
		p {
			margin: 0;
			text-align: center;
		}

		color: ${TEXT_GRAYED_OUT};
		cursor: pointer;
		margin-bottom: 19px;

		#in-daycare {
			@media screen and (min-width: 768px) {
				color: ${(props) =>
					props.selected === 1 ? TEXT_GRAYED_OUT : TEXT_PURPLE};
			}
		}
		#available {
			@media screen and (min-width: 768px) {
				color: ${(props) =>
					props.selected === 0 ? TEXT_GRAYED_OUT : TEXT_PURPLE};
			}
		}
	}
`;

export const AvailableBabiezDiv = styled.div<PropsInterface>`
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	display: ${(props) => (props.display === 'grid' ? 'flex' : 'none')};
	flex-direction: column;
	gap: 0.5rem;

	::--webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none;
	-ms-overflow-style: none;

	@media screen and (min-width: 768px) {
		max-height: 100%;
		display: ${(props) => props.display};
		flex-wrap: wrap;
		justify-content: space-between;
		grid-template-columns: 100%;
		gap: 10px;
		grid-template-columns: 25% 25% 25% 25%;
		padding-right: 2rem;

		::-webkit-scrollbar {
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-track {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-corner {
			background-color: ${FILTER_MAIN};
		}

		::-webkit-scrollbar-thumb {
			background-color: ${BUTTON_SECONDARY};
			border-radius: 2px;
		}

		::-webkit-scrollbar-button {
			background-color: ${TERTIARY_MAIN};
			display: block;
			height: 10px;
			width: 10px;
		}

		::-webkit-scrollbar-button:vertical:start:increment,
		::-webkit-scrollbar-button:vertical:end:decrement {
			display: none;
		}

		::-webkit-scrollbar-button:vertical:increment {
			background-image: url(${ScrollbarArrowDown});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}

		::-webkit-scrollbar-button:vertical:decrement {
			background-image: url(${ScrollbarArrowUp});
			background-size: 5px 5px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
`;

AvailableBabiezDiv.defaultProps = {
	display: 'none',
};

export const StakeProgressContainer = styled.div<PropsInterface>`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: ${(props) => props.padding};
`;
