import React from 'react';
import { TEXT_PURPLE } from '../../resources/constants/colors';
import { releaseNotes } from '../../resources/placeholders/releaseNotes';
import { PageContainer } from '../../resources/styles/Page.styles';
import {
	ReleaseNotesContainer,
	ReleaseNotesDate,
	ReleaseNotesHeader,
	ReleaseNotesText,
	ReleaseVersion,
} from '../../resources/styles/ReleaseNotes.styles';
import Changes from './components/Changes';

const ReleaseNotesPage = (): JSX.Element => {
	return (
		<PageContainer paddingTop="50px">
			<h1>Release Notes</h1>
			<br></br>
			{releaseNotes.notes.map((release) => {
				const { version, date, description, changes } = release;
				return (
					<ReleaseNotesContainer key={release.version}>
						<ReleaseNotesHeader>
							<ReleaseVersion bgColor={TEXT_PURPLE}>
								<b> {version} </b>
							</ReleaseVersion>
							<ReleaseNotesDate>{date}</ReleaseNotesDate>
						</ReleaseNotesHeader>
						<ReleaseNotesText>
							<p>{description || ''}</p>
							<Changes changes={changes} />
						</ReleaseNotesText>
					</ReleaseNotesContainer>
				);
			})}
		</PageContainer>
	);
};

export default ReleaseNotesPage;
