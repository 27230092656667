import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { TransactionState } from '../../../resources/enums/states';

import Loader from '../../../components/Loader';
import ChooseSkvllpvnkzToMint from './components/ChooseSkvllpvnkzToMint';
import MintProgress from './components/MintProgress';

import {
	FlexDiv,
	MintContentDiv,
	MintDiv,
	MintHeader,
} from '../../../resources/styles/DaycarePage/MintSkvllbabiezTab/MintSkvllbabiezTab.styles';

import WalletNotConnected from '../../../components/WalletNotConnected';
import CantMintBabiez from './components/CantMintBabiez';
import PageHeaderContent from '../../../components/PageHeaderContent';
import { updateSkvllpvnkzList } from '../../../redux/actions/skvllpvnkz';
import bannerDesktop1 from '../../../resources/images/banner/bannerDesktop1.png';
import config from '../../../config/index';
const MintSkvllbabiezTab = (): JSX.Element => {
	const {
		isMintSkvllbabiezLive,
		mintableBabiezCount,
		mintingState,
		totalBabiezMinted,
	} = useSelector((state: RootReducerStateInterface) => state.skvllbabiez);
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);

	const { skvllpvnkz, ownedSkvllpvnkzList } = useSelector(
		(state: RootReducerStateInterface) => state.skvllpvnkz
	);

	const [canMintBabiez, setCanMintBabiez] = useState(false);
	const [mintHeader, setMintHeader] = useState('Loading your skvllpvnkz...');
	const [isLoaded, setIsLoaded] = useState(false);
	const [hasSkvllpvnkz, setHasSkvllpvnkz] = useState(false);
	const [mintState, setMintState] = useState<TransactionState>(
		TransactionState.DEFAULT
	);

	useEffect(() => {
		if (isMintSkvllbabiezLive) {
			if (mintableBabiezCount || ownedSkvllpvnkzList.length > 0) {
				setCanMintBabiez(true);
				setMintHeader('Mint Skvllbabiez');
			}
			if (ownedSkvllpvnkzList) {
				setIsLoaded(false);
				if (ownedSkvllpvnkzList.length > 0) {
					setHasSkvllpvnkz(true);
				}
			}
			if (skvllpvnkz) {
				setIsLoaded(true);
			}
		}
		if (typeof mintingState === 'number') {
			setMintState(mintingState);
		}
	}, [
		mintableBabiezCount,
		isMintSkvllbabiezLive,
		mintingState,
		ownedSkvllpvnkzList,
		skvllpvnkz,
	]);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticated) {
			dispatch(updateSkvllpvnkzList());
		}
	}, [isAuthenticated]);

	const statsData = {
		totalOwners: {
			title: 'Skvllbabiez Minted',
			value: `${totalBabiezMinted ? `${totalBabiezMinted} / ${5000}` : 0}`,
			toolTip: 'Total Skvllbabiez minted',
		},
		averagePrice: {
			title: 'Virgins Left',
			value: `${totalBabiezMinted ? `${(5000 - totalBabiezMinted) * 2}` : 0}`,
			toolTip: 'Total Virgins left',
		},
		totalSupply: {
			title: 'Generate Per Day',
			value: '4 AMMO',
			ammoIcon: true,
			toolTip: 'Total AMMO generated per day',
		},
	};

	const pageHeader = {
		title: 'Breeding Station',
		subtitle: config.SKVLLBABIEZ_CONTRACT_ADDRESS,
		textAlign: 'center',
		textTransform: 'capitalize',
	};

	const Stats = {
		areStatsLoading: false,
		statsData,
		stylesGrid: '3, 1fr',
	};

	const Mint = canMintBabiez ? (
		<ChooseSkvllpvnkzToMint
			handleHeader={(header: string) => setMintHeader(header)}
		/>
	) : (
		<CantMintBabiez hasSkvllpvnkz={hasSkvllpvnkz} />
	);

	const SkvllpvnkzLoaded =
		mintState === TransactionState.DEFAULT ? (
			Mint
		) : (
			<MintProgress
				handleHeader={(header: string) => setMintHeader(header)}
				mintingState={mintState}
			/>
		);

	const WalletConnected = isLoaded ? (
		SkvllpvnkzLoaded
	) : (
		<Loader color="text-secondary" />
	);

	const Content = isAuthenticated ? (
		WalletConnected
	) : (
		<div style={{ marginLeft: '26%', marginTop: -73 }}>
			<WalletNotConnected
				subtitle="In order to be able to mint skvllbabiez you'll need to have several
				skvllpvnkz. Please note that for one baby it will require two punkz!"
				width="65%"
			/>
		</div>
	);

	return (
		<div>
			<PageHeaderContent
				pageHeader={pageHeader}
				stats={Stats}
				backgroundImage={bannerDesktop1}
			/>
			<FlexDiv>
				<MintDiv>
					<MintHeader>
						{isAuthenticated ? <b>{mintHeader}</b> : <b>Mint Skvllbabiez</b>}
					</MintHeader>
					<MintContentDiv>{Content}</MintContentDiv>
				</MintDiv>
			</FlexDiv>
		</div>
	);
};

export default MintSkvllbabiezTab;
