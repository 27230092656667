import React from 'react';
import NoNFTFound from '../../../../components/NoNFTFound';
import { SiteRoutes } from '../../../../resources/enums/SiteRoutes';

interface Props {
	hasSkvllpvnkz: boolean;
}

const CantMintBabiez = (props: Props): JSX.Element => {
	const { hasSkvllpvnkz } = props;
	return (
		<div style={{ marginTop: -120 }}>
			<NoNFTFound
				title={hasSkvllpvnkz ? 'No virgin Skvllpvnkz' : 'No Skvllpvnkz found'}
				route={SiteRoutes.discover}
			/>
		</div>
	);
};

export default CantMintBabiez;
