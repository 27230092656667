import styled from 'styled-components';

interface PropsInterface {
	boxShadow?: string;
	marginBottom?: string;
}

export const LazyLoadSpinnerDiv = styled.div<PropsInterface>`
	margin-bottom: ${(props) => props.marginBottom};
	height: 300px;
	background-color: #131b21;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	// box-shadow: 2px 8px 10px 2px rgba(${(props) => props.boxShadow}, 1);
`;

LazyLoadSpinnerDiv.defaultProps = {
	boxShadow: '10, 10, 10',
};
