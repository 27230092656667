import {
	BABIEZ_AMMOLITE_INCREMENT_RATE,
	PVNKZ_AMMOLITE_INCREMENT_RATE,
} from '../../resources/constants/rewards';
import PendingRewardsReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/PendingRewardsReducerStateInterface';
import {
	CONNECT_BABIEZ_AMMOLITE_UPDATER,
	CONNECT_SKVLLPVNKZ_AMMOLITE_UPDATER,
	INCREMENT_DAYCARE_REWARDS,
	INCREMENT_HIDEOUT_REWARDS,
	RESET_PENDING_REWARDS_STATE,
	SET_DAYCARE_INTERVAL_ID,
	SET_HIDEOUT_INTERVAL_ID,
} from '../actions/types/pendingRewards';

const initialState: PendingRewardsReducerStateInterface = {
	totalHideoutRewards: 0,
	totalDaycareRewards: 0,
	hideoutIntervalUpdaterID: null,
	daycareIntervalUpdaterID: null,
};

interface PayloadInterface {
	skvllpvnkzRewards: number;
	babiezRewards: number;
	intervalId: number;
	ammountMultiplier: number;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const pendingRewardsReducer = (
	state = initialState,
	action: ActionInterface
): PendingRewardsReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case CONNECT_SKVLLPVNKZ_AMMOLITE_UPDATER: {
			if (state.hideoutIntervalUpdaterID !== null) {
				clearInterval(state.hideoutIntervalUpdaterID);
			}
			return {
				...state,
				totalHideoutRewards: payload.skvllpvnkzRewards,
				hideoutIntervalUpdaterID: payload.intervalId,
			};
		}
		case CONNECT_BABIEZ_AMMOLITE_UPDATER: {
			if (state.daycareIntervalUpdaterID !== null) {
				clearInterval(state.daycareIntervalUpdaterID);
			}
			return {
				...state,
				totalDaycareRewards: payload.babiezRewards,
				daycareIntervalUpdaterID: payload.intervalId,
			};
		}
		case SET_HIDEOUT_INTERVAL_ID:
			return {
				...state,
				hideoutIntervalUpdaterID: payload.intervalId,
			};
		case SET_DAYCARE_INTERVAL_ID:
			return {
				...state,
				daycareIntervalUpdaterID: payload.intervalId,
			};
		case INCREMENT_HIDEOUT_REWARDS:
			if (payload.intervalId !== state.hideoutIntervalUpdaterID) {
				clearInterval(payload.intervalId);
			}
			return {
				...state,
				totalHideoutRewards:
					state.totalHideoutRewards +
					PVNKZ_AMMOLITE_INCREMENT_RATE * payload.ammountMultiplier,
			};
		case INCREMENT_DAYCARE_REWARDS:
			if (payload.intervalId !== state.daycareIntervalUpdaterID) {
				clearInterval(payload.intervalId);
			}
			return {
				...state,
				totalDaycareRewards:
					state.totalDaycareRewards +
					BABIEZ_AMMOLITE_INCREMENT_RATE * payload.ammountMultiplier,
			};
		case RESET_PENDING_REWARDS_STATE:
			if (state.daycareIntervalUpdaterID) {
				clearInterval(state.daycareIntervalUpdaterID);
			}
			if (state.hideoutIntervalUpdaterID) {
				clearInterval(state.hideoutIntervalUpdaterID);
			}
			return initialState;
		default:
			return state;
	}
};

export default pendingRewardsReducer;
