import styled from 'styled-components';
import {
	TEXT_WHITE,
} from '../../constants/colors';

export const CountdownContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 37px;
	font-family: PennStation;
`;

export const DateContainer = styled.div`
	width: 80px;
	border-radius: 10px;
	color: ${TEXT_WHITE};
	font-weight: 600;
	font-size: 3rem;
	overflow: hidden;

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-size: .7rem;
		font-weight: 700;
		line-height: 14px;
		height: 26px;
		margin-bottom: 5px;
	}
`;
