import styled from 'styled-components';
import { MOBILE_DEVICE_WIDTH } from '../../constants/dimensions';

export const DividerDiv = styled.div``;
export const DividerButtonWrapper = styled.div`
	margin-bottom: -16px;
	padding-top: 30px;
`;

export const StoreCollectionContainer = styled.div`
	display: grid;
	grid-column-gap: 0px;
	margin-top: 20px;
	padding: 0px 150px;

	@media screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
		grid-template-rows: 1fr;
		padding: 0px 30px;
	}
`;
