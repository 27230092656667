import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setCheckOutState,
	setHideoutRewardState,
	setStakedDaycareRewardState,
} from '../../redux/actions/rewardsAndStaking';
import {
	BUTTON_PRIMARY_PURPLE,
	BUTTON_PRIMARY_PURPLE_DARK,
	BUTTON_PRIMARY_PURPLE_LIGHT,
	HIGHLIGHT_COLOR,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
	BUTTON_PRIMARY_MAIN,
} from '../../resources/constants/colors';
import { CollectionTitles } from '../../resources/enums/CollectionTitles';
import RewardCollection from '../../resources/enums/RewardCollection';
import { TransactionState } from '../../resources/enums/states';
import NFTAssetInterface from '../../resources/interfaces/NFTAssetInterface';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import CustomButton from '../../resources/styles/CustomButton.styles';
import {
	CheckInAndOutDiv,
	NFTVerticalScrollDiv,
	StakeContainer,
} from '../../resources/styles/StakeNFT.styles';
import Skvllbabiez from '../../services/Skvllbabiez';
import Skvllpvnkz from '../../services/Skvllpvnkz';
import StakeNFTContent from './components/StakeNFTContent';

interface PropInterface {
	nftList: NFTAssetInterface[] | undefined;
	contract: Skvllpvnkz | Skvllbabiez | undefined;
	collectionTitle: CollectionTitles;
	rewardCollection: RewardCollection;
	title?: string;
}

const StakeNFT = (props: PropInterface): JSX.Element => {
	const { nftList, contract, rewardCollection, collectionTitle, title } = props;
	const { isAuthenticated } = useSelector(
		(state: RootReducerStateInterface) => state.auth
	);
	const dispatch = useDispatch();
	const {
		daycare,
		isDaycareOpen,
		hideout,
		isHideoutRewardsOpen,
		checkOutState,
		daycareRewardState,
		hideoutRewardState,
	} = useSelector(
		(state: RootReducerStateInterface) => state.rewards_and_staking
	);
	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite
	);
	const [clickedNFTIds, setClickedNFTIds] = useState<number[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (nftList) {
			setIsLoading(false);
		}
	}, [nftList]);

	const handleClick = (id: number) => {
		if (handleIncludeCheck(id)) {
			setClickedNFTIds(clickedNFTIds.filter((nftID) => nftID !== id));
		} else {
			setClickedNFTIds([...clickedNFTIds, id]);
		}
	};

	const handleIncludeCheck = (id: number): boolean =>
		clickedNFTIds.some((nftID) => nftID === id);

	const handleCheckout = async () => {
		dispatch(setCheckOutState(TransactionState.IN_PROGRESS));
		const idList = clickedNFTIds;
		setClickedNFTIds([]);
		if (daycare) {
			await daycare.checkOutSkvllbabiesBatch(idList);
		}
	};

	const handleCollectReward = () => {
		const idList = clickedNFTIds;
		setClickedNFTIds([]);
		if (ammolite) {
			if (rewardCollection === RewardCollection.daycare_staking) {
				dispatch(setStakedDaycareRewardState(TransactionState.IN_PROGRESS));
				daycare?.collectBatchBabyReward(idList, true);
			} else {
				dispatch(setHideoutRewardState(TransactionState.IN_PROGRESS));
				hideout?.collectBatchSkvllpvnkzReward(idList);
			}
		}
	};

	const handleSelectAll = () => {
		if (nftList) {
			if (
				clickedNFTIds.length === nftList.length ||
				clickedNFTIds.length >= 1
			) {
				setClickedNFTIds([]);
			} else {
				const allIds = nftList?.map((nft) => nft.id);
				setClickedNFTIds(allIds);
			}
		}
	};

	const alignment =
		isLoading ||
		(typeof nftList !== 'undefined' && nftList.length === 0) ||
		!isAuthenticated
			? 'center'
			: 'flex-start';

	const isHideoutButtonDisabled =
		hideoutRewardState !== TransactionState.DEFAULT || !isHideoutRewardsOpen;

	const isDaycareButtonDisabled =
		checkOutState !== TransactionState.DEFAULT ||
		daycareRewardState.stakedDaycare !== TransactionState.DEFAULT ||
		!isDaycareOpen;

	const isCollectionDisabled =
		rewardCollection === RewardCollection.daycare_staking
			? isDaycareButtonDisabled
			: isHideoutButtonDisabled;

	const isButtonDisabled = isLoading || isCollectionDisabled;

	return (
		<StakeContainer>
			<div className="mobile-select-all">
				<p>
					{clickedNFTIds.length
						? `${clickedNFTIds.length} ${title} selected`
						: `Tap a ${title} to check-in`}
				</p>
				<CustomButton
					width="116px"
					height="34px"
					fontSize="14px"
					borderRadius="0px"
					borderWidth="0px"
					backgroundColor={clickedNFTIds.length ? 'transparent' : 'transparent'}
					color={BUTTON_PRIMARY_MAIN}
					backgroundColorHover="transparent"
					backgroundColorActive="transparent"
					fontColorActive={TEXT_WHITE}
					fontColorHover={TEXT_WHITE}
					disabled={
						isButtonDisabled ||
						(typeof nftList !== 'undefined' && nftList.length < 1)
					}
					onClick={handleSelectAll}
				>
					<b>{clickedNFTIds.length ? `Unselect all` : 'Select all'}</b>
				</CustomButton>
			</div>
			<NFTVerticalScrollDiv justify="center" align={alignment}>
				<StakeNFTContent
					isAuthenticated={isAuthenticated}
					handleClick={handleClick}
					handleIncludeCheck={handleIncludeCheck}
					collection={collectionTitle}
					nftsWithRewards={nftList}
					contract={contract}
				/>
			</NFTVerticalScrollDiv>

			<CheckInAndOutDiv>
				{rewardCollection === RewardCollection.daycare_staking && (
					<CustomButton
						width="116px"
						height="34px"
						fontSize="12px"
						borderRadius="5px"
						borderWidth="0px"
						backgroundColor={
							clickedNFTIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
						}
						color={clickedNFTIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
						backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
						backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
						fontColorActive={TEXT_WHITE}
						disabled={isButtonDisabled || clickedNFTIds.length < 1}
						onClick={handleCheckout}
					>
						<b>Check out</b>
					</CustomButton>
				)}
				<CustomButton
					width="116px"
					height="34px"
					fontSize="12px"
					borderRadius="5px"
					borderWidth="0px"
					backgroundColor={
						clickedNFTIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
					}
					color={clickedNFTIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
					backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
					backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
					fontColorActive={TEXT_WHITE}
					disabled={isButtonDisabled || clickedNFTIds.length < 1}
					onClick={handleCollectReward}
				>
					<b>Collect rewards</b>
				</CustomButton>
				<div className="desktop-select-all">
					<CustomButton
						width="116px"
						height="34px"
						fontSize="12px"
						borderRadius="5px"
						borderWidth="0px"
						backgroundColor={
							clickedNFTIds.length ? BUTTON_PRIMARY_PURPLE : HIGHLIGHT_COLOR
						}
						color={clickedNFTIds.length ? TEXT_WHITE : TEXT_GRAYED_OUT}
						backgroundColorHover={BUTTON_PRIMARY_PURPLE_LIGHT}
						backgroundColorActive={BUTTON_PRIMARY_PURPLE_DARK}
						fontColorActive={TEXT_WHITE}
						fontColorHover={TEXT_WHITE}
						disabled={
							isButtonDisabled ||
							(typeof nftList !== 'undefined' && nftList.length < 1)
						}
						onClick={handleSelectAll}
					>
						<b>{clickedNFTIds.length ? `Unselect All` : 'Select All'}</b>
					</CustomButton>
				</div>
			</CheckInAndOutDiv>
		</StakeContainer>
	);
};

export default StakeNFT;
