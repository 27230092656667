import React, { Dispatch, SetStateAction, useState } from 'react';
import { TitleDiv } from '../../../../resources/styles/RafflePage/RafflePage.styles';
import raffleEntryIcon from '../../../../resources/images/raffle-entry.gif';
import {
	AmountArrows,
	PortalKeyImage,
	PurchaseEntryAmountDiv,
	RaffleEntryContainer,
} from '../../../../resources/styles/RafflePage/PurchaseEntry.styles';
import amountArrowIcon from '../../../../resources/images/icons/raffle-entry-arrow.png';
import ammoliteIconWhite from '../../../../resources/images/icons/AmmoliteIconWhite.png';
import { RafflePageTabIds } from '../../../../resources/enums/RafflePageTabIds';
import CustomButton from '../../../../resources/styles/CustomButton.styles';
import { TEXT_WHITE } from '../../../../resources/constants/colors';
import RaffleLoader from '../RaffleLoader';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import SweetAlert from '../../../../components/SweetAlert';
import config from '../../../../config';
import { GenericError } from '../../../../resources/enums/GenericError';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { LotteryInterface } from '../../../../resources/interfaces/LotteryInterface';
import Lottery from '../../../../services/Lottery';

interface PropsInterface {
	changeRaffleTab: (tabId: RafflePageTabIds) => void;
	currentLottery: LotteryInterface | undefined;
	lottery: Lottery | undefined;
	isExecutingATransaction: boolean;
	setIsExecutingATransaction: Dispatch<SetStateAction<boolean>>;
}

const PurchaseEntry = (props: PropsInterface): JSX.Element => {
	const {
		currentLottery,
		lottery,
		setIsExecutingATransaction,
		isExecutingATransaction,
	} = props;

	const [amountOfEntries, setAmountOfEntries] = useState(1);

	const { loaderSubtitleText } = useSelector(
		(state: RootReducerStateInterface) => state.loader
	);

	const { discord, id } = useSelector(
		(state: RootReducerStateInterface) => state.discord
	);
	const { ammolite } = useSelector(
		(state: RootReducerStateInterface) => state.ammolite
	);

	const handlePurchaseEntries = async () => {
		setIsExecutingATransaction(true);
		const isDiscordLinked = !!id;
		if (!isDiscordLinked) {
			return SweetAlert({
				showConfirmButton: true,
				title: 'Connect Discord',
				text: 'You first need to link your Discord account before you are able to make purchases in the Hideout store.',
				confirmCallbackFunction: () => discord?.connectDiscord(),
			});
		}
		if (lottery && currentLottery) {
			try {
				await lottery.buyEntry(currentLottery, amountOfEntries);
				SweetAlert({
					title: 'Purchase Successful',
					text: `You have successfully aquired keys for portal ${currentLottery.portalName}.`,
				});
			} catch (error) {
				console.error(error);
				handleTransactionError(error);
				setIsExecutingATransaction(false);
			}
		}
		setIsExecutingATransaction(false);
	};

	const handleTransactionError = (error: unknown) => {
		if (error instanceof Error) {
			if (
				error.message === GenericError.ALLOWANCE_LIMIT_EXCEEDED &&
				currentLottery
			) {
				SweetAlert({
					showConfirmButton: true,
					title: 'Increase Spend',
					text: GenericError.ALLOWANCE_LIMIT_EXCEEDED,
					confirmCallbackFunction: () =>
						ammolite?.increaseAllowance(
							currentLottery.entryPrice * amountOfEntries,
							config.LOTTERY_CONTRACT_ADDRESS
						),
				});
			} else {
				SweetAlert({
					showConfirmButton: true,
					title: 'Purchase Failed',
					text: error.message,
					confirmButtonText: 'Got it',
				});
			}
		}
	};

	const totalEntriesOfCurrentLottery = currentLottery?.numberOfEntries || 0;
	const chanceOfWinning =
		totalEntriesOfCurrentLottery > 0
			? ((amountOfEntries || 0) /
					(totalEntriesOfCurrentLottery + (amountOfEntries || 0))) *
			  100
			: 0;

	if (isExecutingATransaction) {
		return (
			<RaffleLoader
				title="Aquiring Portal Keys"
				subtitle={loaderSubtitleText}
			/>
		);
	}

	const subTotalPrice =
		amountOfEntries *
		parseInt(formatUnits(BigNumber.from(currentLottery?.entryPrice || 0), 18));
	const totalPrice = subTotalPrice < 0 ? 0 : subTotalPrice;
	return (
		<RaffleEntryContainer>
			<PortalKeyImage src={raffleEntryIcon} alt="entry" />
			<TitleDiv marginTop="25px" fontSize="22px">
				How many keys?
			</TitleDiv>
			<PurchaseEntryAmountDiv>
				<input
					type="number"
					value={amountOfEntries.toString()}
					onChange={(e) =>
						setAmountOfEntries(parseInt(e.currentTarget.value) || 1)
					}
				/>
				<AmountArrows>
					<img
						id="up"
						src={amountArrowIcon}
						alt="arrow"
						onClick={() => {
							if (currentLottery) {
								const maxEntriesPerWallet =
									amountOfEntries < currentLottery?.maxEntriesPerWallet;
								if (maxEntriesPerWallet) {
									setAmountOfEntries(amountOfEntries + 1);
								}
							}
						}}
					/>
					<img
						src={amountArrowIcon}
						alt="arrow"
						onClick={() => {
							if (amountOfEntries > 1) {
								setAmountOfEntries(amountOfEntries - 1);
							}
						}}
					/>
				</AmountArrows>
			</PurchaseEntryAmountDiv>
			<CustomButton
				marginTop="23px"
				marginTopResponsive="23px"
				marginBottom="20px"
				marginBottomResponsive="20px"
				borderRadius="5px"
				fontSize="16px"
				color={TEXT_WHITE}
				borderWidth="0"
				onClick={handlePurchaseEntries}
			>
				<span>
					<b>
						Craft Key <img src={ammoliteIconWhite} alt="ammo" id="ammo" />{' '}
						{totalPrice}
					</b>
				</span>
			</CustomButton>
			<div>
				<p>{chanceOfWinning.toFixed(2)}% chance of winning</p>
			</div>
		</RaffleEntryContainer>
	);
};

export default PurchaseEntry;
