import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { auth } from '../../firebase';
import { resetReduxState } from '../../redux/actions/wallet';
import ConnectWalletPage from './ConnectWalletPage';

const ChangeWallet = (): JSX.Element => {
	const dispatch = useDispatch();
	useEffect(() => {
		auth.signOut();
		dispatch(resetReduxState());
	}, []);

	return <ConnectWalletPage isChangeWallets={true} />;
};

export default ChangeWallet;
