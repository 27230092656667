import { SET_STATS } from '../actions/types/stats';
import UserStatsReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/UserStatsReducerInterface';

const initialState: UserStatsReducerStateInterface = {
	stats: {
		account: undefined,
		averageValue: undefined,
		collections: undefined,
		numberOfSales: undefined,
		totalGas: undefined,
		totalSellVolume: undefined,
		totalVolume: undefined,
		transactionCount: undefined,
	},
	isFetched: false,
};

interface PayloadInterface {
	account?: string;
	averageValue?: number;
	collections?: number;
	numberOfSales?: number;
	totalGas?: number;
	totalSellVolume?: number;
	totalVolume?: number;
	transactionCount?: number;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const statsReducer = (
	state = initialState,
	action: ActionInterface
): UserStatsReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SET_STATS:
			return {
				...state,
				...payload,
				isFetched: true,
			};

		default:
			return state;
	}
};

export default statsReducer;
