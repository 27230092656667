import styled from 'styled-components';

export const EthereumIcon = styled.img`
	height: calc(8px + 0.7rem);
	padding-right: 0.25rem;
	margin-top: -0.2rem;
`;

export const CollectionDetailsContainer = styled.div`
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	grid-template-columns: 100%;

	height: 40vh;
	margin: auto;
	grid-column-gap: 1vw;

	@media screen and (max-width: 990px) {
		height: auto;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0a0c10 55%);
		margin-block-start: 2rem !important;
	}
`;

export const CollectionTitle = styled.div`
	font-size: 2rem;
	font-weight: 600;
	text-align: left;
	padding-bottom: 10px;
`;

export const CollectionDescription = styled.div`
	font-size: 0.9rem;
`;

export const CollectionSubtitle = styled.div`
	font-size: 1rem;
	font-weight: 600;
	padding-bottom: 8px;
`;

export const CollectionText = styled.div`
	margin-top: 20px;
	padding: 20px;
	text-align: left;
`;
interface GridProps {
	stylesGrid?: string;
}
export const CollectionStatsDetails = styled.div<GridProps>`
	margin-top: 15px;
	display: flex;
	width: 100%;
	grid-template-columns: repeat(4, 1fr);
	z-index: 1;
	border-radius: 10px;
	text-align: left;
	padding: 0.5vw;
	@media screen and (max-width: 990px) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		${(props) =>
			props.stylesGrid
				? `grid-template-columns: repeat(${props.stylesGrid})`
				: 'grid-template-columns: repeat(4, 1fr);'}
	}
`;

export const StatBox = styled.div`
	text-align: center;
	padding: 15px;
	margin: auto;
	padding: 0.5vw;
`;

export const StatValue = styled.div`
	text-align: center;
	font-weight: 600;
	min-width: 6vw;
	font-size: calc(12px + 0.6rem);
	padding-bottom: 10px;

	div {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const StatName = styled.div`
	font-size: 0.5rem;
	font-size: calc(12px + 0.28vw);
	color: #7663c3;
`;

export const CollectionStatsContainer = styled.div`
	padding: 20px;
	display: flex;
	align-items: flex-end;
	@media screen and (max-width: 990px) {
		align-items: center;
	}
`;
