import React from 'react';
import { useSelector } from 'react-redux';
import { CollectionTitles } from '../../../resources/enums/CollectionTitles';
import RewardCollection from '../../../resources/enums/RewardCollection';
import { TransactionState } from '../../../resources/enums/states';
import NFTAssetInterface from '../../../resources/interfaces/NFTAssetInterface';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import Skvllbabiez from '../../../services/Skvllbabiez';
import Skvllpvnkz from '../../../services/Skvllpvnkz';
import CollectRewardProgress from '../../CollectRewardProgress';
import Loader from '../../Loader';
import WalletNotConnected from '../../WalletNotConnected';
import CheckoutProgress from './CheckoutProgress';
import RewardsClosed from './RewardsClosed';
import StakeNFTTable from './StakeNFTTable';

interface PropsInterface {
	isAuthenticated: boolean;
	handleClick: (id: number) => void;
	handleIncludeCheck: (id: number) => boolean;
	collection: CollectionTitles;
	nftsWithRewards: NFTAssetInterface[] | undefined;
	contract: Skvllpvnkz | Skvllbabiez | undefined;
}

const StakeNFTContent = (props: PropsInterface): JSX.Element => {
	const {
		isAuthenticated,
		handleClick,
		handleIncludeCheck,
		collection,
		nftsWithRewards,
		contract,
	} = props;
	const {
		checkOutState,
		daycareRewardState,
		isDaycareOpen,
		hideoutRewardState,
		isHideoutRewardsOpen,
	} = useSelector(
		(state: RootReducerStateInterface) => state.rewards_and_staking
	);

	const DefaultContent =
		nftsWithRewards && contract ? (
			<StakeNFTTable
				handleClick={(id: number) => handleClick(id)}
				handleIncludeCheck={(id: number) => handleIncludeCheck(id)}
				collection={collection}
				nftWithRewards={nftsWithRewards}
			/>
		) : (
			<Loader />
		);

	const HideoutRewardsOpenContent =
		hideoutRewardState === TransactionState.DEFAULT ? (
			DefaultContent
		) : (
			<CollectRewardProgress
				state={hideoutRewardState}
				isStaked={false}
				rewardCollection={RewardCollection.hideout_rewards}
			/>
		);

	const HideoutRewardsContent = isHideoutRewardsOpen ? (
		HideoutRewardsOpenContent
	) : (
		<RewardsClosed title="Hideout" />
	);

	const DaycareContractInProgress =
		checkOutState !== TransactionState.DEFAULT ? (
			<CheckoutProgress checkoutState={checkOutState} />
		) : (
			<CollectRewardProgress
				state={daycareRewardState.stakedDaycare}
				isStaked={true}
				rewardCollection={RewardCollection.daycare_staking}
			/>
		);

	const DaycareOpenContent =
		checkOutState === TransactionState.DEFAULT &&
			daycareRewardState.stakedDaycare === TransactionState.DEFAULT
			? DefaultContent
			: DaycareContractInProgress;

	const DaycareContent = isDaycareOpen ? (
		DaycareOpenContent
	) : (
		<RewardsClosed title="Daycare" />
	);

	const WalletConnectedContent =
		collection === CollectionTitles.Skvllbabiez
			? DaycareContent
			: HideoutRewardsContent;

	return isAuthenticated ? (
		WalletConnectedContent
	) : (
		<WalletNotConnected subtitle="Connect your wallet to access this feature" />
	);
};

export default StakeNFTContent;
