import { signInWithCustomToken } from 'firebase/auth';
import config from '../config';
import { auth } from '../firebase';
import { getAuthNonceToSign, verifyAuthSignature } from '../firebase/functions';

export default class Auth {
	public static generateAuthMessage(nonce: string): string {
		return `${config.AUTH_SIGNATURE_LABEL}\n\n${nonce}`;
	}

	public static async loginWithFirebase(
		account: string,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		library: any
	): Promise<void> {
		// fetch nonce
		const nonce = (
			await getAuthNonceToSign({
				walletAddress: account,
			})
		).data;
		const message = Auth.generateAuthMessage(nonce);

		// sign nonce with private key
		const signer = library.getSigner();
		const signature = await signer.signMessage(message);

		// verifySignature
		const userToken = (
			await verifyAuthSignature({
				walletAddress: account,
				signature,
			})
		).data;

		// login to firebase with custom token
		if (userToken) {
			await signInWithCustomToken(auth, userToken);
		}
	}
}
