import { formatUnits } from 'ethers/lib/utils';
import React from 'react';
import { formatPortalKeyCode } from '../../../../firebase/functions/lottery';
import { formatAddress } from '../../../../helpers/address';
import { formatDate } from '../../../../helpers/date';
import {
	TEXT_GRAYED_OUT,
	TEXT_GREEN,
	TEXT_PURPLE,
} from '../../../../resources/constants/colors';
import { EntryFilterEnum } from '../../../../resources/enums/Entries';
import { ExternalRoutes } from '../../../../resources/enums/SiteRoutes';
import AmmoliteIcon from '../../../../resources/images/icons/AmmoliteIcon.png';
import {
	Code,
	GreenLink,
	PortalKeyStatus,
} from '../../../../resources/styles/RafflePage/RafflePage.styles';

interface PropsInterface {
	entryTime: Date;
	portalName: string;
	entryId: string;
	amount: string;
	status: 'Activated' | 'Pending' | 'Corrupted';
	transactionHash: string;
}

const Entry = (props: PropsInterface): JSX.Element => {
	const { entryTime, portalName, entryId, amount, status, transactionHash } =
		props;
	const formattedEntryTime = formatDate(entryTime);
	const formattedKeyCode = formatPortalKeyCode(entryId);
	const formattedKeyPrice = (+formatUnits(String(amount))).toFixed(0);
	const NotActivatedStatusColor =
		status === EntryFilterEnum.pending ? TEXT_GREEN : TEXT_GRAYED_OUT;
	const statusColor =
		status === EntryFilterEnum.activated
			? TEXT_PURPLE
			: NotActivatedStatusColor;

	const tableHeader = (
		<>
			<td>
				<b>Date</b>
			</td>
			<td>
				<b>Portal</b>
			</td>
			<td>
				<b>Code</b>
			</td>
			<td>
				<b>Amount</b>
			</td>
			<td>
				<b>Hash</b>
			</td>
			<td>
				<b>Status</b>
			</td>
		</>
	);
	return (
		<tr className="table-wrapper">
			<tr className="mobile">{tableHeader}</tr>
			<tr className="table-row table-entries">
				<td>{formattedEntryTime}</td>
				<td>{portalName}</td>
				<Code onClick={() => navigator.clipboard.writeText(entryId)}>
					{formattedKeyCode.part1}
					{formattedKeyCode.part2}
				</Code>
				<td>
					<span>
						{formattedKeyPrice}{' '}
						<img src={AmmoliteIcon} alt="Ammolite" height="12px" />
					</span>
				</td>
				<td>
					<GreenLink href={`${ExternalRoutes.etherscanTx}${transactionHash}`}>
						{formatAddress(transactionHash)}
					</GreenLink>
				</td>
				<PortalKeyStatus color={statusColor}>{status}</PortalKeyStatus>
			</tr>
		</tr>
	);
};

export default React.memo(Entry);
