import React, { useState } from 'react';
import { PageContainer } from '../../../resources/styles/Page.styles';
import { RafflePageTableContainer } from '../../../resources/styles/RafflePage/PreviousWinners.styles';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import PurchaseTableRow from './PurchaseTableRow';
import LoadPurchase from '../../../components/Navigationbar/Sidebar/components/SidebarStorePurchases/LoadPurchase';
import {
	SwitchButtonActivated,
	SwitchButtonAll,
	SwitchButtonCorrupted,
	SwitchButtonPending,
	SwitchButtonWrapper,
} from '../../../resources/styles/PorttalKeyFilters.styles';
import { Button } from 'reactstrap';
import { PurchaseFilterEnum } from '../../../resources/enums/Purchase';

const Purchases = (): JSX.Element => {
	const { storeData, purchasedItems, reservedItems } = useSelector(
		(state: RootReducerStateInterface) => state.store
	);

	const [buttonState, setButtonState] = useState(PurchaseFilterEnum.all);

	const PurchasedData = (): JSX.Element => {
		return (
			<>
				{purchasedItems.map((item, key) => {
					const matchedItem = storeData.find(
						(storeItem) => storeItem.id === item.id
					);
					if (matchedItem)
						return (
							<PurchaseTableRow
								key={key}
								tableHeader={tableHeader}
								item={matchedItem}
							/>
						);
				})}
			</>
		);
	};

	const ReservedData = (): JSX.Element => {
		return (
			<>
				{reservedItems.map((item, key) => {
					const matchedItem = storeData.find(
						(storeItem) => storeItem.id === item.itemId
					);

					if (!item.isExpired && !item.purchaseCompleted && matchedItem)
						return (
							<PurchaseTableRow
								key={key}
								isReserved
								tableHeader={tableHeader}
								item={matchedItem}
							/>
						);
				})}
			</>
		);
	};

	const ExpiredData = (): JSX.Element => {
		return (
			<>
				{reservedItems.map((item, key) => {
					const matchedItem = storeData.find(
						(storeItem) => storeItem.id === item.itemId
					);

					if (matchedItem && item.isExpired)
						return (
							<PurchaseTableRow
								key={key}
								tableHeader={tableHeader}
								item={matchedItem}
								isExpired={item.isExpired}
							/>
						);
				})}
			</>
		);
	};

	const tableHeader = (
		<>
			<td className="center-text">
				<b>Name</b>
			</td>
			<td className="center-text">
				<b>Category</b>
			</td>
			<td className="center-text">
				<b>Project</b>
			</td>
			<td className="center-text">
				<b>Price</b>
			</td>
			<td className="center-text">
				<b>Status</b>
			</td>
			{/* <td className="center-text">
                <b>Date</b>
            </td> */}
		</>
	);

	return (
		<PageContainer noBackgroundImage>
			<RafflePageTableContainer>
				<div className="table-top">
					<div className="portal-desktop">
						<span></span>
					</div>
					<SwitchButtonWrapper>
						<div>
							<SwitchButtonAll
								isBackgroundColorActive={buttonState === PurchaseFilterEnum.all}
								onClick={() => setButtonState(PurchaseFilterEnum.all)}
							>
								<Button>All</Button>
							</SwitchButtonAll>
							<SwitchButtonPending
								isBackgroundColorActive={
									buttonState === PurchaseFilterEnum.reserved
								}
								onClick={() => setButtonState(PurchaseFilterEnum.reserved)}
							>
								<Button>Reserved</Button>
							</SwitchButtonPending>
							<SwitchButtonActivated
								isBackgroundColorActive={
									buttonState === PurchaseFilterEnum.purchased
								}
								onClick={() => setButtonState(PurchaseFilterEnum.purchased)}
							>
								<Button>Purchased</Button>
							</SwitchButtonActivated>
							<SwitchButtonCorrupted
								isBackgroundColorActive={
									buttonState === PurchaseFilterEnum.expired
								}
								onClick={() => setButtonState(PurchaseFilterEnum.expired)}
							>
								<Button>Expired</Button>
							</SwitchButtonCorrupted>
						</div>
					</SwitchButtonWrapper>
				</div>
				<table>
					<thead className="entries-table-head">
						<tr>{tableHeader}</tr>
					</thead>
					<tbody>
						<LoadPurchase />
						{buttonState === PurchaseFilterEnum.all && (
							<>
								<PurchasedData />
								<ReservedData />
								<ExpiredData />
							</>
						)}

						{buttonState === PurchaseFilterEnum.purchased && (
							<>
								<PurchasedData />
							</>
						)}

						{buttonState === PurchaseFilterEnum.reserved && (
							<>
								<ReservedData />
							</>
						)}

						{buttonState === PurchaseFilterEnum.expired && (
							<>
								<ExpiredData />
							</>
						)}
					</tbody>
				</table>
			</RafflePageTableContainer>
		</PageContainer>
	);
};

export default Purchases;
