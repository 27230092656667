import React from 'react';
import { useDispatch } from 'react-redux';
import {
	setStakedDaycareRewardState,
	setHideoutRewardState,
	setNotStakedDaycareRewardState,
} from '../redux/actions/rewardsAndStaking';
import RewardCollection from '../resources/enums/RewardCollection';
import { TransactionState } from '../resources/enums/states';
import CustomButton from '../resources/styles/CustomButton.styles';
import { StakeProgressContainer } from '../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import {
	SubtitleDiv,
	TitleDiv,
} from '../resources/styles/WalletNotConnected.styles';
import Loader from './Loader';

interface PropsInterface {
	state: TransactionState;
	isStaked: boolean;
	rewardCollection: RewardCollection;
}

const CollectRewardProgress = (props: PropsInterface): JSX.Element => {
	const { state, rewardCollection, isStaked } = props;
	const dispatch = useDispatch();

	let title: string;
	let content: string;
	switch (state) {
		case TransactionState.SUCCESS:
			title = 'You successfully collected your AMMOLITE!';
			content = `Spend your AMMO wisely! We don't want you to be broke.`;
			break;
		case TransactionState.FAILED:
			title = 'Uh oh, it seems that the transaction failed.';
			content =
				'The transaction failed. Please check Etherscan for details and contact the Devs on discord if you have any questions.';
			break;
		default:
			title = 'Counting your AMMO...';
			content = 'Rest assured that we will count your AMMO correctly.';
	}

	const showOkButton = state !== TransactionState.IN_PROGRESS;
	const showSpinner = state === TransactionState.IN_PROGRESS;

	const handleButtonClick = () => {
		if (rewardCollection === RewardCollection.daycare_staking) {
			const dispatchFunction = isStaked
				? setStakedDaycareRewardState
				: setNotStakedDaycareRewardState;
			dispatch(dispatchFunction(TransactionState.DEFAULT));
		} else {
			dispatch(setHideoutRewardState(TransactionState.DEFAULT));
		}
	};

	return (
		<StakeProgressContainer padding="0px">
			<div>
				<TitleDiv>{title}</TitleDiv>
				<SubtitleDiv>{content}</SubtitleDiv>
				{showOkButton && (
					<CustomButton
						onClick={handleButtonClick}
						width="120px"
						height="40px"
						marginTop="15px"
						borderRadius="5px"
					>
						<b>Ok</b>
					</CustomButton>
				)}
				{showSpinner && <Loader color="text-secondary" marginTop="20px" />}
			</div>
		</StakeProgressContainer>
	);
};

export default CollectRewardProgress;
