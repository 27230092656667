import DiscordReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/DiscordReducerStateInterface';
import Discord from '../../services/Discord';
import {
	RESET_DISCORD_STATE,
	SETUP_DISCORD,
	UPDATE_DISCORD_PROFILE,
} from '../actions/types/discord';

const initialState: DiscordReducerStateInterface = {
	discord: null,
	id: undefined,
	username: undefined,
};

interface PayloadInterface {
	discord: Discord;
	id: string;
	username: string;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const discordReducer = (
	state = initialState,
	action: ActionInterface
): DiscordReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SETUP_DISCORD:
			return {
				...state,
				discord: payload.discord,
			};
		case UPDATE_DISCORD_PROFILE:
			return {
				...state,
				id: payload.id,
				username: payload.username,
			};
		case RESET_DISCORD_STATE:
			return initialState;
		default:
			return state;
	}
};

export default discordReducer;
