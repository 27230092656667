import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Loader from '../../components/Loader';
import { mapWalletToDiscord } from '../../firebase/functions/discord';
import { fetchUsernameAndID } from '../../helpers/discord';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	LoginContainer,
	MainContainer,
} from '../../resources/styles/DiscordPage/DiscordPage.styles';
import { PageContainer } from '../../resources/styles/Page.styles';
import { BsCheckCircle } from 'react-icons/bs';
import { VscError } from 'react-icons/vsc';
import { BUTTON_PRIMARY_MAIN } from '../../resources/constants/colors';
import CustomButton from '../../resources/styles/CustomButton.styles';

const DiscordPage = () => {
	const location = useLocation();
	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);
	const [isMappingInProgress, setIsMappingInProgress] = useState(true);
	const [isMappingSuccessful, setIsMappingSuccessful] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>();

	const getDiscordData = async (walletAddress: string) => {
		const fragment = new URLSearchParams(location.hash.slice(1));
		const [tokenType, accessToken] = [
			fragment.get('token_type'),
			fragment.get('access_token'),
			fragment.get('state'),
		];

		try {
			const { username, id } = await fetchUsernameAndID(tokenType, accessToken);
			if (!username || !id) {
				throw new Error('Something went wrong.');
			}

			await mapWalletToDiscord({
				walletAddress,
				discord: {
					username,
					id,
				},
			});
			setIsMappingSuccessful(true);
		} catch (err) {
			setIsMappingSuccessful(false);
			if (err instanceof Error) {
				setErrorMessage(err.message);
			}
			console.error(err);
		} finally {
			setIsMappingInProgress(false);
		}
	};

	useEffect(() => {
		if (user) {
			getDiscordData(user.uid);
		}
	}, [user]);

	const TextContent = isMappingSuccessful ? (
		<>
			<BsCheckCircle size={30} color={BUTTON_PRIMARY_MAIN} />
			<h3>Discord connected</h3>
			<p>
				You have successfully signed into your account. You can close this
				window and continue using the product.
			</p>
		</>
	) : (
		<>
			<VscError size={30} color={BUTTON_PRIMARY_MAIN} />
			<h3>{errorMessage}</h3>
			<p>Please contact the developers on discord to get support.</p>
		</>
	);

	return (
		<PageContainer>
			<MainContainer>
				<LoginContainer>
					{isMappingInProgress ? (
						<Loader size="40px" />
					) : (
						<div>
							{TextContent}
							<CustomButton
								borderRadius="5px"
								onClick={() => window.close()}
								marginBottom="40px"
							>
								<b>Close Window</b>
							</CustomButton>
						</div>
					)}
				</LoginContainer>
			</MainContainer>
		</PageContainer>
	);
};

export default DiscordPage;
