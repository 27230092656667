import styled from 'styled-components';
import {
	LINE_COLOR,
	FILTER_MAIN,
	TEXT_DARK,
	TEXT_GRAYED_OUT,
	TEXT_WHITE,
} from '../../constants/colors';
import ArrowDownImage from '../../images/icons/arrow-down.png';
import SearchIcon from '../../images/icons/search-icon.png';

interface Props {
	fontSize?: string;
	color?: string;
	lineHeight?: string;
	textAlign?: string;
	paddingBottom?: string;
	paddingTop?: string;
	shadowColor?: string;
}
export const GalleryContainer = styled.div`
	text-align: center;
	grid-area: gallery;
	padding-right: 40px;
	width: 100%;

	#col {
		margin-bottom: 20px;
		margin-top: 5px;
		@media screen and (max-width: 1980px) {
			min-width: 13vw;
		}
	}

	#p-results {
		text-align: left;
		font-size: 16px;
		color: ${TEXT_GRAYED_OUT};
	}

	span {
		font-size: 16px !important;
		color: ${TEXT_WHITE} !important;
	}

	#gallery-header {
		width: calc(100% + 50px);
		position: -webkit-sticky;
		position: sticky;
		top: 100px;
		padding-top: 35px;
		padding-right: 35px;
		padding-left: 35px;
		margin-left: -25px;
		z-index: 2;
		background-color: transparent;
		height: 138px;
		margin-bottom: 5px;
	}

	#filter-wrapper {
		display: flex;
		justify-content: space-between;
	}

	#loader {
		position: absolute;
		left: 0px;
		background-color: black;
		min-width: 100%;
	}
`;

export const FilterGallerySelect = styled.select`
	position: absolute;
	right: 0;
	margin-right: 35px;
	background-color: transparent;
	border-color: ${LINE_COLOR};
	color: white;
	width: 207px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	padding-left: 10px;
	margin-left: 28px;
	outline: none;
	appearance: none;
	background: url(${ArrowDownImage}) 94% / 6% no-repeat ${FILTER_MAIN};
	opacity: 0.7;

	@media screen and (max-width: 768px) {
		width: 150px;
	}

	@media screen and (max-width: 414px) {
		width: 100px;
	}
`;

export const FilterSearch = styled.input`
	background-color: ${FILTER_MAIN};
	color: white;
	width: 290px;
	height: 46px;
	border-radius: 5px;
	font-size: 13px;
	border: 1px ${LINE_COLOR} solid;
	outline: none;
	margin-bottom: 20px;
	margin-left: -10px;
	padding-left: 30px;
	background: url(${SearchIcon}) 3% / 5% no-repeat ${FILTER_MAIN};

	::placeholder {
		color: ${TEXT_GRAYED_OUT};
	}
`;

export const GalleryCard = styled.div<Props>`
	font-size: 0.8rem;
	cursor: pointer;
	display: block;
	width: auto;
	height: auto;
	grid-template-rows: 70% 30%;
	padding-bottom: 0.8rem;
	background-color: #131b21;
	position: relative;

	border-radius: 10px;
	:hover {
		top: -2px;
	}
`;

export const ImageCard = styled.img`
	display: block;
	width: 100%;
	height: auto;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const ImageWrapper = styled.div``;

export const VideoWrapper = styled.div``;

export const Video = styled.source``;

export const GalleryCardTagRight = styled.div`
	background-color: ${TEXT_WHITE};
	color: ${TEXT_DARK};
	position: absolute;
	width: auto;
	height: 22px;
	padding: 3px;
	padding-left: 5px;
	right: 10px;
	top: 10px;
	font-size: 0.7rem;
	font-weight: 600;
	z-index: 1;
	border-radius: 5px;
`;

export const GalleryCardTag = styled.div`
	background-color: ${TEXT_WHITE};
	color: ${TEXT_DARK};
	position: absolute;
	width: auto;
	height: 22px;
	padding: 3px;
	padding-right: 5px;
	padding-left: 5px;
	left: 10px;
	top: 10px;
	font-size: 0.7rem;
	font-weight: 600;
	z-index: 1;
	border-radius: 5px;
`;

export const VideoCard = styled.video`
	display: block;
	width: 100%;
	height: auto;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
`;

export const GalleryRankAndPriceDetails = styled.div`
	display: grid;
	grid-template-rows: 50% 50%;

	height: 80px;
`;

export const GalleryCardTitle = styled.div`
	font-size: 1rem;
	text-align: center;
	font-weight: 600;
	padding-top: 10px;
	min-height: 2rem;
	max-height: 2rem;
	line-height: 1.2rem;
`;

export const GalleryCardRank = styled.div<Props>`
	margin-top: 5px;
	line-height: 0.9rem;
`;

export const CardRankAndScore = styled.div<Props>`
	margin-top: -2px;
	display: grid;
	grid-template-columns: 50% 50%;

	.rank-adjustment {
		display: flex;
	}
`;

export const Ammolite = styled.img<Props>`
	margin-top: -2px;
	padding-right: 5px;
`;

export const CardRank = styled.div<Props>`
	color: ${(props) => props.color};
	font-size: 0.8rem;
	text-align: left;
`;

export const RankDetails = styled.div<Props>`
	color: ${(props) => props.color};
	line-height: 0.8rem;
	font-size: 0.8rem;
	text-align: center;
	padding-top: 15px;
	margin-top: -5px;
	height: 60px;
	border-bottom-left-radius: 10px;
	background: linear-gradient(
		0deg,
		rgba(37, 184, 167, 0.217) 20%,
		rgba(37, 184, 167, 0) 100%
	);
	.rank,
	.rank-number {
		margin-left: -10px;
	}
	.rank {
		margin-bottom: 5px;
	}
	.rank-number {
		color: #fff;
	}
`;

export const CardPrice = styled.div<Props>`
	text-align: right;
`;

export const CardScore = styled.div<Props>`
	text-align: right;
	margin-left: -10px;
`;

export const CardScoreAndPrice = styled.div<Props>`
	font-size: 0.8rem;
	text-align: right;
	line-height: 0.8rem;
	padding-right: 24px;
	padding-top: 15px;
	margin-top: -5px;
	height: 60px;
	border-bottom-right-radius: 10px;
	background: linear-gradient(
		0deg,
		rgba(118, 99, 195, 0.21) 0%,
		rgba(118, 99, 195, 0) 100%
	);
`;

export const GalleryCardSkeleton = styled.div`
	background-color: #131b21;
	width: 193px;
	height: 300px;
	border-radius: 10px;
	animation: skeleton-loading 1s linear infinite alternate;

	@keyframes skeleton-loading {
		0% {
			background-color: rgba(19, 27, 33, 0.5);
		}

		100% {
			background-color: rgba(19, 27, 33, 0.95);
		}
	}
`;
