import LoaderReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/LoaderReducerStateInterface';
import {
	RESET_LOADER_STATE,
	SET_LOADER_SUBTITLE,
} from '../actions/types/loader';

const initialState = {
	loaderSubtitleText: ' ',
};

interface PayloadInterface {
	loaderSubtitleText: string;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const loaderReducer = (
	state = initialState,
	action: ActionInterface
): LoaderReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SET_LOADER_SUBTITLE:
			return {
				...state,
				loaderSubtitleText: payload.loaderSubtitleText,
			};
		case RESET_LOADER_STATE:
			return initialState;
		default:
			return state;
	}
};

export default loaderReducer;
