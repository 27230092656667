import React, { useState, useEffect } from 'react';
import { PageContainer } from '../../resources/styles/Page.styles';
import DiscoverContent from './components/DiscoverContent';
import { useDispatch } from 'react-redux';
import { setSelectedCollection } from '../../redux/actions/filter';
import { Collections } from '../../resources/enums/Collections';
import { ButtonStatus } from '../../resources/enums/ProfileButton';
import { useSelector } from 'react-redux';
import RootReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { fetchCollectionStats } from '../../firebase/functions/collections';
import placeholderCollectionStats from '../../resources/placeholders/placeHolderCollectionStats';
import PageHeaderContent from '../../components/PageHeaderContent';
import bannerDesktop1 from '../../resources/images/banner/bannerDesktop1.png';
import bannerDesktop2 from '../../resources/images/banner/bannerDesktop2.png';
import config from '../../config/index';
const DiscoverPage = (): JSX.Element => {
	const dispatch = useDispatch();

	const tabIndex = Object.values(Collections);
	const bannerDesktopIMGS = [bannerDesktop1, bannerDesktop2];
	const contractAddress = [
		config.SKVLLPVNKZ_CONTRACT_ADDRESS,
		config.SKVLLBABIEZ_CONTRACT_ADDRESS,
	];
	const [backgroundImage, setBackgroundImage] = useState(bannerDesktopIMGS[0]);
	const [headerAddress, setheaderAddress] = useState(contractAddress[0]);

	const handleClick = (active: number) => {
		dispatch(setSelectedCollection(tabIndex[active]));
		setBackgroundImage(bannerDesktopIMGS[active]);
		setheaderAddress(contractAddress[active]);
	};

	const { selectedCollection } = useSelector(
		(state: RootReducerStateInterface) => state.filter
	);

	const [collectionStats, setCollectionStats] = useState(
		placeholderCollectionStats
	);

	const user = useSelector(
		(state: RootReducerStateInterface) => state.auth.user
	);

	const [areStatsLoading, setAreStatsLoading] = useState(true);
	const fetchStats = async () => {
		setCollectionStats(await fetchCollectionStats(selectedCollection));
		setAreStatsLoading(false);
	};

	useEffect(() => {
		setAreStatsLoading(true);
		fetchStats();
	}, [selectedCollection]);

	const {
		totalVolume,
		totalOwners,
		valueIncreasePerc,
		valueIncrease,
		averagePrice,
		totalSupply,
		transactionCount,
		currentMarketCap,
	} = collectionStats;

	const statsData = {
		totalOwners: {
			title: 'Owners',
			value: totalOwners,
			ethIcon: true,
			toolTip: 'Current number of holders',
		},
		totalVolume: {
			title: 'Volume',
			value: totalVolume.toFixed(0),
			ethIcon: false,
			toolTip: 'The total volume traded on all platforms',
		},
		averagePrice: {
			title: 'Avg. Price',
			value: averagePrice.toFixed(2),
			ethIcon: true,
			toolTip: 'Average price for all time',
		},
		totalSupply: {
			title: 'Supply',
			value: totalSupply,
			ethIcon: false,
			toolTip: 'Current market supply',
		},
		transactionCount: {
			title: 'Trades',
			value: transactionCount,
			ethIcon: false,
			toolTip: 'Total number of sales and transfers',
		},
		currentMarketCap: {
			title: 'Market Cap',
			value: currentMarketCap.toFixed(0),
			ethIcon: true,
			toolTip: 'Market cap is the average price multiplied by the total supply',
		},
		valueIncrease: {
			title: 'Value Inc.',
			value: valueIncrease.toFixed(0),
			ethIcon: true,
			toolTip: 'The total value growth in Ethereum',
		},
		valueIncreasePerc: {
			title: 'Perc. Inc.',
			value: valueIncreasePerc.toFixed(0),
			ethIcon: false,
			toolTip: 'The total value growth as percentage',
		},
	};

	const pageHeader = {
		user: user,
		title: `${selectedCollection.replace('_', ' ')}`,
		subtitle: headerAddress,
		textAlign: 'center',
		textTransform: 'capitalize',
	};

	const Stats = {
		areStatsLoading,
		statsData,
	};

	const Tabs = {
		activeTab: ButtonStatus.FIRST_BUTTON,
		buttonParameters: Collections,
		handleContentChange: handleClick,
	};

	return (
		<PageContainer noBackgroundImage>
			<div>
				<PageHeaderContent
					pageHeader={pageHeader}
					stats={Stats}
					tabs={Tabs}
					backgroundImage={backgroundImage}
				/>
				<DiscoverContent showSearchFilter />
			</div>
		</PageContainer>
	);
};

export default DiscoverPage;
