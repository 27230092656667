import NFTImageInterface from '../../resources/interfaces/NFTImageInterface';
import GalleryReducerStateInterface from '../../resources/interfaces/ReducerInterfaces/GalleryReducerStateInterface';
import placeholderNFT from '../../resources/placeholders/placeholderNFT';
import {
	SET_COLLECTION_DATA,
	SET_IS_GALLERY_LOADING,
	SET_IS_LAST_ASSET,
	SET_IS_MODAL_OPEN,
	SET_NEW_START_AT,
	SET_SELECTED_NFT,
} from '../actions/types/gallery';

const initialState: GalleryReducerStateInterface = {
	isGalleryLoading: true,
	startAt: 0,
	isLastAsset: false,
	collectionData: [],
	isModalOpen: false,
	selectedNFT: placeholderNFT,
};

interface PayloadInterface {
	isGalleryLoading: boolean;
	newStartAt: number;
	isLastAsset: boolean;
	collectionData: NFTImageInterface[];
	isModalOpen: boolean;
	selectedNFT: NFTImageInterface;
}

interface ActionInterface {
	type: string;
	payload: PayloadInterface;
}

const galleryReducer = (
	state = initialState,
	action: ActionInterface
): GalleryReducerStateInterface => {
	const { type, payload } = action;
	switch (type) {
		case SET_IS_GALLERY_LOADING:
			return {
				...state,
				isGalleryLoading: payload.isGalleryLoading,
			};
		case SET_NEW_START_AT:
			return {
				...state,
				startAt: payload.newStartAt,
			};
		case SET_IS_LAST_ASSET:
			return {
				...state,
				isLastAsset: payload.isLastAsset,
			};
		case SET_COLLECTION_DATA:
			return {
				...state,
				collectionData: payload.collectionData,
			};
		case SET_IS_MODAL_OPEN:
			return {
				...state,
				isModalOpen: payload.isModalOpen,
			};
		case SET_SELECTED_NFT:
			return {
				...state,
				selectedNFT: payload.selectedNFT,
			};
		default:
			return state;
	}
};

export default galleryReducer;
