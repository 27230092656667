import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	calculateMillisecondDifference,
	convertMillisecondDifference,
} from '../../../../helpers/date';
import { floorToZero } from '../../../../helpers/math';
import {
	DAY_TO_MILLISECONDS,
	HOUR_TO_MILLISECONDS,
	MINUTE_TO_MILLISECONDS,
	SECOND_TO_MILLISECONDS,
} from '../../../../resources/constants/conversions';
import RootReducerStateInterface from '../../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import {
	CountdownContainer,
	DateContainer,
} from '../../../../resources/styles/RafflePage/CountdownTimers.styles';

interface PropsInterface {
	setIsDrawInProgressLocally: React.Dispatch<React.SetStateAction<boolean>>;
}

const CountdownTimer = (props: PropsInterface): JSX.Element => {
	const { setIsDrawInProgressLocally } = props;
	const currentLottery = useSelector(
		(state: RootReducerStateInterface) => state.lottery.currentLottery
	);
	const [timeRemaining, setTimeRemaining] = useState({
		days: '00',
		hours: '00',
		minutes: '00',
		seconds: '00',
	});

	const updateCountdownTimers = () => {
		if (currentLottery) {
			const millisecondDifference = calculateMillisecondDifference(
				currentLottery.endTime
			);

			if (millisecondDifference < 1) {
				setIsDrawInProgressLocally(true);
			}

			const secondsRemaining = floorToZero(
				convertMillisecondDifference(
					millisecondDifference,
					SECOND_TO_MILLISECONDS,
					60
				)
			);
			const minutesRemaining = floorToZero(
				convertMillisecondDifference(
					millisecondDifference,
					MINUTE_TO_MILLISECONDS,
					60
				)
			);
			const hoursRemaining = floorToZero(
				convertMillisecondDifference(
					millisecondDifference,
					HOUR_TO_MILLISECONDS,
					24
				)
			);
			const daysRemaining = floorToZero(
				convertMillisecondDifference(millisecondDifference, DAY_TO_MILLISECONDS)
			);

			setTimeRemaining({
				days: daysRemaining.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
				}),
				hours: hoursRemaining.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
				}),
				minutes: minutesRemaining.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
				}),
				seconds: secondsRemaining.toLocaleString('en-US', {
					minimumIntegerDigits: 2,
				}),
			});
		}
	};

	useEffect(() => {
		if (currentLottery?.isOpen) {
			updateCountdownTimers();
			const timer = setInterval(updateCountdownTimers, SECOND_TO_MILLISECONDS);
			return () => clearInterval(timer);
		}
	}, [currentLottery]);
	const { days, hours, minutes,seconds } = timeRemaining;
	return (
		<CountdownContainer>
			<DateContainer>
				<div>Days</div>
				{days}
			</DateContainer>
			<DateContainer>
				<div>Hrs</div>
				{hours}
			</DateContainer>
			<DateContainer>
				<div>Mins</div>
				{minutes}
			</DateContainer>
			<DateContainer>
				<div>Secs</div>
				{seconds}
			</DateContainer>
		</CountdownContainer>
	);
};

export default CountdownTimer;
