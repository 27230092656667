import React from 'react';
import Select from 'react-select';
import {
	FilterCollapse,
	FilterSection,
} from '../../../resources/styles/FilterPanel.styles';
import {
	PRIMARY_MAIN,
	SECONDARY_DARK_GRAY,
	TEXT_GREEN,
	TEXT_PURPLE,
} from '../../../resources/constants/colors';
import { OrderByKeys } from '../../../resources/enums/OrderByKeys';
import { FilterTypes } from '../../../resources/enums/FilterTypes';
import { useDispatch, useSelector } from 'react-redux';
import RootReducerStateInterface from '../../../resources/interfaces/ReducerInterfaces/RootReducerStateInterface';
import { Collapse } from 'reactstrap';
import {
	setFilterCollapseStates,
	setOrderByFilter,
} from '../../../redux/actions/filter';

interface Props {
	filterSectionIcon: (isCollapseOpen: boolean) => JSX.Element;
}
const OrderFilter = (props: Props): JSX.Element => {
	const { filterSectionIcon } = props;
	const filterCollapseStates = useSelector(
		(state: RootReducerStateInterface) => state.filter.filterCollapseStates
	);
	const isFilterOpen = filterCollapseStates[FilterTypes.ORDER].isOpen;
	const dispatch = useDispatch();

	const options = [
		{ label: 'Token ID', value: OrderByKeys.TOKEN },
		{ label: 'Rank', value: OrderByKeys.RANK },
		{ label: 'Ammo', value: OrderByKeys.REWARDS },
		{ label: 'Price', value: OrderByKeys.PRICE },
	];

	const customStyles = {
		control: () => ({
			display: 'flex',
			backgroundColor: 'transparent',
			borderWidth: '0px',
		}),
	};

	return (
		<>
			<FilterSection
				onClick={() => dispatch(setFilterCollapseStates(FilterTypes.ORDER))}
				active={isFilterOpen}
				paddingRight="20px"
			>
				Order By
				{filterSectionIcon(isFilterOpen)}
			</FilterSection>
			<FilterCollapse>
				<Collapse className="filter-collapse" isOpen={isFilterOpen}>
					<div className="select">
						<Select
							onChange={(event) =>
								dispatch(setOrderByFilter(event?.value || OrderByKeys.TOKEN))
							}
							options={options}
							components={{ IndicatorSeparator: () => null }}
							styles={customStyles}
							theme={(theme) => ({
								...theme,
								borderRadius: 0,
								border: 'none',
								colors: {
									...theme.colors,
									primary25: TEXT_GREEN,
									primary50: TEXT_PURPLE,
									primary75: TEXT_GREEN,
									primary: SECONDARY_DARK_GRAY,
									neutral0: PRIMARY_MAIN,
									neutral40: TEXT_GREEN,
									neutral5: PRIMARY_MAIN,
								},
							})}
						/>
					</div>
				</Collapse>
			</FilterCollapse>
		</>
	);
};

export default OrderFilter;
