import React from 'react';
import { NotesListItemsInterface } from '../../../resources/placeholders/releaseNotes';

interface PropsInterface {
	changes: NotesListItemsInterface;
}

const ChangedItems = (props: PropsInterface): JSX.Element => {
	const { changes } = props;
	return (
		<ul>
			{changes.description && <br></br>}
			{changes.items?.map((item) => {
				return <li key={item}>{item}</li>;
			})}
		</ul>
	);
};

export default ChangedItems;
