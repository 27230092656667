import React from 'react';
import { useDispatch } from 'react-redux';
import { setCheckOutState } from '../../../redux/actions/rewardsAndStaking';
import { TransactionState } from '../../../resources/enums/states';
import CustomButton from '../../../resources/styles/CustomButton.styles';
import { StakeProgressContainer } from '../../../resources/styles/DaycarePage/DaycareTab/DaycareTab.styles';
import {
	SubtitleDiv,
	TitleDiv,
} from '../../../resources/styles/WalletNotConnected.styles';
import Loader from '../../Loader';

interface PropsInterface {
	checkoutState: TransactionState;
}

const CheckoutProgress = (props: PropsInterface): JSX.Element => {
	const { checkoutState } = props;
	const dispatch = useDispatch();

	let title: string;
	let content: string;
	switch (checkoutState) {
		case TransactionState.SUCCESS:
			title = 'You successfully checked out your baby!';
			content =
				'Thank you for trusting Skvllpvnkz Daycare. We hope to see you again!';
			break;
		case TransactionState.FAILED:
			title = 'Uh oh, it seems that the transaction failed.';
			content =
				'The transaction failed. Please check Etherscan for details and contact the Devs on discord if you have any questions.';
			break;
		default:
			title = 'Waiting for you to get your baby...';
			content = 'Your baby is waiting patiently…';
	}

	const showOkButton = checkoutState !== TransactionState.IN_PROGRESS;
	const showSpinner = checkoutState === TransactionState.IN_PROGRESS;

	return (
		<StakeProgressContainer padding="0px">
			<div>
				<TitleDiv>{title}</TitleDiv>
				<SubtitleDiv>{content}</SubtitleDiv>
				{showOkButton && (
					<CustomButton
						onClick={() => {
							dispatch(setCheckOutState(TransactionState.DEFAULT));
						}}
						width="120px"
						height="40px"
						marginTop="15px"
						borderRadius="5px"
					>
						<b>Ok</b>
					</CustomButton>
				)}
				{showSpinner && <Loader color="text-secondary" marginTop="20px" />}
			</div>
		</StakeProgressContainer>
	);
};

export default CheckoutProgress;
