import React from 'react';
import { PageContainer } from '../../resources/styles/Page.styles';
import MintSkvllbabiezTab from './MintSkvllbabiezTab/MintSkvllbabiezTab';

const BreedingPage = (): JSX.Element => {
	return (
		<PageContainer>
			<MintSkvllbabiezTab />
		</PageContainer>
	);
};

export default BreedingPage;
